// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

export function toDDHHMMSS(total_seconds: number): string {
  const days = Math.floor(total_seconds / (3600 * 24));
  const hours = Math.floor((total_seconds - (days * 3600 * 24)) / 3600);
  const minutes = Math.floor((total_seconds - (days * 3600 * 24) - (hours * 3600)) / 60);
  const seconds = total_seconds - (days * 3600 * 24) - (hours * 3600) - (minutes * 60);

  let result = buildNextPart('', days, 'd');
  result = buildNextPart(result, hours, 'h');
  result = buildNextPart(result, minutes, 'm');
  result = buildNextPart(result, seconds, 's');

  return result;
}

export function toMegaBytes(bytes: number): string {
  return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
}

function buildNextPart(result: string, value: number, postfix: string): string {
  let valueStr = value.toString() + postfix;
  valueStr = result !== '' && value < 10 ?
    ('0' + valueStr) :
    (result === '' && value === 0 ? '' : valueStr);
  return result + valueStr;
}

export function dateToYYYYMMDD(d: Date): string {
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  const year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;

  if (day.length < 2)
    day = '0' + day;

  return `${year}-${month}-${day}`;
}

export function dateToYYYYMMDDHHMMSS(d: Date): string {
  let second = d.getSeconds().toString();
  let minute = d.getMinutes().toString();
  let hour = d.getHours().toString();
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  const year = d.getFullYear();

  if (second.length < 2)
    second = '0' + second;

  if (minute.length < 2)
    minute = '0' + minute;

  if (hour.length < 2)
    hour = '0' + hour;

  if (month.length < 2)
    month = '0' + month;

  if (day.length < 2)
    day = '0' + day;

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function YYYYMMDDtoDate(value: string): Date {
  const parts = value.split("-").map(p => +p);
  return new Date(parts[0], (parts[1]) - 1, parts[2]);
}