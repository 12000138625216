// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useState } from "react";
import type { Node } from "react";
import styles from "./PdfTemplateForm.module.css";
import { Button, ButtonGroup, Col, ControlLabel, Form, FormControl, FormGroup } from "react-bootstrap";
import type { PDFTemplate } from "../../rpc/model";

type PdfTemplateFormProps = {
  template: PDFTemplate,
  applyChanges: (template: PDFTemplate) => Promise<string>;
  close: () => void;
}

export default function PdfTemplateForm(props: PdfTemplateFormProps): Node {
  const [template, setTemplate] = useState<PDFTemplate>(props.template);
  const [dirty, setDirty] = useState<boolean>(false);
  const [titleValidation, setTitleValidation] = useState<string>("");
  const [templateValidation, setTemplateValidation] = useState<string>("");

  async function onSaveChangesClick() {
    if (!validate())
      return;

    const errors = await props.applyChanges(template);
    if (errors !== "")
      setTemplateValidation(errors);
    else
      props.close();
  }

  function onCancelClick() {
    props.close();
  }

  function onTitleChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setTemplate({ ...template, title: e.currentTarget.value });
    setTitleValidation("");
    setDirty(true);
  }

  function onTemplateChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setTemplate({ ...template, template: e.currentTarget.value });
    setTemplateValidation("");
    setDirty(true);
  }

  function validate(): boolean {
    let hasErrors = false;
    if (template.title === "") {
      setTitleValidation("The title shouldn't be empty");
      hasErrors = true;
    }
    if (template.template === "") {
      setTemplateValidation("The template shouldn't be empty");
      hasErrors = true;
    }
    return !hasErrors;
  }

  return <div className={styles.root}>
    <div className={styles.errors}>
      <div>{titleValidation}</div>
      <div>{templateValidation}</div>
    </div>
    <Form horizontal>
      <FormGroup controlId="title" validationState={titleValidation ? "error" : null}>
        <Col componentClass={ControlLabel} sm={3}>
          Title
        </Col>
        <Col sm={9}>
          <FormControl
            placeholder="title"
            value={template.title}
            onChange={onTitleChanged}
            autoFocus={true}
          />
        </Col>
      </FormGroup>
      <FormGroup controlId="template" validationState={templateValidation ? "error" : null}>
        <Col componentClass={ControlLabel} sm={3}>
          Template
        </Col>
        <Col sm={9}>
          <FormControl
            componentClass="textarea"
            value={template.template}
            onChange={onTemplateChanged}
          />
        </Col>
      </FormGroup>
    </Form>
    <ButtonGroup className={styles.buttons}>
      <Button bsStyle="info" onClick={onSaveChangesClick} disabled={!dirty}>
        Save Changes
      </Button>
      <Button bsStyle="warning" onClick={onCancelClick}>
        Cancel
      </Button>
    </ButtonGroup>
  </div>;
}
