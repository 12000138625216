// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React, { useEffect, useState } from "react";
import styles from "./SearchView.module.css";
import { SearchState } from "./SearchState";
import { observer } from "mobx-react-lite";
import type { User } from "../../rpc/model";
import Form from "react-bootstrap/lib/Form";
import Button from "react-bootstrap/lib/Button";
import { runInAction } from 'mobx';
import { FormControl, FormGroup } from 'react-bootstrap';
import FolderToRender from '../Folder/Folder';
import { apiEndpoint } from '../../utils/http';
import ReactTooltip from 'react-tooltip';

type SearchViewProps = {
  me: User,
  goto: (path: string) => void,
}

function SearchView(props: SearchViewProps): Node {
  const [state] = useState<SearchState>(() => new SearchState());

  useEffect(() => {
    return () => state.dispose();
  }, [state]);

  function onQueryChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    // $FlowIgnore[prop-missing]
    const value = e.target.value;
    runInAction(() => {
      state.query = value;
    });
  }

  function onFilterChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    // $FlowIgnore[prop-missing]
    const value = e.target.value;
    runInAction(() => {
      state.filter = value;
    });
  }

  return <div className={styles.root}>
    <div className={styles.header}>
      <Form inline onSubmit={e => {
        e.preventDefault();
        state.search();
      }}>
        <FormGroup controlId="query">
          <FormControl
            value={state.query}
            onChange={onQueryChanged}
            disabled={state.searching}
          />
          <FormControl componentClass="select" value={state.filter} onChange={onFilterChanged}>
            <option value="all">Folders and SDE Items</option>
            <option value="folders">Folders</option>
            <option value="sde_items">SDE Items</option>
          </FormControl>
        </FormGroup>
        <Button
          className={styles.searchButton}
          bsStyle="info"
          disabled={!state.query || state.searching}
          onClick={state.search}
        >
          {state.searching ? "Searching..." : "Search"}
        </Button>
      </Form>
    </div>
    <div>
      {state.folders.map((f, i) => <FolderToRender
        key={f.id}
        folder={f}
        folderIndex={i}
        onFolderNameClick={async () => {}}
        onClick={() => props.goto(`/folder/${f.virtualId}`)}
        user={props.me} />)}
    </div>
    <div className="col-xs-12"/>
    <div>
      {state.folderItems.map(item => {
        const tooltip = `Created: ${new Date(Date.parse(item.createdAt)).toLocaleString()}`;

        return <div key={item.id} id={"child-" + item.id} className='col-lg-4 col-sm-6 col-xs-12' onClick={() => props.goto(`/folder/${item.virtualId}`)}>
          <div className={styles.mediaCard}>
            <div className={styles.mediaImg} data-tip data-for={"tooltip-" + item.id}>
              <img src={apiEndpoint() + `/api/image/thumbnail/${item.thumbnailId}`} alt='thumbnail'
                onContextMenu={preventDefault} />
              {(item.mime.startsWith("video/") || item.mime.startsWith("audio/")) &&
                <img src={require("../../images/video.png")} alt='video' />}
              <ReactTooltip html={true} multiline={true} id={"tooltip-" + item.id} place="top" effect="solid"
                type="info" backgroundColor="#404040" offset={{ top: 0, left: 100 }}>
                {tooltip}
              </ReactTooltip>
            </div>

            <div className={styles.mediaInfo}>
              <h5 className='text-ellipsis'>
                {item.name}
              </h5>
            </div>
          </div>
        </div>;
      })}
    </div>
  </div>;
}

function preventDefault(e: SyntheticEvent<HTMLElement>) {
  e.preventDefault();
}

export default (observer(SearchView): typeof SearchView);
