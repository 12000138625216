// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { autorun, makeAutoObservable, runInAction } from "mobx";
import type { FolderNote } from "../../rpc/model";
import { apiEndpoint } from "../../utils/http";
import { FolderService } from "../../rpc/folders";
import { IReactionDisposer } from 'mobx';

export class FolderNotesState {
  folderId: string;
  notes: Array<FolderNote>;
  editingNote: string;
  editingNoteId: string | null;
  disposes: (typeof IReactionDisposer)[];

  constructor(folderId: string) {
    this.notes = [];
    this.editingNote = '';
    this.editingNoteId = null;
    this.folderId = folderId;
    this.disposes = [];

    makeAutoObservable(this, {
      disposes: false
    }, { autoBind: true });

    this.disposes.push(
      autorun(() => {
        this.fetchNotes(this.folderId);
      })
    );
  }

  dispose() {
    this.disposes.forEach(dispose => dispose());
  }

  async fetchNotes(folderId: string) {
    const resp = await new FolderService(apiEndpoint()).folderNotes({ folderId });
    runInAction(() => {
      if (folderId === this.folderId) {
        this.notes = resp.notes;
        this.editingNoteId = null;
        this.editingNote = '';
      }
    });
  }

  startEditNote(note: FolderNote) {
    this.editingNoteId = note.id;
    this.editingNote = note.note;
  }

  startAddNote() {
    this.editingNoteId = '';
    this.editingNote = '';
  }

  cancelEditNote() {
    this.editingNoteId = null;
    this.editingNote = '';
  }

  async addNote() {
    const folderId = this.folderId;
    if (this.editingNoteId == null || this.editingNoteId !== '') {
      return;
    }
    const resp = await new FolderService(apiEndpoint()).addFolderNote({ folderId, note: this.editingNote });
    runInAction(() => {
      if (folderId === this.folderId && resp.note != null) {
        this.notes.push(resp.note);
      }
      this.editingNoteId = null;
      this.editingNote = '';
    });
  }

  async editNote() {
    const noteId = this.editingNoteId;
    if (noteId == null || noteId === '') {
      return;
    }
    const note = this.editingNote;
    await new FolderService(apiEndpoint()).editFolderNote({ noteId, note });
    runInAction(() => {
      const n = this.notes.find(n => n.id === noteId);
      if (n != null) {
        n.note = note;
      }
      this.editingNoteId = null;
      this.editingNote = '';
    });
  }
}
