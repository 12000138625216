// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';
import ReactPlayer from "react-player";
import debounce from "lodash/debounce";
import findLast from "lodash/findLast";
import difference from "lodash/difference";
import union from "lodash/union";
import uuid4 from "uuid4";
import cx from "classnames";
import ImageView from "../image-view/ImageView";
import LabelList from "../label-list/LabelList";
import type { LiveLabel } from "../../model";
import { Features, isDetectionLabel, LabelTypes } from "../../model";
import styles from "./ImageEdit.module.css";
import { fromLiveLabel, getCommonNodeIndexes, getPointIndexes, toLiveLabel } from "../../utils/label";
import Modal from "react-modal";
import OrmsList from "../orms-list/OrmsList";
import PositionEdit from "../position-edit/PositionEdit";
import type { DetectEngine, Folder, FolderItem, FolderItemNote, LabelType, User } from "../../rpc/model";
import { LabelService } from "../../rpc/labels";
import { LabelTypeService } from "../../rpc/label_types";
import { ActivityService } from "../../rpc/activity";
import { FolderService } from "../../rpc/folders";
import { UserService } from "../../rpc/users";
import { DetectService } from "../../rpc/detect";
import { FolderItemService } from "../../rpc/folder_items";
import { getTabId } from "../../utils/tab";
import { apiEndpoint, apiGet } from "../../utils/http";
import FrameExtractor from './FrameExtractorView';
import FolderItemNotes from './FolderItemNotes';
import PdfViewer from './PdfViewer';
import AppState from '../../AppState';
import { observer } from 'mobx-react-lite';

type ImageEditProps = {
  appState: AppState,
  folder: Folder,
  image: FolderItem,
  onClose: (needReload: boolean) => Promise<void>,
  onImageTitleChanged: (imageId: string, newTitle: string) => void;
  onImagePositionChanged: (imageId: string, x: number, y: number, z: number, t: string) => void;
  onImagesAdded: (newImages: Array<FolderItem>) => void;
}

type EngineSelecting = {
  selectedEngineNames: Array<string>,
  enginesFilter?: (engine: DetectEngine) => boolean,
  onSelect: (engines: Array<DetectEngine>, parameterValues: { [string]: { [string]: string } }) => Promise<void>,
  selectTitle: string,
  onLater?: ?(engines: Array<DetectEngine>) => (void | Promise<void>),
  laterTitle?: string,
  onLaterEnabled?: (engines: Array<DetectEngine>) => boolean,
  selectPlusOne?: ?(engines: Array<DetectEngine>, parameterValues: { [string]: { [string]: string } }) => Promise<void>,
  showPendingCounters?: boolean,
}

type SaveImageLabelDebounced = {
  (item: FolderItem, label: LiveLabel, selectedLabelId: ?string, labelTypes: Array<LabelType>): void,
  flush: () => void,
  cancel: () => void,
  labelId: ?string,
};

type SaveItemNoteDebounced = {
  (item: FolderItem, note: FolderItemNote): void,
  flush: () => void,
  cancel: () => void,
  noteId: ?string,
};

const ormsEnginesStorageKey = "orms-engines";
const detectLaterStorageKey = "detect-later";

function updateLabelsStyle(labels: Array<LiveLabel>, selectedId: ?string, labelTypes: Array<LabelType>): Array<LiveLabel> {
  const regionStyle = {
    borderWidth: "4px",
  };
  return labels.map(label => {
    const labelType = labelTypes.find(lt => lt.id === label.labelTypeId);
    const labelColor = labelType != null && labelType.color !== "" ? labelType.color : "blue";

    const borderStyle = labelType != null && label.isRectangleOrLine !== false ? "solid" : "dashed";
    const labelRegionStyle = labelType != null && labelType.labelType !== LabelTypes.common ? {
      ...regionStyle,
      borderWidth: "0px"
    } : regionStyle;
    return label.id !== selectedId ?
      {
        ...label,
        data: { ...label.data, regionStyle: { ...labelRegionStyle, borderColor: labelColor, borderStyle } }
      } :
      {
        ...label,
        data: {
          ...label.data,
          regionStyle: {
            ...labelRegionStyle,
            borderColor: labelColor,
            borderStyle,
            background: "rgba(0, 255, 0, 0.2)"
          }
        }
      };
  });
}

// Image Editor (Image View + Label List).
function ImageEdit(props: ImageEditProps): Node {
  const changingLabelId = useRef<?string>(null);
  const saveImageLabelDebounced = useRef<SaveImageLabelDebounced>(debounce(_saveImageLabel, 500));
  const saveItemNoteDebounced = useRef<SaveItemNoteDebounced>(debounce(_saveItemNote, 500));
  const playerRef = useRef<typeof ReactPlayer>(null);
  const needReloadFolder = useRef<boolean>(false);

  const [item, setItem] = useState<FolderItem>(props.image);
  const [user, setUser] = useState<?User>(null);
  const [labelTypes, setLabelTypes] = useState<Array<LabelType>>([]);
  const [workingCopies, setWorkingCopies] = useState<Array<FolderItem>>([]);
  const [labels, setLabels] = useState<Array<LiveLabel>>([]);
  const [selectedLabelId, setSelectedLabelId] = useState<?string>(null);
  const [engineSelecting, setEngineSelecting] = useState<?EngineSelecting>(null);
  const [labelsVisibility, setLabelsVisibility] = useState<Array<string>>([]);
  const [itemData, setItemData] = useState<string>("");
  const [positionEditing, setPositionEditing] = useState<boolean>(false);
  const [positionX, setPositionX] = useState<number>(props.image.positionX);
  const [positionY, setPositionY] = useState<number>(props.image.positionY);
  const [positionZ, setPositionZ] = useState<number>(props.image.positionZ);
  const [positionT, setPositionT] = useState<string>(props.image.positionT);
  const [extractingFrames, setExtractingFrames] = useState<boolean>(false);
  const [notes, setNotes] = useState<Array<FolderItemNote>>([]);
  const [mapAppUrl, setMapAppUrl] = useState<string>('');
  const [mapAppTitle, setMapAppTitle] = useState<string>('');

  useEffect(() => {
    setPositionX(item.positionX);
    setPositionY(item.positionY);
    setPositionZ(item.positionZ);
    setPositionT(item.positionT);
  }, [item]);

  useEffect(() => {
    new ActivityService(apiEndpoint()).visitPage({
      pageUrl: window.location.pathname,
      innerBlock: `/item/${item.id}`,
    });
  }, [item.id]);

  useEffect(() => {
    async function fetchMe() {
      const resp = await new UserService(apiEndpoint()).me();
      setUser(resp.user);
    }

    fetchMe();
  }, []);

  useEffect(() => {
    async function fetchLabelTypes() {
      const resp = await new LabelTypeService(apiEndpoint()).get();
      setLabelTypes(resp.labelTypes);
    }

    fetchLabelTypes();
  }, []);

  useEffect(() => {
    async function fetchLabelVisibility() {
      const resp = await new LabelService(apiEndpoint()).visibility({ itemId: item.id });
      setLabelsVisibility(resp.labels);
    }

    fetchLabelVisibility();
  }, [item.id]);

  useEffect(() => {
    async function fetchWorkingCopies() {
      if (item.kind !== "$annotated")
        setWorkingCopies([]);
      else {
        const resp = await new FolderService(apiEndpoint()).workingCopies({ folderId: props.folder.parentId, sortBy: props.appState.sortFoldersBy });
        setWorkingCopies(resp.items);
      }
    }

    fetchWorkingCopies();
  }, [item.id, item.kind, props.folder.parentId, props.appState.sortFoldersBy]);

  useEffect(() => {
    async function fetchLabels() {
      if (labelTypes.length === 0)
        return;

      const resp = await new LabelService(apiEndpoint()).get({ itemId: item.id });
      let labels = resp.labels;
      labels = labels.map(label => toLiveLabel(label, item.width, item.height));
      labels = updateLabelsStyle(labels, null, labelTypes);

      setLabels(labels);
      setSelectedLabelId(null);
    }

    fetchLabels();
  }, [item.id, item.height, item.width, labelTypes]);

  useEffect(() => {
    async function fetchNotes() {
      const resp = await new FolderItemService(apiEndpoint()).itemNotes({ itemId: item.id });
      setNotes(resp.notes);
    }

    fetchNotes();
  }, [item.id]);

  useEffect(() => {
    async function fetchItemData() {
      if (!item.mime.startsWith("text/")) {
        setItemData("");
      } else {
        const response = await apiGet(`/api/image/${item.id}`);
        setItemData((response.data: string));
      }
    }

    fetchItemData();
  }, [item.id, item.mime]);

  useEffect(() => {
    async function fetchMapApp() {
      const resp = await new FolderItemService(apiEndpoint()).mapViewSettings({ itemId: item.id });
      setMapAppTitle(resp.appTitle);
      setMapAppUrl(resp.appUrl.replace('&kml=', `&kml=${encodeURIComponent(window.location.origin+apiEndpoint())}`));
    }

    setMapAppTitle('');
    setMapAppUrl('');
    if (item.mime === 'application/vnd.google-earth.kml+xml') {
      fetchMapApp();
    }
  }, [item.id, item.mime]);

  function saveImageLabel(item: FolderItem, label: LiveLabel) {
    if (saveImageLabelDebounced.current.labelId == null || saveImageLabelDebounced.current.labelId !== label.id) {
      saveImageLabelDebounced.current.flush();
      saveImageLabelDebounced.current.labelId = label.id;
    } else
      saveImageLabelDebounced.current.cancel();

    saveImageLabelDebounced.current(item, label, selectedLabelId, labelTypes);
  }

  async function _saveImageLabel(item: FolderItem, label: LiveLabel, selectedLabelId: ?string, labelTypes: Array<LabelType>) {
    saveImageLabelDebounced.current.labelId = null;
    const response = await new LabelService(apiEndpoint()).addOrEdit({
      itemId: item.id,
      label: fromLiveLabel(label, item.width, item.height)
    });
    if (response.label == null)
      return;

    const newLabel = toLiveLabel(response.label, item.width, item.height);
    const newSelectedLabelId = selectedLabelId != null ? selectedLabelId : newLabel.id;

    setLabels(labels => {
      const newLabels = labels.map(lbl => lbl.id === label.id ?
        {
          ...lbl,
          id: newLabel.id,
          itemId: newLabel.itemId,
          userId: newLabel.userId,
          my: newLabel.my,
          note: newLabel.note
        } :
        lbl);
      return updateLabelsStyle(newLabels, newSelectedLabelId, labelTypes);
    });
    setSelectedLabelId(newSelectedLabelId);
  }

  function saveItemNote(item: FolderItem, note: FolderItemNote) {
    if (saveItemNoteDebounced.current.noteId == null || saveItemNoteDebounced.current.noteId !== note.id) {
      saveItemNoteDebounced.current.flush();
      saveItemNoteDebounced.current.noteId = note.id;
    } else
      saveItemNoteDebounced.current.cancel();

    saveItemNoteDebounced.current(item, note);
  }

  async function _saveItemNote(item: FolderItem, note: FolderItemNote) {
    saveItemNoteDebounced.current.noteId = null;
    await new FolderItemService(apiEndpoint()).setItemNote({
      itemId: item.id,
      noteId: note.id,
      note: note.note,
      whomId: note.whom != null ? note.whom.id : '',
    });
  }

  async function saveImageLabelsVisibility(added: Array<string>, deleted: Array<string>) {
    await new LabelService(apiEndpoint()).editVisibility({ itemId: item.id, added: added, deleted: deleted });
  }

  async function onLabelsChanged(visibleLabels: Array<LiveLabel>, mode: 'rectangle' | 'arrow' | 'line', isMove: boolean, resizeDir: string) {
    let changingLabel = findLast(visibleLabels, lbl => lbl.new);
    if (changingLabel == null)
      changingLabel = findLast(visibleLabels, lbl => lbl.isChanging);

    if (changingLabel != null) {
      if (changingLabel.labelTypeId == null) {
        if (mode === 'arrow' || mode === 'line') {
          const labelType = labelTypes.find(t => t.labelType === mode);

          changingLabel = {
            ...changingLabel,
            labelTypeId: labelType != null ? labelType.id : labelTypes[0].id,
          };
        } else {
          const lastUsedLabelTypeIdStr = window.localStorage.getItem("last-used-label-type-id");
          const lastUsedLabelTypeId = lastUsedLabelTypeIdStr != null ? parseInt(lastUsedLabelTypeIdStr) : null;
          const lastUsedLabelType = labelTypes.filter(lt => lt.labelType === LabelTypes.common).find(t => t.id === lastUsedLabelTypeId);

          changingLabel = {
            ...changingLabel,
            labelTypeId: lastUsedLabelType != null ? lastUsedLabelType.id : labelTypes.filter(lt => lt.labelType === LabelTypes.common)[0].id,
          };
        }
      }

      if (changingLabel.id == null)
        changingLabel = { ...changingLabel, id: uuid4() };

      if (changingLabel.start == null)
        changingLabel = { ...changingLabel, start: { x: changingLabel.x, y: changingLabel.y } };
    }

    if (changingLabel != null && (changingLabel.my === false || changingLabel.isRectangleOrLine === false)) {
      changingLabelId.current = null;
      return;
    }

    if (changingLabel != null) {
      const labelType = labelTypes.find(t => t.id === changingLabel.labelTypeId);

      const points1 = [
        { x: changingLabel.prevX, y: changingLabel.prevY },
        { x: changingLabel.prevX + changingLabel.prevWidth, y: changingLabel.prevY },
        { x: changingLabel.prevX + changingLabel.prevWidth, y: changingLabel.prevY + changingLabel.prevHeight },
        { x: changingLabel.prevX, y: changingLabel.prevY + changingLabel.prevHeight },
      ];

      const points2 = [
        { x: changingLabel.x, y: changingLabel.y },
        { x: changingLabel.x + changingLabel.width, y: changingLabel.y },
        { x: changingLabel.x + changingLabel.width, y: changingLabel.y + changingLabel.height },
        { x: changingLabel.x, y: changingLabel.y + changingLabel.height },
      ];

      changingLabel = {
        ...changingLabel,
        prevX: changingLabel.x,
        prevY: changingLabel.y,
        prevWidth: changingLabel.width,
        prevHeight: changingLabel.height,
      };

      if (labelType != null && (labelType.labelType === 'arrow' || labelType.labelType === 'line')) {
        if (!isMove && !resizeDir) {
          if (changingLabel.x === changingLabel.start.x && changingLabel.y === changingLabel.start.y) {
            changingLabel = {
              ...changingLabel,
              points: [
                { x: changingLabel.start.x, y: changingLabel.start.y },
                { x: changingLabel.start.x + changingLabel.width, y: changingLabel.start.y + changingLabel.height },
              ]
            };
          } else if (changingLabel.x === changingLabel.start.x && changingLabel.y < changingLabel.start.y) {
            changingLabel = {
              ...changingLabel,
              points: [
                { x: changingLabel.start.x, y: changingLabel.start.y },
                { x: changingLabel.start.x + changingLabel.width, y: changingLabel.start.y - changingLabel.height },
              ]
            };
          } else if (changingLabel.x < changingLabel.start.x && changingLabel.y === changingLabel.start.y) {
            changingLabel = {
              ...changingLabel,
              points: [
                { x: changingLabel.start.x, y: changingLabel.start.y },
                { x: changingLabel.start.x - changingLabel.width, y: changingLabel.start.y + changingLabel.height },
              ]
            };
          } else if (changingLabel.x < changingLabel.start.x && changingLabel.y < changingLabel.start.y) {
            changingLabel = {
              ...changingLabel,
              points: [
                { x: changingLabel.start.x, y: changingLabel.start.y },
                { x: changingLabel.start.x - changingLabel.width, y: changingLabel.start.y - changingLabel.height },
              ]
            };
          }
        } else if (!isMove) {
          const [index1, index2] = getCommonNodeIndexes(points1, points2);

          const t1 = [
            [0, 1, 2, 3],
            [1, 0, 3, 4],
            [2, 3, 0, 1],
            [3, 4, 1, 0],
          ];

          const t2 = [
            [0, 1, 2, 3],
            [1, 0, 3, 2],
            [2, 1, 0, 3],
            [3, 2, 1, 0],
            [0, 3, 2, 1],
          ];

          const moves = t2[t1[index1][index2]];

          const [i1, i2] = getPointIndexes(changingLabel.points[0], changingLabel.points[1]);
          changingLabel = {
            ...changingLabel,
            points: [
              points2[moves[i1]],
              points2[moves[i2]],
            ]
          };
        } else {
          const [i1, i2] = getPointIndexes(changingLabel.points[0], changingLabel.points[1]);
          changingLabel = {
            ...changingLabel,
            points: [
              points2[i1],
              points2[i2],
            ]
          };
        }
      } else {
        changingLabel = {
          ...changingLabel,
          points: points2,
        };
      }
    }

    if (changingLabel != null) {
      changingLabelId.current = changingLabel.id;
      let newLabels = labels.some(lbl => lbl.id === changingLabel.id) ?
        labels.map(lbl => lbl.id === changingLabel.id ? changingLabel : lbl) :
        [...labels, changingLabel];
      newLabels = updateLabelsStyle(newLabels, changingLabel.id, labelTypes);
      setLabels(newLabels);
      setSelectedLabelId(changingLabel.id);
    } else if (changingLabelId.current != null) {
      let changingLabel = visibleLabels.find(lbl => lbl.id === changingLabelId.current);
      changingLabelId.current = null;
      if (changingLabel != null) {
        if (changingLabel.width === 0 || changingLabel.height === 0) {
          let newLabels = labels.filter(lbl => changingLabel == null || lbl.id !== changingLabel.id);
          const newSelectedLabelId = labels.length > 0 ? labels[labels.length - 1].id : null;
          newLabels = updateLabelsStyle(newLabels, newSelectedLabelId, labelTypes);
          setLabels(newLabels);
          setSelectedLabelId(setSelectedLabelId);
        } else {
          changingLabel = { ...changingLabel, isChanging: false, new: false };
          const newLabels = labels.map(lbl => changingLabel != null && lbl.id === changingLabel.id ? changingLabel : lbl);
          setLabels(newLabels);
          saveImageLabel(item, changingLabel);
        }
      }
    }
  }

  function onSelectionChanged(selectedId: string) {
    setLabels(labels => {
      return updateLabelsStyle(labels, selectedId, labelTypes);
    });
    setSelectedLabelId(selectedId);

    if (item.mime.startsWith("video/")) {
      const selectedLabel = labels.find(lbl => lbl.id === selectedId);
      if (selectedLabel != null) {
        playerRef.current.seekTo(selectedLabel.startPosition, "seconds");
      }
    }
  }

  async function onLabelTypeChanged(labelId: string, labelTypeId: number) {
    window.localStorage.setItem("last-used-label-type-id", labelTypeId);
    const label = labels.find(lbl => lbl.id === labelId);
    if (label != null) {
      const newLabel = { ...label, labelTypeId: labelTypeId };
      let newLabels = labels.map(lbl => lbl.id === newLabel.id ? newLabel : lbl);
      newLabels = updateLabelsStyle(newLabels, selectedLabelId, labelTypes);
      setLabels(newLabels);

      saveImageLabel(item, newLabel);
    }
  }

  async function onLabelNoteChanged(labelId: string, note: string) {
    const label = labels.find(lbl => lbl.id === labelId);
    if (label != null) {
      const newLabel = { ...label, note: note };
      let newLabels = labels.map(lbl => lbl.id === newLabel.id ? newLabel : lbl);
      newLabels = updateLabelsStyle(newLabels, selectedLabelId, labelTypes);
      setLabels(newLabels);

      saveImageLabel(item, newLabel);
    }
  }

  async function onMyItemNoteChanged(noteText: string) {
    if (user == null) {
      return;
    }

    const note = notes.find(n => n.user != null && n.user.id === user.id && n.whom == null);
    if (note != null) {
      const newNote = { ...note, note: noteText };
      const newNotes = notes.map(n => n.id === newNote.id ? newNote : n);
      setNotes(newNotes);
      saveItemNote(item, newNote);
    } else {
      const newNote = {
        id: uuid4(),
        itemId: props.image.id,
        user: { ...user },
        note: noteText,
        whom: null,
        createdAt: '',
        updatedAt: '',
      };
      const newNotes = [...notes, newNote];
      setNotes(newNotes);
      saveItemNote(item, newNote);
    }
  }

  async function onWhomItemNoteChanged(whom: User, noteText: string) {
    if (user == null) {
      return;
    }

    const note = notes.find(n => n.user != null && n.user.id === user.id && n.whom != null && n.whom.id === whom.id);
    if (note != null) {
      const newNote = { ...note, note: noteText };
      const newNotes = notes.map(n => n.id === newNote.id ? newNote : n);
      setNotes(newNotes);
      saveItemNote(item, newNote);
    } else {
      const newNote = {
        id: uuid4(),
        itemId: props.image.id,
        user: { ...user },
        note: noteText,
        whom: { ...whom },
        createdAt: '',
        updatedAt: '',
      };
      const newNotes = [...notes, newNote];
      setNotes(newNotes);
      saveItemNote(item, newNote);
    }
  }

  async function onClassificationLabelAdded(labelType: LabelType) {
    const newLabel = {
      id: uuid4(),
      userId: null,
      itemId: item.id,
      my: true,
      labelTypeId: labelType.id,
      note: '',
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      prevX: 0,
      prevY: 0,
      prevWidth: 0,
      prevHeight: 0,
      startFrame: 0,
      endFrame: 0,
      startPosition: 0,
      endPosition: 0,
      additionalInfo: "",
      points: [],
      isRectangleOrLine: false,
      new: false,
      isChanging: false,
      data: {},
      start: undefined,
    };
    let newLabels = [...labels, newLabel];
    newLabels = updateLabelsStyle(newLabels, selectedLabelId, labelTypes);
    setLabels(newLabels);

    saveImageLabel(item, newLabel);
  }

  async function onLabelsClear() {
    await new LabelService(apiEndpoint()).deleteAll({ itemId: item.id });
    setLabels(labels.filter(lbl => lbl.my === false));
    setSelectedLabelId(null);
  }

  async function onLabelDeleted(labelId: string) {
    await new LabelService(apiEndpoint()).delete({ itemId: item.id, labelId: labelId });
    setLabels(labels.filter(lbl => lbl.id !== labelId));
    setSelectedLabelId(null);
  }

  async function onLabelCrop(labelId: string) {
    if (item.mime.startsWith("image/"))
      await cropLabel(labelId);
    else if (item.mime.startsWith("video/"))
      await clipLabel(labelId);
  }

  async function cropLabel(labelId: string) {
    const label = labels.find(lbl => lbl.id === labelId);
    if (label == null)
      return;
    const labelType = labelTypes.find(lt => lt.id === label.labelTypeId);

    const promptMessage = `You are going to create a cropped child image.\nThe cropped image title:`;

    let title = window.prompt(promptMessage, label.note);
    if (title == null)
      return;

    title = title.trim();
    const shortLabelId = label.id != null ? label.id.substr(0, 3) : "";
    const labelIdentifier = labelType != null ?
      (labelType.labelType === LabelTypes.common ? `${labelType.name}-${shortLabelId}` : labelType.name) :
      shortLabelId;

    const croppedTitle = title !== "" ? `${title} (${labelIdentifier})` : labelIdentifier;
    const response = await new LabelService(apiEndpoint()).crop({
      itemId: item.id,
      labelId: labelId,
      title: croppedTitle
    });
    const croppedImage = response.item;
    if (croppedImage == null)
      return;

    if (props.image.id === item.id)
      props.onImagesAdded([croppedImage]);

    if (title !== label.note && title !== "")
      await onLabelNoteChanged(labelId, title);
  }

  async function clipLabel(labelId: string) {
    if (props.image.id === item.id) {
      needReloadFolder.current = true;
    }
    await new FolderItemService(apiEndpoint()).extractVideoLabelFrames({
      itemId: item.id,
      labelId: labelId,
      tabId: getTabId(),
    });
  }

  async function onLabelVisibilityChanged(labelId: string) {
    const index = labels.findIndex(lbl => lbl.id === labelId);
    if (index < 0)
      return;

    const i = labelsVisibility.indexOf(labelId);
    const newLabelsVisibility = i >= 0 ? [...labelsVisibility.slice(0, i), ...labelsVisibility.slice(i + 1)] : [...labelsVisibility, labelId];
    await saveImageLabelsVisibility(i < 0 ? [labelId] : [], i >= 0 ? [labelId] : []);
    setLabelsVisibility(newLabelsVisibility);
  }

  async function onUserVisibilityChanged(user: User, visible: boolean) {
    let newLabelsVisibility;

    if (user.me)
      newLabelsVisibility = visible ?
        difference(labelsVisibility, labels.filter(lbl => lbl.my !== false).map(lbl => lbl.id)) :
        union(labelsVisibility, labels.filter(lbl => lbl.my !== false).map(lbl => lbl.id));
    else
      newLabelsVisibility = visible ?
        union(labelsVisibility, labels.filter(lbl => lbl.my === false && lbl.userId === user.id).map(lbl => lbl.id)) :
        difference(labelsVisibility, labels.filter(lbl => lbl.my === false && lbl.userId === user.id).map(lbl => lbl.id));

    await saveImageLabelsVisibility(difference(newLabelsVisibility, labelsVisibility), difference(labelsVisibility, newLabelsVisibility));
    setLabelsVisibility(newLabelsVisibility);
  }

  async function onDetectLabelsClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const detectLaterItemId = window.sessionStorage.getItem(detectLaterStorageKey);
    setEngineSelecting({
      selectedEngineNames: JSON.parse(window.localStorage.getItem(ormsEnginesStorageKey)) || [],
      onSelect: autoDetect,
      selectTitle: "Detect",
      onLater: (engines: Array<DetectEngine>) => {
        window.sessionStorage.setItem(detectLaterStorageKey, item.id);
      },
      selectPlusOne: (!detectLaterItemId || detectLaterItemId === item.id) ? null : autoDetectPlusOne,
      showPendingCounters: true,
    });
  }

  async function onRetrainClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const resp = await new FolderService(apiEndpoint()).checkRetrainFolder({ folderId: props.folder.id });
    if (!resp.workingCopyHasLabels) {
      window.alert("The working copy has no labels");
      return;
    }
    const retrainFolderExists = resp.retrainFolderExists;

    setEngineSelecting({
      selectedEngineNames: item.kind.startsWith("$") ? [] : [item.kind],
      enginesFilter: engine => engine.retrainServerUrl != null && engine.retrainServerUrl !== "",
      onSelect: retrain,
      selectTitle: "Retrain",
      onLater: retrainFolderExists ? async (engines: Array<DetectEngine>) => {
        for (let i = 0; i < engines.length; i++) {
          await new FolderService(apiEndpoint()).copyItemsToRetrainFolder({
            sourceFolderId: item.folderId,
            items: [item.id],
            engineName: engines[i].name,
          });
        }
      } : null,
      laterTitle: "Offline",
      onLaterEnabled: (engines: Array<DetectEngine>) => engines.length > 0,
    });
  }

  function onExtractFramesClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setExtractingFrames(true);
  }

  function closeExtractFrames() {
    setExtractingFrames(false);
  }

  async function autoDetect(engines: Array<DetectEngine>, parameterValues: { [string]: { [string]: string } }) {
    await autoDetectItems(engines, [item.id], parameterValues);
  }

  async function autoDetectPlusOne(engines: Array<DetectEngine>, parameterValues: { [string]: { [string]: string } }) {
    const detectLaterItemId = window.sessionStorage.getItem(detectLaterStorageKey);
    await autoDetectItems(engines, [item.id, detectLaterItemId], parameterValues);
    window.sessionStorage.removeItem(detectLaterStorageKey);
  }

  async function autoDetectItems(engines: Array<DetectEngine>, itemIds: Array<string>, parameterValues: { [string]: { [string]: string } }) {
    const engineNames = engines.map(e => e.name);
    window.localStorage.setItem(ormsEnginesStorageKey, JSON.stringify(engineNames));
    setEngineSelecting(null);
    await new ActivityService(apiEndpoint()).action({ pageUrl: window.location.pathname, name: "auto-detect" });
    await new DetectService(apiEndpoint()).detectLabels({
      itemIds: itemIds,
      engines: engineNames.map(name => {
        const engineParameters = parameterValues[name];
        const parameters = [];
        if (engineParameters != null) {
          for (const pName in engineParameters) {
            if (engineParameters.hasOwnProperty(pName))
              parameters.push({ name: pName, value: engineParameters[pName] });
          }
        }
        return {
          name: name,
          parameters: parameters,
        };
      }),
      tabId: getTabId(),
    });
  }

  async function retrain(engines: Array<DetectEngine>, parameterValues: { [string]: { [string]: string } }) {
    setEngineSelecting(null);

    await new DetectService(apiEndpoint()).retrainLabels({
      folders: [item.folderId],
      engines: engines.map(e => e.name),
      tabId: getTabId(),
    });
  }

  function onEnginesSelectedCancel() {
    setEngineSelecting(null);
  }

  async function onEditTitleClick(e: SyntheticMouseEvent<HTMLElement>) {
    e.stopPropagation();
    e.preventDefault();

    const newTitle = window.prompt("Input new image title", item.title);
    if (newTitle == null)
      return;

    await new FolderItemService(apiEndpoint()).setItemTitle({ itemId: item.id, title: newTitle });

    if (props.image.id === item.id)
      props.onImageTitleChanged(item.id, newTitle);

    setItem(item => ({ ...item, title: newTitle }));
  }

  async function onEditPositionClick(e: SyntheticMouseEvent<HTMLElement>) {
    e.stopPropagation();
    e.preventDefault();

    setPositionEditing(true);
  }

  async function onEditingPositionApply(x: number, y: number, z: number, t: string) {
    await new FolderItemService(apiEndpoint()).setItemPosition({ itemId: item.id, position: { x, y, z, t } });

    if (props.image.id === item.id)
      props.onImagePositionChanged(item.id, x, y, z, t);

    setPositionEditing(false);
    setPositionX(x);
    setPositionY(y);
    setPositionZ(z);
    setPositionT(t);
  }

  function onEditingPositionCancel() {
    setPositionEditing(false);
  }

  function onClose() {
    props.onClose(needReloadFolder.current);
  }

  function renderImageView() {
    if (item.mime.startsWith("image/")) {
      const visibleLabels = labels.filter(lbl => lbl.my !== false ? labelsVisibility.indexOf(lbl.id) < 0 : labelsVisibility.indexOf(lbl.id) >= 0);

      return <div className={styles.images}>
        <ImageView
          image={item}
          labels={visibleLabels.filter(isDetectionLabel)}
          labelTypes={labelTypes}
          onLabelsChanged={item.kind === "$original" ? null : onLabelsChanged}
          onLabelTypeChanged={onLabelTypeChanged} />
      </div>;
    }

    if (item.mime.startsWith("video/") || item.mime.startsWith("audio/"))
      return <ReactPlayer
        ref={playerRef}
        url={apiEndpoint() + `/api/image/${item.id}`}
        controls={true}
        width='50%'
        height={item.mime.startsWith("video/") ? undefined : "100px"} />;

    if (item.mime.startsWith("text/")) {
      return <div className={styles.text}>
        {itemData}
      </div>;
    }

    if (item.mime === "application/pdf") {
      return <PdfViewer item={item} />;
    }

    if (item.mime === "application/vnd.google-earth.kml+xml" && mapAppUrl !== '') {
      return <div>
        <a href={mapAppUrl} target='_blank' rel="noopener noreferrer">Open in {mapAppTitle}</a>
      </div>;
    }

    return null;
  }

  async function onWorkingCopyNavigate(e: SyntheticMouseEvent<HTMLButtonElement>, step: number) {
    e.preventDefault();

    const index = workingCopies.findIndex(it => it.id === item.id);
    let newIndex = index + step;
    if (newIndex < 0)
      newIndex = workingCopies.length + newIndex;
    newIndex = newIndex % workingCopies.length;

    if (newIndex !== index) {
      setWorkingCopies([...workingCopies.slice(0, index), item, ...workingCopies.slice(index + 1)]);
      setItem(workingCopies[newIndex]);
    }
  }

  let formats: Array<string> = [];
  if (item.mime.startsWith("image/"))
    formats = ["image"];
  else if (item.mime.startsWith("video/"))
    formats = ["video"];
  else if (item.mime.startsWith("audio/"))
    formats = ["audio"];
  else if (item.mime === "text/csv")
    formats = ["csv"];
  else if (item.mime === "application/vnd.google-earth.kml+xml")
    formats = ["kml"];
  else if (item.mime === "application/pdf")
    formats = ["pdf"];

  const imagesPanel = renderImageView();

  const downloadLink = user != null && user.features.indexOf(Features.downloadFiles) >= 0 ?
    <a className={styles.action} href={apiEndpoint() + `/api/image/${item.id}/download`} download>Download</a> :
    null;

  const autoDetectLink = !item.kind.startsWith("$") || (!item.mime.startsWith("image/") && !item.mime.startsWith("video/") && !item.mime.startsWith("audio/")
    && item.mime !== "text/csv" && item.mime !== 'application/vnd.google-earth.kml+xml' && item.mime !== "application/pdf") ?
    null :
    <a className={styles.action} href='/' onClick={onDetectLabelsClick}>
      Auto Detect
    </a>;

  const retrainLink = (item.kind === "$original" || item.kind === "$annotated") && (item.mime.startsWith("image/") || item.mime.startsWith("video/") || item.mime.startsWith("audio/")) ?
    <a className={styles.action} href='/' onClick={onRetrainClick}>
      Retrain
    </a> : null;

  const extractFramesLink = item.mime.startsWith("video/") &&
    <a className={styles.action} href='/' onClick={onExtractFramesClick}>
      Extract frames
    </a>;

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "50%",
      width: "50%",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "95vh"
    }
  };

  const title = <span>
    <span className={styles.titleCaption}>Title</span> (<a href='/' onClick={onEditTitleClick}>edit</a>): {item.title !== "" ? item.title : "unspecified"}
  </span>;

  const xyzPosition = positionX === 0 && positionY === 0 && positionZ === 0 ?
    "unspecified" :
    `(${positionX}, ${positionY}, ${positionZ})`;

  const position = <span>
    <span className={styles.titleCaption}>Position</span> (<a href='/' onClick={onEditPositionClick}>edit</a>): {xyzPosition} {positionT}
  </span>;

  return (
    <div className={cx(styles.root, { [styles.textRoot]: item.mime.startsWith("text/") })}>
      <div className={styles.editor}>
        <div className={styles.header}>
          <button onClick={onClose}>Close</button>
          {downloadLink} {autoDetectLink} {retrainLink} {extractFramesLink}
        </div>
        <div className={styles.header}>
          {item.name}
        </div>
        <div className={styles.title}>
          <div>{title}</div>
          <div>
            {position}
            {workingCopies.length > 1 && <div className={styles.workingCopy}>
              <a className={styles.workingCopyLink} href="/"
                onClick={e => onWorkingCopyNavigate(e, -1)}>Prev Working Copy
              </a>
              <a className={styles.workingCopyLink} href="/"
                onClick={e => onWorkingCopyNavigate(e, 1)}>Next Working Copy
              </a>
            </div>}
          </div>
        </div>
        {imagesPanel}
      </div>
      {
        item.kind !== "$original" &&
        <Tabs defaultActiveKey='1' className={styles.rightPane}>
          {(item.mime.startsWith("image/") || item.mime.startsWith("video/") || item.mime.startsWith("audio/") || labels.length > 0) &&
          <Tab eventKey='1' title='Labels'>
            <LabelList
              className={styles.labelList}
              isVideo={item.mime.startsWith("video/")}
              imageWidth={item.width}
              imageHeight={item.height}
              labels={labels}
              notes={notes.filter(n => n.whom == null)}
              selectedLabelId={selectedLabelId}
              labelTypes={labelTypes}
              labelsVisibility={labelsVisibility}
              onSelectionChanged={onSelectionChanged}
              onLabelTypeChanged={onLabelTypeChanged}
              onLabelNoteChanged={onLabelNoteChanged}
              onLabelVisibilityChanged={onLabelVisibilityChanged}
              onUserVisibilityChanged={onUserVisibilityChanged}
              onClassificationLabelAdded={onClassificationLabelAdded}
              onDelete={onLabelDeleted}
              onLabelCrop={onLabelCrop}
              onClear={onLabelsClear}
              onItemNoteChanged={onMyItemNoteChanged}
            />
          </Tab>
          }
          {user && <Tab eventKey='2' title='Notes'>
            <FolderItemNotes
              item={props.image}
              me={user}
              notes={notes.filter(n => n.whom != null)}
              onItemNoteChanged={onWhomItemNoteChanged}
            />
          </Tab>}
        </Tabs>
      }
      <Modal
        isOpen={engineSelecting != null}
        style={modalStyle}
      >
        {engineSelecting != null && <OrmsList
          formats={formats}
          showParameters={engineSelecting.enginesFilter == null}
          selectedEngines={engineSelecting.selectedEngineNames}
          enginesFilter={engineSelecting.enginesFilter}
          onEnginesSelected={engineSelecting.onSelect}
          onEnginesSelectedCancel={onEnginesSelectedCancel}
          selectTitle={engineSelecting.selectTitle}
          onLater={engineSelecting.onLater}
          laterTitle={engineSelecting.laterTitle}
          onLaterEnabled={engineSelecting.onLaterEnabled}
          selectPlusOne={engineSelecting.selectPlusOne}
          showPendingCounters={engineSelecting.showPendingCounters ?? false}
        />}
      </Modal>
      {positionEditing && <Modal isOpen={positionEditing} style={modalStyle}>
        <PositionEdit x={positionX} y={positionY} z={positionZ} t={positionT}
          applyChanges={onEditingPositionApply}
          cancel={onEditingPositionCancel}
        />
      </Modal>}
      {extractingFrames && <Modal isOpen={extractingFrames} style={modalStyle} ariaHideApp={false}>
        <FrameExtractor videoId={props.image.id} currentPosition={playerRef.current.getCurrentTime()} close={closeExtractFrames} />
      </Modal>}
    </div>
  );
}

export default (observer(ImageEdit): typeof ImageEdit);