// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React from 'react';
import type { Node } from "react";
import type { ArrowOrientation } from '../../model';

const PathStyle = {
  fill: 'none'
};

type LineProps = {
  width: number,
  height: number,
  orientation: ArrowOrientation,
  lineWidth: number,
  color: string,
}

export default function Line(props: LineProps): Node {
  const { width, height, lineWidth, orientation, color } = props;

  const viewBox = `0 0 ${width} ${height}`;

  let path = [];
  switch (orientation) {
    case 'n':
      path = [`M ${width / 2} ${height}`, `L ${width / 2} 0`];
      break;
    case 'ne':
      path = [`M 0 ${height}`, `L ${width} 0`];
      break;
    case 'e':
      path = [`M 0 ${height / 2}`, `L ${width} ${height / 2}`];
      break;
    case 'se':
      path = [`M 0 0`, `L ${width} ${height}`];
      break;
    case 's':
      path = [`M ${width / 2} 0`, `L ${width / 2} ${height}`];
      break;
    case 'sw':
      path = [`M ${width} 0`, `L 0 ${height}`];
      break;
    case 'w':
      path = [`M ${width} ${height / 2}`, `L 0 ${height / 2}`];
      break;
    case 'nw':
      path = [`M ${width} ${height}`, `L 0 0`];
      break;
    default:
      break;
  }

  let strokeWidth = lineWidth;
  switch (orientation) {
    case 'n':
    case 'e':
    case 'w':
    case 's':
      strokeWidth--;
      break;
    default:
  }

  const lineStyle = {
    ...PathStyle,
    stroke: color,
    strokeWidth: strokeWidth,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      style={{ overflow: 'visible' }}
    >
      <path
        style={lineStyle}
        d={path.join(' ')}
      />
    </svg>
  );
}
