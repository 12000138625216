// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React from "react";
import type { Node } from "react";
import styles from "./HeaderLinks.module.css";
import { Nav, NavItem } from "react-bootstrap";
import type AppState from '../../AppState';
import { observer } from 'mobx-react-lite';

type Props = {
  appState: AppState,
  showHelpDialog: () => void,
}

function HeaderLinks(props: Props): Node {
  function supportButton() {
    if (props.appState.me !== null) {
      return (
        <NavItem className={styles.support} eventKey={3} onClick={props.showHelpDialog}>
          <i className="pe-7s-help1" /><span>Help</span>
        </NavItem>
      );
    } else {
      return null;
    }
  }

  function logoutButton() {
    if (props.appState.me != null) {
      return (
        <NavItem className={styles.support} eventKey={4} href="/oauth/logout" onClick={props.appState.logout}>
          <i className="pe-7s-power" /><span>Logout</span>
        </NavItem>
      );
    } else {
      return null;
    }
  }

  return (
    <div>
      <Nav pullRight>
        <div className={styles.rightButtons}>
          {props.appState.me != null && <div><a href={`/user/${props.appState.me.id}`}>
            {props.appState.me.displayName}
            {props.appState.unreadAlertCount > 0 && <span className={styles.unreadAlertCount}>{props.appState.unreadAlertCount}</span>}</a></div>}
          {supportButton()}
          {logoutButton()}
        </div>
      </Nav>
    </div>
  );
}

export default (observer(HeaderLinks): typeof HeaderLinks);
