// @flow strict
// Copyright (C) 2018-2021 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { makeAutoObservable, runInAction } from "mobx";
import { apiEndpoint } from '../../utils/http';
import { FolderItemService } from '../../rpc/folder_items';
import { toMegaBytes } from '../../utils/convert';
import { getTabId } from '../../utils/tab';

export class FrameExtractorState {
  videoId: string;
  currentPosition: ?number;
  extractAllFrames: boolean;
  extractCurrentFrame: boolean;
  extractFrameRange: boolean;
  startPosition: number;
  endPosition: number;
  maxPosition: number;
  fps: number;
  fpsItems: Array<number>;
  waiting: boolean;

  constructor(videoId: string, currentPosition: ?number) {
    this.videoId = videoId;
    this.currentPosition = currentPosition;
    this.extractAllFrames = false;
    this.extractCurrentFrame = false;
    this.extractFrameRange = false;
    this.startPosition = 0;
    this.endPosition = 1;
    this.maxPosition = 1;
    this.fps = 1;
    this.fpsItems = [this.fps];
    this.waiting = false;
    makeAutoObservable(this, {}, { autoBind: true });
    this.fetchVideoInfo();
  }

  setExtractAllFrames() {
    this.extractAllFrames = true;
    this.extractCurrentFrame = false;
    this.extractFrameRange = false;
  }

  setExtractCurrentFrame() {
    if (this.currentPosition == null)
      return;

    this.extractAllFrames = false;
    this.extractCurrentFrame = true;
    this.extractFrameRange = false;
  }

  setExtractFrameRange() {
    this.extractAllFrames = false;
    this.extractCurrentFrame = false;
    this.extractFrameRange = true;
  }

  setFrameRange(start: number, end: number) {
    this.startPosition = start;
    this.endPosition = end;
  }

  setFPS(fps: number) {
    this.fps = fps;
  }

  async fetchVideoInfo() {
    const resp = await new FolderItemService(apiEndpoint()).videoInfo({ itemId: this.videoId });
    runInAction(() => {
      this.endPosition = resp.duration;
      this.maxPosition = resp.duration;

      const fpsItems = [Math.ceil(resp.fps)];
      if (resp.fps !== Math.ceil(resp.fps)) {
        fpsItems.unshift(resp.fps);
      }
      for (let i = Math.ceil(resp.fps)-1; i >= 1; i--)
        fpsItems.push(i);
      fpsItems.push(0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1);
      this.fpsItems = fpsItems;
      this.fps = resp.fps;
    });
  }

  async extractFrames(): Promise<boolean> {
    if (this.extractCurrentFrame) {
      const currentPosition = this.currentPosition;
      if (currentPosition != null) {
        await new FolderItemService(apiEndpoint()).extractVideoFrame({
          itemId: this.videoId,
          position: currentPosition,
          tabId: getTabId(),
        });
      }
      return true;
    }

    this.waiting = true;
    try {
      const resp = await new FolderItemService(apiEndpoint()).estimateVideoExtraction({
        itemId: this.videoId,
        startPosition: this.startPosition,
        endPosition: this.endPosition < this.maxPosition ? this.endPosition : 0,
        fps: this.fps,
      });
      const availableSize = resp.availableSize;
      const estimatedSize = resp.estimatedSize;
      const frameCount = resp.frameCount;
      if (availableSize >= 0 && estimatedSize > availableSize * 0.8) {
        window.alert(`Estimated storage size for ${frameCount} extracted images is too large: ${toMegaBytes(estimatedSize)}\nAvailable size: ${toMegaBytes(availableSize)}`);
        return false;
      }

      if (estimatedSize > 10 * 1024 * 1024 || (availableSize >= 0 && estimatedSize > availableSize / 5)) {
        let msg = `Estimated storage size for ${frameCount} extracted images: ${toMegaBytes(estimatedSize)}`;
        if (availableSize >= 0) {
          msg += `\nAvailable size: ${toMegaBytes(availableSize)}`;
        }
        if (!window.confirm(`${msg}\nContinue?`)) {
          return false;
        }
      }

      await new FolderItemService(apiEndpoint()).extractVideoFrames({
        itemId: this.videoId,
        tabId: getTabId(),
        startPosition: this.startPosition,
        endPosition: this.endPosition < this.maxPosition ? this.endPosition : 0,
        fps: this.fps < this.fpsItems[0] ? this.fps : 0,
      });

      return true;
    } finally {
      runInAction(() => {
        this.waiting = false;
      });
    }
  }
}