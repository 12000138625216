// @flow strict
/* eslint-disable */
import { sendTwirpRequest } from "./twirp";
import type { LabelType, LabelTypeJSON } from "./model";
import { LabelTypeToJSON, JSONToLabelType } from "./model";
export type LabelTypeGetRequest = {
}

export type LabelTypeGetRequestJSON = {
}

export function LabelTypeGetRequestToJSON(m: LabelTypeGetRequest): LabelTypeGetRequestJSON {
  return {
  };
}

export function JSONToLabelTypeGetRequest(m: LabelTypeGetRequestJSON): LabelTypeGetRequest {
  return {
  };
}

export type LabelTypeGetResponse = {
  labelTypes: Array<LabelType>;
}

export type LabelTypeGetResponseJSON = {
  label_types?: Array<LabelTypeJSON>;
}

export function LabelTypeGetResponseToJSON(m: LabelTypeGetResponse): LabelTypeGetResponseJSON {
  return {
    label_types: m.labelTypes.map(LabelTypeToJSON),
  };
}

export function JSONToLabelTypeGetResponse(m: LabelTypeGetResponseJSON): LabelTypeGetResponse {
  return {
    labelTypes: m.label_types != null ? m.label_types.map(JSONToLabelType) : [],
  };
}

export type LabelTypeAddOrEditRequest = {
  labelType: ?LabelType;
}

export type LabelTypeAddOrEditRequestJSON = {
  label_type?: LabelTypeJSON;
}

export function LabelTypeAddOrEditRequestToJSON(m: LabelTypeAddOrEditRequest): LabelTypeAddOrEditRequestJSON {
  return {
    label_type: m.labelType != null ? LabelTypeToJSON(m.labelType) : undefined,
  };
}

export function JSONToLabelTypeAddOrEditRequest(m: LabelTypeAddOrEditRequestJSON): LabelTypeAddOrEditRequest {
  return {
    labelType: m.label_type != null ? JSONToLabelType(m.label_type) : null,
  };
}

export type LabelTypeAddOrEditResponse = {
  labelType: ?LabelType;
}

export type LabelTypeAddOrEditResponseJSON = {
  label_type?: LabelTypeJSON;
}

export function LabelTypeAddOrEditResponseToJSON(m: LabelTypeAddOrEditResponse): LabelTypeAddOrEditResponseJSON {
  return {
    label_type: m.labelType != null ? LabelTypeToJSON(m.labelType) : undefined,
  };
}

export function JSONToLabelTypeAddOrEditResponse(m: LabelTypeAddOrEditResponseJSON): LabelTypeAddOrEditResponse {
  return {
    labelType: m.label_type != null ? JSONToLabelType(m.label_type) : null,
  };
}

export type LabelTypeDeleteRequest = {
  labelTypeId: number;
}

export type LabelTypeDeleteRequestJSON = {
  label_type_id?: number;
}

export function LabelTypeDeleteRequestToJSON(m: LabelTypeDeleteRequest): LabelTypeDeleteRequestJSON {
  return {
    label_type_id: m.labelTypeId,
  };
}

export function JSONToLabelTypeDeleteRequest(m: LabelTypeDeleteRequestJSON): LabelTypeDeleteRequest {
  return {
    labelTypeId: m.label_type_id != null ? m.label_type_id : 0,
  };
}

export type LabelTypeDeleteResponse = {
}

export type LabelTypeDeleteResponseJSON = {
}

export function LabelTypeDeleteResponseToJSON(m: LabelTypeDeleteResponse): LabelTypeDeleteResponseJSON {
  return {
  };
}

export function JSONToLabelTypeDeleteResponse(m: LabelTypeDeleteResponseJSON): LabelTypeDeleteResponse {
  return {
  };
}

export type LabelTypeUsageRequest = {
  labelTypeId: number;
}

export type LabelTypeUsageRequestJSON = {
  label_type_id?: number;
}

export function LabelTypeUsageRequestToJSON(m: LabelTypeUsageRequest): LabelTypeUsageRequestJSON {
  return {
    label_type_id: m.labelTypeId,
  };
}

export function JSONToLabelTypeUsageRequest(m: LabelTypeUsageRequestJSON): LabelTypeUsageRequest {
  return {
    labelTypeId: m.label_type_id != null ? m.label_type_id : 0,
  };
}

export type LabelTypeUsageResponse = {
  labelCount: number;
  itemCount: number;
}

export type LabelTypeUsageResponseJSON = {
  label_count?: number;
  item_count?: number;
}

export function LabelTypeUsageResponseToJSON(m: LabelTypeUsageResponse): LabelTypeUsageResponseJSON {
  return {
    label_count: m.labelCount,
    item_count: m.itemCount,
  };
}

export function JSONToLabelTypeUsageResponse(m: LabelTypeUsageResponseJSON): LabelTypeUsageResponse {
  return {
    labelCount: m.label_count != null ? m.label_count : 0,
    itemCount: m.item_count != null ? m.item_count : 0,
  };
}

export type LabelTypeRenameRequest = {
  labelTypeId: number;
  newName: string;
}

export type LabelTypeRenameRequestJSON = {
  label_type_id?: number;
  new_name?: string;
}

export function LabelTypeRenameRequestToJSON(m: LabelTypeRenameRequest): LabelTypeRenameRequestJSON {
  return {
    label_type_id: m.labelTypeId,
    new_name: m.newName,
  };
}

export function JSONToLabelTypeRenameRequest(m: LabelTypeRenameRequestJSON): LabelTypeRenameRequest {
  return {
    labelTypeId: m.label_type_id != null ? m.label_type_id : 0,
    newName: m.new_name != null ? m.new_name : "",
  };
}

export type LabelTypeRenameResponse = {
}

export type LabelTypeRenameResponseJSON = {
}

export function LabelTypeRenameResponseToJSON(m: LabelTypeRenameResponse): LabelTypeRenameResponseJSON {
  return {
  };
}

export function JSONToLabelTypeRenameResponse(m: LabelTypeRenameResponseJSON): LabelTypeRenameResponse {
  return {
  };
}

export type LabelTypeSortItemsRequest = {
  sortedItems: Array<number>;
}

export type LabelTypeSortItemsRequestJSON = {
  sorted_items?: Array<number>;
}

export function LabelTypeSortItemsRequestToJSON(m: LabelTypeSortItemsRequest): LabelTypeSortItemsRequestJSON {
  return {
    sorted_items: m.sortedItems,
  };
}

export function JSONToLabelTypeSortItemsRequest(m: LabelTypeSortItemsRequestJSON): LabelTypeSortItemsRequest {
  return {
    sortedItems: m.sorted_items != null ? m.sorted_items : [],
  };
}

export type LabelTypeSortItemsResponse = {
}

export type LabelTypeSortItemsResponseJSON = {
}

export function LabelTypeSortItemsResponseToJSON(m: LabelTypeSortItemsResponse): LabelTypeSortItemsResponseJSON {
  return {
  };
}

export function JSONToLabelTypeSortItemsResponse(m: LabelTypeSortItemsResponseJSON): LabelTypeSortItemsResponse {
  return {
  };
}

export type UserLabelTypeGetRequest = {
  userId: string;
}

export type UserLabelTypeGetRequestJSON = {
  user_id?: string;
}

export function UserLabelTypeGetRequestToJSON(m: UserLabelTypeGetRequest): UserLabelTypeGetRequestJSON {
  return {
    user_id: m.userId,
  };
}

export function JSONToUserLabelTypeGetRequest(m: UserLabelTypeGetRequestJSON): UserLabelTypeGetRequest {
  return {
    userId: m.user_id != null ? m.user_id : "",
  };
}

export type UserLabelTypeGetResponse = {
  labelTypes: Array<LabelType>;
}

export type UserLabelTypeGetResponseJSON = {
  label_types?: Array<LabelTypeJSON>;
}

export function UserLabelTypeGetResponseToJSON(m: UserLabelTypeGetResponse): UserLabelTypeGetResponseJSON {
  return {
    label_types: m.labelTypes.map(LabelTypeToJSON),
  };
}

export function JSONToUserLabelTypeGetResponse(m: UserLabelTypeGetResponseJSON): UserLabelTypeGetResponse {
  return {
    labelTypes: m.label_types != null ? m.label_types.map(JSONToLabelType) : [],
  };
}

export type UserLabelTypeSetRequest = {
  userId: string;
  labelTypes: Array<LabelType>;
}

export type UserLabelTypeSetRequestJSON = {
  user_id?: string;
  label_types?: Array<LabelTypeJSON>;
}

export function UserLabelTypeSetRequestToJSON(m: UserLabelTypeSetRequest): UserLabelTypeSetRequestJSON {
  return {
    user_id: m.userId,
    label_types: m.labelTypes.map(LabelTypeToJSON),
  };
}

export function JSONToUserLabelTypeSetRequest(m: UserLabelTypeSetRequestJSON): UserLabelTypeSetRequest {
  return {
    userId: m.user_id != null ? m.user_id : "",
    labelTypes: m.label_types != null ? m.label_types.map(JSONToLabelType) : [],
  };
}

export type UserLabelTypeSetResponse = {
}

export type UserLabelTypeSetResponseJSON = {
}

export function UserLabelTypeSetResponseToJSON(m: UserLabelTypeSetResponse): UserLabelTypeSetResponseJSON {
  return {
  };
}

export function JSONToUserLabelTypeSetResponse(m: UserLabelTypeSetResponseJSON): UserLabelTypeSetResponse {
  return {
  };
}

export class LabelTypeService {
  hostname: string;
  pathPrefix: string = "/rpc.LabelTypeService/";

  constructor(hostname: string) {
    this.hostname = hostname;
  }

  async get(): Promise<LabelTypeGetResponse> {
    const url = this.hostname + this.pathPrefix + "Get";
    const data = await sendTwirpRequest(url, {});
    return JSONToLabelTypeGetResponse(data);
  }

  async addOrEdit(labelTypeAddOrEditRequest: LabelTypeAddOrEditRequest): Promise<LabelTypeAddOrEditResponse> {
    const url = this.hostname + this.pathPrefix + "AddOrEdit";
    const body: LabelTypeAddOrEditRequestJSON = LabelTypeAddOrEditRequestToJSON(labelTypeAddOrEditRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToLabelTypeAddOrEditResponse(data);
  }

  async delete(labelTypeDeleteRequest: LabelTypeDeleteRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "Delete";
    const body: LabelTypeDeleteRequestJSON = LabelTypeDeleteRequestToJSON(labelTypeDeleteRequest);
    await sendTwirpRequest(url, body);
  }

  async usage(labelTypeUsageRequest: LabelTypeUsageRequest): Promise<LabelTypeUsageResponse> {
    const url = this.hostname + this.pathPrefix + "Usage";
    const body: LabelTypeUsageRequestJSON = LabelTypeUsageRequestToJSON(labelTypeUsageRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToLabelTypeUsageResponse(data);
  }

  async rename(labelTypeRenameRequest: LabelTypeRenameRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "Rename";
    const body: LabelTypeRenameRequestJSON = LabelTypeRenameRequestToJSON(labelTypeRenameRequest);
    await sendTwirpRequest(url, body);
  }

  async sortItems(labelTypeSortItemsRequest: LabelTypeSortItemsRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "SortItems";
    const body: LabelTypeSortItemsRequestJSON = LabelTypeSortItemsRequestToJSON(labelTypeSortItemsRequest);
    await sendTwirpRequest(url, body);
  }

  async userGet(userLabelTypeGetRequest: UserLabelTypeGetRequest): Promise<UserLabelTypeGetResponse> {
    const url = this.hostname + this.pathPrefix + "UserGet";
    const body: UserLabelTypeGetRequestJSON = UserLabelTypeGetRequestToJSON(userLabelTypeGetRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToUserLabelTypeGetResponse(data);
  }

  async userSet(userLabelTypeSetRequest: UserLabelTypeSetRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "UserSet";
    const body: UserLabelTypeSetRequestJSON = UserLabelTypeSetRequestToJSON(userLabelTypeSetRequest);
    await sendTwirpRequest(url, body);
  }
}
