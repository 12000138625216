// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useRef, useState } from 'react';
import type { Node } from "react";
import uuid4 from 'uuid4';
import styles from './StreamView.module.css';
import Button from '../Button/Button';
import type { DetectEngine, Folder, FolderItem } from "../../rpc/model";
import { DetectService } from "../../rpc/detect";
import EngineParameters from "../orms-list/EngineParameters";
import { ActivityService } from "../../rpc/activity";
import Loader from "react-loader-spinner";
import { apiDelete, apiEndpoint, apiPost } from "../../utils/http";
import { getTabId } from "../../utils/tab";

type StreamViewProps = {
  folder: Folder,
  item: FolderItem,
  onClose: (needReload: boolean) => Promise<void>,
}

type StreamInfo = {
  duration: number,
  frame_count: number,
  fps: number,
  labels: Array<{ label: string, count: number, percentage: number }>,
  live_labels: Array<{ label: string, additional_info: string }>,
}

const DETECT_SERVER_NOT_REACHABLE_STATE = "Not Reachable";

export default function StreamView(props: StreamViewProps): Node {
  const [engines, setEngines] = useState<Array<DetectEngine>>([]);
  const [streamEngine, setStreamEngine] = useState<?string>(null);
  const [streamId, setStreamId] = useState<?string>(null);
  const [streamURL, setStreamURL] = useState<?string>(null);
  const [streamInfo, setStreamInfo] = useState<?StreamInfo>(null);
  const [hasRecentDetections, setHasRecentDetections] = useState<boolean>(false);
  const [parameterValues, setParameterValues] = useState<{ [string]: { [string]: string } }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [paused, setPaused] = useState<boolean>(false);
  const emptyStreamData = useRef<boolean>(true);

  useEffect(() => {
    async function fetchEnginesAndParameters() {
      const enginesResponse = await new DetectService(apiEndpoint()).visibleEngines({ withVersions: true });
      const engines = enginesResponse.engines.filter(e => e.supportedFormats.indexOf('stream') >= 0);

      const parameterValues: { [string]: { [string]: string } } = {};
      engines.forEach(e => {
        const values: { [string]: string } = {};
        const storedValues = JSON.parse(window.localStorage.getItem(`orms.${e.name}.parameters`)) || {};
        e.parameters
          .filter(p => p.supportedFormats.length === 0 || p.supportedFormats.indexOf("stream") >= 0)
          .forEach(p => values[p.name] = storedValues[p.name] != null ? storedValues[p.name] : p.defaultValue);

        parameterValues[e.name] = values;
      });

      setEngines(engines);
      setParameterValues(parameterValues);
    }

    fetchEnginesAndParameters();
  }, []);

  useEffect(() => {
    return () => {
      if (streamURL != null)
        URL.revokeObjectURL(streamURL);
    };
  }, [streamURL]);

  useEffect(() => {
    return () => {
      if (streamId != null)
        apiDelete(`/api/detect/stream/${props.item.id}/${streamId}`);
    };
  }, [props.item.id, streamId]);

  async function onStartClick(engine: DetectEngine) {
    await new ActivityService(apiEndpoint()).action({ pageUrl: window.location.pathname, name: 'stream-start' });

    const streamId = uuid4();
    setStreamId(streamId);
    setStreamEngine(engine.name);
    setStreamURL(null);
    setHasRecentDetections(false);
    setLoading(true);
    setPaused(false);
    emptyStreamData.current = true;

    const queryParams: Array<string> = [
      `engine=${encodeURIComponent(engine.name)}`,
      `tabId=${encodeURIComponent(getTabId())}`,
    ];
    const paramValues = parameterValues[engine.name];
    if (paramValues != null) {
      for (const name in paramValues) {
        if (paramValues.hasOwnProperty(name))
          queryParams.push(`${encodeURIComponent(name)}=${encodeURIComponent(paramValues[name])}`);
      }
    }

    fetch(apiEndpoint() + `/api/detect/stream/${props.item.id}/${streamId}?${queryParams.join("&")}`, { credentials: 'include' }).then(function (response) {
      const body = response.body;
      if (body == null)
        return;

      const reader = body.getReader();

      let buffer: ?Uint8Array = null;
      let size: ?number = null;
      let isImage = true;

      function go() {
        reader.read().then(function (result: { done: boolean, value: ?Uint8Array }) {
          setLoading(false);

          if (result.done) {
            if (emptyStreamData.current) {
              window.alert("Error Occurred - Please recheck your URL");
            }

            return;
          }

          const resultValue = result.value || new Uint8Array(0);

          if (buffer != null) {
            const newBuffer = new Uint8Array(buffer.length + resultValue.length);
            newBuffer.set(buffer);
            newBuffer.set(resultValue, buffer.length);
            buffer = newBuffer;
          } else {
            buffer = resultValue;
          }

          while (buffer != null) {
            if (size == null) {
              if (buffer.length < 4)
                break;

              size = ((buffer[0] * 256 + buffer[1]) * 256 + buffer[2]) * 256 + buffer[3];
              buffer = buffer.subarray(4);
            }

            if (buffer.length < size)
              break;

            if (size > 0) {
              if (isImage) {
                const blob = new Blob([buffer.subarray(0, size)]);
                setStreamURL(URL.createObjectURL(blob));
                emptyStreamData.current = false;
              } else {
                const info = new TextDecoder("utf-8").decode(buffer.subarray(0, size));
                const streamInfo = JSON.parse(info);
                setStreamInfo(streamInfo);
                setHasRecentDetections(hasRecentDetections => hasRecentDetections || (streamInfo.live_labels.length > 0));
              }
            }
            isImage = !isImage;

            if (buffer.length > size) {
              buffer = buffer.slice(size);
            } else {
              buffer = null;
            }
            size = null;
          }

          go();
        });
      }

      go();
    });
  }

  async function onStopClick() {
    emptyStreamData.current = false;
    setStreamId(null);
    setStreamEngine(null);
    setLoading(false);
    setPaused(false);
  }

  async function onPauseClick() {
    if (streamId == null)
      return;

    await apiPost(`/api/detect/stream/pause/${props.item.id}/${streamId}`);
    const newPaused = !paused;
    setPaused(newPaused);
    if (!newPaused)
      setHasRecentDetections(false);
  }

  async function onCloseClick() {
    emptyStreamData.current = false;
    setStreamId(null);
    setStreamEngine(null);
    props.onClose(true);
  }

  function onDetectAlertClick() {
    setHasRecentDetections(false);
  }

  return <div className={styles.root}>
    <div className={styles.header}>
      <button onClick={onCloseClick}>Close</button>
      {props.item.name}
    </div>

    <div className={styles.buttons}>
      {engines.map(e => (
        <div key={e.name} className={styles.button}>
          <Button onClick={streamId == null ? () => onStartClick(e) : onStopClick}
            disabled = {e.version === DETECT_SERVER_NOT_REACHABLE_STATE}
            hidden={streamId != null && e.name !== streamEngine}>
            {streamId == null ?
              `Start Streaming (${e.name})` :
              (e.name === streamEngine ? `Stop Streaming (${e.name})` : e.name)}
          </Button>
          {(streamId == null || e.name === streamEngine) && <EngineParameters
            engine={e}
            formats={["stream"]}
            disabled={streamId != null}
            parameterValues={parameterValues[e.name]}
            onParameterValueChanged={(p, value) => {
              const storedValues = JSON.parse(window.localStorage.getItem(`orms.${e.name}.parameters`)) || {};
              storedValues[p.name] = value;
              window.localStorage.setItem(`orms.${e.name}.parameters`, JSON.stringify(storedValues));

              setParameterValues({
                ...parameterValues,
                [e.name]: {
                  ...parameterValues[e.name],
                  [p.name]: value
                }
              });
            }}
          />}
        </div>
      ))}
      {streamId != null && !loading && <div key="pause" className={styles.button}>
        <Button onClick={onPauseClick}>
          {paused ? "Resume" : "Pause"}
        </Button>
      </div>}
    </div>
    {loading ?
      <div className={styles.loadingImage}>
        <Loader type='Oval' color='green' height={40} width={40} />
        Stream Loading
      </div> :
      <img alt='stream' className={streamURL != null ? styles.image : styles.emptyImage}
        src={streamURL != null ?
          streamURL :
          (apiEndpoint() + `/api/image/thumbnail/${props.item.id}`)} />}
    {streamInfo != null && <div className={styles.info}>
      <div>Duration: {streamInfo.duration} sec</div>
      <div>Frame Count: {streamInfo.frame_count}</div>
      <div>FPS: {streamInfo.fps.toFixed(1)}</div>
      <div className={styles.infoAlert}>
        <span className={hasRecentDetections ? styles.infoAlertError : styles.infoAlertInfo}
          onClick={onDetectAlertClick}>
          Detection
        </span>
      </div>
      <div className={styles.infoLabels}>
        {streamInfo.labels.map(lbl => <div key={lbl.label}>{lbl.label}: {lbl.count} ({lbl.percentage}%)</div>)}
      </div>
      <div className={styles.infoLiveLabels}>
        {streamInfo.live_labels.map(lbl => <div key={lbl.label} className={styles.infoLiveLabel}>
          <span className={styles.infoLiveLabelName}>{lbl.label}</span>
          {lbl.additional_info !== "" &&
          <span className={styles.infoLiveLabelAdditionalInfo}>{lbl.additional_info}</span>}
        </div>)}
      </div>
    </div>}
  </div>;
}
