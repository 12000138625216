// @flow strict

import { IReactionDisposer, makeAutoObservable, runInAction } from "mobx";
import type { User } from './rpc/model';
import { UserService } from './rpc/users';
import { apiEndpoint } from './utils/http';

export default class AppState {
  me: ?User;
  unreadAlertCount: number;
  unreadAlertsInterval: ?IntervalID;
  sortFoldersBy: string;
  disposes: (typeof IReactionDisposer)[];

  constructor() {
    this.me = undefined;
    this.unreadAlertCount = 0;
    let sortFoldersBy = window.localStorage.getItem("folders.sortBy");
    if (sortFoldersBy == null) {
      if (window.localStorage.getItem("folders.sortByActivity") === 'true') {
        sortFoldersBy = '>activity';
      }
    }
    this.sortFoldersBy = sortFoldersBy;
    this.disposes = [];

    makeAutoObservable(this, {
      unreadAlertsInterval: false,
      disposes: false,
    }, { autoBind: true });

    this.fetchMe();
    this.unreadAlertsInterval = setInterval(this.fetchUnreadAdminAlertCount, 60000);
  }

  dispose() {
    if (this.unreadAlertsInterval) {
      clearInterval(this.unreadAlertsInterval);
    }
    this.disposes.forEach(dispose => dispose());
  }

  async fetchMe() {
    const resp = await new UserService(apiEndpoint()).me();
    runInAction(() => {
        this.me = resp.user;
        this.fetchUnreadAdminAlertCount();
    });
  }

  async fetchUnreadAdminAlertCount() {
    const resp = await new UserService(apiEndpoint()).unreadAdminAlertCount();
    runInAction(() => {
        this.unreadAlertCount = resp.count;
    });
  }

  logout() {
    this.me = null;
    if (this.unreadAlertsInterval) {
      clearInterval(this.unreadAlertsInterval);
    }
  }

  setSortBy(sortBy: string) {
    this.sortFoldersBy =sortBy;
    window.localStorage.setItem("folders.sortBy", sortBy);
  }
}
