// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React from 'react';
import type { Node } from "react";
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";
import styles from './EngineParameters.module.css';
import type { DetectEngine, DetectEngineParameter } from "../../rpc/model";
import ToggleButton from "react-toggle-button";

type Props = {
  engine: DetectEngine,
  formats: Array<string>,
  disabled?: boolean,
  parameterValues: ?{ [string]: string },
  onParameterValueChanged: (p: DetectEngineParameter, value: string) => void,
};

const floatRe = /^(((\d){0,3}(\.(\d){0,2})?)|(1000(\.0{0,2})?))$/;

export default function EngineParameters(props: Props): Node {
  function changePercentageValue(p: DetectEngineParameter, value: number) {
    if (props.disabled)
      return;

    props.onParameterValueChanged(p, value.toString());
  }

  function changeBooleanValue(p: DetectEngineParameter, value: string) {
    if (props.disabled)
      return;

    props.onParameterValueChanged(p, value === "true" ? "false" : "true");
  }

  function changeFloatValue(p: DetectEngineParameter, value: string) {
    if (props.disabled)
      return;

    if (floatRe.test(value))
      props.onParameterValueChanged(p, value);
  }

  function editor(p: DetectEngineParameter) {
    const value = props.parameterValues != null && props.parameterValues[p.name] != null ? props.parameterValues[p.name] : p.defaultValue;
    if (p.parameterType === "percentage") {
      return <div className={styles.percentage}>
        <div className={styles.percentageRange}>
          <InputRange
            value={+value}
            minValue={0}
            maxValue={100}
            step={1}
            disabled={props.disabled}
            onChange={value => changePercentageValue(p, value)}
          />
        </div>
        <span className={styles.percentageValue}>{+value}%</span>
      </div>;
    }

    if (p.parameterType === "boolean") {
      return <div>
        <ToggleButton
          value={value === "true"}
          onToggle={() => changeBooleanValue(p, value)} />
      </div>;
    }

    if (p.parameterType === "float") {
      return <div className={styles.float}>
        <input
          type='text'
          value={value}
          onChange={e => changeFloatValue(p, e.target.value)} />
      </div>;
    }

    return null;
  }

  return <div className={styles.root}>
    {props.engine.parameters
      .filter(p => p.supportedFormats.length === 0 || p.supportedFormats.some(f => props.formats.indexOf(f) >= 0))
      .map(p => <div key={p.name} className={styles.parameter}>
        <span className={styles.name}>{p.title}</span>
        {editor(p)}
      </div>)}
  </div>;
}
