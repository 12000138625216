// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import styles from './FolderList.module.css';
import FolderToRender from '../Folder/Folder';
import TextEditor from '../text-editor/TextEditor';
import Button from '../Button/Button';
import Modal from 'react-modal';
import { Features, FolderKinds } from "../../model";
import type { Folder, User } from "../../rpc/model";
import { FolderService } from "../../rpc/folders";
import { ActivityService } from "../../rpc/activity";
import { MenuItem } from "react-bootstrap";
import DropdownButton from "../Button/DropdownButton";
import FolderSelector from "../folder-selector/FolderSelector";
import CustomDropZone from "../CustomDropZone/CustomDropZone";
import Loader from "react-loader-spinner";
import { Line } from "rc-progress";
import { apiEndpoint, apiPost } from "../../utils/http";
import type { FolderKind } from "../../model";
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import sortBy from 'lodash/sortBy';
import type AppState from '../../AppState';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

type FolderListProps = {
  history: {
    push: (path: string) => void,
  },
  appState: AppState,
  getFolders: () => Promise<void>,
  showPrivateFolders: boolean,
  showSharedFolders: boolean,
  match?: {
    params: {
      id: string
    }
  },
}

type CopiedFolder = {
  folderId: string,
  kind: string,
}

const sharedRootID = '$shared-root';
const privateRootID = '$private-root';
const copiedFolderStorageKey = "copied-folder";

// A list of allowed shared and private folders.
function FolderList(props: FolderListProps): Node {
  const [sharedRoot, setSharedRoot] = useState<Folder>({
    id: sharedRootID,
    name: '',
    title: '',
    isShared: true,
    isReadonly: false,
    forMobileApi: false,
    parentId: "",
    children: [],
    itemId: "",
    ancestors: [],
    mime: '',
    kind: FolderKinds.root,
    sortIndex: 0,
    userId: "",
    isAutoCreated: false,
    virtualId: sharedRootID,
    virtualParentId: "",
    createdAt: '',
    openIn: '',
    usedAt: '',
    updatedAt: '',
  });

  const [privateRoot, setPrivateRoot] = useState<Folder>({
    id: privateRootID,
    name: '',
    title: '',
    isShared: false,
    isReadonly: false,
    forMobileApi: false,
    parentId: "",
    children: [],
    itemId: "",
    ancestors: [],
    mime: '',
    kind: FolderKinds.root,
    sortIndex: 0,
    userId: "",
    isAutoCreated: false,
    virtualId: privateRootID,
    virtualParentId: "",
    createdAt: '',
    openIn: '',
    usedAt: '',
    updatedAt: '',
  });

  const [privateUsers, setPrivateUsers] = useState<Array<User>>([]);

  const [editingFolder, setEditingFolder] = useState<?Folder>(null);
  const [exportingFolder, setExportingFolder] = useState<boolean>(false);
  const [processedCount, setProcessedCount] = useState<number>(0);
  const [processingTotalCount, setProcessingTotalCount] = useState<number>(0);
  const [folderToPaste, setFolderToPaste] = useState<?CopiedFolder>(null);

  useEffect(() => {
    new ActivityService(apiEndpoint()).visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
  }, []);

  useEffect(() => {
    async function fetchFolders() {
      const resp = await new FolderService(apiEndpoint()).tree({ sortBy: props.appState.sortFoldersBy });
      setSharedRoot(root => ({ ...root, children: resp.shared }));
      setPrivateRoot(root => ({ ...root, children: resp.private }));
      setPrivateUsers(resp.privateUsers);
    }

    fetchFolders();
  }, [props.appState.sortFoldersBy]);

  useEffect(() => {
    const copiedFolder = getCopiedFolder();
    setFolderToPaste(copiedFolder);
  }, []);

  async function onFolderNameClick(e: SyntheticMouseEvent<HTMLElement>, folder: Folder) {
    e.stopPropagation();
    e.preventDefault();
    setEditingFolder(folder);
  }

  async function onEditNameOK(newName: string) {
    if (editingFolder == null)
      return;

    try {
      await new FolderService(apiEndpoint()).setName({ folderId: editingFolder.id, name: newName });

      if (sharedRoot.children != null) {
        const i = sharedRoot.children.findIndex(f => f.id === editingFolder.id);
        if (i >= 0)
          setSharedRoot({
            ...sharedRoot,
            children: [
              ...sharedRoot.children.slice(0, i),
              { ...sharedRoot.children[i], name: newName },
              ...sharedRoot.children.slice(i + 1),
            ]
          });
      }

      if (privateRoot.children != null) {
        const i = privateRoot.children.findIndex(f => f.id === editingFolder.id);
        if (i >= 0)
          setPrivateRoot({
            ...privateRoot,
            children: [
              ...privateRoot.children.slice(0, i),
              { ...privateRoot.children[i], name: newName },
              ...privateRoot.children.slice(i + 1),
            ]
          });
      }

      await props.getFolders();
    } finally {
      setEditingFolder(null);
    }
  }

  function onEditNameCancel() {
    setEditingFolder(null);
  }

  async function createNewFolder(e: SyntheticMouseEvent<HTMLElement>, parent: Folder, kind: FolderKind) {
    e.preventDefault();

    let folderName = window.prompt("New folder name");
    if (folderName == null)
      return;

    folderName = folderName.trim();
    if (folderName === '')
      return;

    const currentIndex = parent.children != null ? parent.children.findIndex(f => f.name === folderName && !f.itemId) : -1;

    if (currentIndex >= 0) {
      alert(`Folder '${folderName}' already exists`);
      return;
    }

    try {
      const parentId = parent.id === sharedRootID || parent.id === privateRootID ? "" : parent.id;
      const response = await new FolderService(apiEndpoint()).addFolder({
        name: folderName,
        isShared: parent.isShared,
        parentId: parentId,
        kind: kind,
      });
      if (response.folder == null)
        return;

      props.history.push(`/folder/${response.folder.virtualId}`);
      //to recall api in sidebar
      props.getFolders();
    } catch (error) {
      if (error.response != null) {
        if (error.response.status === 409) {
          alert(`Folder '${folderName}' already exists`);
        } else {
          alert(error.message);
        }
      }
    }
  }

  async function selectExportFolders() {
    setExportingFolder(true);
  }

  async function exportFoldersData(folders: Array<Folder>) {
    await callDownloadApi("export-folders", folders.map(f => f.id));
    setExportingFolder(false);
  }

  function cancelExportingFolder() {
    setExportingFolder(false);
  }

  async function callDownloadApi(apiMethod: string, selection: Array<string>) {
    const resp = await new FolderService(apiEndpoint()).saveTemporalSelection({ items: selection });
    const selectionId = resp.selectionId;

    const downloadURL = apiEndpoint() + `/api/folder/${apiMethod}?selection=${selectionId}`;
    window.location.assign(downloadURL);
  }

  async function importFolderData(acceptedFiles: Array<File>) {
    setProcessedCount(0);
    setProcessingTotalCount(1);
    let message = null;
    try {
      const file = acceptedFiles[0];
      const data = new FormData();
      data.append("file", file, file.name);
      const response = await apiPost(`/api/folder/import-folders`, data, { headers: { "content-type": "multipart/form-data" } });
      message = (response.data.message: string);

      await props.getFolders();
    } finally {
      setProcessedCount(0);
      setProcessingTotalCount(0);
    }
    if (message)
      window.alert(message);
  }

  async function onPasteFolderClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    if (folderToPaste == null)
      return;

    const resp = await new FolderService(apiEndpoint()).pasteFolder({
      sourceFolderId: folderToPaste.folderId,
      destFolderId: "",
    });
    if (resp.error) {
      window.alert(resp.error);
      return;
    }

    setCopiedFolder(null);
    setFolderToPaste(null);
    const treeResp = await new FolderService(apiEndpoint()).tree({ sortBy: props.appState.sortFoldersBy });
    setSharedRoot(root => ({ ...root, children: treeResp.shared }));
    setPrivateRoot(root => ({ ...root, children: treeResp.private }));
    await props.getFolders();
  }

  async function onSharedFoldersSortEnd(e: { oldIndex: number, newIndex: number }) {
    if (props.appState.me == null || !props.appState.me.isAdmin) {
      return;
    }

    const newFolders = arrayMove(sharedRoot.children, e.oldIndex, e.newIndex);
    await new FolderService(apiEndpoint()).sortRootFolders({
      isShared: true,
      sortedFolders: newFolders.map(f => f.id)
    });
    setSharedRoot(sharedRoot => ({ ...sharedRoot, children: newFolders }));
    await props.getFolders();
  }

  async function onPrivateFoldersSortEnd(e: { oldIndex: number, newIndex: number }) {
    const newFolders = arrayMove(privateRoot.children, e.oldIndex, e.newIndex);
    await new FolderService(apiEndpoint()).sortRootFolders({
      isShared: false,
      sortedFolders: newFolders.map(f => f.id)
    });
    setPrivateRoot(privateRoot => ({ ...privateRoot, children: newFolders }));
    await props.getFolders();
  }

  const userId = props.match?.params.id;
  const privateUser = userId ? privateUsers.find(u => u.id === userId) : null;

  const sharedFolders = <FoldersView
    axis='xy'
    distance={1}
    onSortEnd={onSharedFoldersSortEnd}
    folders={sharedRoot.children}
    onFolderNameClick={onFolderNameClick}
    user={props.appState.me}
    disableSort={props.appState.sortFoldersBy}
  />;

  const folders = <FoldersView
    axis='xy'
    distance={1}
    onSortEnd={onPrivateFoldersSortEnd}
    folders={userId ? privateRoot.children.filter(child => child.userId === userId) : privateRoot.children}
    onFolderNameClick={onFolderNameClick}
    user={props.appState.me}
    disableSort={props.appState.sortFoldersBy}
  />;

  const exportMenuItems: Array<typeof MenuItem> = [];
  if (props.appState.me != null && props.appState.me.features.indexOf(Features.exportLabels) >= 0) {
    exportMenuItems.push(
      <MenuItem key="folders" eventKey="1" onSelect={selectExportFolders}>
        Folder Data
      </MenuItem>);
  }

  const exportDataLink = exportMenuItems.length > 0 &&
    <DropdownButton id="export-data" title="Export">
      {exportMenuItems}
    </DropdownButton>;

  const importMenuItems: Array<typeof MenuItem> = [];
  if (props.appState.me != null && props.appState.me.features.indexOf(Features.importLabels) >= 0) {
    importMenuItems.push(
      <MenuItem key="folders" eventKey="1">
        <CustomDropZone onDrop={importFolderData} label="Folder Data" accept={["application/zip", ".zip"]} multiple={false} />
      </MenuItem>);
  }

  const importDataLink = importMenuItems.length > 0 &&
    <DropdownButton id="import-data" title="Import">
      {importMenuItems}
    </DropdownButton>;

  const pasteFolderLink = folderToPaste != null && (folderToPaste.kind === FolderKinds.project || folderToPaste.kind === FolderKinds.org)
      && <Button onClick={onPasteFolderClick}>
        Paste the copied folder
      </Button>;

  const sharedFoldersTable = (props.appState.me == null || !props.appState.me.isAdmin) && sharedRoot.children.length === 0 ?
    null :
    <div className={styles.folders}>
      <div className="container-fluid">
        <div className="row">
          <div className={`col-sm-12 ptb-15 ${styles.header}`}>
            Shared Project Folders
            {props.appState.me != null && props.appState.me.isAdmin &&
              <React.Fragment>
                <Button cls="pull-right" onClick={e => createNewFolder(e, sharedRoot, FolderKinds.org)}>Add Org Folder</Button>
                <Button cls="pull-right" onClick={e => createNewFolder(e, sharedRoot, FolderKinds.project)}>Add Project Folder</Button>
              </React.Fragment>}
          </div>
          <div className="col-sm-12">
            {sharedFolders}
          </div>
        </div>
      </div>
    </div>;

  const privateFoldersTable = <div className={styles.folders}>
    <div className="container-fluid">
      <div className="row">
        <div className={`col-sm-12 ptb-15 ${styles.header}`}>
          {`Private Project Folders${privateUser == null ? '' : ` (${privateUser.id === props.appState.me?.id ? 'My' : privateUser.displayName})`}`}
          {(!userId || userId === props.appState.me?.id) && <Button cls="pull-right" onClick={e => createNewFolder(e, privateRoot, FolderKinds.org)}>Add Org Folder</Button>}
          {(!userId || userId === props.appState.me?.id) && <Button cls="pull-right" onClick={e => createNewFolder(e, privateRoot, FolderKinds.project)}>Add Project Folder</Button>}
        </div>
        <div className="col-sm-12">
          { folders}
        </div>
      </div>
    </div>
  </div>;

  const spinnerStyle = {
    overlay: {
      backgroundColor: "rgba(255, 255, 255, 0.5)"
    },
    content: {
      top: "50%",
      right: "50%",
      width: "50%",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "95vh"
    }
  };

  const sortByOptions = [
    <option key='' value=''>Default</option>,
    <option key='<name' value='<name'>Name</option>,
    <option key='>activity' value='>activity'>Activity Date (most recent first)</option>,
    <option key='<activity' value='<activity'>Activity Date (most recent last)</option>,
    <option key='>created' value='>created'>Creation Date (most recent first)</option>,
    <option key='<created' value='<created'>Creation Date (most recent last)</option>,
    <option key='>updated' value='>updated'>Modification Date (most recent first)</option>,
    <option key='<updated' value='<updated'>Modification Date (most recent last)</option>,
  ];

  function onSortByChanged(e: SyntheticEvent<HTMLSelectElement>) {
    props.appState.setSortBy(e.currentTarget.value);
  }

  return (<div>
    <div className={styles.root}>
      <div className={styles.header}>
        Project Folders
      </div>
      <div>
        {exportDataLink} {importDataLink} {pasteFolderLink}
      </div>
      <div className={styles.folderActions}>
        <span className={styles.sortByTitle}>
          <label>Sort by</label>
        </span>
        <span className={styles.sortBySelect}>
          <select className={cx("form-control", "cursor-pointer")}
            value={props.appState.sortFoldersBy}
            onChange={onSortByChanged}>
            {sortByOptions}
          </select>
        </span>
      </div>
      {props.showSharedFolders && sharedFoldersTable}
      {props.showPrivateFolders && privateFoldersTable}
    </div>
    {
      editingFolder != null && <Modal isOpen={true} style={spinnerStyle} ariaHideApp={false}>
        <TextEditor title='Input new folder name:'
          text={editingFolder.name}
          width={600}
          height={200}
          ok={onEditNameOK}
          cancel={onEditNameCancel} />
      </Modal>
    }
    {exportingFolder && <Modal isOpen={true} style={spinnerStyle} ariaHideApp={false}>
      <FolderSelector
        rootFolderId={null}
        selectTitle="Export"
        select={exportFoldersData}
        cancel={cancelExportingFolder}
      />
    </Modal>}
    {processingTotalCount > 0 && <Modal isOpen={true} style={spinnerStyle} ariaHideApp={false}>
      <div>
        <Loader type='Watch' color='green' height={80} width={80} />
        <Line percent={processedCount * 100 / processingTotalCount} strokeWidth='4'
          strokeColor='green' />
        <div
          className={styles.progress}>{Math.floor(processedCount * 100 / processingTotalCount)}%
        </div>
      </div>
    </Modal>}
  </div>);
}

function getCopiedFolder(): ?CopiedFolder {
  const copiedFolderContent = window.sessionStorage.getItem(copiedFolderStorageKey);
  if (copiedFolderContent)
    return (JSON.parse(copiedFolderContent): CopiedFolder);
  return null;
}

function setCopiedFolder(copiedFolder: ?CopiedFolder) {
  if (copiedFolder == null)
    window.sessionStorage.removeItem(copiedFolderStorageKey);
  else
    window.sessionStorage.setItem(copiedFolderStorageKey, JSON.stringify(copiedFolder));
}

const FolderView = SortableElement(({
  folder,
  folderIndex,
  onFolderNameClick,
  user,
}) => {
  return <FolderToRender
    folder={folder}
    folderIndex={folderIndex}
    onFolderNameClick={onFolderNameClick}
    user={user} />;
});

const FoldersView = SortableContainer(({
    folders,
    onFolderNameClick,
    user,
    disableSort,
  }) => {
    const sortedIds = sortBy(folders, folder => folder.createdAt).map(folder => folder.id);
    return <div className={styles.items}>
      {folders.map((f, i) =>
        <FolderView
          key={f.id}
          index={i}
          disabled={disableSort}
          folder={f}
          folderIndex={sortedIds.indexOf(f.id)}
          onFolderNameClick={onFolderNameClick}
          user={user} />
      )}
    </div>;
  }
);

export default (observer(FolderList): typeof FolderList);
