// @flow strict
/* eslint-disable */
import { sendTwirpRequest } from "./twirp";
import type { Setting, SettingJSON } from "./model";
import { SettingToJSON, JSONToSetting } from "./model";
export type SettingGetRequest = {
  names: Array<string>;
}

export type SettingGetRequestJSON = {
  names?: Array<string>;
}

export function SettingGetRequestToJSON(m: SettingGetRequest): SettingGetRequestJSON {
  return {
    names: m.names,
  };
}

export function JSONToSettingGetRequest(m: SettingGetRequestJSON): SettingGetRequest {
  return {
    names: m.names != null ? m.names : [],
  };
}

export type SettingGetResponse = {
  settings: Array<Setting>;
}

export type SettingGetResponseJSON = {
  settings?: Array<SettingJSON>;
}

export function SettingGetResponseToJSON(m: SettingGetResponse): SettingGetResponseJSON {
  return {
    settings: m.settings.map(SettingToJSON),
  };
}

export function JSONToSettingGetResponse(m: SettingGetResponseJSON): SettingGetResponse {
  return {
    settings: m.settings != null ? m.settings.map(JSONToSetting) : [],
  };
}

export type SettingAddOrEditRequest = {
  setting: ?Setting;
}

export type SettingAddOrEditRequestJSON = {
  setting?: SettingJSON;
}

export function SettingAddOrEditRequestToJSON(m: SettingAddOrEditRequest): SettingAddOrEditRequestJSON {
  return {
    setting: m.setting != null ? SettingToJSON(m.setting) : undefined,
  };
}

export function JSONToSettingAddOrEditRequest(m: SettingAddOrEditRequestJSON): SettingAddOrEditRequest {
  return {
    setting: m.setting != null ? JSONToSetting(m.setting) : null,
  };
}

export type SettingAddOrEditResponse = {
}

export type SettingAddOrEditResponseJSON = {
}

export function SettingAddOrEditResponseToJSON(m: SettingAddOrEditResponse): SettingAddOrEditResponseJSON {
  return {
  };
}

export function JSONToSettingAddOrEditResponse(m: SettingAddOrEditResponseJSON): SettingAddOrEditResponse {
  return {
  };
}

export class SettingService {
  hostname: string;
  pathPrefix: string = "/rpc.SettingService/";

  constructor(hostname: string) {
    this.hostname = hostname;
  }

  async get(settingGetRequest: SettingGetRequest): Promise<SettingGetResponse> {
    const url = this.hostname + this.pathPrefix + "Get";
    const body: SettingGetRequestJSON = SettingGetRequestToJSON(settingGetRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToSettingGetResponse(data);
  }

  async addOrEdit(settingAddOrEditRequest: SettingAddOrEditRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "AddOrEdit";
    const body: SettingAddOrEditRequestJSON = SettingAddOrEditRequestToJSON(settingAddOrEditRequest);
    await sendTwirpRequest(url, body);
  }
}
