// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import styles from './Statistics.module.css';
import { ActivityService } from "../../rpc/activity";
import { apiEndpoint, apiGet } from "../../utils/http";

type summaryItem = {
  name: string,
  cpu_percentage: number,
  resident_set_size_kb: number,
  detect_speed: number,
}

type usageItem = {
  timestamp: string,
  user_id: string,
  user_name: string,
  user_ip: string,
  name: string,
  cpu_percentage: number,
  resident_set_size_kb: number,
  detect_speed: number,
}

export default function ProcessStatistics(): Node {
  const [summary, setSummary] = useState<Array<summaryItem>>([]);
  const [last, setLast] = useState<Array<usageItem>>([]);

  useEffect(() => {
    new ActivityService(apiEndpoint()).visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
  }, []);

  useEffect(() => {
    async function fetchStatistics() {
      const resp = await apiGet('/api/statistics/process');
      setSummary(resp.data.summary);
      setLast(resp.data.last);
    }

    fetchStatistics();
  }, []);

  const summaryTable = summary.length !== 0 ?
    <div className={styles.root}>
      <h4>Summary</h4>
      <table className={styles.table}>
        <thead>
        <tr>
          <th>Name</th>
          <th>Average CPU (%)</th>
          <th>Average Memory (MB)</th>
          <th>Detect Speed (second/megapixel)</th>
        </tr>
        </thead>
        <tbody>
        {summary.map(s =>
          <tr key={s.name}>
            <td className={styles.counter}>{s.name}</td>
            <td className={styles.counter}>{s.cpu_percentage.toFixed(1)}</td>
            <td className={styles.counter}>{(s.resident_set_size_kb / 1024).toFixed(1)}</td>
            <td className={styles.counter}>{s.detect_speed > 0 ? s.detect_speed.toFixed(2) : "-"}</td>
          </tr>)}
        </tbody>
      </table>
    </div> :
    null;

  const lastTable = last.length !== 0 ?
    <div className={styles.root}>
      <h4>Last Launches</h4>
      <table className={styles.table}>
        <thead>
        <tr>
          <th>Timestamp</th>
          <th>Name</th>
          <th>User</th>
          <th>User IP</th>
          <th>Average CPU (%)</th>
          <th>Average Memory (MB)</th>
          <th>Detect Speed (second/megapixel)</th>
        </tr>
        </thead>
        <tbody>
        {last.map((s, i) =>
          <tr key={i}>
            <td className={styles.counter}>{s.timestamp}</td>
            <td className={styles.counter}>{s.name}</td>
            <td className={styles.counter}>{s.user_name}</td>
            <td className={styles.counter}>{s.user_ip}</td>
            <td className={styles.counter}>{s.cpu_percentage.toFixed(1)}</td>
            <td className={styles.counter}>{(s.resident_set_size_kb / 1024).toFixed(1)}</td>
            <td className={styles.counter}>{s.detect_speed > 0 ? s.detect_speed.toFixed(2) : "-"}</td>
          </tr>)}
        </tbody>
      </table>
    </div> :
    null;

  return <div>
    {summaryTable}
    {lastTable}
  </div>;
}
