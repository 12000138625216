// @flow strict
/* eslint-disable */
import { sendTwirpRequest } from "./twirp";
import type { DetectEngine, DetectEngineJSON } from "./model";
import { DetectEngineToJSON, JSONToDetectEngine } from "./model";
import type { DetectEngineRight, DetectEngineRightJSON } from "./model";
import { DetectEngineRightToJSON, JSONToDetectEngineRight } from "./model";
export type DetectVisibleEnginesRequest = {
  withVersions: boolean;
}

export type DetectVisibleEnginesRequestJSON = {
  with_versions?: boolean;
}

export function DetectVisibleEnginesRequestToJSON(m: DetectVisibleEnginesRequest): DetectVisibleEnginesRequestJSON {
  return {
    with_versions: m.withVersions,
  };
}

export function JSONToDetectVisibleEnginesRequest(m: DetectVisibleEnginesRequestJSON): DetectVisibleEnginesRequest {
  return {
    withVersions: m.with_versions != null ? m.with_versions : false,
  };
}

export type DetectVisibleEnginesResponse = {
  engines: Array<DetectEngine>;
}

export type DetectVisibleEnginesResponseJSON = {
  engines?: Array<DetectEngineJSON>;
}

export function DetectVisibleEnginesResponseToJSON(m: DetectVisibleEnginesResponse): DetectVisibleEnginesResponseJSON {
  return {
    engines: m.engines.map(DetectEngineToJSON),
  };
}

export function JSONToDetectVisibleEnginesResponse(m: DetectVisibleEnginesResponseJSON): DetectVisibleEnginesResponse {
  return {
    engines: m.engines != null ? m.engines.map(JSONToDetectEngine) : [],
  };
}

export type DetectEnginesRequest = {
  withVersions: boolean;
}

export type DetectEnginesRequestJSON = {
  with_versions?: boolean;
}

export function DetectEnginesRequestToJSON(m: DetectEnginesRequest): DetectEnginesRequestJSON {
  return {
    with_versions: m.withVersions,
  };
}

export function JSONToDetectEnginesRequest(m: DetectEnginesRequestJSON): DetectEnginesRequest {
  return {
    withVersions: m.with_versions != null ? m.with_versions : false,
  };
}

export type DetectEnginesResponse = {
  engines: Array<DetectEngine>;
}

export type DetectEnginesResponseJSON = {
  engines?: Array<DetectEngineJSON>;
}

export function DetectEnginesResponseToJSON(m: DetectEnginesResponse): DetectEnginesResponseJSON {
  return {
    engines: m.engines.map(DetectEngineToJSON),
  };
}

export function JSONToDetectEnginesResponse(m: DetectEnginesResponseJSON): DetectEnginesResponse {
  return {
    engines: m.engines != null ? m.engines.map(JSONToDetectEngine) : [],
  };
}

export type DetectRightsRequest = {
}

export type DetectRightsRequestJSON = {
}

export function DetectRightsRequestToJSON(m: DetectRightsRequest): DetectRightsRequestJSON {
  return {
  };
}

export function JSONToDetectRightsRequest(m: DetectRightsRequestJSON): DetectRightsRequest {
  return {
  };
}

export type DetectRightsResponse = {
  rights: Array<DetectEngineRight>;
}

export type DetectRightsResponseJSON = {
  rights?: Array<DetectEngineRightJSON>;
}

export function DetectRightsResponseToJSON(m: DetectRightsResponse): DetectRightsResponseJSON {
  return {
    rights: m.rights.map(DetectEngineRightToJSON),
  };
}

export function JSONToDetectRightsResponse(m: DetectRightsResponseJSON): DetectRightsResponse {
  return {
    rights: m.rights != null ? m.rights.map(JSONToDetectEngineRight) : [],
  };
}

export type DetectGrantRightRequest = {
  engineId: string;
  userId: string;
  granted: boolean;
}

export type DetectGrantRightRequestJSON = {
  engine_id?: string;
  user_id?: string;
  granted?: boolean;
}

export function DetectGrantRightRequestToJSON(m: DetectGrantRightRequest): DetectGrantRightRequestJSON {
  return {
    engine_id: m.engineId,
    user_id: m.userId,
    granted: m.granted,
  };
}

export function JSONToDetectGrantRightRequest(m: DetectGrantRightRequestJSON): DetectGrantRightRequest {
  return {
    engineId: m.engine_id != null ? m.engine_id : "",
    userId: m.user_id != null ? m.user_id : "",
    granted: m.granted != null ? m.granted : false,
  };
}

export type DetectGrantRightResponse = {
}

export type DetectGrantRightResponseJSON = {
}

export function DetectGrantRightResponseToJSON(m: DetectGrantRightResponse): DetectGrantRightResponseJSON {
  return {
  };
}

export function JSONToDetectGrantRightResponse(m: DetectGrantRightResponseJSON): DetectGrantRightResponse {
  return {
  };
}

export type DetectDetectEngineParameter = {
  name: string;
  value: string;
}

export type DetectDetectEngineParameterJSON = {
  name?: string;
  value?: string;
}

export function DetectDetectEngineParameterToJSON(m: DetectDetectEngineParameter): DetectDetectEngineParameterJSON {
  return {
    name: m.name,
    value: m.value,
  };
}

export function JSONToDetectDetectEngineParameter(m: DetectDetectEngineParameterJSON): DetectDetectEngineParameter {
  return {
    name: m.name != null ? m.name : "",
    value: m.value != null ? m.value : "",
  };
}

export type DetectDetectEngine = {
  name: string;
  parameters: Array<DetectDetectEngineParameter>;
}

export type DetectDetectEngineJSON = {
  name?: string;
  parameters?: Array<DetectDetectEngineParameterJSON>;
}

export function DetectDetectEngineToJSON(m: DetectDetectEngine): DetectDetectEngineJSON {
  return {
    name: m.name,
    parameters: m.parameters.map(DetectDetectEngineParameterToJSON),
  };
}

export function JSONToDetectDetectEngine(m: DetectDetectEngineJSON): DetectDetectEngine {
  return {
    name: m.name != null ? m.name : "",
    parameters: m.parameters != null ? m.parameters.map(JSONToDetectDetectEngineParameter) : [],
  };
}

export type DetectDetectLabelsRequest = {
  itemIds: Array<string>;
  engines: Array<DetectDetectEngine>;
  tabId: string;
}

export type DetectDetectLabelsRequestJSON = {
  item_ids?: Array<string>;
  engines?: Array<DetectDetectEngineJSON>;
  tab_id?: string;
}

export function DetectDetectLabelsRequestToJSON(m: DetectDetectLabelsRequest): DetectDetectLabelsRequestJSON {
  return {
    item_ids: m.itemIds,
    engines: m.engines.map(DetectDetectEngineToJSON),
    tab_id: m.tabId,
  };
}

export function JSONToDetectDetectLabelsRequest(m: DetectDetectLabelsRequestJSON): DetectDetectLabelsRequest {
  return {
    itemIds: m.item_ids != null ? m.item_ids : [],
    engines: m.engines != null ? m.engines.map(JSONToDetectDetectEngine) : [],
    tabId: m.tab_id != null ? m.tab_id : "",
  };
}

export type DetectDetectLabelsResponse = {
}

export type DetectDetectLabelsResponseJSON = {
}

export function DetectDetectLabelsResponseToJSON(m: DetectDetectLabelsResponse): DetectDetectLabelsResponseJSON {
  return {
  };
}

export function JSONToDetectDetectLabelsResponse(m: DetectDetectLabelsResponseJSON): DetectDetectLabelsResponse {
  return {
  };
}

export type DetectDetectFolderLabelsRequest = {
  folderId: string;
  itemIds: Array<string>;
  additionalItemIds: Array<string>;
  engines: Array<DetectDetectEngine>;
  tabId: string;
}

export type DetectDetectFolderLabelsRequestJSON = {
  folder_id?: string;
  item_ids?: Array<string>;
  additional_item_ids?: Array<string>;
  engines?: Array<DetectDetectEngineJSON>;
  tab_id?: string;
}

export function DetectDetectFolderLabelsRequestToJSON(m: DetectDetectFolderLabelsRequest): DetectDetectFolderLabelsRequestJSON {
  return {
    folder_id: m.folderId,
    item_ids: m.itemIds,
    additional_item_ids: m.additionalItemIds,
    engines: m.engines.map(DetectDetectEngineToJSON),
    tab_id: m.tabId,
  };
}

export function JSONToDetectDetectFolderLabelsRequest(m: DetectDetectFolderLabelsRequestJSON): DetectDetectFolderLabelsRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    itemIds: m.item_ids != null ? m.item_ids : [],
    additionalItemIds: m.additional_item_ids != null ? m.additional_item_ids : [],
    engines: m.engines != null ? m.engines.map(JSONToDetectDetectEngine) : [],
    tabId: m.tab_id != null ? m.tab_id : "",
  };
}

export type DetectDetectFolderLabelsResponse = {
}

export type DetectDetectFolderLabelsResponseJSON = {
}

export function DetectDetectFolderLabelsResponseToJSON(m: DetectDetectFolderLabelsResponse): DetectDetectFolderLabelsResponseJSON {
  return {
  };
}

export function JSONToDetectDetectFolderLabelsResponse(m: DetectDetectFolderLabelsResponseJSON): DetectDetectFolderLabelsResponse {
  return {
  };
}

export type DetectRetrainLabelsRequest = {
  folders: Array<string>;
  engines: Array<string>;
  tabId: string;
}

export type DetectRetrainLabelsRequestJSON = {
  folders?: Array<string>;
  engines?: Array<string>;
  tab_id?: string;
}

export function DetectRetrainLabelsRequestToJSON(m: DetectRetrainLabelsRequest): DetectRetrainLabelsRequestJSON {
  return {
    folders: m.folders,
    engines: m.engines,
    tab_id: m.tabId,
  };
}

export function JSONToDetectRetrainLabelsRequest(m: DetectRetrainLabelsRequestJSON): DetectRetrainLabelsRequest {
  return {
    folders: m.folders != null ? m.folders : [],
    engines: m.engines != null ? m.engines : [],
    tabId: m.tab_id != null ? m.tab_id : "",
  };
}

export type DetectRetrainLabelsResponse = {
}

export type DetectRetrainLabelsResponseJSON = {
}

export function DetectRetrainLabelsResponseToJSON(m: DetectRetrainLabelsResponse): DetectRetrainLabelsResponseJSON {
  return {
  };
}

export function JSONToDetectRetrainLabelsResponse(m: DetectRetrainLabelsResponseJSON): DetectRetrainLabelsResponse {
  return {
  };
}

export type DetectPendingDetection = {
  engine: string;
  count: number;
}

export type DetectPendingDetectionJSON = {
  engine?: string;
  count?: number;
}

export function DetectPendingDetectionToJSON(m: DetectPendingDetection): DetectPendingDetectionJSON {
  return {
    engine: m.engine,
    count: m.count,
  };
}

export function JSONToDetectPendingDetection(m: DetectPendingDetectionJSON): DetectPendingDetection {
  return {
    engine: m.engine != null ? m.engine : "",
    count: m.count != null ? m.count : 0,
  };
}

export type DetectPendingDetectionsResponse = {
  detections: Array<DetectPendingDetection>;
}

export type DetectPendingDetectionsResponseJSON = {
  detections?: Array<DetectPendingDetectionJSON>;
}

export function DetectPendingDetectionsResponseToJSON(m: DetectPendingDetectionsResponse): DetectPendingDetectionsResponseJSON {
  return {
    detections: m.detections.map(DetectPendingDetectionToJSON),
  };
}

export function JSONToDetectPendingDetectionsResponse(m: DetectPendingDetectionsResponseJSON): DetectPendingDetectionsResponse {
  return {
    detections: m.detections != null ? m.detections.map(JSONToDetectPendingDetection) : [],
  };
}

export class DetectService {
  hostname: string;
  pathPrefix: string = "/rpc.DetectService/";

  constructor(hostname: string) {
    this.hostname = hostname;
  }

  async visibleEngines(detectVisibleEnginesRequest: DetectVisibleEnginesRequest): Promise<DetectVisibleEnginesResponse> {
    const url = this.hostname + this.pathPrefix + "VisibleEngines";
    const body: DetectVisibleEnginesRequestJSON = DetectVisibleEnginesRequestToJSON(detectVisibleEnginesRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToDetectVisibleEnginesResponse(data);
  }

  async engines(detectEnginesRequest: DetectEnginesRequest): Promise<DetectEnginesResponse> {
    const url = this.hostname + this.pathPrefix + "Engines";
    const body: DetectEnginesRequestJSON = DetectEnginesRequestToJSON(detectEnginesRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToDetectEnginesResponse(data);
  }

  async rights(): Promise<DetectRightsResponse> {
    const url = this.hostname + this.pathPrefix + "Rights";
    const data = await sendTwirpRequest(url, {});
    return JSONToDetectRightsResponse(data);
  }

  async grantRight(detectGrantRightRequest: DetectGrantRightRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "GrantRight";
    const body: DetectGrantRightRequestJSON = DetectGrantRightRequestToJSON(detectGrantRightRequest);
    await sendTwirpRequest(url, body);
  }

  async detectLabels(detectDetectLabelsRequest: DetectDetectLabelsRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "DetectLabels";
    const body: DetectDetectLabelsRequestJSON = DetectDetectLabelsRequestToJSON(detectDetectLabelsRequest);
    await sendTwirpRequest(url, body);
  }

  async detectFolderLabels(detectDetectFolderLabelsRequest: DetectDetectFolderLabelsRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "DetectFolderLabels";
    const body: DetectDetectFolderLabelsRequestJSON = DetectDetectFolderLabelsRequestToJSON(detectDetectFolderLabelsRequest);
    await sendTwirpRequest(url, body);
  }

  async retrainLabels(detectRetrainLabelsRequest: DetectRetrainLabelsRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "RetrainLabels";
    const body: DetectRetrainLabelsRequestJSON = DetectRetrainLabelsRequestToJSON(detectRetrainLabelsRequest);
    await sendTwirpRequest(url, body);
  }

  async pendingDetections(): Promise<DetectPendingDetectionsResponse> {
    const url = this.hostname + this.pathPrefix + "PendingDetections";
    const data = await sendTwirpRequest(url, {});
    return JSONToDetectPendingDetectionsResponse(data);
  }
}
