// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from "react";
import type { Node } from "react";
import styles from "./FolderStatisticsView.module.css";
import { getFolderCaptionName } from "../../utils/folder";
import Button from "../Button/Button";
import type { Folder } from "../../rpc/model";
import { apiGet } from "../../utils/http";

type Props = {
  folder: Folder,
  onClose: () => void,
}

type FolderLabelStat = {
  total_count: number,
  image_count: number,
  image_percentage: number,
  video_count: number,
  video_percentage: number,
  stream_count: number,
  stream_percentage: number,
  label_name: string,
  label_color: string
}

type FolderFrameStat = {
  label_name: string,
  label_color: string,
  frame_count: number,
  start_frame: number,
  end_frame: number
}

type FolderVideoStat = {
  video_name: string,
  total_frame_count: number,
  labeled_frame_count: number,
  labeled_frame_percentage: number,
  frames: Array<FolderFrameStat>
}

type SubFolderStat = {
  folder_type: string,
  folder_name: string,
  image_count: number,
  video_count: number,
  stream_count: number,
  labels: Array<FolderLabelStat>,
  videos: Array<FolderVideoStat>
}

type FolderStat = {
  folder_name: string,
  image_count: number,
  video_count: number,
  stream_count: number,
  labels: Array<FolderLabelStat>,
  videos: Array<FolderVideoStat>,
  sub_folder: Array<SubFolderStat>
}

export default function FolderStatisticsView(props: Props): Node {
  const [stat, setStat] = useState<?FolderStat>(null);

  useEffect(() => {
    async function fetchStat() {
      const resp = await apiGet(`/api/statistics/folder/${props.folder.id}`);
      setStat(resp.data);
    }

    fetchStat();
  }, [props.folder.id]);

  return <React.Fragment>
    <div className={styles.statsBox}>
      <div>
        <h4>
          {getFolderCaptionName(props.folder)}
          <span> Stat </span>
        </h4>
      </div>
      <div>
        <Button onClick={props.onClose}>Close</Button>
      </div>
    </div>

    { !stat &&
      <div> "Loading Statistics Wait ....."</div>
    }

    { stat && <h5>
      Folder Name: <strong>{stat.folder_name}</strong>
    </h5>}

    { stat && <h5>
      Image Count: <strong>{stat.image_count}</strong>
      <span> </span>
      Video Count: <strong>{stat.video_count}</strong>
      <span> </span>
      Stream Count: <strong>{stat.stream_count}</strong>
    </h5>}

    { stat && stat.labels && stat.labels.length > 0 &&
       <table className={styles.statTable}>
        <thead>
        <tr>
          <th>Label name</th>
          <th>Total count</th>
          <th>Image count</th>
          <th>Image percentage</th>
          <th>Video count</th>
          <th>Video percentage</th>
          <th>Stream count</th>
          <th>Stream percentage</th>
        </tr>
        </thead>
        <tbody>
        {stat.labels.map((e, i) => <tr key={i}>
           <td>{e.label_name}</td>
           <td>{e.total_count}</td>
           <td>{e.image_count}</td>
           <td>{`${e.image_percentage}%`}</td>
           <td>{e.video_count}</td>
           <td>{`${e.video_percentage}%`}</td>
           <td>{e.stream_count}</td>
           <td>{`${e.stream_percentage}%`}</td>
        </tr>)}
        </tbody>
      </table>}

     { stat && stat.sub_folder && stat.sub_folder.length > 0 &&
     <div>
       { stat.sub_folder.map(x => <tr key={x.folder_name}>
        <h5>{x.folder_type} Name: <strong>{x.folder_name}</strong></h5>
        <h5>Image Count: <strong>{x.image_count}</strong>
        <span> </span>
        Video Count: <strong>{x.video_count}</strong>
        <span> </span>
        Stream Count: <strong>{x.stream_count}</strong>
        </h5>
       { x.labels && x.labels.length > 0 &&
       <table className={styles.statTable}>
         <thead>
         <tr>
           <th>Label name</th>
           <th>Total count</th>
           <th>Image count</th>
           <th>Image percentage</th>
           <th>Video count</th>
           <th>Video percentage</th>
           <th>Stream count</th>
           <th>Stream percentage</th>
         </tr>
         </thead>
         <tbody>
         { x.labels.map((e, i) => <tr key={i}>
           <td>{e.label_name}</td>
           <td>{e.total_count}</td>
           <td>{e.image_count}</td>
           <td>{`${e.image_percentage}%`}</td>
           <td>{e.video_count}</td>
           <td>{`${e.video_percentage}%`}</td>
           <td>{e.stream_count}</td>
           <td>{`${e.stream_percentage}%`}</td>
         </tr>)}
         </tbody>
       </table>}

       { x.videos && x.videos.length > 0 &&
       <div>
        { x.videos.map(e => <div key={e.video_name}>
       <table className={styles.statTable}>
        <thead>
        <tr>
          <th>Video</th>
          <th>Total Frame count</th>
          <th>Labeled Frame count</th>
          <th>Labeled Frame percentage</th>
        </tr>
        </thead>
        <tbody>
        <tr>
           <td>{e.video_name}</td>
           <td>{e.total_frame_count}</td>
           <td>{e.labeled_frame_count}</td>
           <td>{`${e.labeled_frame_percentage}%`}</td>
        </tr>
        </tbody>
        </table>
      { e.frames && e.frames.length > 0 &&
       <table className={styles.statTable}>
          <thead>
          <tr>
            <th>Label Name</th>
            <th>Frame count</th>
          </tr>
          </thead>
          <tbody>
          {e.frames.map(y => <tr key= {y.label_name}>
            <td>{y.label_name}</td>
            <td>{y.frame_count}</td>
          </tr>)}
          </tbody>
        </table>}
        </div>)}
        </div>}
       </tr>)}
    </div>}

    { stat && stat.videos && stat.videos.length > 0 &&
     <div>
       { stat.videos.map(e => <div key={e.video_name}>
       <table className={styles.statTable}>
        <thead>
        <tr>
          <th>Video</th>
          <th>Total Frame count</th>
          <th>Labeled Frame count</th>
          <th>Labeled Frame percentage</th>
        </tr>
        </thead>
        <tbody>
        <tr>
           <td>{e.video_name}</td>
           <td>{e.total_frame_count}</td>
           <td>{e.labeled_frame_count}</td>
           <td>{`${e.labeled_frame_percentage}%`}</td>
        </tr>
        </tbody>
        </table>
      { e.frames && e.frames.length > 0 &&
       <table className={styles.statTable}>
          <thead>
          <tr>
            <th>Label Name</th>
            <th>Frame count</th>
          </tr>
          </thead>
          <tbody>
          {e.frames.map(y => <tr key= {y.label_name}>
            <td>{y.label_name}</td>
            <td>{y.frame_count}</td>
          </tr>)}
          </tbody>
        </table>}
       </div>)}
    </div>}

  </React.Fragment>;
}
