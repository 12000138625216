// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from "react";
import type { Node } from "react";
import styles from "./StoreFrontAppForm.module.css";
import { Button, ButtonGroup, Col, ControlLabel, Form, FormControl, FormGroup } from "react-bootstrap";
import type { StorefrontApp } from "../../rpc/model";
import ToggleButton from "react-toggle-button";
import { Check, X } from "../toggle-button/buttons";
import Dropzone from "react-dropzone";

type StoreFrontAppProps = {
  app: StorefrontApp,
  applyChanges: (app: StorefrontApp, imageFile: ?File) => Promise<void>;
  cancel: () => void;
}

export default function StoreFrontAppForm(props: StoreFrontAppProps): Node {
  const [app, setApp] = useState<StorefrontApp>(props.app);
  const [dirty, setDirty] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<?File>(null);
  const [imagePreview, setImagePreview] = useState<?string>(null);

  useEffect(() => {
    return () => {
      if (imagePreview != null)
        URL.revokeObjectURL(imagePreview);
    };
  }, [imagePreview]);

  async function onSaveChangesClick() {
    props.applyChanges(app, imageFile);
  }

  function onCancelClick() {
    props.cancel();
  }

  function onTitleChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setApp({ ...app, title: e.currentTarget.value });
    setDirty(true);
  }

  function onDescriptionChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setApp({ ...app, description: e.currentTarget.value });
    setDirty(true);
  }

  function onAppURLChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setApp({ ...app, appUrl: e.currentTarget.value });
    setDirty(true);
  }

  function onInfoURLChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setApp({ ...app, infoUrl: e.currentTarget.value });
    setDirty(true);
  }

  function onImageURLChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setApp({ ...app, imageUrl: e.currentTarget.value });
    setDirty(true);
  }

  function onVisibleChanged() {
    setApp({ ...app, visible: !app.visible });
    setDirty(true);
  }

  function onImageDrop(acceptedFiles: Array<File>) {
    if (acceptedFiles.length === 0)
      return;

    setDirty(true);
    setImageFile(acceptedFiles[0]);
    setImagePreview(URL.createObjectURL(acceptedFiles[0]));
  }

  function onImageDelete(e: SyntheticMouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    e.stopPropagation();

    setDirty(true);
    setApp({ ...app, imageId: "" });
    setImageFile(null);
    setImagePreview(null);
  }

  return <div className={styles.root}>
    <Form horizontal>
      <FormGroup controlId="visible">
        <Col componentClass={ControlLabel} sm={3}>
          Visible
        </Col>
        <Col sm={9}>
          <div className={styles.toggleButton}>
            <ToggleButton
              inactiveLabel={<X />}
              activeLabel={<Check />}
              value={app.visible}
              onToggle={onVisibleChanged} />
          </div>
        </Col>
      </FormGroup>
      <FormGroup controlId="title">
        <Col componentClass={ControlLabel} sm={3}>
          Title
        </Col>
        <Col sm={9}>
          <FormControl
            placeholder="title"
            value={app.title}
            onChange={onTitleChanged}
            autoFocus={true}
          />
        </Col>
      </FormGroup>
      <FormGroup controlId="description">
        <Col componentClass={ControlLabel} sm={3}>
          Description
        </Col>
        <Col sm={9}>
          <FormControl
            componentClass="textarea"
            placeholder="description"
            value={app.description}
            onChange={onDescriptionChanged}
          />
        </Col>
      </FormGroup>
      <FormGroup controlId="appUrl">
        <Col componentClass={ControlLabel} sm={3}>
          App URL
        </Col>
        <Col sm={9}>
          <FormControl
            placeholder="app URL"
            value={app.appUrl}
            onChange={onAppURLChanged}
          />
        </Col>
      </FormGroup>
      <FormGroup controlId="infoUrl">
        <Col componentClass={ControlLabel} sm={3}>
          Info URL
        </Col>
        <Col sm={9}>
          <FormControl
            placeholder="info URL"
            value={app.infoUrl}
            onChange={onInfoURLChanged}
          />
        </Col>
      </FormGroup>
      <FormGroup controlId="imageUrl">
        <Col componentClass={ControlLabel} sm={3}>
          Image URL
        </Col>
        <Col sm={9}>
          <FormControl
            placeholder="image URL"
            value={app.imageUrl}
            onChange={onImageURLChanged}
          />
        </Col>
      </FormGroup>
      <FormGroup controlId="imageId">
        <Col componentClass={ControlLabel} sm={3}>
          Uploaded Image
        </Col>
        <Col sm={9}>
          <Dropzone accept='image/*' multiple={false} onDrop={onImageDrop}>
            {({ getRootProps, getInputProps }) => {
              const rootProps = { ...getRootProps() };
              delete rootProps.tabIndex;
              return <div {...rootProps} className={styles.image}>
                <input {...getInputProps()} />
                {imagePreview == null && app.imageId === '' ?
                  <div>Upload a local image</div> :
                  <div className={styles.imageDelete} onClick={onImageDelete}>Delete the uploaded image</div>}
                {(imagePreview != null || app.imageId !== '') &&
                <img alt='preview' className={styles.imagePreview}
                  crossOrigin="anonymous"
                  src={imagePreview != null ? imagePreview : `/api/blob/${app.imageId}`}
                />}
              </div>;
            }}
          </Dropzone>
        </Col>
      </FormGroup>
    </Form>
    <ButtonGroup className={styles.buttons}>
      <Button bsStyle="info" onClick={onSaveChangesClick} disabled={!dirty}>
        Save Changes
      </Button>
      <Button bsStyle="warning" onClick={onCancelClick}>
        Cancel
      </Button>
    </ButtonGroup>
  </div>;
}
