// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/light-bootstrap-dashboard.scss?v=1.2.0";
import "./assets/css/pe-icon-7-stroke.css";
import "./index.css";
import "./assets/css/style.css";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";
import { setApiEndpoint } from "./utils/http";

setApiEndpoint(window.apiEndpoint);

if (localStorage.getItem('isDarkTheme') === "dark") {
  document.documentElement.setAttribute('data-theme', 'dark');
} else {
  document.documentElement.setAttribute('data-theme', 'light');
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
