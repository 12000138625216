// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import styles from './StoreFrontRights.module.css';
import type { StorefrontApp, User } from "../../rpc/model";
import { UserService } from "../../rpc/users";
import { ActivityService } from "../../rpc/activity";
import { apiEndpoint } from "../../utils/http";
import { StorefrontService } from "../../rpc/storefront";

export default function StoreFrontRights(): Node {
  const [apps, setApps] = useState<Array<StorefrontApp>>([]);
  const [users, setUsers] = useState<Array<User>>([]);
  const [rights, setRights] = useState<{ [user: string]: { [appId: number]: string } }>({});

  useEffect(() => {
    new ActivityService(apiEndpoint()).visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
  }, []);

  useEffect(() => {
    async function fetchApps() {
      const resp = await new StorefrontService(apiEndpoint()).apps({ myApps: false, category: "" });
      setApps(resp.apps);
    }

    fetchApps();
  }, []);

  useEffect(() => {
    async function fetchUsers() {
      const resp = await new UserService(apiEndpoint()).users();
      setUsers(resp.users);
    }

    fetchUsers();
  }, []);

  useEffect(() => {
    async function fetchRights() {
      const resp = await new StorefrontService(apiEndpoint()).rights();
      const rawRights = resp.rights;
      const rights = {};
      for (let i = 0; i < rawRights.length; i++) {
        const right = rawRights[i];
        rights[right.userId] = rights[right.userId] || {};
        rights[right.userId][right.appId] = right.permission;
      }

      setRights(rights);
    }

    fetchRights();
  }, []);

  async function onRightChanged(e: SyntheticInputEvent<HTMLInputElement>, user: User, app: StorefrontApp) {
    const permission = e.target.value;
    await new StorefrontService(apiEndpoint()).grantRight({ appId: app.id, userId: user.id, permission: permission });

    setRights(rights => ({
      ...rights,
      [user.id]: {
        ...rights[user.id],
        [app.id]: permission,
      }
    }));
  }

  function renderCategory(category: string, title: string) {
    const categoryApps = apps.filter(app => app.category === category);
    if (categoryApps.length === 0)
      return null;

    return <div>
      <h3>{title}</h3>
      <table className={styles.table}>
        <thead>
        <tr>
          <th>USER</th>
          {categoryApps.map(app => <th key={app.id}>{app.title}</th>)}
        </tr>
        </thead>
        <tbody>
        {users.filter(u => u.isAdmin === false).map(u =>
          <tr key={u.id}>
            <td key={-1}>{u.displayName}</td>
            {categoryApps.map(app =>
              <td key={app.id}>
                <select className={styles.input} value={(rights[u.id] ?? {})[app.id] ?? ''}
                  onChange={e => onRightChanged(e, u, app)}>
                  <option value="" key=""></option>
                  <option value="hidden" key="hidden">Hidden</option>
                  <option value="storefront" key="storefront">StoreFront</option>
                  <option value="myapp" key="myapp">MyApps/StoreFront</option>
                </select>
              </td>)}
          </tr>)}
        </tbody>
      </table>
    </div>;
  }

  return <div className={styles.root}>
    {renderCategory("visualization", "Visualization/Reporting Apps")}
    {renderCategory("analytics", "Analytics apps")}
    {renderCategory("dashboard", "Dashboards")}
  </div>;
}
