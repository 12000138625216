// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useRef, useState } from 'react';
import type { Node } from "react";
import type { LiveLabel } from "../../model";
import { isClassificationLabel, isDetectionLabel, LabelTypes } from '../../model';
import styles from './LabelList.module.css';
import { fromLiveLabel } from "../../utils/label";
import LabelTypesDropdown from '../label-types-dropdown/LabelTypesDropdown';
import type { FolderItemNote, LabelType, User } from "../../rpc/model";
import { UserService } from "../../rpc/users";
import { apiEndpoint } from "../../utils/http";
import ContentEditable from 'react-contenteditable';

type LabelListProps = {
  isVideo: boolean,
  imageWidth: number,
  imageHeight: number,
  labels: Array<LiveLabel>,
  selectedLabelId: ?string,
  labelsVisibility: Array<string>,
  onSelectionChanged: (labelId: string) => void,
  onLabelTypeChanged: (labelId: string, labelTypeId: number) => Promise<void>,
  onLabelNoteChanged: (labelId: string, note: string) => Promise<void>,
  onLabelVisibilityChanged: (labelId: string) => Promise<void>,
  onLabelCrop: (labelId: string) => Promise<void>,
  onUserVisibilityChanged: (user: User, visible: boolean) => Promise<void>,
  onDelete: (labelId: string) => Promise<void>,
  onClassificationLabelAdded: (labelType: LabelType) => Promise<void>,
  onClear: () => Promise<void>,
  className: string,
  labelTypes: Array<LabelType>,
  notes: Array<FolderItemNote>,
  onItemNoteChanged: (note: string) => Promise<void>,
}

// List of labels for an image
export default function LabelList(props: LabelListProps): Node {
  const [showCoordinates, setShowCoordinates] = useState<boolean>(false);
  const [users, setUsers] = useState<Array<User>>([]);
  const htmlLabelNotes = useRef<{[string]: string}>({});
  const htmlItemNotes = useRef<{[string]: string}>({});

  useEffect(() => {
    async function fetchUsers() {
      const resp = await new UserService(apiEndpoint()).users();
      setUsers(resp.users);
    }

    fetchUsers();
  }, []);

  function onLabelClick(e: MouseEvent, labelId: ?string) {
    e.preventDefault();

    if (labelId != null)
      props.onSelectionChanged(labelId);
  }

  function onDeleteAll(e: MouseEvent) {
    e.preventDefault();

    if (!window.confirm(`You are going to delete all labels.\nAre you sure?`))
      return;

    props.onClear();
  }

  function onLabelTypeChanged(labelId: ?string, labelType: LabelType) {
    if (labelId != null)
      props.onLabelTypeChanged(labelId, labelType.id);
  }

  function onLabelNoteChanged(e: SyntheticEvent<HTMLInputElement>, labelId: ?string) {
    if (labelId != null) {
      // $FlowIgnore[prop-missing]
      let note = e.target.value;

      htmlLabelNotes.current[labelId] = note;

      note = note.split('&nbsp;').join(' ');
      if (note.indexOf('<div>') === 0) {
        note = note.replace('<div>', '');
      }
      note = note.split('<div>').join('\n');
      note = note.split('</div>').join('');
      note = note.split('<br>').join('');
      props.onLabelNoteChanged(labelId, note);
    }
  }

  function onItemNoteChanged(e: SyntheticEvent<HTMLInputElement>, user: User) {
    // $FlowIgnore[prop-missing]
    let note = e.target.value;

    htmlItemNotes.current[user.id] = note;

    note = note.split('&nbsp;').join(' ');
    if (note.indexOf('<div>') === 0) {
      note = note.replace('<div>', '');
    }
    note = note.split('<div>').join('\n');
    note = note.split('</div>').join('');
    note = note.split('<br>').join('');
    props.onItemNoteChanged(note);
  }

  function getLabelCoordinates(label: LiveLabel): string {
    const storedLabel = fromLiveLabel(label, props.imageWidth, props.imageHeight);
    return `(${storedLabel.left},${storedLabel.top})-(${storedLabel.right},${storedLabel.bottom})`;
  }

  async function onLabelDelete(e: SyntheticMouseEvent<HTMLImageElement>, labelId: ?string) {
    e.stopPropagation();
    e.preventDefault();

    if (labelId != null)
      props.onDelete(labelId);
  }

  async function onLabelVisibilityChanged(e: SyntheticMouseEvent<HTMLImageElement>, labelId: ?string) {
    e.stopPropagation();
    e.preventDefault();

    if (labelId != null)
      await props.onLabelVisibilityChanged(labelId);
  }

  async function onLabelCrop(e: SyntheticMouseEvent<HTMLAnchorElement>, labelId: ?string) {
    e.stopPropagation();
    e.preventDefault();

    if (labelId != null)
      await props.onLabelCrop(labelId);
  }

  async function onUserVisibilityChanged(e: SyntheticMouseEvent<HTMLImageElement>, user: User, visible: boolean) {
    e.stopPropagation();
    e.preventDefault();
    await props.onUserVisibilityChanged(user, visible);
  }

  async function onShowCoordinatesChanged() {
    setShowCoordinates(!showCoordinates);
  }

  function onClassificationLabelTypeSelected(labelType: LabelType) {
    props.onClassificationLabelAdded(labelType);
  }

  function renderUserLabels(user: User, labels: Array<LiveLabel>) {
    if (props.isVideo)
      return renderUserDetectionLabels(user, labels);

    return [
      renderUserClassificationLabels(user, labels.filter(lbl => isClassificationLabel(lbl))),
      ...renderUserDetectionLabels(user, labels.filter(lbl => isDetectionLabel(lbl)))
    ];
  }

  function renderUserDetectionLabels(user: User, labels: Array<LiveLabel>) {
    return labels.map((label, i) => {
      const classNames = [styles.item];
      if (label.id === props.selectedLabelId) {
        classNames.push(styles.selectedItem);
      }

      const labelTypes = [...props.labelTypes];
      let labelType = labelTypes.find(lt => lt.id === label.labelTypeId);
      if (labelType == null) {
        labelType = { id: label.labelTypeId, name: label.labelTypeId.toString(), color: 'blue', labelType: LabelTypes.common };
        labelTypes.push(labelType);
      }

      const shortLabelId = label.id != null && !props.isVideo && labelType.labelType === LabelTypes.common ? label.id.substr(0, 3) : '';
      const labelTypesSelector = label.my !== false && label.isRectangleOrLine !== false && labelType.labelType === LabelTypes.common ?
        <LabelTypesDropdown
          currentLabelType={labelType}
          labelTypes={labelTypes.filter(lt => lt.labelType === LabelTypes.common)}
          onLabelTypeSelected={lt => onLabelTypeChanged(label.id, lt)}
          postfix={shortLabelId}
        /> :
         <LabelTypesDropdown
          currentLabelType={labelType}
          labelTypes={[]}
          onLabelTypeSelected={() => {}}
          postfix={shortLabelId}
        />; 

      const coordinateSelector = 
         <LabelTypesDropdown
          currentLabelType={{ id: 0, name: "C", color: "", labelType: LabelTypes.common }}
          labelTypes={[]}
          onLabelTypeSelected={lt => onLabelTypeChanged(label.id, lt)}
          postfix={getLabelCoordinates(label)}
        />;

      const showHide = (user.me && props.labelsVisibility.indexOf(label.id) >= 0) || (!user.me && props.labelsVisibility.indexOf(label.id) < 0) ? 'Show' : 'Hide';
      let { startPosition, endPosition } = label;
      startPosition = Math.trunc(startPosition).toFixed(0);
      endPosition = Math.trunc(endPosition).toFixed(0);

      let note = label.id != null ? htmlLabelNotes.current[label.id] : label.note;
      if (note === undefined && label.id != null) {
        if (label.note == null || label.note.indexOf('\n') < 0) {
          note = label.note;
        } else {
          note = '<div>' + label.note.split(' \n').join('&nbsp;</div><div>').split('\n').join('</div><div>') + '</div>';
        }

        if (label.id != null)
          htmlLabelNotes.current[label.id] = note;
      }

      return <li key={label.id != null ? label.id : `detection${i}`} className={classNames.join(' ')} style={{ backgroundColor: labelType.color }} onClick={e => onLabelClick(e, label.id)}>
        <div>
          {labelTypesSelector} {showCoordinates ? coordinateSelector : null}
          <div className={styles.itemCommands}>
            {label.my !== false ?
              <a href='/' className={styles.itemDelete} onClick={(e) => onLabelDelete(e, label.id)}>Delete</a> : null}
            {!props.isVideo &&
            <a href='/' className={styles.itemShowHide} onClick={e => onLabelVisibilityChanged(e, label.id)}>{showHide}</a>}
            {labelType.labelType !== LabelTypes.common ? null :
              <a href='/' className={styles.itemCrop} onClick={e => onLabelCrop(e, label.id)}>{props.isVideo ? 'Clip' : 'Crop'}</a>}
          </div>
        </div>
        {props.isVideo && <div className={styles.frames}>
          {label.startFrame === label.endFrame ? `Frame: ${label.startFrame}` : `Frames: ${label.startFrame}-${label.endFrame}`}
          {` (${(label.endPosition - label.startPosition).toFixed(1)}sec)`}
          <span>  </span>
          {startPosition === endPosition ? `Position: ${startPosition}s` : `Position: ${startPosition}-${endPosition}sec`}
        </div>}
        <div>
          <ContentEditable className={styles.itemNote}
            html={note}
            disabled={label.my === false || label.isRectangleOrLine === false || labelType.labelType === LabelTypes.line}
            onChange={e => onLabelNoteChanged(e, label.id)} />
        </div>
      </li>;
    });
  }

  function renderUserClassificationLabels(user: User, labels: Array<LiveLabel>) {
    const labelTypes = [...props.labelTypes];
    const items = labels.map(label => {
      let labelType = labelTypes.find(lt => lt.id === label.labelTypeId);
      if (labelType == null) {
        labelType = { id: label.labelTypeId, name: label.labelTypeId.toString(), color: 'blue', labelType: LabelTypes.common };
        labelTypes.push(labelType);
      }

      return <div key={labelType.id} className={styles.classificationItem} style={{ backgroundColor: labelType.color }}>
        {labelType.name}{label.additionalInfo !== "" && ` (${label.additionalInfo})`}
        {user.me &&
        <i className='fa fa-times-circle cursor-pointer' onClick={(e) => onLabelDelete(e, label.id)} />}
      </div>;
    });

    return <div key="classification-items" className={styles.classificationItems}>
      {items}
      {user.me && <LabelTypesDropdown key="add-label-tag"
        currentLabelType={null}
        onLabelTypeSelected={onClassificationLabelTypeSelected}
        labelTypes={labelTypes.filter(lt => lt.labelType === LabelTypes.common && !labels.some(lbl => lbl.labelTypeId === lt.id))}
        postfix={''}
        placeholder={'Add label tag'}
      />}
    </div>;
  }

  const labelUsers = users.filter(u => props.labels.some(lbl => lbl.userId === u.id) || u.me);
  labelUsers.sort((u1, u2) => {
    if (u1.me)
      return -1;

    if (u2.me)
      return 1;

    return u1.displayName < u2.displayName ? -1 : (u1.displayName === u2.displayName ? 0 : 1);
  });

  const usersGroups = labelUsers.map(user => {
    const labels = props.labels.filter(lbl => lbl.new !== true).filter(lbl => user.me ? lbl.my !== false : lbl.userId === user.id);

    let note = '';
    const notes = props.notes.filter(n => n.user != null && n.user.id === user.id);
    if (notes.length > 0) {
      note = htmlItemNotes.current[user.id];
      if (note === undefined) {
        note = notes.length > 0 ? notes[0].note : '';
        if (note.indexOf('\n') >= 0) {
          note = '<div>' + note.split(' \n').join('&nbsp;</div><div>').split('\n').join('</div><div>') + '</div>';
        }
        htmlItemNotes.current[user.id] = note;
      }
    }

    const hasVisibleLabels = labels.some(lbl => user.me ? props.labelsVisibility.indexOf(lbl.id) < 0 : props.labelsVisibility.indexOf(lbl.id) >= 0);
    const hasHiddenLabels = labels.some(lbl => user.me ? props.labelsVisibility.indexOf(lbl.id) >= 0 : props.labelsVisibility.indexOf(lbl.id) < 0);
    return <div key={user.id}>
      <div className={styles.user}>
        {user.me ? 'My' : user.displayName}
        {!props.isVideo && <div className={styles.itemCommands}>
          {hasHiddenLabels ?
            <a href='/' className={styles.itemShowHide} onClick={e => onUserVisibilityChanged(e, user, true)}>Show</a> : null}
          {hasVisibleLabels ?
            <a href='/' className={styles.itemShowHide} onClick={e => onUserVisibilityChanged(e, user, false)}>Hide</a> : null}
        </div>}
      </div>
      {(user.me || note !== '') && <div>
        Information Box
        <ContentEditable className={styles.itemNote}
          html={note}
          disabled={!user.me}
          onChange={e => onItemNoteChanged(e, user)} />
      </div>}
      {renderUserLabels(user, labels)}
    </div>;
  });

  return (
    <div className={props.className}>
      <a href='/' onClick={onDeleteAll}>Delete all</a>
      {!props.isVideo && <label className={styles.showBoxesLabel}>
        <input type='checkbox' className={styles.showBoxesCheckbox} checked={showCoordinates} onChange={onShowCoordinatesChanged} />
        Show Coordinates
      </label>}
      <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
        {usersGroups}
      </ul>
    </div>
  );
}
