// @flow strict
// Copyright (C) 2018-2021 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React, { useCallback, useState } from "react";
import Measure from 'react-measure';
import throttle from 'lodash/throttle';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import styles from "./PdfViewer.module.css";
import { apiEndpoint } from "../../utils/http";
import type { FolderItem } from '../../rpc/model';

type PdfViewerProps = {
  item: FolderItem,
}

export default function PdfViewer(props: PdfViewerProps): Node {
  const [pageCount, setPageCount] = useState<number>(0);
  const [wrapperWidth, setWrapperWidth] = useState<number>(0);

  function onPdfDocumentLoadSuccess({ numPages }) {
    setPageCount(numPages);
  }

  const setWrapperDimensions = useCallback(throttle((w) => {
    setWrapperWidth(w);
  }, 500), []);

  return <div className={styles.root}>
    <Measure
      bounds
      onResize={(contentRect) => setWrapperDimensions(contentRect.bounds.width, contentRect.bounds.height)}
    >
      {({ measureRef }) => (<div ref={measureRef}>
          <Document
            file={apiEndpoint() + `/api/image/${props.item.id}`}
            onLoadSuccess={onPdfDocumentLoadSuccess}
          >
            {
              Array.from(
                new Array(pageCount),
                (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    width={wrapperWidth}
                  />
                ),
              )
            }
          </Document>
        </div>
      )}
    </Measure>
  </div>;
}