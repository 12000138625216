// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useRef, useState } from 'react';
import type { Node } from "react";
import styles from './FolderItemNotes.module.css';
import type { FolderItem, FolderItemNote, User } from "../../rpc/model";
import { apiEndpoint } from "../../utils/http";
import ContentEditable from 'react-contenteditable';
import { FolderService } from '../../rpc/folders';

type FolderItemNotesProps = {
  item: FolderItem,
  me: User,
  notes: Array<FolderItemNote>,
  onItemNoteChanged: (whom: User, note: string) => Promise<void>,
}

// List of notes for a folder item
export default function FolderItemNotes(props: FolderItemNotesProps): Node {
  const [users, setUsers] = useState<Array<User>>([]);
  const htmlNotes = useRef<{[string]: string}>({});

  useEffect(() => {
    async function fetchUsers() {
      const resp = await new FolderService(apiEndpoint()).grantedUsers({ folderId: props.item.folderId });
      setUsers(resp.users);
    }

    fetchUsers();
  }, [props.item.folderId]);

  function onItemNoteChanged(e: SyntheticEvent<HTMLInputElement>, whom: User) {
    // $FlowIgnore[prop-missing]
    let note = e.target.value;

    htmlNotes.current[`${props.me.id}_${whom.id}`] = note;

    note = note.split('&nbsp;').join(' ');
    if (note.indexOf('<div>') === 0) {
      note = note.replace('<div>', '');
    }
    note = note.split('<div>').join('\n');
    note = note.split('</div>').join('');
    note = note.split('<br>').join('');
    props.onItemNoteChanged(whom, note);
  }

  const otherUsers = users.filter(u => u.id !== props.me.id);
  otherUsers.sort((u1, u2) => {
    return u1.displayName < u2.displayName ? -1 : (u1.displayName === u2.displayName ? 0 : 1);
  });

  const usersGroups = otherUsers.map(user => {
    let fromMeNote = '';
    const fromMeNotes = props.notes.filter(n => n.whom != null && n.whom.id === user.id);
    if (fromMeNotes.length > 0) {
      fromMeNote = htmlNotes.current[`${props.me.id}_${user.id}`];
      if (fromMeNote === undefined) {
        fromMeNote = fromMeNotes.length > 0 ? fromMeNotes[0].note : '';
        if (fromMeNote.indexOf('\n') >= 0) {
          fromMeNote = '<div>' + fromMeNote.split(' \n').join('&nbsp;</div><div>').split('\n').join('</div><div>') + '</div>';
        }
        htmlNotes.current[`${props.me.id}_${user.id}`] = fromMeNote;
      }
    }

    let toMeNote = '';
    const toMeNotes = props.notes.filter(n => n.user != null && n.user.id === user.id);
    if (toMeNotes.length > 0) {
      toMeNote = htmlNotes.current[`${user.id}_${props.me.id}`];
      if (toMeNote === undefined) {
        toMeNote = toMeNotes.length > 0 ? toMeNotes[0].note : '';
        if (toMeNote.indexOf('\n') >= 0) {
          toMeNote = '<div>' + toMeNote.split(' \n').join('&nbsp;</div><div>').split('\n').join('</div><div>') + '</div>';
        }
        htmlNotes.current[`${user.id}_${props.me.id}`] = toMeNote;
      }
    }

    return <div key={user.id}>
      <div className={styles.user}>
        {user.displayName}
      </div>
      <div>
        <ContentEditable className={styles.itemNote}
          html={fromMeNote}
          onChange={e => onItemNoteChanged(e, user)}
        />
        {toMeNote !== '' && <ContentEditable className={`${styles.itemNote} ${styles.itemOtherNote}`}
          html={toMeNote}
          disabled={true}
          onChange={() => {}}
        />}
      </div>
    </div>;
  });

  return (
    <div className={styles.root}>
      <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
        {usersGroups}
      </ul>
    </div>
  );
}
