// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import styles from './EmailNotifications.module.css';
import { ActivityService } from "../../rpc/activity";
import { SettingService } from "../../rpc/settings";
import Button from "react-bootstrap/lib/Button";
import ToggleButton from "react-toggle-button";
import { Check, X } from "../toggle-button/buttons";
import type { User } from "../../rpc/model";
import { UserService } from "../../rpc/users";
import { apiEndpoint } from "../../utils/http";

export default function EmailNotifications(): Node {
  const [email, setEmail] = useState<string>("");
  const [actions, setActions] = useState<Array<string>>([]);
  const [disabledUsers, setDisabledUsers] = useState<Array<string>>([]);
  const [users, setUsers] = useState<Array<User>>([]);

  useEffect(() => {
    new ActivityService("").visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
  }, []);

  useEffect(() => {
    async function fetchSettings() {
      const resp = await new SettingService("").get({ names: ["notify.email", "notify.actions", "notify.disabledUsers"] });
      setEmail(resp.settings[0].value);
      setActions(resp.settings[1].value !== "" ? resp.settings[1].value.split(",") : []);
      setDisabledUsers(resp.settings[2].value !== "" ? resp.settings[2].value.split(",") : []);
    }

    fetchSettings();
  }, []);

  useEffect(() => {
    async function fetchUsers() {
      const resp = await new UserService(apiEndpoint()).users();
      setUsers(resp.users);
    }

    fetchUsers();
  }, []);

  async function onEmailClick() {
    let newEmail = window.prompt("Admin Email", email);
    if (newEmail == null)
      return;

    newEmail = newEmail.trim();

    await new SettingService("").addOrEdit({ setting: { name: "notify.email", value: newEmail } });
    setEmail(newEmail);
  }

  async function onActionChange(name: string) {
    const newActions = actions.indexOf(name) >= 0 ?
      actions.filter(action => action !== name) :
      [...actions, name];

    await new SettingService("").addOrEdit({ setting: { name: "notify.actions", value: newActions.join(",") } });
    setActions(newActions);
  }

  async function onUserNotifyChange(user: User) {
    const newDisabledUsers = disabledUsers.indexOf(user.id) >= 0 ?
      disabledUsers.filter(id => id !== user.id) :
      [...disabledUsers, user.id];

    await new SettingService("").addOrEdit({ setting: { name: "notify.disabledUsers", value: newDisabledUsers.join(",") } });
    setDisabledUsers(newDisabledUsers);
  }

  const loginAction = "login";
  const uploadAction = "sde-upload";
  const labelAction = "label-actions";

  return <div className={styles.root}>
    <h4>Email Notifications</h4>
    <div>
      <span className={styles.header}>Admin Email:   </span>
      <Button bsStyle="info" onClick={onEmailClick}>
        {email !== "" ? email : "not specified"}
      </Button>
    </div>
    <h5>Actions</h5>
    <div className={styles.action}>
      <ToggleButton
        inactiveLabel={<X />}
        activeLabel={<Check />}
        value={actions.indexOf(loginAction) >= 0}
        onToggle={() => onActionChange(loginAction)} />
      <span className={styles.actionTitle}>User Login</span>
    </div>
    <div className={styles.action}>
      <ToggleButton
        inactiveLabel={<X />}
        activeLabel={<Check />}
        value={actions.indexOf(uploadAction) >= 0}
        onToggle={() => onActionChange(uploadAction)} />
      <span className={styles.actionTitle}>Upload SDE items</span>
    </div>
    <div className={styles.action}>
      <ToggleButton
        inactiveLabel={<X />}
        activeLabel={<Check />}
        value={actions.indexOf(labelAction) >= 0}
        onToggle={() => onActionChange(labelAction)} />
      <span className={styles.actionTitle}>Label Actions</span>
    </div>
    <h5>Users</h5>
    <table className={styles.userTable}>
      <thead>
      <tr>
        <th>Login</th>
        <th>Email</th>
        <th>Display Name</th>
        <th>Notify</th>
      </tr>
      </thead>
      <tbody>
      {users.map(u => <tr key={u.id}>
        <td>{u.login}</td>
        <td>{u.email}</td>
        <td>{u.displayName}</td>
        <td>
          <ToggleButton
            inactiveLabel={<X />}
            activeLabel={<Check />}
            value={disabledUsers.indexOf(u.id) < 0}
            onToggle={() => onUserNotifyChange(u)} />
        </td>
      </tr>)}
      </tbody>
    </table>
  </div>;
}
