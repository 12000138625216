//@flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React from "react";
import type { Node } from "react";
import Dropzone from "react-dropzone";

type Props = {
  accept?: string | string[],
  label?: string,
  cls?: string,
  multiple?: boolean,
  onDrop?: (acceptedFiles: Array<File>) => void | Promise<void>,
}
export default function CustomDropZone(props: Props): Node {
  const { cls, label, accept, multiple, onDrop } = props;
  const allowMultiple = (multiple == null) ? true : multiple;
  return (
    <Dropzone accept={accept} multiple={allowMultiple} onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => {
        const rootProps = { ...getRootProps() };
        delete rootProps.tabIndex;
        return <div {...rootProps} className={cls}>
            <input {...getInputProps()} />
          {label}
          </div>;
      }}
    </Dropzone>
  );

}
