// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from "react";
import type { Node } from "react";
import styles from "./FolderStatisticsView.module.css";
import { getFolderCaptionName } from "../../utils/folder";
import Button from "../Button/Button";
import type { Folder } from "../../rpc/model";
import { apiEndpoint } from "../../utils/http";
import type { FolderAutoDetectStatisticsItem } from "../../rpc/folders";
import { FolderService } from "../../rpc/folders";

type Props = {
  folder: Folder,
  onClose: () => void,
}

export default function FolderAutoDetectStatisticsView(props: Props): Node {
  const [stat, setStat] = useState<?Array<FolderAutoDetectStatisticsItem>>(null);

  useEffect(() => {
    async function fetchStat() {
      const resp = await new FolderService(apiEndpoint()).autoDetectStatistics({ folderId: props.folder.id });
      setStat(resp.items);
    }

    fetchStat();
  }, [props.folder.id]);

  return <React.Fragment>
    <div className={styles.statsBox}>
      <div>
        <h4>
          {getFolderCaptionName(props.folder)}
          <span> Auto Detect Stat </span>
        </h4>
      </div>
      <div>
        <Button onClick={props.onClose}>Close</Button>
      </div>
    </div>

    { !stat &&
      <div>Loading Statistics Wait...</div>
    }

    { stat &&
       <table className={styles.statTable}>
        <thead>
        <tr>
          <th>Batch Job Name</th>
          <th>Engine</th>
          <th>Launch Size</th>
          <th>Launch Settings</th>
          <th>Start Time</th>
          <th>End Time</th>
          <th>Duration (sec)</th>
          <th>Average Time per SDE (sec)</th>
          <th>Max SDE Processing Time (sec)</th>
          <th>Min SDE Processing Time (sec)</th>
          <th>Number of SDEs Analyzed</th>
          <th>Total Number of Labels</th>
          <th>Label wise Breakdown</th>
        </tr>
        </thead>
        <tbody>
        {stat.map(e => <tr key={e.name}>
           <td>{e.name}</td>
           <td>{e.engine}</td>
           <td>{e.totalCount}</td>
          <td className={styles.preLine}>{e.engineParameters.join('\n')}</td>
           <td>{e.startedAt}</td>
           <td>{e.finishedAt}</td>
           <td>{e.durationSec > 0 ? e.durationSec : ''}</td>
           <td>{(e.averagePaceMs/1000).toFixed(1)}</td>
           <td>{(e.maxPaceMs/1000).toFixed(1)}</td>
           <td>{(e.minPaceMs/1000).toFixed(1)}</td>
           <td>{e.succeedCount}</td>
           <td>{e.totalLabelCount}</td>
           <td className={styles.preLine}>{e.labelCounts.join('\n')}</td>
        </tr>)}
        </tbody>
      </table>}
  </React.Fragment>;
}
