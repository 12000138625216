// @flow strict
/* eslint-disable */
import { sendTwirpRequest } from "./twirp";
import type { StorefrontApp, StorefrontAppJSON } from "./model";
import { StorefrontAppToJSON, JSONToStorefrontApp } from "./model";
import type { StorefrontAppRight, StorefrontAppRightJSON } from "./model";
import { StorefrontAppRightToJSON, JSONToStorefrontAppRight } from "./model";
export type StorefrontAppsRequest = {
  myApps: boolean;
  category: string;
}

export type StorefrontAppsRequestJSON = {
  my_apps?: boolean;
  category?: string;
}

export function StorefrontAppsRequestToJSON(m: StorefrontAppsRequest): StorefrontAppsRequestJSON {
  return {
    my_apps: m.myApps,
    category: m.category,
  };
}

export function JSONToStorefrontAppsRequest(m: StorefrontAppsRequestJSON): StorefrontAppsRequest {
  return {
    myApps: m.my_apps != null ? m.my_apps : false,
    category: m.category != null ? m.category : "",
  };
}

export type StorefrontAppsResponse = {
  apps: Array<StorefrontApp>;
}

export type StorefrontAppsResponseJSON = {
  apps?: Array<StorefrontAppJSON>;
}

export function StorefrontAppsResponseToJSON(m: StorefrontAppsResponse): StorefrontAppsResponseJSON {
  return {
    apps: m.apps.map(StorefrontAppToJSON),
  };
}

export function JSONToStorefrontAppsResponse(m: StorefrontAppsResponseJSON): StorefrontAppsResponse {
  return {
    apps: m.apps != null ? m.apps.map(JSONToStorefrontApp) : [],
  };
}

export type StorefrontAddOrEditAppRequest = {
  app: ?StorefrontApp;
}

export type StorefrontAddOrEditAppRequestJSON = {
  app?: StorefrontAppJSON;
}

export function StorefrontAddOrEditAppRequestToJSON(m: StorefrontAddOrEditAppRequest): StorefrontAddOrEditAppRequestJSON {
  return {
    app: m.app != null ? StorefrontAppToJSON(m.app) : undefined,
  };
}

export function JSONToStorefrontAddOrEditAppRequest(m: StorefrontAddOrEditAppRequestJSON): StorefrontAddOrEditAppRequest {
  return {
    app: m.app != null ? JSONToStorefrontApp(m.app) : null,
  };
}

export type StorefrontAddOrEditAppResponse = {
  app: ?StorefrontApp;
}

export type StorefrontAddOrEditAppResponseJSON = {
  app?: StorefrontAppJSON;
}

export function StorefrontAddOrEditAppResponseToJSON(m: StorefrontAddOrEditAppResponse): StorefrontAddOrEditAppResponseJSON {
  return {
    app: m.app != null ? StorefrontAppToJSON(m.app) : undefined,
  };
}

export function JSONToStorefrontAddOrEditAppResponse(m: StorefrontAddOrEditAppResponseJSON): StorefrontAddOrEditAppResponse {
  return {
    app: m.app != null ? JSONToStorefrontApp(m.app) : null,
  };
}

export type StorefrontDeleteAppRequest = {
  appId: number;
}

export type StorefrontDeleteAppRequestJSON = {
  app_id?: number;
}

export function StorefrontDeleteAppRequestToJSON(m: StorefrontDeleteAppRequest): StorefrontDeleteAppRequestJSON {
  return {
    app_id: m.appId,
  };
}

export function JSONToStorefrontDeleteAppRequest(m: StorefrontDeleteAppRequestJSON): StorefrontDeleteAppRequest {
  return {
    appId: m.app_id != null ? m.app_id : 0,
  };
}

export type StorefrontDeleteAppResponse = {
}

export type StorefrontDeleteAppResponseJSON = {
}

export function StorefrontDeleteAppResponseToJSON(m: StorefrontDeleteAppResponse): StorefrontDeleteAppResponseJSON {
  return {
  };
}

export function JSONToStorefrontDeleteAppResponse(m: StorefrontDeleteAppResponseJSON): StorefrontDeleteAppResponse {
  return {
  };
}

export type StorefrontSortAppsRequest = {
  category: string;
  sortedApps: Array<number>;
}

export type StorefrontSortAppsRequestJSON = {
  category?: string;
  sorted_apps?: Array<number>;
}

export function StorefrontSortAppsRequestToJSON(m: StorefrontSortAppsRequest): StorefrontSortAppsRequestJSON {
  return {
    category: m.category,
    sorted_apps: m.sortedApps,
  };
}

export function JSONToStorefrontSortAppsRequest(m: StorefrontSortAppsRequestJSON): StorefrontSortAppsRequest {
  return {
    category: m.category != null ? m.category : "",
    sortedApps: m.sorted_apps != null ? m.sorted_apps : [],
  };
}

export type StorefrontSortAppsResponse = {
  apps: Array<StorefrontApp>;
}

export type StorefrontSortAppsResponseJSON = {
  apps?: Array<StorefrontAppJSON>;
}

export function StorefrontSortAppsResponseToJSON(m: StorefrontSortAppsResponse): StorefrontSortAppsResponseJSON {
  return {
    apps: m.apps.map(StorefrontAppToJSON),
  };
}

export function JSONToStorefrontSortAppsResponse(m: StorefrontSortAppsResponseJSON): StorefrontSortAppsResponse {
  return {
    apps: m.apps != null ? m.apps.map(JSONToStorefrontApp) : [],
  };
}

export type StorefrontRightsRequest = {
}

export type StorefrontRightsRequestJSON = {
}

export function StorefrontRightsRequestToJSON(m: StorefrontRightsRequest): StorefrontRightsRequestJSON {
  return {
  };
}

export function JSONToStorefrontRightsRequest(m: StorefrontRightsRequestJSON): StorefrontRightsRequest {
  return {
  };
}

export type StorefrontRightsResponse = {
  rights: Array<StorefrontAppRight>;
}

export type StorefrontRightsResponseJSON = {
  rights?: Array<StorefrontAppRightJSON>;
}

export function StorefrontRightsResponseToJSON(m: StorefrontRightsResponse): StorefrontRightsResponseJSON {
  return {
    rights: m.rights.map(StorefrontAppRightToJSON),
  };
}

export function JSONToStorefrontRightsResponse(m: StorefrontRightsResponseJSON): StorefrontRightsResponse {
  return {
    rights: m.rights != null ? m.rights.map(JSONToStorefrontAppRight) : [],
  };
}

export type StorefrontGrantRightRequest = {
  appId: number;
  userId: string;
  permission: string;
}

export type StorefrontGrantRightRequestJSON = {
  app_id?: number;
  user_id?: string;
  permission?: string;
}

export function StorefrontGrantRightRequestToJSON(m: StorefrontGrantRightRequest): StorefrontGrantRightRequestJSON {
  return {
    app_id: m.appId,
    user_id: m.userId,
    permission: m.permission,
  };
}

export function JSONToStorefrontGrantRightRequest(m: StorefrontGrantRightRequestJSON): StorefrontGrantRightRequest {
  return {
    appId: m.app_id != null ? m.app_id : 0,
    userId: m.user_id != null ? m.user_id : "",
    permission: m.permission != null ? m.permission : "",
  };
}

export type StorefrontGrantRightResponse = {
}

export type StorefrontGrantRightResponseJSON = {
}

export function StorefrontGrantRightResponseToJSON(m: StorefrontGrantRightResponse): StorefrontGrantRightResponseJSON {
  return {
  };
}

export function JSONToStorefrontGrantRightResponse(m: StorefrontGrantRightResponseJSON): StorefrontGrantRightResponse {
  return {
  };
}

export class StorefrontService {
  hostname: string;
  pathPrefix: string = "/rpc.StorefrontService/";

  constructor(hostname: string) {
    this.hostname = hostname;
  }

  async apps(storefrontAppsRequest: StorefrontAppsRequest): Promise<StorefrontAppsResponse> {
    const url = this.hostname + this.pathPrefix + "Apps";
    const body: StorefrontAppsRequestJSON = StorefrontAppsRequestToJSON(storefrontAppsRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToStorefrontAppsResponse(data);
  }

  async addOrEditApp(storefrontAddOrEditAppRequest: StorefrontAddOrEditAppRequest): Promise<StorefrontAddOrEditAppResponse> {
    const url = this.hostname + this.pathPrefix + "AddOrEditApp";
    const body: StorefrontAddOrEditAppRequestJSON = StorefrontAddOrEditAppRequestToJSON(storefrontAddOrEditAppRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToStorefrontAddOrEditAppResponse(data);
  }

  async deleteApp(storefrontDeleteAppRequest: StorefrontDeleteAppRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "DeleteApp";
    const body: StorefrontDeleteAppRequestJSON = StorefrontDeleteAppRequestToJSON(storefrontDeleteAppRequest);
    await sendTwirpRequest(url, body);
  }

  async sortApps(storefrontSortAppsRequest: StorefrontSortAppsRequest): Promise<StorefrontSortAppsResponse> {
    const url = this.hostname + this.pathPrefix + "SortApps";
    const body: StorefrontSortAppsRequestJSON = StorefrontSortAppsRequestToJSON(storefrontSortAppsRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToStorefrontSortAppsResponse(data);
  }

  async rights(): Promise<StorefrontRightsResponse> {
    const url = this.hostname + this.pathPrefix + "Rights";
    const data = await sendTwirpRequest(url, {});
    return JSONToStorefrontRightsResponse(data);
  }

  async grantRight(storefrontGrantRightRequest: StorefrontGrantRightRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "GrantRight";
    const body: StorefrontGrantRightRequestJSON = StorefrontGrantRightRequestToJSON(storefrontGrantRightRequest);
    await sendTwirpRequest(url, body);
  }
}
