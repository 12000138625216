// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import { arrayMove } from "react-sortable-hoc";
import styles from './StoreFront.module.css';
import App from './StoreFrontApp.js';
import type { StorefrontApp, User } from "../../rpc/model";
import { StorefrontService } from "../../rpc/storefront";
import Button from "../Button/Button";
import Modal from "react-modal";
import StoreFrontAppForm from "./StoreFrontAppForm";
import CustomDropZone from "../CustomDropZone/CustomDropZone";
import { apiPost, apiEndpoint } from "../../utils/http";

type StoreFrontProps = {
  history: {
    push: (path: string) => void,
  },
  user: ?User,
  myAppsMode: boolean,
}

export default function StoreFront(props: StoreFrontProps): Node {
  const [apps, setApps] = useState<Array<StorefrontApp>>([]);
  const [editingApp, setEditingApp] = useState<?StorefrontApp>(null);

  useEffect(() => {
    async function fetchApps() {
      const resp = await new StorefrontService(apiEndpoint()).apps({ myApps: props.myAppsMode, category: "" });
      setApps(resp.apps);
    }

    fetchApps();
  }, [props.myAppsMode]);

  function addVisualizationApp() {
    addApp("visualization");
  }

  function addAnalyticsApp() {
    addApp("analytics");
  }

  function addDashboardApp() {
    addApp("dashboard");
  }

  function addExperimentalFeatureApp() {
    addApp("experimental-features");
  }

  function exportStorefront() {
    if (props.myAppsMode)
      return;

    window.location.assign("/api/storefront/export");
  }

  function showRights() {
    if (props.myAppsMode)
      return;

    props.history.push("/storefront/rights");
  }

  async function importStorefront(acceptedFiles: Array<File>) {
    if (props.myAppsMode)
      return;

    const file = acceptedFiles[0];
    const data = new FormData();
    data.append("file", file, file.name);
    await apiPost(`/api/storefront/import`, data, { headers: { "content-type": "multipart/form-data" } });

    const resp = await new StorefrontService(apiEndpoint()).apps({ myApps: props.myAppsMode, category: "" });
    setApps(resp.apps);
  }

  function addApp(category: string) {
    if (props.myAppsMode)
      return;

    setEditingApp({
      id: -1,
      category: category,
      title: "",
      description: "",
      visible: true,
      imageId: "",
      imageUrl: "",
      appUrl: "",
      sortIndex: 0,
      infoUrl: "",
    });
  }

  async function onEditingAppApply(app: StorefrontApp, imageFile: ?File) {
    if (props.myAppsMode)
      return;

    if (imageFile != null) {
      const data = new FormData();
      data.append('image-file', imageFile);
      const resp = await apiPost(`/api/blob`, data, { headers: { 'content-type': 'multipart/form-data' } });
      const blobIDs: Array<string> = resp.data;
      if (blobIDs.length > 0)
        app.imageId = blobIDs[0];
    }

    const resp = await new StorefrontService(apiEndpoint()).addOrEditApp({ app: app });
    const newApp = resp.app;

    if (newApp != null) {
      if (app.id <= 0) {
        setApps(apps => [...apps, newApp]);
      } else {
        setApps(apps => apps.map(a => a.id === app.id ? newApp : a));
      }
    }
    setEditingApp(null);
  }

  function onEditingAppCancel() {
    setEditingApp(null);
  }

  function editApp(app: StorefrontApp) {
    if (props.myAppsMode)
      return;

    setEditingApp(app);
  }

  async function deleteApp(app: StorefrontApp) {
    if (props.myAppsMode)
      return;

    if (!window.confirm(`You are going to delete the app '${app.title}'.\nAre you sure?`))
      return;

    await new StorefrontService(apiEndpoint()).deleteApp({ appId: app.id });
    setApps(apps => apps.filter(a => a.id !== app.id));
  }

  async function changeAppVisible(app: StorefrontApp) {
    if (props.myAppsMode)
      return;

    const editingApp = apps.find(a => a.id === app.id);
    if (editingApp != null)
      await onEditingAppApply({ ...editingApp, visible: !editingApp.visible });
  }

  async function moveApp(app: StorefrontApp, delta: number) {
    if (props.myAppsMode)
      return;

    const categoryApps = apps.filter(a => a.category === app.category);

    const index = categoryApps.findIndex(a => a.id === app.id);
    let newIndex = index + delta;
    if (newIndex < 0)
      newIndex = 0;
    else if (newIndex >= categoryApps.length)
      newIndex = categoryApps.length - 1;

    if (index === newIndex)
      return;

    const newCategoryApps = arrayMove(categoryApps, index, newIndex);
    const resp = await new StorefrontService(apiEndpoint()).sortApps({
      category: app.category,
      sortedApps: newCategoryApps.map(a => a.id),
    });
    setApps(resp.apps);
  }

  const isAdmin = props.user != null && props.user.isAdmin && !props.myAppsMode;

  const visualApps = apps.filter(app => app.category === "visualization").map((app, index) =>
    <App key={index} app={app} myAppMode={props.myAppsMode} isAdmin={isAdmin} onEdit={editApp} onDelete={deleteApp} onVisibleChanged={changeAppVisible} onMove={moveApp} />
  );

  const analyticsApps = apps.filter(app => app.category === "analytics").map((app, index) =>
    <App key={index} app={app} myAppMode={props.myAppsMode} isAdmin={isAdmin} onEdit={editApp} onDelete={deleteApp} onVisibleChanged={changeAppVisible} onMove={moveApp} />
  );

  const dashboards = apps.filter(app => app.category === "dashboard").map((app, index) =>
    <App key={index} app={app} myAppMode={props.myAppsMode} isAdmin={isAdmin} onEdit={editApp} onDelete={deleteApp} onVisibleChanged={changeAppVisible} onMove={moveApp} />
  );

  const experimentalFeatures = apps.filter(app => isAdmin && app.category === "experimental-features").map((app, index) =>
    <App key={index} app={app} myAppMode={props.myAppsMode} isAdmin={isAdmin} onEdit={editApp} onDelete={deleteApp} onVisibleChanged={changeAppVisible} onMove={moveApp} />
  );

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "50%",
      width: "30%",
      bottom: "auto",
      marginRight: "-30%",
      transform: "translate(-50%, -50%)",
    }
  };

  return (
    <div>
      {isAdmin && <div>
        <Button onClick={exportStorefront}>Export</Button>
        <Button>
          <CustomDropZone onDrop={importStorefront} label="Import" accept={["application/zip", ".zip"]} multiple={false} />
        </Button>
        <Button onClick={showRights}>User Access for Apps</Button>
      </div>}
      <div className='container-fluid'>
        <div className='row'>
          <div className={styles.utilityHead}>
            <span>Visualization/Reporting Apps</span>
            {isAdmin && <Button onClick={addVisualizationApp}>New App</Button>}
          </div>
          {visualApps}

          <div className="clearfix" />
          <div className={styles.utilityHead}>
            <span>Analytics apps</span>
            {isAdmin && <Button onClick={addAnalyticsApp}>New App</Button>}
          </div>
          {analyticsApps}

          <div className="clearfix" />
          <div className={styles.utilityHead}>
            <span>Dashboards</span>
            {isAdmin && <Button onClick={addDashboardApp}>New App</Button>}
          </div>
          {dashboards}

          {isAdmin && <React.Fragment>
            <div className="clearfix" />
            <div className={styles.utilityHead}>
              <span>Experimental Features</span>
              {isAdmin && <Button onClick={addExperimentalFeatureApp}>New App</Button>}
            </div>
            {experimentalFeatures}
          </React.Fragment>}
        </div>
      </div>
      {editingApp != null && <Modal isOpen={true} style={modalStyle}>
        <StoreFrontAppForm app={editingApp}
          applyChanges={onEditingAppApply}
          cancel={onEditingAppCancel}
        />
      </Modal>}
    </div>
  );
}
