// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useState } from 'react';
import type { Node } from "react";
import styles from './TextEditor.module.css';

type TextEditorProps = {
  title: string,
  text: string,
  width: number,
  height: number,
  ok: (text: string) => Promise<void>,
  cancel: () => void,
}

export default function TextEditor(props: TextEditorProps): Node {
  const [prevText, setPrevText] = useState<string>("");
  const [liveText, setLiveText] = useState<string>("");

  if (props.text !== prevText) {
    setPrevText(props.text);
    setLiveText(props.text);
  }

  function onTextChange(e: SyntheticInputEvent<HTMLTextAreaElement>) {
    setLiveText(e.target.value);
  }

  function onOkClick() {
    props.ok(liveText);
  }

  function onCancelClick() {
    props.cancel();
  }

  return <div className={styles.root} style={{ width: props.width, height: props.height }}>
    <div className={styles.title}>{props.title}</div>
    <textarea className={styles.area} value={liveText} onChange={onTextChange} />
    <div className={styles.buttons}>
      <button className={styles.button} onClick={onOkClick}>OK</button>
      <button className={styles.button} onClick={onCancelClick}>Cancel</button>
    </div>
  </div>;
}
