// @flow strict
/* eslint-disable */
import { sendTwirpRequest } from "./twirp";
import type { User, UserJSON } from "./model";
import { UserToJSON, JSONToUser } from "./model";
import type { UserNote, UserNoteJSON } from "./model";
import { UserNoteToJSON, JSONToUserNote } from "./model";
export type UserMeRequest = {
}

export type UserMeRequestJSON = {
}

export function UserMeRequestToJSON(m: UserMeRequest): UserMeRequestJSON {
  return {
  };
}

export function JSONToUserMeRequest(m: UserMeRequestJSON): UserMeRequest {
  return {
  };
}

export type UserMeResponse = {
  user: ?User;
}

export type UserMeResponseJSON = {
  user?: UserJSON;
}

export function UserMeResponseToJSON(m: UserMeResponse): UserMeResponseJSON {
  return {
    user: m.user != null ? UserToJSON(m.user) : undefined,
  };
}

export function JSONToUserMeResponse(m: UserMeResponseJSON): UserMeResponse {
  return {
    user: m.user != null ? JSONToUser(m.user) : null,
  };
}

export type UserUsersRequest = {
}

export type UserUsersRequestJSON = {
}

export function UserUsersRequestToJSON(m: UserUsersRequest): UserUsersRequestJSON {
  return {
  };
}

export function JSONToUserUsersRequest(m: UserUsersRequestJSON): UserUsersRequest {
  return {
  };
}

export type UserUsersResponse = {
  users: Array<User>;
}

export type UserUsersResponseJSON = {
  users?: Array<UserJSON>;
}

export function UserUsersResponseToJSON(m: UserUsersResponse): UserUsersResponseJSON {
  return {
    users: m.users.map(UserToJSON),
  };
}

export function JSONToUserUsersResponse(m: UserUsersResponseJSON): UserUsersResponse {
  return {
    users: m.users != null ? m.users.map(JSONToUser) : [],
  };
}

export type UserSetTierRequest = {
  userId: string;
  tierId: number;
}

export type UserSetTierRequestJSON = {
  user_id?: string;
  tier_id?: number;
}

export function UserSetTierRequestToJSON(m: UserSetTierRequest): UserSetTierRequestJSON {
  return {
    user_id: m.userId,
    tier_id: m.tierId,
  };
}

export function JSONToUserSetTierRequest(m: UserSetTierRequestJSON): UserSetTierRequest {
  return {
    userId: m.user_id != null ? m.user_id : "",
    tierId: m.tier_id != null ? m.tier_id : 0,
  };
}

export type UserSetTierResponse = {
}

export type UserSetTierResponseJSON = {
}

export function UserSetTierResponseToJSON(m: UserSetTierResponse): UserSetTierResponseJSON {
  return {
  };
}

export function JSONToUserSetTierResponse(m: UserSetTierResponseJSON): UserSetTierResponse {
  return {
  };
}

export type UserGetUserRequest = {
  email: string;
}

export type UserGetUserRequestJSON = {
  email?: string;
}

export function UserGetUserRequestToJSON(m: UserGetUserRequest): UserGetUserRequestJSON {
  return {
    email: m.email,
  };
}

export function JSONToUserGetUserRequest(m: UserGetUserRequestJSON): UserGetUserRequest {
  return {
    email: m.email != null ? m.email : "",
  };
}

export type UserGetUserResponse = {
  user: ?User;
}

export type UserGetUserResponseJSON = {
  user?: UserJSON;
}

export function UserGetUserResponseToJSON(m: UserGetUserResponse): UserGetUserResponseJSON {
  return {
    user: m.user != null ? UserToJSON(m.user) : undefined,
  };
}

export function JSONToUserGetUserResponse(m: UserGetUserResponseJSON): UserGetUserResponse {
  return {
    user: m.user != null ? JSONToUser(m.user) : null,
  };
}

export type UserUserProfileRequest = {
  id: string;
}

export type UserUserProfileRequestJSON = {
  id?: string;
}

export function UserUserProfileRequestToJSON(m: UserUserProfileRequest): UserUserProfileRequestJSON {
  return {
    id: m.id,
  };
}

export function JSONToUserUserProfileRequest(m: UserUserProfileRequestJSON): UserUserProfileRequest {
  return {
    id: m.id != null ? m.id : "",
  };
}

export type UserEditUserProfileRequest = {
  userId: string;
  name: string;
  phone: string;
  profileId: string;
}

export type UserEditUserProfileRequestJSON = {
  user_id?: string;
  name?: string;
  phone?: string;
  profile_id?: string;
}

export function UserEditUserProfileRequestToJSON(m: UserEditUserProfileRequest): UserEditUserProfileRequestJSON {
  return {
    user_id: m.userId,
    name: m.name,
    phone: m.phone,
    profile_id: m.profileId,
  };
}

export function JSONToUserEditUserProfileRequest(m: UserEditUserProfileRequestJSON): UserEditUserProfileRequest {
  return {
    userId: m.user_id != null ? m.user_id : "",
    name: m.name != null ? m.name : "",
    phone: m.phone != null ? m.phone : "",
    profileId: m.profile_id != null ? m.profile_id : "",
  };
}

export type UserUserProfileResponse = {
  user: ?User;
  license: string;
}

export type UserUserProfileResponseJSON = {
  user?: UserJSON;
  license?: string;
}

export function UserUserProfileResponseToJSON(m: UserUserProfileResponse): UserUserProfileResponseJSON {
  return {
    user: m.user != null ? UserToJSON(m.user) : undefined,
    license: m.license,
  };
}

export function JSONToUserUserProfileResponse(m: UserUserProfileResponseJSON): UserUserProfileResponse {
  return {
    user: m.user != null ? JSONToUser(m.user) : null,
    license: m.license != null ? m.license : "",
  };
}

export type UserDeleteUserRequest = {
  userId: string;
  mode: string;
}

export type UserDeleteUserRequestJSON = {
  user_id?: string;
  mode?: string;
}

export function UserDeleteUserRequestToJSON(m: UserDeleteUserRequest): UserDeleteUserRequestJSON {
  return {
    user_id: m.userId,
    mode: m.mode,
  };
}

export function JSONToUserDeleteUserRequest(m: UserDeleteUserRequestJSON): UserDeleteUserRequest {
  return {
    userId: m.user_id != null ? m.user_id : "",
    mode: m.mode != null ? m.mode : "",
  };
}

export type UserSendIssueRequest = {
  action: string;
  issue: string;
  note: string;
}

export type UserSendIssueRequestJSON = {
  action?: string;
  issue?: string;
  note?: string;
}

export function UserSendIssueRequestToJSON(m: UserSendIssueRequest): UserSendIssueRequestJSON {
  return {
    action: m.action,
    issue: m.issue,
    note: m.note,
  };
}

export function JSONToUserSendIssueRequest(m: UserSendIssueRequestJSON): UserSendIssueRequest {
  return {
    action: m.action != null ? m.action : "",
    issue: m.issue != null ? m.issue : "",
    note: m.note != null ? m.note : "",
  };
}

export type UserProfilesResponse = {
  profiles: Array<string>;
}

export type UserProfilesResponseJSON = {
  profiles?: Array<string>;
}

export function UserProfilesResponseToJSON(m: UserProfilesResponse): UserProfilesResponseJSON {
  return {
    profiles: m.profiles,
  };
}

export function JSONToUserProfilesResponse(m: UserProfilesResponseJSON): UserProfilesResponse {
  return {
    profiles: m.profiles != null ? m.profiles : [],
  };
}

export type UserAddProfileRequest = {
  name: string;
}

export type UserAddProfileRequestJSON = {
  name?: string;
}

export function UserAddProfileRequestToJSON(m: UserAddProfileRequest): UserAddProfileRequestJSON {
  return {
    name: m.name,
  };
}

export function JSONToUserAddProfileRequest(m: UserAddProfileRequestJSON): UserAddProfileRequest {
  return {
    name: m.name != null ? m.name : "",
  };
}

export type UserRenameProfileRequest = {
  name: string;
  newName: string;
}

export type UserRenameProfileRequestJSON = {
  name?: string;
  new_name?: string;
}

export function UserRenameProfileRequestToJSON(m: UserRenameProfileRequest): UserRenameProfileRequestJSON {
  return {
    name: m.name,
    new_name: m.newName,
  };
}

export function JSONToUserRenameProfileRequest(m: UserRenameProfileRequestJSON): UserRenameProfileRequest {
  return {
    name: m.name != null ? m.name : "",
    newName: m.new_name != null ? m.new_name : "",
  };
}

export type UserDeleteProfileRequest = {
  name: string;
}

export type UserDeleteProfileRequestJSON = {
  name?: string;
}

export function UserDeleteProfileRequestToJSON(m: UserDeleteProfileRequest): UserDeleteProfileRequestJSON {
  return {
    name: m.name,
  };
}

export function JSONToUserDeleteProfileRequest(m: UserDeleteProfileRequestJSON): UserDeleteProfileRequest {
  return {
    name: m.name != null ? m.name : "",
  };
}

export type UserUsageProfileRequest = {
  name: string;
}

export type UserUsageProfileRequestJSON = {
  name?: string;
}

export function UserUsageProfileRequestToJSON(m: UserUsageProfileRequest): UserUsageProfileRequestJSON {
  return {
    name: m.name,
  };
}

export function JSONToUserUsageProfileRequest(m: UserUsageProfileRequestJSON): UserUsageProfileRequest {
  return {
    name: m.name != null ? m.name : "",
  };
}

export type UserUsageProfileResponse = {
  userCount: number;
}

export type UserUsageProfileResponseJSON = {
  user_count?: number;
}

export function UserUsageProfileResponseToJSON(m: UserUsageProfileResponse): UserUsageProfileResponseJSON {
  return {
    user_count: m.userCount,
  };
}

export function JSONToUserUsageProfileResponse(m: UserUsageProfileResponseJSON): UserUsageProfileResponse {
  return {
    userCount: m.user_count != null ? m.user_count : 0,
  };
}

export type UserUserNotesRequest = {
  userId: string;
  fromAdmin: boolean;
}

export type UserUserNotesRequestJSON = {
  user_id?: string;
  from_admin?: boolean;
}

export function UserUserNotesRequestToJSON(m: UserUserNotesRequest): UserUserNotesRequestJSON {
  return {
    user_id: m.userId,
    from_admin: m.fromAdmin,
  };
}

export function JSONToUserUserNotesRequest(m: UserUserNotesRequestJSON): UserUserNotesRequest {
  return {
    userId: m.user_id != null ? m.user_id : "",
    fromAdmin: m.from_admin != null ? m.from_admin : false,
  };
}

export type UserUserNotesResponse = {
  notes: Array<UserNote>;
}

export type UserUserNotesResponseJSON = {
  notes?: Array<UserNoteJSON>;
}

export function UserUserNotesResponseToJSON(m: UserUserNotesResponse): UserUserNotesResponseJSON {
  return {
    notes: m.notes.map(UserNoteToJSON),
  };
}

export function JSONToUserUserNotesResponse(m: UserUserNotesResponseJSON): UserUserNotesResponse {
  return {
    notes: m.notes != null ? m.notes.map(JSONToUserNote) : [],
  };
}

export type UserAddUserNoteRequest = {
  userId: string;
  fromAdmin: boolean;
  note: string;
}

export type UserAddUserNoteRequestJSON = {
  user_id?: string;
  from_admin?: boolean;
  note?: string;
}

export function UserAddUserNoteRequestToJSON(m: UserAddUserNoteRequest): UserAddUserNoteRequestJSON {
  return {
    user_id: m.userId,
    from_admin: m.fromAdmin,
    note: m.note,
  };
}

export function JSONToUserAddUserNoteRequest(m: UserAddUserNoteRequestJSON): UserAddUserNoteRequest {
  return {
    userId: m.user_id != null ? m.user_id : "",
    fromAdmin: m.from_admin != null ? m.from_admin : false,
    note: m.note != null ? m.note : "",
  };
}

export type UserAddUserNoteResponse = {
  note: ?UserNote;
}

export type UserAddUserNoteResponseJSON = {
  note?: UserNoteJSON;
}

export function UserAddUserNoteResponseToJSON(m: UserAddUserNoteResponse): UserAddUserNoteResponseJSON {
  return {
    note: m.note != null ? UserNoteToJSON(m.note) : undefined,
  };
}

export function JSONToUserAddUserNoteResponse(m: UserAddUserNoteResponseJSON): UserAddUserNoteResponse {
  return {
    note: m.note != null ? JSONToUserNote(m.note) : null,
  };
}

export type UserEditUserNoteRequest = {
  noteId: string;
  note: string;
}

export type UserEditUserNoteRequestJSON = {
  note_id?: string;
  note?: string;
}

export function UserEditUserNoteRequestToJSON(m: UserEditUserNoteRequest): UserEditUserNoteRequestJSON {
  return {
    note_id: m.noteId,
    note: m.note,
  };
}

export function JSONToUserEditUserNoteRequest(m: UserEditUserNoteRequestJSON): UserEditUserNoteRequest {
  return {
    noteId: m.note_id != null ? m.note_id : "",
    note: m.note != null ? m.note : "",
  };
}

export type UserMarkUserNoteAsReadRequest = {
  noteId: string;
}

export type UserMarkUserNoteAsReadRequestJSON = {
  note_id?: string;
}

export function UserMarkUserNoteAsReadRequestToJSON(m: UserMarkUserNoteAsReadRequest): UserMarkUserNoteAsReadRequestJSON {
  return {
    note_id: m.noteId,
  };
}

export function JSONToUserMarkUserNoteAsReadRequest(m: UserMarkUserNoteAsReadRequestJSON): UserMarkUserNoteAsReadRequest {
  return {
    noteId: m.note_id != null ? m.note_id : "",
  };
}

export type UserDeleteUserNoteRequest = {
  noteId: string;
}

export type UserDeleteUserNoteRequestJSON = {
  note_id?: string;
}

export function UserDeleteUserNoteRequestToJSON(m: UserDeleteUserNoteRequest): UserDeleteUserNoteRequestJSON {
  return {
    note_id: m.noteId,
  };
}

export function JSONToUserDeleteUserNoteRequest(m: UserDeleteUserNoteRequestJSON): UserDeleteUserNoteRequest {
  return {
    noteId: m.note_id != null ? m.note_id : "",
  };
}

export type UserUnreadAdminAlertCountResponse = {
  count: number;
}

export type UserUnreadAdminAlertCountResponseJSON = {
  count?: number;
}

export function UserUnreadAdminAlertCountResponseToJSON(m: UserUnreadAdminAlertCountResponse): UserUnreadAdminAlertCountResponseJSON {
  return {
    count: m.count,
  };
}

export function JSONToUserUnreadAdminAlertCountResponse(m: UserUnreadAdminAlertCountResponseJSON): UserUnreadAdminAlertCountResponse {
  return {
    count: m.count != null ? m.count : 0,
  };
}

export class UserService {
  hostname: string;
  pathPrefix: string = "/rpc.UserService/";

  constructor(hostname: string) {
    this.hostname = hostname;
  }

  async me(): Promise<UserMeResponse> {
    const url = this.hostname + this.pathPrefix + "Me";
    const data = await sendTwirpRequest(url, {});
    return JSONToUserMeResponse(data);
  }

  async users(): Promise<UserUsersResponse> {
    const url = this.hostname + this.pathPrefix + "Users";
    const data = await sendTwirpRequest(url, {});
    return JSONToUserUsersResponse(data);
  }

  async setTier(userSetTierRequest: UserSetTierRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "SetTier";
    const body: UserSetTierRequestJSON = UserSetTierRequestToJSON(userSetTierRequest);
    await sendTwirpRequest(url, body);
  }

  async getUser(userGetUserRequest: UserGetUserRequest): Promise<UserGetUserResponse> {
    const url = this.hostname + this.pathPrefix + "GetUser";
    const body: UserGetUserRequestJSON = UserGetUserRequestToJSON(userGetUserRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToUserGetUserResponse(data);
  }

  async userProfile(userUserProfileRequest: UserUserProfileRequest): Promise<UserUserProfileResponse> {
    const url = this.hostname + this.pathPrefix + "UserProfile";
    const body: UserUserProfileRequestJSON = UserUserProfileRequestToJSON(userUserProfileRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToUserUserProfileResponse(data);
  }

  async editUserProfile(userEditUserProfileRequest: UserEditUserProfileRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "EditUserProfile";
    const body: UserEditUserProfileRequestJSON = UserEditUserProfileRequestToJSON(userEditUserProfileRequest);
    await sendTwirpRequest(url, body);
  }

  async deleteUser(userDeleteUserRequest: UserDeleteUserRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "DeleteUser";
    const body: UserDeleteUserRequestJSON = UserDeleteUserRequestToJSON(userDeleteUserRequest);
    await sendTwirpRequest(url, body);
  }

  async sendIssue(userSendIssueRequest: UserSendIssueRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "SendIssue";
    const body: UserSendIssueRequestJSON = UserSendIssueRequestToJSON(userSendIssueRequest);
    await sendTwirpRequest(url, body);
  }

  async profiles(): Promise<UserProfilesResponse> {
    const url = this.hostname + this.pathPrefix + "Profiles";
    const data = await sendTwirpRequest(url, {});
    return JSONToUserProfilesResponse(data);
  }

  async addProfile(userAddProfileRequest: UserAddProfileRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "AddProfile";
    const body: UserAddProfileRequestJSON = UserAddProfileRequestToJSON(userAddProfileRequest);
    await sendTwirpRequest(url, body);
  }

  async renameProfile(userRenameProfileRequest: UserRenameProfileRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "RenameProfile";
    const body: UserRenameProfileRequestJSON = UserRenameProfileRequestToJSON(userRenameProfileRequest);
    await sendTwirpRequest(url, body);
  }

  async deleteProfile(userDeleteProfileRequest: UserDeleteProfileRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "DeleteProfile";
    const body: UserDeleteProfileRequestJSON = UserDeleteProfileRequestToJSON(userDeleteProfileRequest);
    await sendTwirpRequest(url, body);
  }

  async usageProfile(userUsageProfileRequest: UserUsageProfileRequest): Promise<UserUsageProfileResponse> {
    const url = this.hostname + this.pathPrefix + "UsageProfile";
    const body: UserUsageProfileRequestJSON = UserUsageProfileRequestToJSON(userUsageProfileRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToUserUsageProfileResponse(data);
  }

  async userNotes(userUserNotesRequest: UserUserNotesRequest): Promise<UserUserNotesResponse> {
    const url = this.hostname + this.pathPrefix + "UserNotes";
    const body: UserUserNotesRequestJSON = UserUserNotesRequestToJSON(userUserNotesRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToUserUserNotesResponse(data);
  }

  async addUserNote(userAddUserNoteRequest: UserAddUserNoteRequest): Promise<UserAddUserNoteResponse> {
    const url = this.hostname + this.pathPrefix + "AddUserNote";
    const body: UserAddUserNoteRequestJSON = UserAddUserNoteRequestToJSON(userAddUserNoteRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToUserAddUserNoteResponse(data);
  }

  async editUserNote(userEditUserNoteRequest: UserEditUserNoteRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "EditUserNote";
    const body: UserEditUserNoteRequestJSON = UserEditUserNoteRequestToJSON(userEditUserNoteRequest);
    await sendTwirpRequest(url, body);
  }

  async markUserNoteAsRead(userMarkUserNoteAsReadRequest: UserMarkUserNoteAsReadRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "MarkUserNoteAsRead";
    const body: UserMarkUserNoteAsReadRequestJSON = UserMarkUserNoteAsReadRequestToJSON(userMarkUserNoteAsReadRequest);
    await sendTwirpRequest(url, body);
  }

  async deleteUserNote(userDeleteUserNoteRequest: UserDeleteUserNoteRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "DeleteUserNote";
    const body: UserDeleteUserNoteRequestJSON = UserDeleteUserNoteRequestToJSON(userDeleteUserNoteRequest);
    await sendTwirpRequest(url, body);
  }

  async unreadAdminAlertCount(): Promise<UserUnreadAdminAlertCountResponse> {
    const url = this.hostname + this.pathPrefix + "UnreadAdminAlertCount";
    const data = await sendTwirpRequest(url, {});
    return JSONToUserUnreadAdminAlertCountResponse(data);
  }
}
