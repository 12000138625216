// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useState } from "react";
import type { Node } from "react";
import styles from "./PositionEdit.module.css";
import { Button, ButtonGroup, Col, ControlLabel, Form, FormControl, FormGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { dateToYYYYMMDD, YYYYMMDDtoDate } from "../../utils/convert";

type PositionEditProps = {
  x: number;
  y: number;
  z: number;
  t: string;
  applyChanges: (x: number, y: number, z: number, t: string) => Promise<void>;
  cancel: () => void;
}

export default function PositionEdit(props: PositionEditProps): Node {
  const [x, setX] = useState<string>(props.x !== 0 ? props.x.toString() : "");
  const [y, setY] = useState<string>(props.y !== 0 ? props.y.toString() : "");
  const [z, setZ] = useState<string>(props.z !== 0 ? props.z.toString() : "");
  const [t, setT] = useState<string>(props.t);

  const [dirty, setDirty] = useState<boolean>(false);

  const [xValid, setXValid] = useState<boolean>(true);
  const [yValid, setYValid] = useState<boolean>(true);
  const [zValid, setZValid] = useState<boolean>(true);
  const [tValid, setTValid] = useState<boolean>(true);

  function onXChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setX(e.currentTarget.value);
    setXValid(true);
    setDirty(true);
  }

  function onYChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setY(e.currentTarget.value);
    setYValid(true);
    setDirty(true);
  }

  function onZChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setZ(e.currentTarget.value);
    setZValid(true);
    setDirty(true);
  }

  function onTChanged(d: Date) {
    setT(dateToYYYYMMDD(d));
    setTValid(true);
    setDirty(true);
  }

  function onSaveChangesClick() {
    const xV = x.trim() !== "" && !isNaN(x);
    const yV = y.trim() !== "" && !isNaN(y);
    const zV = z.trim() !== "" && !isNaN(z);
    const tV = t.trim() !== "";

    setXValid(xV);
    setYValid(yV);
    setZValid(zV);
    setTValid(tV);

    if (xV && yV && zV && tV)
      props.applyChanges(+x, +y, +z, t);
  }

  function onCancelClick() {
    props.cancel();
  }

  return <div className={styles.root}>
    <Form horizontal>
      <FormGroup controlId="x" validationState={xValid ? null : "error"}>
        <Col componentClass={ControlLabel} sm={1}>
          X
        </Col>
        <Col sm={11}>
          <FormControl
            placeholder="x position"
            value={x}
            onChange={onXChanged}
          />
        </Col>
      </FormGroup>
      <FormGroup controlId="y" validationState={yValid ? null : "error"}>
        <Col componentClass={ControlLabel} sm={1}>
          Y
        </Col>
        <Col sm={11}>
          <FormControl
            placeholder="y position"
            value={y}
            onChange={onYChanged}
          />
        </Col>
      </FormGroup>
      <FormGroup controlId="z" validationState={zValid ? null : "error"}>
        <Col componentClass={ControlLabel} sm={1}>
          Z
        </Col>
        <Col sm={11}>
          <FormControl
            placeholder="z position"
            value={z}
            onChange={onZChanged}
          />
        </Col>
      </FormGroup>
      <FormGroup controlId="t" validationState={tValid ? null : "error"}>
        <Col componentClass={ControlLabel} sm={1}>
          T
        </Col>
        <Col sm={11}>
          <FormControl
            readOnly
            value={t}
          />
          <DatePicker inline selected={t !== "" ? YYYYMMDDtoDate(t) : null} onChange={onTChanged} />
        </Col>
      </FormGroup>
    </Form>
    <ButtonGroup className={styles.buttons}>
      <Button bsStyle="info" onClick={onSaveChangesClick} disabled={!dirty}>
        Save Changes
      </Button>
      <Button bsStyle="warning" onClick={onCancelClick}>
        Cancel
      </Button>
    </ButtonGroup>
  </div>;
}
