// @flow strict
/* eslint-disable */
import { sendTwirpRequest } from "./twirp";
import type { ApiKey, ApiKeyJSON } from "./model";
import { ApiKeyToJSON, JSONToApiKey } from "./model";
export type AdminApiKeysResponse = {
  keys: Array<ApiKey>;
}

export type AdminApiKeysResponseJSON = {
  keys?: Array<ApiKeyJSON>;
}

export function AdminApiKeysResponseToJSON(m: AdminApiKeysResponse): AdminApiKeysResponseJSON {
  return {
    keys: m.keys.map(ApiKeyToJSON),
  };
}

export function JSONToAdminApiKeysResponse(m: AdminApiKeysResponseJSON): AdminApiKeysResponse {
  return {
    keys: m.keys != null ? m.keys.map(JSONToApiKey) : [],
  };
}

export type AdminAddApiKeyRequest = {
  appName: string;
}

export type AdminAddApiKeyRequestJSON = {
  app_name?: string;
}

export function AdminAddApiKeyRequestToJSON(m: AdminAddApiKeyRequest): AdminAddApiKeyRequestJSON {
  return {
    app_name: m.appName,
  };
}

export function JSONToAdminAddApiKeyRequest(m: AdminAddApiKeyRequestJSON): AdminAddApiKeyRequest {
  return {
    appName: m.app_name != null ? m.app_name : "",
  };
}

export type AdminAddApiKeyResponse = {
  key: ?ApiKey;
}

export type AdminAddApiKeyResponseJSON = {
  key?: ApiKeyJSON;
}

export function AdminAddApiKeyResponseToJSON(m: AdminAddApiKeyResponse): AdminAddApiKeyResponseJSON {
  return {
    key: m.key != null ? ApiKeyToJSON(m.key) : undefined,
  };
}

export function JSONToAdminAddApiKeyResponse(m: AdminAddApiKeyResponseJSON): AdminAddApiKeyResponse {
  return {
    key: m.key != null ? JSONToApiKey(m.key) : null,
  };
}

export type AdminDeleteApiKeyRequest = {
  id: string;
}

export type AdminDeleteApiKeyRequestJSON = {
  id?: string;
}

export function AdminDeleteApiKeyRequestToJSON(m: AdminDeleteApiKeyRequest): AdminDeleteApiKeyRequestJSON {
  return {
    id: m.id,
  };
}

export function JSONToAdminDeleteApiKeyRequest(m: AdminDeleteApiKeyRequestJSON): AdminDeleteApiKeyRequest {
  return {
    id: m.id != null ? m.id : "",
  };
}

export type AdminEditApiKeyRequest = {
  id: string;
  appName: string;
  note1: string;
  note2: string;
}

export type AdminEditApiKeyRequestJSON = {
  id?: string;
  app_name?: string;
  note1?: string;
  note2?: string;
}

export function AdminEditApiKeyRequestToJSON(m: AdminEditApiKeyRequest): AdminEditApiKeyRequestJSON {
  return {
    id: m.id,
    app_name: m.appName,
    note1: m.note1,
    note2: m.note2,
  };
}

export function JSONToAdminEditApiKeyRequest(m: AdminEditApiKeyRequestJSON): AdminEditApiKeyRequest {
  return {
    id: m.id != null ? m.id : "",
    appName: m.app_name != null ? m.app_name : "",
    note1: m.note1 != null ? m.note1 : "",
    note2: m.note2 != null ? m.note2 : "",
  };
}

export type AdminEnableApiKeyRequest = {
  id: string;
  disabled: boolean;
}

export type AdminEnableApiKeyRequestJSON = {
  id?: string;
  disabled?: boolean;
}

export function AdminEnableApiKeyRequestToJSON(m: AdminEnableApiKeyRequest): AdminEnableApiKeyRequestJSON {
  return {
    id: m.id,
    disabled: m.disabled,
  };
}

export function JSONToAdminEnableApiKeyRequest(m: AdminEnableApiKeyRequestJSON): AdminEnableApiKeyRequest {
  return {
    id: m.id != null ? m.id : "",
    disabled: m.disabled != null ? m.disabled : false,
  };
}

export class AdminService {
  hostname: string;
  pathPrefix: string = "/rpc.AdminService/";

  constructor(hostname: string) {
    this.hostname = hostname;
  }

  async backup(): Promise<void> {
    const url = this.hostname + this.pathPrefix + "Backup";
    await sendTwirpRequest(url, {});
  }

  async apiKeys(): Promise<AdminApiKeysResponse> {
    const url = this.hostname + this.pathPrefix + "ApiKeys";
    const data = await sendTwirpRequest(url, {});
    return JSONToAdminApiKeysResponse(data);
  }

  async addApiKey(adminAddApiKeyRequest: AdminAddApiKeyRequest): Promise<AdminAddApiKeyResponse> {
    const url = this.hostname + this.pathPrefix + "AddApiKey";
    const body: AdminAddApiKeyRequestJSON = AdminAddApiKeyRequestToJSON(adminAddApiKeyRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToAdminAddApiKeyResponse(data);
  }

  async deleteApiKey(adminDeleteApiKeyRequest: AdminDeleteApiKeyRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "DeleteApiKey";
    const body: AdminDeleteApiKeyRequestJSON = AdminDeleteApiKeyRequestToJSON(adminDeleteApiKeyRequest);
    await sendTwirpRequest(url, body);
  }

  async editApiKey(adminEditApiKeyRequest: AdminEditApiKeyRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "EditApiKey";
    const body: AdminEditApiKeyRequestJSON = AdminEditApiKeyRequestToJSON(adminEditApiKeyRequest);
    await sendTwirpRequest(url, body);
  }

  async enableApiKey(adminEnableApiKeyRequest: AdminEnableApiKeyRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "EnableApiKey";
    const body: AdminEnableApiKeyRequestJSON = AdminEnableApiKeyRequestToJSON(adminEnableApiKeyRequest);
    await sendTwirpRequest(url, body);
  }
}
