// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import { SwatchesPicker } from 'react-color';
import Popup from 'reactjs-popup';
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import styles from './LabelTypesList.module.css';
import type { LabelType } from "../../rpc/model";
import { LabelTypeService } from "../../rpc/label_types";
import Button from "../Button/Button";
import { apiEndpoint } from "../../utils/http";
import { LabelTypes } from '../../model';

const LabelTypeItem = SortableElement(({ labelType, labelTypeIndex, onItemDelete, onItemRename, onItemColorChanged }) => {
  const itemBlock = <div className={styles.item}
    style={{ backgroundColor: labelType.color }}>
    {labelType.name}
  </div>;

  return <div key={labelType.id} className={styles.container}>
    {labelType.labelType === LabelTypes.common && <i className='fa fa-times-circle cursor-pointer' onClick={(e) => onItemDelete(e, labelTypeIndex)} />}
    {labelType.labelType === <i className='fa fa-pencil cursor-pointer' onClick={(e) => onItemRename(e, labelTypeIndex)} /> }
    <Popup trigger={itemBlock} on='click' modal={true} contentStyle={{ width: 'auto' }}>
      {close => <SwatchesPicker color={labelType.color}
        onChangeComplete={(color) => onItemColorChanged(labelTypeIndex, color.hex, close)} />}
    </Popup>
  </div>;
});

const LabelTypeItems = SortableContainer(({ labelTypes, onItemDelete, onItemRename, onItemColorChanged }) =>
  <div className={styles.items}>
    {
      labelTypes.map((labelType, i) => (<LabelTypeItem key={labelType.id}
        index={i}
        labelTypeIndex={i}
        labelType={labelType}
        onItemDelete={onItemDelete}
        onItemRename={onItemRename}
        onItemColorChanged={onItemColorChanged} />))
    }
  </div>
);

export default function LabelTypesList(): Node {
  const [items, setItems] = useState<Array<LabelType>>([]);

  useEffect(() => {
    async function fetchItems() {
      const resp = await new LabelTypeService(apiEndpoint()).get();
      setItems(resp.labelTypes.filter(labelType => labelType.labelType !== LabelTypes.obsolete));
    }

    fetchItems();
  }, []);

  async function onNewItemClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    let newItemName = window.prompt('Input new label name:', '');
    if (newItemName == null)
      return;

    newItemName = newItemName.trim();
    if (newItemName === '')
      return;

    const index = items.findIndex(item => item.name === newItemName);
    if (index >= 0) {
      window.alert('Already exists!');
      return;
    }

    const newItem = { id: 0, name: newItemName, color: '#0000FF', labelType: LabelTypes.common };

    const response = await new LabelTypeService(apiEndpoint()).addOrEdit({ labelType: newItem });

    if (response.labelType != null) {
      setItems([...items, response.labelType]);
    }
  }

  async function onItemDelete(e: SyntheticMouseEvent<HTMLImageElement>, index: number) {
    e.stopPropagation();

    const item = items[index];

    const response = await new LabelTypeService(apiEndpoint()).usage({ labelTypeId: item.id });
    const { labelCount, itemCount } = response;

    const confirmMessageLines: Array<string> = [`You are going to delete the label '${item.name}'`];
    if (labelCount > 0) {
      confirmMessageLines.push(`The label type is used by ${labelCount} labels for ${itemCount} images`);
    }
    confirmMessageLines.push('Are you sure?');

    if (!window.confirm(confirmMessageLines.join('\n')))
      return;

    await new LabelTypeService(apiEndpoint()).delete({ labelTypeId: item.id });

    setItems([...items.slice(0, index), ...items.slice(index + 1)]);
  }

  async function onItemRename(e: SyntheticMouseEvent<HTMLImageElement>, index: number) {
    e.stopPropagation();

    const item = items[index];

    const response = await new LabelTypeService(apiEndpoint()).usage({ labelTypeId: item.id });
    const { labelCount, itemCount } = response;

    const confirmMessageLines: Array<string> = [`You are going to rename the label '${item.name}'`];
    if (labelCount > 0) {
      confirmMessageLines.push(`The label type is used by ${labelCount} labels for ${itemCount} images`);
    }
    confirmMessageLines.push('Are you sure?');

    if (!window.confirm(confirmMessageLines.join('\n')))
      return;

    let newName = window.prompt("New name", item.name);
    if (newName == null)
      return;

    newName = newName.trim();
    if (newName === "" || newName === item.name)
      return;

    if (items.some(it => it.name !== item.name && it.name === newName)) {
      window.alert('The label with the same name already exists!');
      return;
    }

    await new LabelTypeService(apiEndpoint()).rename({ labelTypeId: item.id, newName: newName });

    setItems([
      ...items.slice(0, index),
      { ...item, name: newName },
      ...items.slice(index + 1)
    ]);
  }

  async function onItemColorChanged(index: number, color: string, close: () => void) {
    close();

    const item = items[index];
    const newItem = { ...item, color: color };

    await new LabelTypeService(apiEndpoint()).addOrEdit({ labelType: newItem });

    setItems([...items.slice(0, index), newItem, ...items.slice(index + 1)]);
  }

  async function onSortEnd(e: { oldIndex: number, newIndex: number }) {
    const newItems = arrayMove(items, e.oldIndex, e.newIndex);
    await new LabelTypeService(apiEndpoint()).sortItems({
      sortedItems: newItems.map(item => item.id)
    });
    setItems(newItems);
  }

  return <div>
    <div className={styles.root}>
      <LabelTypeItems axis='xy'
        distance={1}
        onSortEnd={onSortEnd}
        labelTypes={items}
        onItemDelete={onItemDelete}
        onItemRename={onItemRename}
        onItemColorChanged={onItemColorChanged}
      />
      <Button onClick={onNewItemClick}>Add new</Button>
    </div>
  </div>;
}
