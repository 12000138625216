// @flow strict
// Copyright (C) 2018-2021 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React, { useEffect, useState } from "react";
import ContentEditable from 'react-contenteditable';
import cx from "classnames";
import styles from "./UserNotesView.module.css";
import { UserNotesState } from "./UserNotesState";
import { observer } from "mobx-react-lite";
import Button from "react-bootstrap/lib/Button";
import { runInAction } from 'mobx';
import AppState from '../../AppState';

type UserNotesViewProps = {
  appState: AppState,
  userId: string,
  fromAdmin: boolean,
}

function UserNotesView(props: UserNotesViewProps): Node {
  const [state] = useState<UserNotesState>(() => new UserNotesState(props.appState, props.userId, props.fromAdmin));

  useEffect(() => {
    return () => state.dispose();
  }, [state]);

  useEffect(() => {
    state.setUserId(props.userId, props.fromAdmin);
  }, [props.userId, props.fromAdmin, state]);

  function onNoteChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    // $FlowIgnore[prop-missing]
    const value = e.target.value;
    runInAction(() => {
      state.editingNote = value;
    });
  }

  return <div className={styles.root}>
    {state.notes.map(note => {
      return <div key={note.id} className={styles.note}>
        <ContentEditable
          className={cx(styles.noteNote, { [styles.unreadNote]: !note.readAt })}
          disabled={state.editingNoteId !== note.id}
          html={state.editingNoteId !== note.id ? note.note : state.editingNote}
          onChange={onNoteChanged}
        />
        <div className={styles.noteFooter}>
          <span className={styles.noteCreatedAt}>{new Date(Date.parse(note.createdAt)).toLocaleDateString()}</span>
          {state.editingNoteId !== note.id && note.userId === props.appState.me?.id && !note.readAt &&
          <Button
            bsStyle="info"
            bsSize="small"
            disabled={state.editingNoteId != null}
            onClick={() => state.markNoteAsRead(note.id)}
          >
            Mark as Read
          </Button>}
          {state.editingNoteId !== note.id && ((!props.fromAdmin && note.userId === props.appState.me?.id) || (props.fromAdmin && note.fromId === props.appState.me?.id)) &&
          <Button
            bsStyle="info"
            bsSize="small"
            disabled={state.editingNoteId != null}
            onClick={() => state.startEditNote(note)}
          >
            Edit
          </Button>}
          {state.editingNoteId !== note.id && (note.userId === props.appState.me?.id || (props.fromAdmin && note.fromId === props.appState.me?.id)) &&
          <Button
            bsStyle="warning"
            bsSize="small"
            disabled={state.editingNoteId != null || (!note.readAt && note.userId === props.appState.me?.id)}
            onClick={() => state.deleteNote(note.id)}
          >
            Delete
          </Button>}
          {state.editingNoteId === note.id && <Button bsStyle="primary" bsSize="small" disabled={state.editingNote === ''} onClick={state.editNote}>Save</Button>}
          {state.editingNoteId === note.id && <Button bsStyle="warning" bsSize="small" onClick={state.cancelEditNote}>Cancel</Button>}
        </div>
      </div>;
    })}
    {state.editingNoteId !== '' && ((props.appState.me?.isAdmin && props.fromAdmin && props.appState.me?.id !== props.userId) || (!props.fromAdmin && props.appState.me?.id === props.userId)) &&
    <Button
      bsStyle="info"
      bsSize="small"
      disabled={state.editingNoteId != null}
      onClick={state.startAddNote}
    >
      Add Note
    </Button>}
    {
      state.editingNoteId === '' &&
      <div key={'new-note'} className={styles.note}>
        <ContentEditable
          className={styles.noteNote}
          html={state.editingNote}
          onChange={onNoteChanged}
        />
        <div className={styles.noteFooter}>
          {<Button bsStyle="primary" bsSize="small" disabled={state.editingNote === ''} onClick={state.addNote}>Save</Button>}
          {<Button bsStyle="warning" bsSize="small" onClick={state.cancelEditNote}>Cancel</Button>}
        </div>
      </div>
    }
  </div>;
}

export default (observer(UserNotesView): typeof UserNotesView);
