// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { LiveLabel } from "../model";
import type { Label, Point } from "../rpc/model";

// Convert Live Label to Stored Label.
export function fromLiveLabel(label: LiveLabel, imageWidth: number, imageHeight: number): Label {
  const leftTop = fromLivePoint({ x: label.x, y: label.y }, imageWidth, imageHeight);
  const rightBottom = fromLivePoint({
    x: label.x + label.width,
    y: label.y + label.height
  }, imageWidth, imageHeight, -1);

  if (rightBottom.x < leftTop.x)
    rightBottom.x = leftTop.x;
  if (rightBottom.y < leftTop.y)
    rightBottom.y = leftTop.y;

  let points = [];
  if (leftTop.x !== 0 || leftTop.y !== 0 || rightBottom.x !== 0 || rightBottom.y !== 0) {
    if (label.points.length === 2) {
      points = [
        fromLivePoint({ x: label.points[0].x, y: label.points[0].y }, imageWidth, imageHeight),
        fromLivePoint({ x: label.points[1].x, y: label.points[1].y }, imageWidth, imageHeight, -1),
      ];
    } else {
      points = [
        leftTop,
        { x: rightBottom.x, y: leftTop.y },
        rightBottom,
        { x: leftTop.x, y: rightBottom.y },
      ];
    }
  }

  return {
    id: label.id || '',
    itemId: label.itemId || "",
    userId: label.userId || "",
    my: label.my !== false,
    labelTypeId: label.labelTypeId,
    note: label.note || '',
    left: leftTop.x,
    top: leftTop.y,
    right: rightBottom.x,
    bottom: rightBottom.y,
    startFrame: label.startFrame || 0,
    endFrame: label.endFrame || 0,
    startPosition: label.startPosition || 0,
    endPosition: label.endPosition || 0,
    additionalInfo: label.additionalInfo || '',
    points: points,
  };
}

// Convert Stored Label to Live Label.
export function toLiveLabel(storedLabel: Label, imageWidth: number, imageHeight: number): LiveLabel {
  const { left, top, right, bottom } = storedLabel;
  const leftTop = toLivePoint({ x: left, y: top }, imageWidth, imageHeight);
  const widthHeight = storedLabel.points.length > 0 ?
    toLivePoint({
      x: right - left + 1,
      y: bottom - top + 1
    }, imageWidth, imageHeight) :
    { x: 0, y: 0 };

  const points = storedLabel.points.map(p => toLivePoint(p, imageWidth, imageHeight));
  const label = {
    id: storedLabel.id,
    itemId: storedLabel.itemId,
    userId: storedLabel.userId,
    my: storedLabel.my !== false,
    labelTypeId: storedLabel.labelTypeId,
    note: storedLabel.note || '',
    x: leftTop.x,
    y: leftTop.y,
    width: widthHeight.x,
    height: widthHeight.y,
    prevX: leftTop.x,
    prevY: leftTop.y,
    prevWidth: widthHeight.x,
    prevHeight: widthHeight.y,
    startFrame: storedLabel.startFrame,
    endFrame: storedLabel.endFrame,
    startPosition: storedLabel.startPosition,
    endPosition: storedLabel.endPosition,
    additionalInfo: storedLabel.additionalInfo,
    points: points,
    isRectangleOrLine: isRectangle(storedLabel) || isArrowOrLine(storedLabel),
    new: false,
    isChanging: false,
    data: {},
    start: undefined,
  };
  label.width = label.x + label.width <= 100 ? label.width : 100 - label.x;
  label.height = label.y + label.height <= 100 ? label.height : 100 - label.y;
  label.prevWidth = label.width;
  label.prevHeight = label.height;

  return label;
}

function fromLivePoint(livePoint: Point, imageWidth: number, imageHeight: number, delta: ?number = 0): Point {
  let x = Math.round(livePoint.x * imageWidth / 100) + (delta != null ? delta : 0);
  let y = Math.round(livePoint.y * imageHeight / 100) + (delta != null ? delta : 0);

  x = x >= imageWidth ? imageWidth - 1 : x;
  x = x < 0 ? 0 : x;

  y = y >= imageHeight ? y - 1 : y;
  y = y < 0 ? 0 : y;

  return { x, y };
}

function toLivePoint(storedPoint: Point, imageWidth: number, imageHeight: number): Point {
  return {
    x: storedPoint.x / imageWidth * 100,
    y: storedPoint.y / imageHeight * 100,
  };
}

function isRectangle(label: Label): boolean {
  if (label.points.length !== 4)
    return false;

  if (label.points[0].x !== label.left || label.points[0].y !== label.top)
    return false;

  if (label.points[1].x !== label.right || label.points[1].y !== label.top)
    return false;

  if (label.points[2].x !== label.right || label.points[2].y !== label.bottom)
    return false;

  return !(label.points[3].x !== label.left || label.points[3].y !== label.bottom);
}

function isArrowOrLine(label: Label): boolean {
  return label.points.length === 2;
}

export function getNearestPoint(point: Point, points: Array<Point>): [number, number] {
  let minDistance = -1;
  let minIndex = -1;
  for (let i = 0; i < points.length; i++) {
    const distance = (point.x - points[i].x)*(point.x - points[i].x) + (point.y - points[i].y)*(point.y - points[i].y);
    if (i === 0 || distance < minDistance) {
      minDistance = distance;
      minIndex = i;
    }
  }
  return [minIndex, minDistance];
}

export function getCommonNodeIndexes(points1: Array<Point>, points2: Array<Point>): [number, number] {
  let minDistance = -1;
  let minIndexes = [-1, -1];
  for (let i = 0; i < points1.length; i++) {
    const [index, distance] = getNearestPoint(points1[i], points2);
    if (i === 0 || distance < minDistance) {
      minDistance = distance;
      minIndexes = [i, index];
    }
  }
  return minIndexes;
}

export function getPointIndexes(point1: Point, point2: Point): [number, number] {
  if (point1.x < point2.x) {
    if (point1.y <= point2.y)
      return [0, 2];

    return [3, 1];
  }

  if (point1.y < point2.y)
    return [1, 3];

  return [2, 0];
}