// @flow strict
/* eslint-disable */
import { sendTwirpRequest } from "./twirp";
import type { FolderPDFTemplate, FolderPDFTemplateJSON } from "./model";
import { FolderPDFTemplateToJSON, JSONToFolderPDFTemplate } from "./model";
import type { PDFTemplate, PDFTemplateJSON } from "./model";
import { PDFTemplateToJSON, JSONToPDFTemplate } from "./model";
export type PDFTemplatesRequest = {
}

export type PDFTemplatesRequestJSON = {
}

export function PDFTemplatesRequestToJSON(m: PDFTemplatesRequest): PDFTemplatesRequestJSON {
  return {
  };
}

export function JSONToPDFTemplatesRequest(m: PDFTemplatesRequestJSON): PDFTemplatesRequest {
  return {
  };
}

export type PDFTemplatesResponse = {
  templates: Array<PDFTemplate>;
}

export type PDFTemplatesResponseJSON = {
  templates?: Array<PDFTemplateJSON>;
}

export function PDFTemplatesResponseToJSON(m: PDFTemplatesResponse): PDFTemplatesResponseJSON {
  return {
    templates: m.templates.map(PDFTemplateToJSON),
  };
}

export function JSONToPDFTemplatesResponse(m: PDFTemplatesResponseJSON): PDFTemplatesResponse {
  return {
    templates: m.templates != null ? m.templates.map(JSONToPDFTemplate) : [],
  };
}

export type PDFAddTemplateRequest = {
  template: ?PDFTemplate;
}

export type PDFAddTemplateRequestJSON = {
  template?: PDFTemplateJSON;
}

export function PDFAddTemplateRequestToJSON(m: PDFAddTemplateRequest): PDFAddTemplateRequestJSON {
  return {
    template: m.template != null ? PDFTemplateToJSON(m.template) : undefined,
  };
}

export function JSONToPDFAddTemplateRequest(m: PDFAddTemplateRequestJSON): PDFAddTemplateRequest {
  return {
    template: m.template != null ? JSONToPDFTemplate(m.template) : null,
  };
}

export type PDFAddTemplateResponse = {
  template: ?PDFTemplate;
  error: string;
}

export type PDFAddTemplateResponseJSON = {
  template?: PDFTemplateJSON;
  error?: string;
}

export function PDFAddTemplateResponseToJSON(m: PDFAddTemplateResponse): PDFAddTemplateResponseJSON {
  return {
    template: m.template != null ? PDFTemplateToJSON(m.template) : undefined,
    error: m.error,
  };
}

export function JSONToPDFAddTemplateResponse(m: PDFAddTemplateResponseJSON): PDFAddTemplateResponse {
  return {
    template: m.template != null ? JSONToPDFTemplate(m.template) : null,
    error: m.error != null ? m.error : "",
  };
}

export type PDFEditTemplateRequest = {
  template: ?PDFTemplate;
}

export type PDFEditTemplateRequestJSON = {
  template?: PDFTemplateJSON;
}

export function PDFEditTemplateRequestToJSON(m: PDFEditTemplateRequest): PDFEditTemplateRequestJSON {
  return {
    template: m.template != null ? PDFTemplateToJSON(m.template) : undefined,
  };
}

export function JSONToPDFEditTemplateRequest(m: PDFEditTemplateRequestJSON): PDFEditTemplateRequest {
  return {
    template: m.template != null ? JSONToPDFTemplate(m.template) : null,
  };
}

export type PDFEditTemplateResponse = {
  error: string;
}

export type PDFEditTemplateResponseJSON = {
  error?: string;
}

export function PDFEditTemplateResponseToJSON(m: PDFEditTemplateResponse): PDFEditTemplateResponseJSON {
  return {
    error: m.error,
  };
}

export function JSONToPDFEditTemplateResponse(m: PDFEditTemplateResponseJSON): PDFEditTemplateResponse {
  return {
    error: m.error != null ? m.error : "",
  };
}

export type PDFDeleteTemplateRequest = {
  templateId: number;
}

export type PDFDeleteTemplateRequestJSON = {
  template_id?: number;
}

export function PDFDeleteTemplateRequestToJSON(m: PDFDeleteTemplateRequest): PDFDeleteTemplateRequestJSON {
  return {
    template_id: m.templateId,
  };
}

export function JSONToPDFDeleteTemplateRequest(m: PDFDeleteTemplateRequestJSON): PDFDeleteTemplateRequest {
  return {
    templateId: m.template_id != null ? m.template_id : 0,
  };
}

export type PDFDeleteTemplateResponse = {
}

export type PDFDeleteTemplateResponseJSON = {
}

export function PDFDeleteTemplateResponseToJSON(m: PDFDeleteTemplateResponse): PDFDeleteTemplateResponseJSON {
  return {
  };
}

export function JSONToPDFDeleteTemplateResponse(m: PDFDeleteTemplateResponseJSON): PDFDeleteTemplateResponse {
  return {
  };
}

export type PDFFolderTemplatesRequest = {
  folderId: string;
}

export type PDFFolderTemplatesRequestJSON = {
  folder_id?: string;
}

export function PDFFolderTemplatesRequestToJSON(m: PDFFolderTemplatesRequest): PDFFolderTemplatesRequestJSON {
  return {
    folder_id: m.folderId,
  };
}

export function JSONToPDFFolderTemplatesRequest(m: PDFFolderTemplatesRequestJSON): PDFFolderTemplatesRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
  };
}

export type PDFFolderTemplatesResponse = {
  templates: Array<FolderPDFTemplate>;
}

export type PDFFolderTemplatesResponseJSON = {
  templates?: Array<FolderPDFTemplateJSON>;
}

export function PDFFolderTemplatesResponseToJSON(m: PDFFolderTemplatesResponse): PDFFolderTemplatesResponseJSON {
  return {
    templates: m.templates.map(FolderPDFTemplateToJSON),
  };
}

export function JSONToPDFFolderTemplatesResponse(m: PDFFolderTemplatesResponseJSON): PDFFolderTemplatesResponse {
  return {
    templates: m.templates != null ? m.templates.map(JSONToFolderPDFTemplate) : [],
  };
}

export type PDFSaveFolderTemplatesRequest = {
  deleted: Array<FolderPDFTemplate>;
  new: Array<FolderPDFTemplate>;
  modified: Array<FolderPDFTemplate>;
}

export type PDFSaveFolderTemplatesRequestJSON = {
  deleted?: Array<FolderPDFTemplateJSON>;
  new?: Array<FolderPDFTemplateJSON>;
  modified?: Array<FolderPDFTemplateJSON>;
}

export function PDFSaveFolderTemplatesRequestToJSON(m: PDFSaveFolderTemplatesRequest): PDFSaveFolderTemplatesRequestJSON {
  return {
    deleted: m.deleted.map(FolderPDFTemplateToJSON),
    new: m.new.map(FolderPDFTemplateToJSON),
    modified: m.modified.map(FolderPDFTemplateToJSON),
  };
}

export function JSONToPDFSaveFolderTemplatesRequest(m: PDFSaveFolderTemplatesRequestJSON): PDFSaveFolderTemplatesRequest {
  return {
    deleted: m.deleted != null ? m.deleted.map(JSONToFolderPDFTemplate) : [],
    new: m.new != null ? m.new.map(JSONToFolderPDFTemplate) : [],
    modified: m.modified != null ? m.modified.map(JSONToFolderPDFTemplate) : [],
  };
}

export type PDFSaveFolderTemplatesResponse = {
}

export type PDFSaveFolderTemplatesResponseJSON = {
}

export function PDFSaveFolderTemplatesResponseToJSON(m: PDFSaveFolderTemplatesResponse): PDFSaveFolderTemplatesResponseJSON {
  return {
  };
}

export function JSONToPDFSaveFolderTemplatesResponse(m: PDFSaveFolderTemplatesResponseJSON): PDFSaveFolderTemplatesResponse {
  return {
  };
}

export class PDFService {
  hostname: string;
  pathPrefix: string = "/rpc.PDFService/";

  constructor(hostname: string) {
    this.hostname = hostname;
  }

  async templates(): Promise<PDFTemplatesResponse> {
    const url = this.hostname + this.pathPrefix + "Templates";
    const data = await sendTwirpRequest(url, {});
    return JSONToPDFTemplatesResponse(data);
  }

  async addTemplate(pDFAddTemplateRequest: PDFAddTemplateRequest): Promise<PDFAddTemplateResponse> {
    const url = this.hostname + this.pathPrefix + "AddTemplate";
    const body: PDFAddTemplateRequestJSON = PDFAddTemplateRequestToJSON(pDFAddTemplateRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToPDFAddTemplateResponse(data);
  }

  async editTemplate(pDFEditTemplateRequest: PDFEditTemplateRequest): Promise<PDFEditTemplateResponse> {
    const url = this.hostname + this.pathPrefix + "EditTemplate";
    const body: PDFEditTemplateRequestJSON = PDFEditTemplateRequestToJSON(pDFEditTemplateRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToPDFEditTemplateResponse(data);
  }

  async deleteTemplate(pDFDeleteTemplateRequest: PDFDeleteTemplateRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "DeleteTemplate";
    const body: PDFDeleteTemplateRequestJSON = PDFDeleteTemplateRequestToJSON(pDFDeleteTemplateRequest);
    await sendTwirpRequest(url, body);
  }

  async folderTemplates(pDFFolderTemplatesRequest: PDFFolderTemplatesRequest): Promise<PDFFolderTemplatesResponse> {
    const url = this.hostname + this.pathPrefix + "FolderTemplates";
    const body: PDFFolderTemplatesRequestJSON = PDFFolderTemplatesRequestToJSON(pDFFolderTemplatesRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToPDFFolderTemplatesResponse(data);
  }

  async saveFolderTemplates(pDFSaveFolderTemplatesRequest: PDFSaveFolderTemplatesRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "SaveFolderTemplates";
    const body: PDFSaveFolderTemplatesRequestJSON = PDFSaveFolderTemplatesRequestToJSON(pDFSaveFolderTemplatesRequest);
    await sendTwirpRequest(url, body);
  }
}
