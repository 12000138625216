// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { FolderKinds } from "../model";
import type { Folder } from "../rpc/model";

export function getFolderCaptionName(folder: Folder): string {
  switch (folder.kind) {
    case FolderKinds.org:
      return "Org Folder";
    case FolderKinds.project:
      return "Project Folder";
    case FolderKinds.sdeMaster:
      return "SDE Master Folder";
    case FolderKinds.sde:
      return "SDE Folder";
    case FolderKinds.sdeFrames:
      return "Frames Folder";
    case FolderKinds.sdeItem:
      if (folder.mime.startsWith('image/'))
        return 'Image Set';

      if (folder.mime.startsWith('video/'))
        return 'Video Set';

      if (folder.mime.startsWith('audio/'))
        return 'Audio Set';

      if (folder.mime.startsWith('stream/'))
        return 'Stream Set';

      if (folder.mime === "3d/fbx")
        return 'FBX Set';

      if (folder.mime === "3d/3dmm")
        return '3dmm Set';

      if (folder.mime === "3d/pcd")
        return 'PCD Set';

      if (folder.mime === "ca/nde")
        return 'NDE Set';

      if (folder.mime === "application/vnd.google-earth.kml+xml")
        return 'KML Set';

      return "Set";

    case FolderKinds.sdeRetrain:
      return "SDE Retrain Folder";

    default:
      return "Folder";
  }
}
