// @flow strict
/* eslint-disable */
import { sendTwirpRequest } from "./twirp";
import type { Folder, FolderJSON } from "./model";
import { FolderToJSON, JSONToFolder } from "./model";
import type { FolderItem, FolderItemJSON } from "./model";
import { FolderItemToJSON, JSONToFolderItem } from "./model";
import type { FolderNote, FolderNoteJSON } from "./model";
import { FolderNoteToJSON, JSONToFolderNote } from "./model";
import type { FolderRight, FolderRightJSON } from "./model";
import { FolderRightToJSON, JSONToFolderRight } from "./model";
import type { LabelType, LabelTypeJSON } from "./model";
import { LabelTypeToJSON, JSONToLabelType } from "./model";
import type { Position, PositionJSON } from "./model";
import { PositionToJSON, JSONToPosition } from "./model";
import type { User, UserJSON } from "./model";
import { UserToJSON, JSONToUser } from "./model";
export type FolderRightsRequest = {
  folderId: string;
}

export type FolderRightsRequestJSON = {
  folder_id?: string;
}

export function FolderRightsRequestToJSON(m: FolderRightsRequest): FolderRightsRequestJSON {
  return {
    folder_id: m.folderId,
  };
}

export function JSONToFolderRightsRequest(m: FolderRightsRequestJSON): FolderRightsRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
  };
}

export type FolderRightsResponse = {
  rights: Array<FolderRight>;
}

export type FolderRightsResponseJSON = {
  rights?: Array<FolderRightJSON>;
}

export function FolderRightsResponseToJSON(m: FolderRightsResponse): FolderRightsResponseJSON {
  return {
    rights: m.rights.map(FolderRightToJSON),
  };
}

export function JSONToFolderRightsResponse(m: FolderRightsResponseJSON): FolderRightsResponse {
  return {
    rights: m.rights != null ? m.rights.map(JSONToFolderRight) : [],
  };
}

export type GrantFolderAccessRequest = {
  userId: string;
  folderId: string;
  permission: string;
}

export type GrantFolderAccessRequestJSON = {
  user_id?: string;
  folder_id?: string;
  permission?: string;
}

export function GrantFolderAccessRequestToJSON(m: GrantFolderAccessRequest): GrantFolderAccessRequestJSON {
  return {
    user_id: m.userId,
    folder_id: m.folderId,
    permission: m.permission,
  };
}

export function JSONToGrantFolderAccessRequest(m: GrantFolderAccessRequestJSON): GrantFolderAccessRequest {
  return {
    userId: m.user_id != null ? m.user_id : "",
    folderId: m.folder_id != null ? m.folder_id : "",
    permission: m.permission != null ? m.permission : "",
  };
}

export type GrantFolderAccessResponse = {
}

export type GrantFolderAccessResponseJSON = {
}

export function GrantFolderAccessResponseToJSON(m: GrantFolderAccessResponse): GrantFolderAccessResponseJSON {
  return {
  };
}

export function JSONToGrantFolderAccessResponse(m: GrantFolderAccessResponseJSON): GrantFolderAccessResponse {
  return {
  };
}

export type FolderItemsRequest = {
  folderId: string;
  itemType: string;
  labelCondition: string;
  labelTypeIds: Array<number>;
  userId: string;
  voteResult: string;
  voteUserId: string;
  itemName: string;
  detectionId: string;
  noteUserId: string;
  sortBy: string;
}

export type FolderItemsRequestJSON = {
  folder_id?: string;
  item_type?: string;
  label_condition?: string;
  label_type_ids?: Array<number>;
  user_id?: string;
  vote_result?: string;
  vote_user_id?: string;
  item_name?: string;
  detection_id?: string;
  note_user_id?: string;
  sort_by?: string;
}

export function FolderItemsRequestToJSON(m: FolderItemsRequest): FolderItemsRequestJSON {
  return {
    folder_id: m.folderId,
    item_type: m.itemType,
    label_condition: m.labelCondition,
    label_type_ids: m.labelTypeIds,
    user_id: m.userId,
    vote_result: m.voteResult,
    vote_user_id: m.voteUserId,
    item_name: m.itemName,
    detection_id: m.detectionId,
    note_user_id: m.noteUserId,
    sort_by: m.sortBy,
  };
}

export function JSONToFolderItemsRequest(m: FolderItemsRequestJSON): FolderItemsRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    itemType: m.item_type != null ? m.item_type : "",
    labelCondition: m.label_condition != null ? m.label_condition : "",
    labelTypeIds: m.label_type_ids != null ? m.label_type_ids : [],
    userId: m.user_id != null ? m.user_id : "",
    voteResult: m.vote_result != null ? m.vote_result : "",
    voteUserId: m.vote_user_id != null ? m.vote_user_id : "",
    itemName: m.item_name != null ? m.item_name : "",
    detectionId: m.detection_id != null ? m.detection_id : "",
    noteUserId: m.note_user_id != null ? m.note_user_id : "",
    sortBy: m.sort_by != null ? m.sort_by : "",
  };
}

export type FolderItemsResponse = {
  items: Array<FolderItem>;
  detectionParameters: Array<string>;
}

export type FolderItemsResponseJSON = {
  items?: Array<FolderItemJSON>;
  detection_parameters?: Array<string>;
}

export function FolderItemsResponseToJSON(m: FolderItemsResponse): FolderItemsResponseJSON {
  return {
    items: m.items.map(FolderItemToJSON),
    detection_parameters: m.detectionParameters,
  };
}

export function JSONToFolderItemsResponse(m: FolderItemsResponseJSON): FolderItemsResponse {
  return {
    items: m.items != null ? m.items.map(JSONToFolderItem) : [],
    detectionParameters: m.detection_parameters != null ? m.detection_parameters : [],
  };
}

export type FolderNestedItemsRequest = {
  folderId: string;
}

export type FolderNestedItemsRequestJSON = {
  folder_id?: string;
}

export function FolderNestedItemsRequestToJSON(m: FolderNestedItemsRequest): FolderNestedItemsRequestJSON {
  return {
    folder_id: m.folderId,
  };
}

export function JSONToFolderNestedItemsRequest(m: FolderNestedItemsRequestJSON): FolderNestedItemsRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
  };
}

export type FolderNestedItemsResponse = {
  items: Array<FolderItem>;
}

export type FolderNestedItemsResponseJSON = {
  items?: Array<FolderItemJSON>;
}

export function FolderNestedItemsResponseToJSON(m: FolderNestedItemsResponse): FolderNestedItemsResponseJSON {
  return {
    items: m.items.map(FolderItemToJSON),
  };
}

export function JSONToFolderNestedItemsResponse(m: FolderNestedItemsResponseJSON): FolderNestedItemsResponse {
  return {
    items: m.items != null ? m.items.map(JSONToFolderItem) : [],
  };
}

export type FolderPasteItemsRequest = {
  sourceFolderId: string;
  destFolderId: string;
  items: Array<string>;
}

export type FolderPasteItemsRequestJSON = {
  source_folder_id?: string;
  dest_folder_id?: string;
  items?: Array<string>;
}

export function FolderPasteItemsRequestToJSON(m: FolderPasteItemsRequest): FolderPasteItemsRequestJSON {
  return {
    source_folder_id: m.sourceFolderId,
    dest_folder_id: m.destFolderId,
    items: m.items,
  };
}

export function JSONToFolderPasteItemsRequest(m: FolderPasteItemsRequestJSON): FolderPasteItemsRequest {
  return {
    sourceFolderId: m.source_folder_id != null ? m.source_folder_id : "",
    destFolderId: m.dest_folder_id != null ? m.dest_folder_id : "",
    items: m.items != null ? m.items : [],
  };
}

export type FolderPasteItemsResponse = {
  items: Array<FolderItem>;
}

export type FolderPasteItemsResponseJSON = {
  items?: Array<FolderItemJSON>;
}

export function FolderPasteItemsResponseToJSON(m: FolderPasteItemsResponse): FolderPasteItemsResponseJSON {
  return {
    items: m.items.map(FolderItemToJSON),
  };
}

export function JSONToFolderPasteItemsResponse(m: FolderPasteItemsResponseJSON): FolderPasteItemsResponse {
  return {
    items: m.items != null ? m.items.map(JSONToFolderItem) : [],
  };
}

export type FolderSetNameRequest = {
  folderId: string;
  name: string;
}

export type FolderSetNameRequestJSON = {
  folder_id?: string;
  name?: string;
}

export function FolderSetNameRequestToJSON(m: FolderSetNameRequest): FolderSetNameRequestJSON {
  return {
    folder_id: m.folderId,
    name: m.name,
  };
}

export function JSONToFolderSetNameRequest(m: FolderSetNameRequestJSON): FolderSetNameRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    name: m.name != null ? m.name : "",
  };
}

export type FolderSetNameResponse = {
}

export type FolderSetNameResponseJSON = {
}

export function FolderSetNameResponseToJSON(m: FolderSetNameResponse): FolderSetNameResponseJSON {
  return {
  };
}

export function JSONToFolderSetNameResponse(m: FolderSetNameResponseJSON): FolderSetNameResponse {
  return {
  };
}

export type FolderSetTitleRequest = {
  folderId: string;
  title: string;
}

export type FolderSetTitleRequestJSON = {
  folder_id?: string;
  title?: string;
}

export function FolderSetTitleRequestToJSON(m: FolderSetTitleRequest): FolderSetTitleRequestJSON {
  return {
    folder_id: m.folderId,
    title: m.title,
  };
}

export function JSONToFolderSetTitleRequest(m: FolderSetTitleRequestJSON): FolderSetTitleRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    title: m.title != null ? m.title : "",
  };
}

export type FolderSetTitleResponse = {
}

export type FolderSetTitleResponseJSON = {
}

export function FolderSetTitleResponseToJSON(m: FolderSetTitleResponse): FolderSetTitleResponseJSON {
  return {
  };
}

export function JSONToFolderSetTitleResponse(m: FolderSetTitleResponseJSON): FolderSetTitleResponse {
  return {
  };
}

export type FolderSetForMobileAPIRequest = {
  folderId: string;
  forMobileApi: boolean;
}

export type FolderSetForMobileAPIRequestJSON = {
  folder_id?: string;
  for_mobile_api?: boolean;
}

export function FolderSetForMobileAPIRequestToJSON(m: FolderSetForMobileAPIRequest): FolderSetForMobileAPIRequestJSON {
  return {
    folder_id: m.folderId,
    for_mobile_api: m.forMobileApi,
  };
}

export function JSONToFolderSetForMobileAPIRequest(m: FolderSetForMobileAPIRequestJSON): FolderSetForMobileAPIRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    forMobileApi: m.for_mobile_api != null ? m.for_mobile_api : false,
  };
}

export type FolderSetForMobileAPIResponse = {
}

export type FolderSetForMobileAPIResponseJSON = {
}

export function FolderSetForMobileAPIResponseToJSON(m: FolderSetForMobileAPIResponse): FolderSetForMobileAPIResponseJSON {
  return {
  };
}

export function JSONToFolderSetForMobileAPIResponse(m: FolderSetForMobileAPIResponseJSON): FolderSetForMobileAPIResponse {
  return {
  };
}

export type FolderChildrenCountRequest = {
  folderId: string;
}

export type FolderChildrenCountRequestJSON = {
  folder_id?: string;
}

export function FolderChildrenCountRequestToJSON(m: FolderChildrenCountRequest): FolderChildrenCountRequestJSON {
  return {
    folder_id: m.folderId,
  };
}

export function JSONToFolderChildrenCountRequest(m: FolderChildrenCountRequestJSON): FolderChildrenCountRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
  };
}

export type FolderChildrenCountResponse = {
  count: number;
}

export type FolderChildrenCountResponseJSON = {
  count?: number;
}

export function FolderChildrenCountResponseToJSON(m: FolderChildrenCountResponse): FolderChildrenCountResponseJSON {
  return {
    count: m.count,
  };
}

export function JSONToFolderChildrenCountResponse(m: FolderChildrenCountResponseJSON): FolderChildrenCountResponse {
  return {
    count: m.count != null ? m.count : 0,
  };
}

export type FolderDeleteRequest = {
  folderId: string;
  onlyDerivedItems: boolean;
}

export type FolderDeleteRequestJSON = {
  folder_id?: string;
  only_derived_items?: boolean;
}

export function FolderDeleteRequestToJSON(m: FolderDeleteRequest): FolderDeleteRequestJSON {
  return {
    folder_id: m.folderId,
    only_derived_items: m.onlyDerivedItems,
  };
}

export function JSONToFolderDeleteRequest(m: FolderDeleteRequestJSON): FolderDeleteRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    onlyDerivedItems: m.only_derived_items != null ? m.only_derived_items : false,
  };
}

export type FolderDeleteResponse = {
}

export type FolderDeleteResponseJSON = {
}

export function FolderDeleteResponseToJSON(m: FolderDeleteResponse): FolderDeleteResponseJSON {
  return {
  };
}

export function JSONToFolderDeleteResponse(m: FolderDeleteResponseJSON): FolderDeleteResponse {
  return {
  };
}

export type FolderDeleteItemRequest = {
  folderId: string;
  itemId: string;
}

export type FolderDeleteItemRequestJSON = {
  folder_id?: string;
  item_id?: string;
}

export function FolderDeleteItemRequestToJSON(m: FolderDeleteItemRequest): FolderDeleteItemRequestJSON {
  return {
    folder_id: m.folderId,
    item_id: m.itemId,
  };
}

export function JSONToFolderDeleteItemRequest(m: FolderDeleteItemRequestJSON): FolderDeleteItemRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    itemId: m.item_id != null ? m.item_id : "",
  };
}

export type FolderDeleteItemResponse = {
}

export type FolderDeleteItemResponseJSON = {
}

export function FolderDeleteItemResponseToJSON(m: FolderDeleteItemResponse): FolderDeleteItemResponseJSON {
  return {
  };
}

export function JSONToFolderDeleteItemResponse(m: FolderDeleteItemResponseJSON): FolderDeleteItemResponse {
  return {
  };
}

export type FolderSortItemsRequest = {
  folderId: string;
  sortedItems: Array<string>;
}

export type FolderSortItemsRequestJSON = {
  folder_id?: string;
  sorted_items?: Array<string>;
}

export function FolderSortItemsRequestToJSON(m: FolderSortItemsRequest): FolderSortItemsRequestJSON {
  return {
    folder_id: m.folderId,
    sorted_items: m.sortedItems,
  };
}

export function JSONToFolderSortItemsRequest(m: FolderSortItemsRequestJSON): FolderSortItemsRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    sortedItems: m.sorted_items != null ? m.sorted_items : [],
  };
}

export type FolderSortItemsResponse = {
}

export type FolderSortItemsResponseJSON = {
}

export function FolderSortItemsResponseToJSON(m: FolderSortItemsResponse): FolderSortItemsResponseJSON {
  return {
  };
}

export function JSONToFolderSortItemsResponse(m: FolderSortItemsResponseJSON): FolderSortItemsResponse {
  return {
  };
}

export type FolderSortChildFoldersRequest = {
  folderId: string;
  sortedChildren: Array<string>;
}

export type FolderSortChildFoldersRequestJSON = {
  folder_id?: string;
  sorted_children?: Array<string>;
}

export function FolderSortChildFoldersRequestToJSON(m: FolderSortChildFoldersRequest): FolderSortChildFoldersRequestJSON {
  return {
    folder_id: m.folderId,
    sorted_children: m.sortedChildren,
  };
}

export function JSONToFolderSortChildFoldersRequest(m: FolderSortChildFoldersRequestJSON): FolderSortChildFoldersRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    sortedChildren: m.sorted_children != null ? m.sorted_children : [],
  };
}

export type FolderSortChildFoldersResponse = {
  children: Array<Folder>;
}

export type FolderSortChildFoldersResponseJSON = {
  children?: Array<FolderJSON>;
}

export function FolderSortChildFoldersResponseToJSON(m: FolderSortChildFoldersResponse): FolderSortChildFoldersResponseJSON {
  return {
    children: m.children.map(FolderToJSON),
  };
}

export function JSONToFolderSortChildFoldersResponse(m: FolderSortChildFoldersResponseJSON): FolderSortChildFoldersResponse {
  return {
    children: m.children != null ? m.children.map(JSONToFolder) : [],
  };
}

export type FolderSortRootFoldersRequest = {
  isShared: boolean;
  sortedFolders: Array<string>;
}

export type FolderSortRootFoldersRequestJSON = {
  is_shared?: boolean;
  sorted_folders?: Array<string>;
}

export function FolderSortRootFoldersRequestToJSON(m: FolderSortRootFoldersRequest): FolderSortRootFoldersRequestJSON {
  return {
    is_shared: m.isShared,
    sorted_folders: m.sortedFolders,
  };
}

export function JSONToFolderSortRootFoldersRequest(m: FolderSortRootFoldersRequestJSON): FolderSortRootFoldersRequest {
  return {
    isShared: m.is_shared != null ? m.is_shared : false,
    sortedFolders: m.sorted_folders != null ? m.sorted_folders : [],
  };
}

export type FolderSearchItemsRequest = {
  folderId: string;
  from: ?Position;
  to: ?Position;
  labels: Array<string>;
}

export type FolderSearchItemsRequestJSON = {
  folder_id?: string;
  from?: PositionJSON;
  to?: PositionJSON;
  labels?: Array<string>;
}

export function FolderSearchItemsRequestToJSON(m: FolderSearchItemsRequest): FolderSearchItemsRequestJSON {
  return {
    folder_id: m.folderId,
    from: m.from != null ? PositionToJSON(m.from) : undefined,
    to: m.to != null ? PositionToJSON(m.to) : undefined,
    labels: m.labels,
  };
}

export function JSONToFolderSearchItemsRequest(m: FolderSearchItemsRequestJSON): FolderSearchItemsRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    from: m.from != null ? JSONToPosition(m.from) : null,
    to: m.to != null ? JSONToPosition(m.to) : null,
    labels: m.labels != null ? m.labels : [],
  };
}

export type FolderSearchItemsResponse = {
  items: Array<FolderItem>;
}

export type FolderSearchItemsResponseJSON = {
  items?: Array<FolderItemJSON>;
}

export function FolderSearchItemsResponseToJSON(m: FolderSearchItemsResponse): FolderSearchItemsResponseJSON {
  return {
    items: m.items.map(FolderItemToJSON),
  };
}

export function JSONToFolderSearchItemsResponse(m: FolderSearchItemsResponseJSON): FolderSearchItemsResponse {
  return {
    items: m.items != null ? m.items.map(JSONToFolderItem) : [],
  };
}

export type FolderRootsRequest = {
}

export type FolderRootsRequestJSON = {
}

export function FolderRootsRequestToJSON(m: FolderRootsRequest): FolderRootsRequestJSON {
  return {
  };
}

export function JSONToFolderRootsRequest(m: FolderRootsRequestJSON): FolderRootsRequest {
  return {
  };
}

export type FolderRootsResponse = {
  folders: Array<Folder>;
}

export type FolderRootsResponseJSON = {
  folders?: Array<FolderJSON>;
}

export function FolderRootsResponseToJSON(m: FolderRootsResponse): FolderRootsResponseJSON {
  return {
    folders: m.folders.map(FolderToJSON),
  };
}

export function JSONToFolderRootsResponse(m: FolderRootsResponseJSON): FolderRootsResponse {
  return {
    folders: m.folders != null ? m.folders.map(JSONToFolder) : [],
  };
}

export type FolderTreeRequest = {
  sortBy: string;
}

export type FolderTreeRequestJSON = {
  sort_by?: string;
}

export function FolderTreeRequestToJSON(m: FolderTreeRequest): FolderTreeRequestJSON {
  return {
    sort_by: m.sortBy,
  };
}

export function JSONToFolderTreeRequest(m: FolderTreeRequestJSON): FolderTreeRequest {
  return {
    sortBy: m.sort_by != null ? m.sort_by : "",
  };
}

export type FolderTreeResponse = {
  shared: Array<Folder>;
  private: Array<Folder>;
  privateUsers: Array<User>;
}

export type FolderTreeResponseJSON = {
  shared?: Array<FolderJSON>;
  private?: Array<FolderJSON>;
  private_users?: Array<UserJSON>;
}

export function FolderTreeResponseToJSON(m: FolderTreeResponse): FolderTreeResponseJSON {
  return {
    shared: m.shared.map(FolderToJSON),
    private: m.private.map(FolderToJSON),
    private_users: m.privateUsers.map(UserToJSON),
  };
}

export function JSONToFolderTreeResponse(m: FolderTreeResponseJSON): FolderTreeResponse {
  return {
    shared: m.shared != null ? m.shared.map(JSONToFolder) : [],
    private: m.private != null ? m.private.map(JSONToFolder) : [],
    privateUsers: m.private_users != null ? m.private_users.map(JSONToUser) : [],
  };
}

export type FolderSubTreeRequest = {
  folderId: string;
}

export type FolderSubTreeRequestJSON = {
  folder_id?: string;
}

export function FolderSubTreeRequestToJSON(m: FolderSubTreeRequest): FolderSubTreeRequestJSON {
  return {
    folder_id: m.folderId,
  };
}

export function JSONToFolderSubTreeRequest(m: FolderSubTreeRequestJSON): FolderSubTreeRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
  };
}

export type FolderSubTreeResponse = {
  folder: ?Folder;
}

export type FolderSubTreeResponseJSON = {
  folder?: FolderJSON;
}

export function FolderSubTreeResponseToJSON(m: FolderSubTreeResponse): FolderSubTreeResponseJSON {
  return {
    folder: m.folder != null ? FolderToJSON(m.folder) : undefined,
  };
}

export function JSONToFolderSubTreeResponse(m: FolderSubTreeResponseJSON): FolderSubTreeResponse {
  return {
    folder: m.folder != null ? JSONToFolder(m.folder) : null,
  };
}

export type FolderAddFolderRequest = {
  name: string;
  isShared: boolean;
  parentId: string;
  kind: string;
}

export type FolderAddFolderRequestJSON = {
  name?: string;
  is_shared?: boolean;
  parent_id?: string;
  kind?: string;
}

export function FolderAddFolderRequestToJSON(m: FolderAddFolderRequest): FolderAddFolderRequestJSON {
  return {
    name: m.name,
    is_shared: m.isShared,
    parent_id: m.parentId,
    kind: m.kind,
  };
}

export function JSONToFolderAddFolderRequest(m: FolderAddFolderRequestJSON): FolderAddFolderRequest {
  return {
    name: m.name != null ? m.name : "",
    isShared: m.is_shared != null ? m.is_shared : false,
    parentId: m.parent_id != null ? m.parent_id : "",
    kind: m.kind != null ? m.kind : "",
  };
}

export type FolderAddFolderResponse = {
  folder: ?Folder;
}

export type FolderAddFolderResponseJSON = {
  folder?: FolderJSON;
}

export function FolderAddFolderResponseToJSON(m: FolderAddFolderResponse): FolderAddFolderResponseJSON {
  return {
    folder: m.folder != null ? FolderToJSON(m.folder) : undefined,
  };
}

export function JSONToFolderAddFolderResponse(m: FolderAddFolderResponseJSON): FolderAddFolderResponse {
  return {
    folder: m.folder != null ? JSONToFolder(m.folder) : null,
  };
}

export type FolderFolderRequest = {
  folderId: string;
  sortBy: string;
}

export type FolderFolderRequestJSON = {
  folder_id?: string;
  sort_by?: string;
}

export function FolderFolderRequestToJSON(m: FolderFolderRequest): FolderFolderRequestJSON {
  return {
    folder_id: m.folderId,
    sort_by: m.sortBy,
  };
}

export function JSONToFolderFolderRequest(m: FolderFolderRequestJSON): FolderFolderRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    sortBy: m.sort_by != null ? m.sort_by : "",
  };
}

export type FolderFolderResponse = {
  folder: ?Folder;
}

export type FolderFolderResponseJSON = {
  folder?: FolderJSON;
}

export function FolderFolderResponseToJSON(m: FolderFolderResponse): FolderFolderResponseJSON {
  return {
    folder: m.folder != null ? FolderToJSON(m.folder) : undefined,
  };
}

export function JSONToFolderFolderResponse(m: FolderFolderResponseJSON): FolderFolderResponse {
  return {
    folder: m.folder != null ? JSONToFolder(m.folder) : null,
  };
}

export type FolderChildrenRequest = {
  folderId: string;
  sortBy: string;
}

export type FolderChildrenRequestJSON = {
  folder_id?: string;
  sort_by?: string;
}

export function FolderChildrenRequestToJSON(m: FolderChildrenRequest): FolderChildrenRequestJSON {
  return {
    folder_id: m.folderId,
    sort_by: m.sortBy,
  };
}

export function JSONToFolderChildrenRequest(m: FolderChildrenRequestJSON): FolderChildrenRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    sortBy: m.sort_by != null ? m.sort_by : "",
  };
}

export type FolderChildrenResponse = {
  children: Array<Folder>;
}

export type FolderChildrenResponseJSON = {
  children?: Array<FolderJSON>;
}

export function FolderChildrenResponseToJSON(m: FolderChildrenResponse): FolderChildrenResponseJSON {
  return {
    children: m.children.map(FolderToJSON),
  };
}

export function JSONToFolderChildrenResponse(m: FolderChildrenResponseJSON): FolderChildrenResponse {
  return {
    children: m.children != null ? m.children.map(JSONToFolder) : [],
  };
}

export type FolderMobileAPICandidatesRequest = {
}

export type FolderMobileAPICandidatesRequestJSON = {
}

export function FolderMobileAPICandidatesRequestToJSON(m: FolderMobileAPICandidatesRequest): FolderMobileAPICandidatesRequestJSON {
  return {
  };
}

export function JSONToFolderMobileAPICandidatesRequest(m: FolderMobileAPICandidatesRequestJSON): FolderMobileAPICandidatesRequest {
  return {
  };
}

export type FolderMobileAPICandidatesResponse = {
  folders: Array<Folder>;
}

export type FolderMobileAPICandidatesResponseJSON = {
  folders?: Array<FolderJSON>;
}

export function FolderMobileAPICandidatesResponseToJSON(m: FolderMobileAPICandidatesResponse): FolderMobileAPICandidatesResponseJSON {
  return {
    folders: m.folders.map(FolderToJSON),
  };
}

export function JSONToFolderMobileAPICandidatesResponse(m: FolderMobileAPICandidatesResponseJSON): FolderMobileAPICandidatesResponse {
  return {
    folders: m.folders != null ? m.folders.map(JSONToFolder) : [],
  };
}

export type FolderWorkingCopiesRequest = {
  folderId: string;
  sortBy: string;
}

export type FolderWorkingCopiesRequestJSON = {
  folder_id?: string;
  sort_by?: string;
}

export function FolderWorkingCopiesRequestToJSON(m: FolderWorkingCopiesRequest): FolderWorkingCopiesRequestJSON {
  return {
    folder_id: m.folderId,
    sort_by: m.sortBy,
  };
}

export function JSONToFolderWorkingCopiesRequest(m: FolderWorkingCopiesRequestJSON): FolderWorkingCopiesRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    sortBy: m.sort_by != null ? m.sort_by : "",
  };
}

export type FolderWorkingCopiesResponse = {
  items: Array<FolderItem>;
}

export type FolderWorkingCopiesResponseJSON = {
  items?: Array<FolderItemJSON>;
}

export function FolderWorkingCopiesResponseToJSON(m: FolderWorkingCopiesResponse): FolderWorkingCopiesResponseJSON {
  return {
    items: m.items.map(FolderItemToJSON),
  };
}

export function JSONToFolderWorkingCopiesResponse(m: FolderWorkingCopiesResponseJSON): FolderWorkingCopiesResponse {
  return {
    items: m.items != null ? m.items.map(JSONToFolderItem) : [],
  };
}

export type FolderSaveTemporalSelectionRequest = {
  items: Array<string>;
}

export type FolderSaveTemporalSelectionRequestJSON = {
  items?: Array<string>;
}

export function FolderSaveTemporalSelectionRequestToJSON(m: FolderSaveTemporalSelectionRequest): FolderSaveTemporalSelectionRequestJSON {
  return {
    items: m.items,
  };
}

export function JSONToFolderSaveTemporalSelectionRequest(m: FolderSaveTemporalSelectionRequestJSON): FolderSaveTemporalSelectionRequest {
  return {
    items: m.items != null ? m.items : [],
  };
}

export type FolderSaveTemporalSelectionResponse = {
  selectionId: string;
}

export type FolderSaveTemporalSelectionResponseJSON = {
  selection_id?: string;
}

export function FolderSaveTemporalSelectionResponseToJSON(m: FolderSaveTemporalSelectionResponse): FolderSaveTemporalSelectionResponseJSON {
  return {
    selection_id: m.selectionId,
  };
}

export function JSONToFolderSaveTemporalSelectionResponse(m: FolderSaveTemporalSelectionResponseJSON): FolderSaveTemporalSelectionResponse {
  return {
    selectionId: m.selection_id != null ? m.selection_id : "",
  };
}

export type FolderTemporalSelectionRequest = {
  selectionId: string;
}

export type FolderTemporalSelectionRequestJSON = {
  selection_id?: string;
}

export function FolderTemporalSelectionRequestToJSON(m: FolderTemporalSelectionRequest): FolderTemporalSelectionRequestJSON {
  return {
    selection_id: m.selectionId,
  };
}

export function JSONToFolderTemporalSelectionRequest(m: FolderTemporalSelectionRequestJSON): FolderTemporalSelectionRequest {
  return {
    selectionId: m.selection_id != null ? m.selection_id : "",
  };
}

export type FolderTemporalSelectionResponse = {
  items: Array<string>;
}

export type FolderTemporalSelectionResponseJSON = {
  items?: Array<string>;
}

export function FolderTemporalSelectionResponseToJSON(m: FolderTemporalSelectionResponse): FolderTemporalSelectionResponseJSON {
  return {
    items: m.items,
  };
}

export function JSONToFolderTemporalSelectionResponse(m: FolderTemporalSelectionResponseJSON): FolderTemporalSelectionResponse {
  return {
    items: m.items != null ? m.items : [],
  };
}

export type FolderLabelTypesRequest = {
  folderId: string;
}

export type FolderLabelTypesRequestJSON = {
  folder_id?: string;
}

export function FolderLabelTypesRequestToJSON(m: FolderLabelTypesRequest): FolderLabelTypesRequestJSON {
  return {
    folder_id: m.folderId,
  };
}

export function JSONToFolderLabelTypesRequest(m: FolderLabelTypesRequestJSON): FolderLabelTypesRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
  };
}

export type FolderLabelTypesResponse = {
  labelTypes: Array<LabelType>;
}

export type FolderLabelTypesResponseJSON = {
  label_types?: Array<LabelTypeJSON>;
}

export function FolderLabelTypesResponseToJSON(m: FolderLabelTypesResponse): FolderLabelTypesResponseJSON {
  return {
    label_types: m.labelTypes.map(LabelTypeToJSON),
  };
}

export function JSONToFolderLabelTypesResponse(m: FolderLabelTypesResponseJSON): FolderLabelTypesResponse {
  return {
    labelTypes: m.label_types != null ? m.label_types.map(JSONToLabelType) : [],
  };
}

export type FolderCheckRetrainFolderRequest = {
  folderId: string;
}

export type FolderCheckRetrainFolderRequestJSON = {
  folder_id?: string;
}

export function FolderCheckRetrainFolderRequestToJSON(m: FolderCheckRetrainFolderRequest): FolderCheckRetrainFolderRequestJSON {
  return {
    folder_id: m.folderId,
  };
}

export function JSONToFolderCheckRetrainFolderRequest(m: FolderCheckRetrainFolderRequestJSON): FolderCheckRetrainFolderRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
  };
}

export type FolderCheckRetrainFolderResponse = {
  retrainFolderExists: boolean;
  workingCopyHasLabels: boolean;
}

export type FolderCheckRetrainFolderResponseJSON = {
  retrain_folder_exists?: boolean;
  working_copy_has_labels?: boolean;
}

export function FolderCheckRetrainFolderResponseToJSON(m: FolderCheckRetrainFolderResponse): FolderCheckRetrainFolderResponseJSON {
  return {
    retrain_folder_exists: m.retrainFolderExists,
    working_copy_has_labels: m.workingCopyHasLabels,
  };
}

export function JSONToFolderCheckRetrainFolderResponse(m: FolderCheckRetrainFolderResponseJSON): FolderCheckRetrainFolderResponse {
  return {
    retrainFolderExists: m.retrain_folder_exists != null ? m.retrain_folder_exists : false,
    workingCopyHasLabels: m.working_copy_has_labels != null ? m.working_copy_has_labels : false,
  };
}

export type FolderCopyItemsToRetrainFolderRequest = {
  sourceFolderId: string;
  items: Array<string>;
  engineName: string;
}

export type FolderCopyItemsToRetrainFolderRequestJSON = {
  source_folder_id?: string;
  items?: Array<string>;
  engine_name?: string;
}

export function FolderCopyItemsToRetrainFolderRequestToJSON(m: FolderCopyItemsToRetrainFolderRequest): FolderCopyItemsToRetrainFolderRequestJSON {
  return {
    source_folder_id: m.sourceFolderId,
    items: m.items,
    engine_name: m.engineName,
  };
}

export function JSONToFolderCopyItemsToRetrainFolderRequest(m: FolderCopyItemsToRetrainFolderRequestJSON): FolderCopyItemsToRetrainFolderRequest {
  return {
    sourceFolderId: m.source_folder_id != null ? m.source_folder_id : "",
    items: m.items != null ? m.items : [],
    engineName: m.engine_name != null ? m.engine_name : "",
  };
}

export type FolderCopyItemsToRetrainFolderResponse = {
}

export type FolderCopyItemsToRetrainFolderResponseJSON = {
}

export function FolderCopyItemsToRetrainFolderResponseToJSON(m: FolderCopyItemsToRetrainFolderResponse): FolderCopyItemsToRetrainFolderResponseJSON {
  return {
  };
}

export function JSONToFolderCopyItemsToRetrainFolderResponse(m: FolderCopyItemsToRetrainFolderResponseJSON): FolderCopyItemsToRetrainFolderResponse {
  return {
  };
}

export type FolderGrantedUsersRequest = {
  folderId: string;
}

export type FolderGrantedUsersRequestJSON = {
  folder_id?: string;
}

export function FolderGrantedUsersRequestToJSON(m: FolderGrantedUsersRequest): FolderGrantedUsersRequestJSON {
  return {
    folder_id: m.folderId,
  };
}

export function JSONToFolderGrantedUsersRequest(m: FolderGrantedUsersRequestJSON): FolderGrantedUsersRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
  };
}

export type FolderGrantedUsersResponse = {
  users: Array<User>;
}

export type FolderGrantedUsersResponseJSON = {
  users?: Array<UserJSON>;
}

export function FolderGrantedUsersResponseToJSON(m: FolderGrantedUsersResponse): FolderGrantedUsersResponseJSON {
  return {
    users: m.users.map(UserToJSON),
  };
}

export function JSONToFolderGrantedUsersResponse(m: FolderGrantedUsersResponseJSON): FolderGrantedUsersResponse {
  return {
    users: m.users != null ? m.users.map(JSONToUser) : [],
  };
}

export type FolderFilterRequest = {
  folderId: string;
}

export type FolderFilterRequestJSON = {
  folder_id?: string;
}

export function FolderFilterRequestToJSON(m: FolderFilterRequest): FolderFilterRequestJSON {
  return {
    folder_id: m.folderId,
  };
}

export function JSONToFolderFilterRequest(m: FolderFilterRequestJSON): FolderFilterRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
  };
}

export type FolderFilterResponse = {
  filter: string;
}

export type FolderFilterResponseJSON = {
  filter?: string;
}

export function FolderFilterResponseToJSON(m: FolderFilterResponse): FolderFilterResponseJSON {
  return {
    filter: m.filter,
  };
}

export function JSONToFolderFilterResponse(m: FolderFilterResponseJSON): FolderFilterResponse {
  return {
    filter: m.filter != null ? m.filter : "",
  };
}

export type FolderSetFilterRequest = {
  folderId: string;
  filter: string;
}

export type FolderSetFilterRequestJSON = {
  folder_id?: string;
  filter?: string;
}

export function FolderSetFilterRequestToJSON(m: FolderSetFilterRequest): FolderSetFilterRequestJSON {
  return {
    folder_id: m.folderId,
    filter: m.filter,
  };
}

export function JSONToFolderSetFilterRequest(m: FolderSetFilterRequestJSON): FolderSetFilterRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    filter: m.filter != null ? m.filter : "",
  };
}

export type FolderAutoDetectStatisticsRequest = {
  folderId: string;
}

export type FolderAutoDetectStatisticsRequestJSON = {
  folder_id?: string;
}

export function FolderAutoDetectStatisticsRequestToJSON(m: FolderAutoDetectStatisticsRequest): FolderAutoDetectStatisticsRequestJSON {
  return {
    folder_id: m.folderId,
  };
}

export function JSONToFolderAutoDetectStatisticsRequest(m: FolderAutoDetectStatisticsRequestJSON): FolderAutoDetectStatisticsRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
  };
}

export type FolderAutoDetectStatisticsItem = {
  name: string;
  totalCount: number;
  succeedCount: number;
  failedCount: number;
  startedAt: string;
  finishedAt: string;
  durationSec: number;
  averagePaceMs: number;
  minPaceMs: number;
  maxPaceMs: number;
  totalLabelCount: number;
  engine: string;
  engineParameters: Array<string>;
  labelCounts: Array<string>;
}

export type FolderAutoDetectStatisticsItemJSON = {
  name?: string;
  total_count?: number;
  succeed_count?: number;
  failed_count?: number;
  started_at?: string;
  finished_at?: string;
  duration_sec?: number;
  average_pace_ms?: number;
  min_pace_ms?: number;
  max_pace_ms?: number;
  total_label_count?: number;
  engine?: string;
  engine_parameters?: Array<string>;
  label_counts?: Array<string>;
}

export function FolderAutoDetectStatisticsItemToJSON(m: FolderAutoDetectStatisticsItem): FolderAutoDetectStatisticsItemJSON {
  return {
    name: m.name,
    total_count: m.totalCount,
    succeed_count: m.succeedCount,
    failed_count: m.failedCount,
    started_at: m.startedAt,
    finished_at: m.finishedAt,
    duration_sec: m.durationSec,
    average_pace_ms: m.averagePaceMs,
    min_pace_ms: m.minPaceMs,
    max_pace_ms: m.maxPaceMs,
    total_label_count: m.totalLabelCount,
    engine: m.engine,
    engine_parameters: m.engineParameters,
    label_counts: m.labelCounts,
  };
}

export function JSONToFolderAutoDetectStatisticsItem(m: FolderAutoDetectStatisticsItemJSON): FolderAutoDetectStatisticsItem {
  return {
    name: m.name != null ? m.name : "",
    totalCount: m.total_count != null ? m.total_count : 0,
    succeedCount: m.succeed_count != null ? m.succeed_count : 0,
    failedCount: m.failed_count != null ? m.failed_count : 0,
    startedAt: m.started_at != null ? m.started_at : "",
    finishedAt: m.finished_at != null ? m.finished_at : "",
    durationSec: m.duration_sec != null ? m.duration_sec : 0,
    averagePaceMs: m.average_pace_ms != null ? m.average_pace_ms : 0,
    minPaceMs: m.min_pace_ms != null ? m.min_pace_ms : 0,
    maxPaceMs: m.max_pace_ms != null ? m.max_pace_ms : 0,
    totalLabelCount: m.total_label_count != null ? m.total_label_count : 0,
    engine: m.engine != null ? m.engine : "",
    engineParameters: m.engine_parameters != null ? m.engine_parameters : [],
    labelCounts: m.label_counts != null ? m.label_counts : [],
  };
}

export type FolderAutoDetectStatisticsResponse = {
  items: Array<FolderAutoDetectStatisticsItem>;
}

export type FolderAutoDetectStatisticsResponseJSON = {
  items?: Array<FolderAutoDetectStatisticsItemJSON>;
}

export function FolderAutoDetectStatisticsResponseToJSON(m: FolderAutoDetectStatisticsResponse): FolderAutoDetectStatisticsResponseJSON {
  return {
    items: m.items.map(FolderAutoDetectStatisticsItemToJSON),
  };
}

export function JSONToFolderAutoDetectStatisticsResponse(m: FolderAutoDetectStatisticsResponseJSON): FolderAutoDetectStatisticsResponse {
  return {
    items: m.items != null ? m.items.map(JSONToFolderAutoDetectStatisticsItem) : [],
  };
}

export type FolderAutoDetectionsRequest = {
  folderId: string;
}

export type FolderAutoDetectionsRequestJSON = {
  folder_id?: string;
}

export function FolderAutoDetectionsRequestToJSON(m: FolderAutoDetectionsRequest): FolderAutoDetectionsRequestJSON {
  return {
    folder_id: m.folderId,
  };
}

export function JSONToFolderAutoDetectionsRequest(m: FolderAutoDetectionsRequestJSON): FolderAutoDetectionsRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
  };
}

export type FolderAutoDetection = {
  id: string;
  name: string;
}

export type FolderAutoDetectionJSON = {
  id?: string;
  name?: string;
}

export function FolderAutoDetectionToJSON(m: FolderAutoDetection): FolderAutoDetectionJSON {
  return {
    id: m.id,
    name: m.name,
  };
}

export function JSONToFolderAutoDetection(m: FolderAutoDetectionJSON): FolderAutoDetection {
  return {
    id: m.id != null ? m.id : "",
    name: m.name != null ? m.name : "",
  };
}

export type FolderAutoDetectionsResponse = {
  detections: Array<FolderAutoDetection>;
}

export type FolderAutoDetectionsResponseJSON = {
  detections?: Array<FolderAutoDetectionJSON>;
}

export function FolderAutoDetectionsResponseToJSON(m: FolderAutoDetectionsResponse): FolderAutoDetectionsResponseJSON {
  return {
    detections: m.detections.map(FolderAutoDetectionToJSON),
  };
}

export function JSONToFolderAutoDetectionsResponse(m: FolderAutoDetectionsResponseJSON): FolderAutoDetectionsResponse {
  return {
    detections: m.detections != null ? m.detections.map(JSONToFolderAutoDetection) : [],
  };
}

export type FolderPasteFolderRequest = {
  sourceFolderId: string;
  destFolderId: string;
}

export type FolderPasteFolderRequestJSON = {
  source_folder_id?: string;
  dest_folder_id?: string;
}

export function FolderPasteFolderRequestToJSON(m: FolderPasteFolderRequest): FolderPasteFolderRequestJSON {
  return {
    source_folder_id: m.sourceFolderId,
    dest_folder_id: m.destFolderId,
  };
}

export function JSONToFolderPasteFolderRequest(m: FolderPasteFolderRequestJSON): FolderPasteFolderRequest {
  return {
    sourceFolderId: m.source_folder_id != null ? m.source_folder_id : "",
    destFolderId: m.dest_folder_id != null ? m.dest_folder_id : "",
  };
}

export type FolderPasteFolderResponse = {
  error: string;
}

export type FolderPasteFolderResponseJSON = {
  error?: string;
}

export function FolderPasteFolderResponseToJSON(m: FolderPasteFolderResponse): FolderPasteFolderResponseJSON {
  return {
    error: m.error,
  };
}

export function JSONToFolderPasteFolderResponse(m: FolderPasteFolderResponseJSON): FolderPasteFolderResponse {
  return {
    error: m.error != null ? m.error : "",
  };
}

export type FolderFolderNotesRequest = {
  folderId: string;
}

export type FolderFolderNotesRequestJSON = {
  folder_id?: string;
}

export function FolderFolderNotesRequestToJSON(m: FolderFolderNotesRequest): FolderFolderNotesRequestJSON {
  return {
    folder_id: m.folderId,
  };
}

export function JSONToFolderFolderNotesRequest(m: FolderFolderNotesRequestJSON): FolderFolderNotesRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
  };
}

export type FolderFolderNotesResponse = {
  notes: Array<FolderNote>;
}

export type FolderFolderNotesResponseJSON = {
  notes?: Array<FolderNoteJSON>;
}

export function FolderFolderNotesResponseToJSON(m: FolderFolderNotesResponse): FolderFolderNotesResponseJSON {
  return {
    notes: m.notes.map(FolderNoteToJSON),
  };
}

export function JSONToFolderFolderNotesResponse(m: FolderFolderNotesResponseJSON): FolderFolderNotesResponse {
  return {
    notes: m.notes != null ? m.notes.map(JSONToFolderNote) : [],
  };
}

export type FolderAddFolderNoteRequest = {
  folderId: string;
  note: string;
}

export type FolderAddFolderNoteRequestJSON = {
  folder_id?: string;
  note?: string;
}

export function FolderAddFolderNoteRequestToJSON(m: FolderAddFolderNoteRequest): FolderAddFolderNoteRequestJSON {
  return {
    folder_id: m.folderId,
    note: m.note,
  };
}

export function JSONToFolderAddFolderNoteRequest(m: FolderAddFolderNoteRequestJSON): FolderAddFolderNoteRequest {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    note: m.note != null ? m.note : "",
  };
}

export type FolderAddFolderNoteResponse = {
  note: ?FolderNote;
}

export type FolderAddFolderNoteResponseJSON = {
  note?: FolderNoteJSON;
}

export function FolderAddFolderNoteResponseToJSON(m: FolderAddFolderNoteResponse): FolderAddFolderNoteResponseJSON {
  return {
    note: m.note != null ? FolderNoteToJSON(m.note) : undefined,
  };
}

export function JSONToFolderAddFolderNoteResponse(m: FolderAddFolderNoteResponseJSON): FolderAddFolderNoteResponse {
  return {
    note: m.note != null ? JSONToFolderNote(m.note) : null,
  };
}

export type FolderEditFolderNoteRequest = {
  noteId: string;
  note: string;
}

export type FolderEditFolderNoteRequestJSON = {
  note_id?: string;
  note?: string;
}

export function FolderEditFolderNoteRequestToJSON(m: FolderEditFolderNoteRequest): FolderEditFolderNoteRequestJSON {
  return {
    note_id: m.noteId,
    note: m.note,
  };
}

export function JSONToFolderEditFolderNoteRequest(m: FolderEditFolderNoteRequestJSON): FolderEditFolderNoteRequest {
  return {
    noteId: m.note_id != null ? m.note_id : "",
    note: m.note != null ? m.note : "",
  };
}

export type FolderSearchRequest = {
  query: string;
  filter: string;
}

export type FolderSearchRequestJSON = {
  query?: string;
  filter?: string;
}

export function FolderSearchRequestToJSON(m: FolderSearchRequest): FolderSearchRequestJSON {
  return {
    query: m.query,
    filter: m.filter,
  };
}

export function JSONToFolderSearchRequest(m: FolderSearchRequestJSON): FolderSearchRequest {
  return {
    query: m.query != null ? m.query : "",
    filter: m.filter != null ? m.filter : "",
  };
}

export type FolderSearchResponse = {
  folders: Array<Folder>;
  folderItems: Array<FolderItem>;
}

export type FolderSearchResponseJSON = {
  folders?: Array<FolderJSON>;
  folder_items?: Array<FolderItemJSON>;
}

export function FolderSearchResponseToJSON(m: FolderSearchResponse): FolderSearchResponseJSON {
  return {
    folders: m.folders.map(FolderToJSON),
    folder_items: m.folderItems.map(FolderItemToJSON),
  };
}

export function JSONToFolderSearchResponse(m: FolderSearchResponseJSON): FolderSearchResponse {
  return {
    folders: m.folders != null ? m.folders.map(JSONToFolder) : [],
    folderItems: m.folder_items != null ? m.folder_items.map(JSONToFolderItem) : [],
  };
}

export class FolderService {
  hostname: string;
  pathPrefix: string = "/rpc.FolderService/";

  constructor(hostname: string) {
    this.hostname = hostname;
  }

  async folderRights(folderRightsRequest: FolderRightsRequest): Promise<FolderRightsResponse> {
    const url = this.hostname + this.pathPrefix + "FolderRights";
    const body: FolderRightsRequestJSON = FolderRightsRequestToJSON(folderRightsRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderRightsResponse(data);
  }

  async grantFolderAccess(grantFolderAccessRequest: GrantFolderAccessRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "GrantFolderAccess";
    const body: GrantFolderAccessRequestJSON = GrantFolderAccessRequestToJSON(grantFolderAccessRequest);
    await sendTwirpRequest(url, body);
  }

  async items(folderItemsRequest: FolderItemsRequest): Promise<FolderItemsResponse> {
    const url = this.hostname + this.pathPrefix + "Items";
    const body: FolderItemsRequestJSON = FolderItemsRequestToJSON(folderItemsRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderItemsResponse(data);
  }

  async nestedItems(folderNestedItemsRequest: FolderNestedItemsRequest): Promise<FolderNestedItemsResponse> {
    const url = this.hostname + this.pathPrefix + "NestedItems";
    const body: FolderNestedItemsRequestJSON = FolderNestedItemsRequestToJSON(folderNestedItemsRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderNestedItemsResponse(data);
  }

  async pasteItems(folderPasteItemsRequest: FolderPasteItemsRequest): Promise<FolderPasteItemsResponse> {
    const url = this.hostname + this.pathPrefix + "PasteItems";
    const body: FolderPasteItemsRequestJSON = FolderPasteItemsRequestToJSON(folderPasteItemsRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderPasteItemsResponse(data);
  }

  async setName(folderSetNameRequest: FolderSetNameRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "SetName";
    const body: FolderSetNameRequestJSON = FolderSetNameRequestToJSON(folderSetNameRequest);
    await sendTwirpRequest(url, body);
  }

  async setTitle(folderSetTitleRequest: FolderSetTitleRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "SetTitle";
    const body: FolderSetTitleRequestJSON = FolderSetTitleRequestToJSON(folderSetTitleRequest);
    await sendTwirpRequest(url, body);
  }

  async setForMobileAPI(folderSetForMobileAPIRequest: FolderSetForMobileAPIRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "SetForMobileAPI";
    const body: FolderSetForMobileAPIRequestJSON = FolderSetForMobileAPIRequestToJSON(folderSetForMobileAPIRequest);
    await sendTwirpRequest(url, body);
  }

  async childrenCount(folderChildrenCountRequest: FolderChildrenCountRequest): Promise<FolderChildrenCountResponse> {
    const url = this.hostname + this.pathPrefix + "ChildrenCount";
    const body: FolderChildrenCountRequestJSON = FolderChildrenCountRequestToJSON(folderChildrenCountRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderChildrenCountResponse(data);
  }

  async delete(folderDeleteRequest: FolderDeleteRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "Delete";
    const body: FolderDeleteRequestJSON = FolderDeleteRequestToJSON(folderDeleteRequest);
    await sendTwirpRequest(url, body);
  }

  async deleteItem(folderDeleteItemRequest: FolderDeleteItemRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "DeleteItem";
    const body: FolderDeleteItemRequestJSON = FolderDeleteItemRequestToJSON(folderDeleteItemRequest);
    await sendTwirpRequest(url, body);
  }

  async sortItems(folderSortItemsRequest: FolderSortItemsRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "SortItems";
    const body: FolderSortItemsRequestJSON = FolderSortItemsRequestToJSON(folderSortItemsRequest);
    await sendTwirpRequest(url, body);
  }

  async sortChildFolders(folderSortChildFoldersRequest: FolderSortChildFoldersRequest): Promise<FolderSortChildFoldersResponse> {
    const url = this.hostname + this.pathPrefix + "SortChildFolders";
    const body: FolderSortChildFoldersRequestJSON = FolderSortChildFoldersRequestToJSON(folderSortChildFoldersRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderSortChildFoldersResponse(data);
  }

  async sortRootFolders(folderSortRootFoldersRequest: FolderSortRootFoldersRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "SortRootFolders";
    const body: FolderSortRootFoldersRequestJSON = FolderSortRootFoldersRequestToJSON(folderSortRootFoldersRequest);
    await sendTwirpRequest(url, body);
  }

  async searchItems(folderSearchItemsRequest: FolderSearchItemsRequest): Promise<FolderSearchItemsResponse> {
    const url = this.hostname + this.pathPrefix + "SearchItems";
    const body: FolderSearchItemsRequestJSON = FolderSearchItemsRequestToJSON(folderSearchItemsRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderSearchItemsResponse(data);
  }

  async roots(): Promise<FolderRootsResponse> {
    const url = this.hostname + this.pathPrefix + "Roots";
    const data = await sendTwirpRequest(url, {});
    return JSONToFolderRootsResponse(data);
  }

  async tree(folderTreeRequest: FolderTreeRequest): Promise<FolderTreeResponse> {
    const url = this.hostname + this.pathPrefix + "Tree";
    const body: FolderTreeRequestJSON = FolderTreeRequestToJSON(folderTreeRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderTreeResponse(data);
  }

  async subTree(folderSubTreeRequest: FolderSubTreeRequest): Promise<FolderSubTreeResponse> {
    const url = this.hostname + this.pathPrefix + "SubTree";
    const body: FolderSubTreeRequestJSON = FolderSubTreeRequestToJSON(folderSubTreeRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderSubTreeResponse(data);
  }

  async addFolder(folderAddFolderRequest: FolderAddFolderRequest): Promise<FolderAddFolderResponse> {
    const url = this.hostname + this.pathPrefix + "AddFolder";
    const body: FolderAddFolderRequestJSON = FolderAddFolderRequestToJSON(folderAddFolderRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderAddFolderResponse(data);
  }

  async folder(folderFolderRequest: FolderFolderRequest): Promise<FolderFolderResponse> {
    const url = this.hostname + this.pathPrefix + "Folder";
    const body: FolderFolderRequestJSON = FolderFolderRequestToJSON(folderFolderRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderFolderResponse(data);
  }

  async children(folderChildrenRequest: FolderChildrenRequest): Promise<FolderChildrenResponse> {
    const url = this.hostname + this.pathPrefix + "Children";
    const body: FolderChildrenRequestJSON = FolderChildrenRequestToJSON(folderChildrenRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderChildrenResponse(data);
  }

  async mobileAPICandidates(): Promise<FolderMobileAPICandidatesResponse> {
    const url = this.hostname + this.pathPrefix + "MobileAPICandidates";
    const data = await sendTwirpRequest(url, {});
    return JSONToFolderMobileAPICandidatesResponse(data);
  }

  async workingCopies(folderWorkingCopiesRequest: FolderWorkingCopiesRequest): Promise<FolderWorkingCopiesResponse> {
    const url = this.hostname + this.pathPrefix + "WorkingCopies";
    const body: FolderWorkingCopiesRequestJSON = FolderWorkingCopiesRequestToJSON(folderWorkingCopiesRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderWorkingCopiesResponse(data);
  }

  async saveTemporalSelection(folderSaveTemporalSelectionRequest: FolderSaveTemporalSelectionRequest): Promise<FolderSaveTemporalSelectionResponse> {
    const url = this.hostname + this.pathPrefix + "SaveTemporalSelection";
    const body: FolderSaveTemporalSelectionRequestJSON = FolderSaveTemporalSelectionRequestToJSON(folderSaveTemporalSelectionRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderSaveTemporalSelectionResponse(data);
  }

  async temporalSelection(folderTemporalSelectionRequest: FolderTemporalSelectionRequest): Promise<FolderTemporalSelectionResponse> {
    const url = this.hostname + this.pathPrefix + "TemporalSelection";
    const body: FolderTemporalSelectionRequestJSON = FolderTemporalSelectionRequestToJSON(folderTemporalSelectionRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderTemporalSelectionResponse(data);
  }

  async labelTypes(folderLabelTypesRequest: FolderLabelTypesRequest): Promise<FolderLabelTypesResponse> {
    const url = this.hostname + this.pathPrefix + "LabelTypes";
    const body: FolderLabelTypesRequestJSON = FolderLabelTypesRequestToJSON(folderLabelTypesRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderLabelTypesResponse(data);
  }

  async copyItemsToRetrainFolder(folderCopyItemsToRetrainFolderRequest: FolderCopyItemsToRetrainFolderRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "CopyItemsToRetrainFolder";
    const body: FolderCopyItemsToRetrainFolderRequestJSON = FolderCopyItemsToRetrainFolderRequestToJSON(folderCopyItemsToRetrainFolderRequest);
    await sendTwirpRequest(url, body);
  }

  async checkRetrainFolder(folderCheckRetrainFolderRequest: FolderCheckRetrainFolderRequest): Promise<FolderCheckRetrainFolderResponse> {
    const url = this.hostname + this.pathPrefix + "CheckRetrainFolder";
    const body: FolderCheckRetrainFolderRequestJSON = FolderCheckRetrainFolderRequestToJSON(folderCheckRetrainFolderRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderCheckRetrainFolderResponse(data);
  }

  async grantedUsers(folderGrantedUsersRequest: FolderGrantedUsersRequest): Promise<FolderGrantedUsersResponse> {
    const url = this.hostname + this.pathPrefix + "GrantedUsers";
    const body: FolderGrantedUsersRequestJSON = FolderGrantedUsersRequestToJSON(folderGrantedUsersRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderGrantedUsersResponse(data);
  }

  async filter(folderFilterRequest: FolderFilterRequest): Promise<FolderFilterResponse> {
    const url = this.hostname + this.pathPrefix + "Filter";
    const body: FolderFilterRequestJSON = FolderFilterRequestToJSON(folderFilterRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderFilterResponse(data);
  }

  async setFilter(folderSetFilterRequest: FolderSetFilterRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "SetFilter";
    const body: FolderSetFilterRequestJSON = FolderSetFilterRequestToJSON(folderSetFilterRequest);
    await sendTwirpRequest(url, body);
  }

  async autoDetectStatistics(folderAutoDetectStatisticsRequest: FolderAutoDetectStatisticsRequest): Promise<FolderAutoDetectStatisticsResponse> {
    const url = this.hostname + this.pathPrefix + "AutoDetectStatistics";
    const body: FolderAutoDetectStatisticsRequestJSON = FolderAutoDetectStatisticsRequestToJSON(folderAutoDetectStatisticsRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderAutoDetectStatisticsResponse(data);
  }

  async autoDetections(folderAutoDetectionsRequest: FolderAutoDetectionsRequest): Promise<FolderAutoDetectionsResponse> {
    const url = this.hostname + this.pathPrefix + "AutoDetections";
    const body: FolderAutoDetectionsRequestJSON = FolderAutoDetectionsRequestToJSON(folderAutoDetectionsRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderAutoDetectionsResponse(data);
  }

  async pasteFolder(folderPasteFolderRequest: FolderPasteFolderRequest): Promise<FolderPasteFolderResponse> {
    const url = this.hostname + this.pathPrefix + "PasteFolder";
    const body: FolderPasteFolderRequestJSON = FolderPasteFolderRequestToJSON(folderPasteFolderRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderPasteFolderResponse(data);
  }

  async folderNotes(folderFolderNotesRequest: FolderFolderNotesRequest): Promise<FolderFolderNotesResponse> {
    const url = this.hostname + this.pathPrefix + "FolderNotes";
    const body: FolderFolderNotesRequestJSON = FolderFolderNotesRequestToJSON(folderFolderNotesRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderFolderNotesResponse(data);
  }

  async addFolderNote(folderAddFolderNoteRequest: FolderAddFolderNoteRequest): Promise<FolderAddFolderNoteResponse> {
    const url = this.hostname + this.pathPrefix + "AddFolderNote";
    const body: FolderAddFolderNoteRequestJSON = FolderAddFolderNoteRequestToJSON(folderAddFolderNoteRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderAddFolderNoteResponse(data);
  }

  async editFolderNote(folderEditFolderNoteRequest: FolderEditFolderNoteRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "EditFolderNote";
    const body: FolderEditFolderNoteRequestJSON = FolderEditFolderNoteRequestToJSON(folderEditFolderNoteRequest);
    await sendTwirpRequest(url, body);
  }

  async search(folderSearchRequest: FolderSearchRequest): Promise<FolderSearchResponse> {
    const url = this.hostname + this.pathPrefix + "Search";
    const body: FolderSearchRequestJSON = FolderSearchRequestToJSON(folderSearchRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderSearchResponse(data);
  }
}
