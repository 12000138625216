// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { InvalidateMessage } from "./model";

type InvalidateListener = (msg: InvalidateMessage) => void;

export default class InvalidateNotifier {
  listeners: Array<InvalidateListener>;

  constructor() {
    this.listeners = [];
  }

  notify(msg: InvalidateMessage) {
    this.listeners.forEach(lst => lst(msg));
  }

  registerListener(listener: InvalidateListener) {
    this.listeners = this.listeners.some(lst => lst === listener) ?
      this.listeners :
      [...this.listeners, listener];
  }

  unregisterListener(listener: InvalidateListener) {
    this.listeners = this.listeners.filter(lst => lst !== listener);
  }
}