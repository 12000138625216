// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useState } from "react";
import type { Node } from "react";
import styles from "./Header.module.css";
import { Navbar, NavItem, Modal } from "react-bootstrap";
import HeaderLinks from "./HeaderLinks.js";
import dashboardRoutes from "../../routes/dashboard.js";
import { Colors } from "../../assets/colors.js";
import type AppState from '../../AppState';
import { observer } from 'mobx-react-lite';
import SearchView from '../search/SearchView';

type Props = {
  location: {
    pathname: string,
  },
  history: {
    push: (path: string) => void,
  },
  appState: AppState,
  toggleDrawer: (value: boolean) => void,
  isDrawerOpen: boolean,
  showHelpDialog: () => void,
}

function Header(props: Props): Node {
  const [isDarkTheme] = useState<boolean>(localStorage.getItem('isDarkTheme') === 'dark');
  const [searching, setSearching] = useState<boolean>(false);
  const route = dashboardRoutes.find(r => r.path === props.location.pathname);
  const brand = route != null ? route.name : "";

  function goto(path: string) {
    props.history.push(path);
    setSearching(false);
  }

  const me = props.appState.me;

  return (
    <Navbar fluid style={isDarkTheme ? {
      backgroundColor: "rgb(14, 12, 14)", borderBottomColor: Colors.black,
      boxShadow: "0 0 15px black"
    } : {}}>
      <Navbar.Header>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <div style={{ padding: 5 }} onClick={() => {
            props.toggleDrawer(!props.isDrawerOpen);
          }}>
            <i className={props.isDrawerOpen ? "pe-7s-close" : "pe-7s-menu"} style={{
              color: isDarkTheme ? Colors.darkTintColor : Colors.lightTintColor, fontSize: 30, marginRight: 5
            }} />
          </div>
          <Navbar.Brand style={{ color: isDarkTheme ? Colors.darkTintColor : Colors.lightTintColor }}>
            {brand}
          </Navbar.Brand>
          <NavItem className={styles.search} eventKey={1} onClick={() => setSearching(true)}>
            Search
          </NavItem>
        </div>
      </Navbar.Header>
      <Navbar.Collapse>
        <HeaderLinks appState={props.appState} showHelpDialog={props.showHelpDialog} />
      </Navbar.Collapse>
      {searching && me && <Modal
        show={true}
        onHide={() => setSearching(false)}
        dialogClassName={styles.modal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Search Folders
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchView me={me} goto={goto} />
        </Modal.Body>
      </Modal>}
    </Navbar>
  );
}

export default (observer(Header): typeof Header);
