// @flow strict
/* eslint-disable */
import { sendTwirpRequest } from "./twirp";
import type { Feature, FeatureJSON } from "./model";
import { FeatureToJSON, JSONToFeature } from "./model";
import type { Tier, TierJSON } from "./model";
import { TierToJSON, JSONToTier } from "./model";
import type { TierFeature, TierFeatureJSON } from "./model";
import { TierFeatureToJSON, JSONToTierFeature } from "./model";
export type TierFeaturesRequest = {
}

export type TierFeaturesRequestJSON = {
}

export function TierFeaturesRequestToJSON(m: TierFeaturesRequest): TierFeaturesRequestJSON {
  return {
  };
}

export function JSONToTierFeaturesRequest(m: TierFeaturesRequestJSON): TierFeaturesRequest {
  return {
  };
}

export type TierFeaturesResponse = {
  features: Array<Feature>;
}

export type TierFeaturesResponseJSON = {
  features?: Array<FeatureJSON>;
}

export function TierFeaturesResponseToJSON(m: TierFeaturesResponse): TierFeaturesResponseJSON {
  return {
    features: m.features.map(FeatureToJSON),
  };
}

export function JSONToTierFeaturesResponse(m: TierFeaturesResponseJSON): TierFeaturesResponse {
  return {
    features: m.features != null ? m.features.map(JSONToFeature) : [],
  };
}

export type TierTiersRequest = {
}

export type TierTiersRequestJSON = {
}

export function TierTiersRequestToJSON(m: TierTiersRequest): TierTiersRequestJSON {
  return {
  };
}

export function JSONToTierTiersRequest(m: TierTiersRequestJSON): TierTiersRequest {
  return {
  };
}

export type TierTiersResponse = {
  tiers: Array<Tier>;
}

export type TierTiersResponseJSON = {
  tiers?: Array<TierJSON>;
}

export function TierTiersResponseToJSON(m: TierTiersResponse): TierTiersResponseJSON {
  return {
    tiers: m.tiers.map(TierToJSON),
  };
}

export function JSONToTierTiersResponse(m: TierTiersResponseJSON): TierTiersResponse {
  return {
    tiers: m.tiers != null ? m.tiers.map(JSONToTier) : [],
  };
}

export type TierTierFeaturesRequest = {
}

export type TierTierFeaturesRequestJSON = {
}

export function TierTierFeaturesRequestToJSON(m: TierTierFeaturesRequest): TierTierFeaturesRequestJSON {
  return {
  };
}

export function JSONToTierTierFeaturesRequest(m: TierTierFeaturesRequestJSON): TierTierFeaturesRequest {
  return {
  };
}

export type TierTierFeaturesResponse = {
  tierFeatures: Array<TierFeature>;
}

export type TierTierFeaturesResponseJSON = {
  tier_features?: Array<TierFeatureJSON>;
}

export function TierTierFeaturesResponseToJSON(m: TierTierFeaturesResponse): TierTierFeaturesResponseJSON {
  return {
    tier_features: m.tierFeatures.map(TierFeatureToJSON),
  };
}

export function JSONToTierTierFeaturesResponse(m: TierTierFeaturesResponseJSON): TierTierFeaturesResponse {
  return {
    tierFeatures: m.tier_features != null ? m.tier_features.map(JSONToTierFeature) : [],
  };
}

export type TierModifyTierFeatureRequest = {
  tierId: number;
  featureId: string;
}

export type TierModifyTierFeatureRequestJSON = {
  tier_id?: number;
  feature_id?: string;
}

export function TierModifyTierFeatureRequestToJSON(m: TierModifyTierFeatureRequest): TierModifyTierFeatureRequestJSON {
  return {
    tier_id: m.tierId,
    feature_id: m.featureId,
  };
}

export function JSONToTierModifyTierFeatureRequest(m: TierModifyTierFeatureRequestJSON): TierModifyTierFeatureRequest {
  return {
    tierId: m.tier_id != null ? m.tier_id : 0,
    featureId: m.feature_id != null ? m.feature_id : "",
  };
}

export type TierModifyTierFeatureResponse = {
}

export type TierModifyTierFeatureResponseJSON = {
}

export function TierModifyTierFeatureResponseToJSON(m: TierModifyTierFeatureResponse): TierModifyTierFeatureResponseJSON {
  return {
  };
}

export function JSONToTierModifyTierFeatureResponse(m: TierModifyTierFeatureResponseJSON): TierModifyTierFeatureResponse {
  return {
  };
}

export type TierAddTierRequest = {
  title: string;
}

export type TierAddTierRequestJSON = {
  title?: string;
}

export function TierAddTierRequestToJSON(m: TierAddTierRequest): TierAddTierRequestJSON {
  return {
    title: m.title,
  };
}

export function JSONToTierAddTierRequest(m: TierAddTierRequestJSON): TierAddTierRequest {
  return {
    title: m.title != null ? m.title : "",
  };
}

export type TierAddTierResponse = {
  tier: ?Tier;
}

export type TierAddTierResponseJSON = {
  tier?: TierJSON;
}

export function TierAddTierResponseToJSON(m: TierAddTierResponse): TierAddTierResponseJSON {
  return {
    tier: m.tier != null ? TierToJSON(m.tier) : undefined,
  };
}

export function JSONToTierAddTierResponse(m: TierAddTierResponseJSON): TierAddTierResponse {
  return {
    tier: m.tier != null ? JSONToTier(m.tier) : null,
  };
}

export type TierDeleteTierRequest = {
  tierId: number;
}

export type TierDeleteTierRequestJSON = {
  tier_id?: number;
}

export function TierDeleteTierRequestToJSON(m: TierDeleteTierRequest): TierDeleteTierRequestJSON {
  return {
    tier_id: m.tierId,
  };
}

export function JSONToTierDeleteTierRequest(m: TierDeleteTierRequestJSON): TierDeleteTierRequest {
  return {
    tierId: m.tier_id != null ? m.tier_id : 0,
  };
}

export type TierDeleteTierResponse = {
}

export type TierDeleteTierResponseJSON = {
}

export function TierDeleteTierResponseToJSON(m: TierDeleteTierResponse): TierDeleteTierResponseJSON {
  return {
  };
}

export function JSONToTierDeleteTierResponse(m: TierDeleteTierResponseJSON): TierDeleteTierResponse {
  return {
  };
}

export type TierIsTierUsedRequest = {
  tierId: number;
}

export type TierIsTierUsedRequestJSON = {
  tier_id?: number;
}

export function TierIsTierUsedRequestToJSON(m: TierIsTierUsedRequest): TierIsTierUsedRequestJSON {
  return {
    tier_id: m.tierId,
  };
}

export function JSONToTierIsTierUsedRequest(m: TierIsTierUsedRequestJSON): TierIsTierUsedRequest {
  return {
    tierId: m.tier_id != null ? m.tier_id : 0,
  };
}

export type TierIsTierUsedResponse = {
  used: boolean;
}

export type TierIsTierUsedResponseJSON = {
  used?: boolean;
}

export function TierIsTierUsedResponseToJSON(m: TierIsTierUsedResponse): TierIsTierUsedResponseJSON {
  return {
    used: m.used,
  };
}

export function JSONToTierIsTierUsedResponse(m: TierIsTierUsedResponseJSON): TierIsTierUsedResponse {
  return {
    used: m.used != null ? m.used : false,
  };
}

export class TierService {
  hostname: string;
  pathPrefix: string = "/rpc.TierService/";

  constructor(hostname: string) {
    this.hostname = hostname;
  }

  async features(): Promise<TierFeaturesResponse> {
    const url = this.hostname + this.pathPrefix + "Features";
    const data = await sendTwirpRequest(url, {});
    return JSONToTierFeaturesResponse(data);
  }

  async tiers(): Promise<TierTiersResponse> {
    const url = this.hostname + this.pathPrefix + "Tiers";
    const data = await sendTwirpRequest(url, {});
    return JSONToTierTiersResponse(data);
  }

  async tierFeatures(): Promise<TierTierFeaturesResponse> {
    const url = this.hostname + this.pathPrefix + "TierFeatures";
    const data = await sendTwirpRequest(url, {});
    return JSONToTierTierFeaturesResponse(data);
  }

  async modifyTierFeature(tierModifyTierFeatureRequest: TierModifyTierFeatureRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "ModifyTierFeature";
    const body: TierModifyTierFeatureRequestJSON = TierModifyTierFeatureRequestToJSON(tierModifyTierFeatureRequest);
    await sendTwirpRequest(url, body);
  }

  async addTier(tierAddTierRequest: TierAddTierRequest): Promise<TierAddTierResponse> {
    const url = this.hostname + this.pathPrefix + "AddTier";
    const body: TierAddTierRequestJSON = TierAddTierRequestToJSON(tierAddTierRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToTierAddTierResponse(data);
  }

  async deleteTier(tierDeleteTierRequest: TierDeleteTierRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "DeleteTier";
    const body: TierDeleteTierRequestJSON = TierDeleteTierRequestToJSON(tierDeleteTierRequest);
    await sendTwirpRequest(url, body);
  }

  async isTierUsed(tierIsTierUsedRequest: TierIsTierUsedRequest): Promise<TierIsTierUsedResponse> {
    const url = this.hostname + this.pathPrefix + "IsTierUsed";
    const body: TierIsTierUsedRequestJSON = TierIsTierUsedRequestToJSON(tierIsTierUsedRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToTierIsTierUsedResponse(data);
  }
}
