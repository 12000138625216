// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import uuid4 from "uuid4";

export function assignTabId() {
  if (window.sessionStorage.getItem("tabId") == null)
    window.sessionStorage.setItem("tabId", uuid4());
}

export function getTabId(): string {
  const tabId = window.sessionStorage.getItem("tabId");
  return tabId != null ? tabId : "";
}