// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useCallback, useEffect, useRef, useState } from "react";
import type { Node } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./layouts/Dashboard/Dashboard.js";
import type { InvalidateMessage } from "./model";
import InvalidateNotifier from "./InvalidateNotifier";
import { assignTabId, getTabId } from "./utils/tab";
import { apiEndpoint } from "./utils/http";
import StoreFront from "./controls/store-front/StoreFront";
import AppState from './AppState';
import { observer } from 'mobx-react-lite';

type WSTaskMessage = {
  task?: string,
  title: string,
  item: string,
  additional?: Array<string>,
  ok?: boolean,
  error?: boolean,
  progress: number,
};

type WSMessage = { kind: "invalidate", message: InvalidateMessage } | { kind: "task", message: WSTaskMessage }

export function App(): Node {
  const [state] = useState<AppState>(() => new AppState());

  const ws = useRef<?WebSocket>(null);
  const pingInterval = useRef<?IntervalID>(null);
  const reconnectInterval = useRef<?IntervalID>(null);
  const taskNotifications = useRef<Map<string, string>>(new Map());
  const invalidateNotifier = useRef<InvalidateNotifier>(new InvalidateNotifier());
  const val = localStorage.getItem('isDarkTheme');
  const [currentTheme] = useState<string>(val ? val : "");

  useEffect(() => {
    return () => state.dispose();
  }, [state]);

  const onMessage = useCallback((msg: MessageEvent) => {
    if (typeof msg.data !== "string") {
      return;
    }

    const event = (JSON.parse(msg.data): WSMessage);
    if (event.kind === "task")
      onTaskMessage(event.message);
    else if (event.kind === "invalidate")
      invalidateNotifier.current.notify(event.message);
  }, []);

  useEffect(() => {
    if (state.me == null)
      return;

    function reconnectWebSocket() {
      if (ws.current != null && ws.current.readyState !== WebSocket.CLOSED)
        return;

      const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
      let apiHost = apiEndpoint();
      if (apiHost !== "") {
        apiHost = apiHost.substr(apiHost.indexOf("://") + "://".length);
      } else {
        apiHost = window.location.host;
      }
      ws.current = new WebSocket(`${wsProtocol}://${apiHost}/websocket?tabId=${getTabId()}`);
      ws.current.onmessage = onMessage;
    }

    assignTabId();

    reconnectWebSocket();
    pingInterval.current = setInterval(reconnectWebSocket, 1000);
    reconnectInterval.current = setInterval(ping, 5000);

    return () => {
      if (pingInterval.current != null)
        clearInterval(pingInterval.current);

      if (reconnectInterval.current != null)
        clearInterval(reconnectInterval.current);

      if (ws.current != null) {
        ws.current.close();
        ws.current = null;
      }
    };
  }, [onMessage, state.me]);

  function ping() {
    if (ws.current != null && ws.current.readyState === WebSocket.OPEN) {
      const payload = { id: "ping", page_url: window.location.pathname };
      ws.current.send(JSON.stringify(payload));
    }
  }

  function onTaskMessage(msg: WSTaskMessage) {
    const task = msg.task;
    if (task == null) {
      return;
    }

    let toastId = taskNotifications.current.get(task);
    if (toastId == null) {
      toastId = toast(<div>
        <div>{msg.title}</div>
        <div>{msg.item}</div>
        <div>{msg.progress}%</div>
        {msg.additional != null && msg.additional.map(item => <div>{item}</div>)}
      </div>, {
        type: toast.TYPE.WARNING,
        draggable: false,
        autoClose: false,
        closeButton: false,
        closeOnClick: false,
        progress: msg.progress / 100,
        onClose: () => {
          taskNotifications.current.delete(task);
        },
      });
      taskNotifications.current.set(task, toastId);
    } else {
      toast.update(toastId, {
        render: <div>
          <div>{msg.title}</div>
          <div>{msg.item}</div>
          <div>{msg.progress}%</div>
          {msg.additional != null && msg.additional.map(item => <div>{item}</div>)}
        </div>,
        type: msg.error === true ? toast.TYPE.ERROR : (msg.progress === 100 ? (msg.ok === false ? toast.TYPE.DEFAULT : toast.TYPE.SUCCESS) : toast.TYPE.WARNING),
        autoClose: msg.progress === 100 ? 10000 : false,
        closeButton: msg.progress === 100,
        closeOnClick: msg.progress === 100,
        progress: msg.progress < 100 ? msg.progress / 100 : 0.99,
      });

      if (msg.progress === 100) {
        setTimeout(function () {
          toast.dismiss(toastId);
        }, 30000);
      }
    }
  }

  if (state.me != null)
    return <BrowserRouter>
      <div className={currentTheme === "dark" ? 'theme--dark' : 'theme--default'}>
        <ToastContainer position='bottom-right' />
        <Route path="/" render={props => <Dashboard appState={state} invalidateNotifier={invalidateNotifier.current} {...props} />} />
      </div>
    </BrowserRouter>;

  if (state.me === null)
    return <div className={currentTheme === "dark" ? 'theme--dark' : 'theme--default'}>
      <StoreFront user={state.me} myAppsMode={false} history={{ push: dummyPush }} />
    </div>;

  return null;
}

function dummyPush() {
}

export default (observer(App): typeof App);
