// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import cx from "classnames";
import orderBy from "lodash/orderBy";
import styles from './TierList.module.css';
import type { Feature, Tier } from "../../rpc/model";
import { TierService } from "../../rpc/tiers";
import ToggleButton from "react-toggle-button";
import { Check, X } from "../toggle-button/buttons";
import Button from "../Button/Button";
import { apiEndpoint } from "../../utils/http";

export default function TierList(): Node {
  const [tiers, setTiers] = useState<Array<Tier>>([]);
  const [features, setFeatures] = useState<Array<Feature>>([]);
  const [tierFeatures, setTierFeatures] = useState<{ [string]: Array<number> }>({});

  useEffect(() => {
    async function fetchTiers() {
      const resp = await new TierService(apiEndpoint()).tiers();
      setTiers(orderBy(resp.tiers.filter(t => t.internalName === "" || t.internalName === 'basic-auto'), ['internalName', 'id'], ['desc', 'asc']));
    }

    fetchTiers();
  }, []);

  useEffect(() => {
    async function fetchFeatures() {
      const resp = await new TierService(apiEndpoint()).features();
      setFeatures(resp.features);
    }

    fetchFeatures();
  }, []);

  useEffect(() => {
    async function fetchTierFeatures() {
      const resp = await new TierService(apiEndpoint()).tierFeatures();
      const m: { [string]: Array<number> } = {};
      resp.tierFeatures.forEach(tf => {
        const tiers = m[tf.featureId];
        if (tiers != null) {
          tiers.push(tf.tierId);
          m[tf.featureId] = tiers;
        } else {
          m[tf.featureId] = [tf.tierId];
        }
      });

      setTierFeatures(m);
    }

    fetchTierFeatures();
  }, []);

  async function onTierFeatureChanged(tier: Tier, feature: Feature) {
    await new TierService(apiEndpoint()).modifyTierFeature({ tierId: tier.id, featureId: feature.id });

    let tiers = tierFeatures[feature.id];
    if (tiers != null && tiers.indexOf(tier.id) >= 0) {
      tiers = tiers.filter(t => t !== tier.id);
    } else {
      tiers = tiers != null ? [...tiers, tier.id] : [tier.id];
    }

    setTierFeatures({ ...tierFeatures, [feature.id]: tiers });
  }

  async function addTier() {
    let tierTitle = window.prompt("New tier");
    if (tierTitle == null)
      return;

    tierTitle = tierTitle.trim();
    if (tierTitle === "")
      return;

    const currentIndex = tiers.findIndex(t => t.title.toLowerCase() === tierTitle.toLowerCase());

    if (currentIndex >= 0) {
      alert(`The tier '${tierTitle}' already exists`);
      return;
    }

    const resp = await new TierService(apiEndpoint()).addTier({ title: tierTitle });
    const tier = resp.tier;
    if (tier != null)
      setTiers(tiers => [...tiers, tier]);
  }

  async function deleteTier(tier: Tier) {
    if (!window.confirm(`You are going to delete the tire '${tier.title}'.\nAre you sure?`))
      return;

    const usedResp = await new TierService(apiEndpoint()).isTierUsed({ tierId: tier.id });
    if (usedResp.used) {
      window.alert("Can't delete - the tire is used");
      return;
    }

    await new TierService(apiEndpoint()).deleteTier({ tierId: tier.id });
    setTiers(tiers => tiers.filter(t => t.id !== tier.id));
  }

  return <div className={styles.root}>
    <h3>
      <span className={styles.header}>User Tiers</span>
      <Button onClick={addTier}>
        Add Tire
      </Button>
    </h3>
    <table className={styles.tierTable}>
      <thead>
      <tr>
        <th>Feature</th>
        {tiers.map(t => <th key={t.id}>
          {t.title}
          {t.internalName === '' && <i className={cx("pe-7s-close-circle action-icons", styles.delete)} onClick={() => deleteTier(t)} />}
        </th>)}
      </tr>
      </thead>
      <tbody>
      {features.map(f => <tr key={f.id}>
        <td>{f.title}</td>
        {tiers.map(t => {
          const tf = tierFeatures[f.id];
          const checked = tf != null && tf.indexOf(t.id) >= 0;

          return <td key={t.id}>
            <ToggleButton
              inactiveLabel={<X />}
              activeLabel={<Check />}
              value={checked}
              onToggle={() => onTierFeatureChanged(t, f)} />
          </td>;
        })}
      </tr>)}
      </tbody>
    </table>
  </div>;
}
