// @flow strict
/* eslint-disable */
import { sendTwirpRequest } from "./twirp";
import type { FolderItem, FolderItemJSON } from "./model";
import { FolderItemToJSON, JSONToFolderItem } from "./model";
import type { Label, LabelJSON } from "./model";
import { LabelToJSON, JSONToLabel } from "./model";
export type LabelGetRequest = {
  itemId: string;
}

export type LabelGetRequestJSON = {
  item_id?: string;
}

export function LabelGetRequestToJSON(m: LabelGetRequest): LabelGetRequestJSON {
  return {
    item_id: m.itemId,
  };
}

export function JSONToLabelGetRequest(m: LabelGetRequestJSON): LabelGetRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
  };
}

export type LabelGetResponse = {
  labels: Array<Label>;
}

export type LabelGetResponseJSON = {
  labels?: Array<LabelJSON>;
}

export function LabelGetResponseToJSON(m: LabelGetResponse): LabelGetResponseJSON {
  return {
    labels: m.labels.map(LabelToJSON),
  };
}

export function JSONToLabelGetResponse(m: LabelGetResponseJSON): LabelGetResponse {
  return {
    labels: m.labels != null ? m.labels.map(JSONToLabel) : [],
  };
}

export type LabelAddOrEditRequest = {
  itemId: string;
  label: ?Label;
}

export type LabelAddOrEditRequestJSON = {
  item_id?: string;
  label?: LabelJSON;
}

export function LabelAddOrEditRequestToJSON(m: LabelAddOrEditRequest): LabelAddOrEditRequestJSON {
  return {
    item_id: m.itemId,
    label: m.label != null ? LabelToJSON(m.label) : undefined,
  };
}

export function JSONToLabelAddOrEditRequest(m: LabelAddOrEditRequestJSON): LabelAddOrEditRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
    label: m.label != null ? JSONToLabel(m.label) : null,
  };
}

export type LabelAddOrEditResponse = {
  label: ?Label;
}

export type LabelAddOrEditResponseJSON = {
  label?: LabelJSON;
}

export function LabelAddOrEditResponseToJSON(m: LabelAddOrEditResponse): LabelAddOrEditResponseJSON {
  return {
    label: m.label != null ? LabelToJSON(m.label) : undefined,
  };
}

export function JSONToLabelAddOrEditResponse(m: LabelAddOrEditResponseJSON): LabelAddOrEditResponse {
  return {
    label: m.label != null ? JSONToLabel(m.label) : null,
  };
}

export type LabelDeleteRequest = {
  itemId: string;
  labelId: string;
}

export type LabelDeleteRequestJSON = {
  item_id?: string;
  label_id?: string;
}

export function LabelDeleteRequestToJSON(m: LabelDeleteRequest): LabelDeleteRequestJSON {
  return {
    item_id: m.itemId,
    label_id: m.labelId,
  };
}

export function JSONToLabelDeleteRequest(m: LabelDeleteRequestJSON): LabelDeleteRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
    labelId: m.label_id != null ? m.label_id : "",
  };
}

export type LabelDeleteResponse = {
}

export type LabelDeleteResponseJSON = {
}

export function LabelDeleteResponseToJSON(m: LabelDeleteResponse): LabelDeleteResponseJSON {
  return {
  };
}

export function JSONToLabelDeleteResponse(m: LabelDeleteResponseJSON): LabelDeleteResponse {
  return {
  };
}

export type LabelDeleteAllRequest = {
  itemId: string;
}

export type LabelDeleteAllRequestJSON = {
  item_id?: string;
}

export function LabelDeleteAllRequestToJSON(m: LabelDeleteAllRequest): LabelDeleteAllRequestJSON {
  return {
    item_id: m.itemId,
  };
}

export function JSONToLabelDeleteAllRequest(m: LabelDeleteAllRequestJSON): LabelDeleteAllRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
  };
}

export type LabelDeleteAllResponse = {
}

export type LabelDeleteAllResponseJSON = {
}

export function LabelDeleteAllResponseToJSON(m: LabelDeleteAllResponse): LabelDeleteAllResponseJSON {
  return {
  };
}

export function JSONToLabelDeleteAllResponse(m: LabelDeleteAllResponseJSON): LabelDeleteAllResponse {
  return {
  };
}

export type LabelVisibilityRequest = {
  itemId: string;
}

export type LabelVisibilityRequestJSON = {
  item_id?: string;
}

export function LabelVisibilityRequestToJSON(m: LabelVisibilityRequest): LabelVisibilityRequestJSON {
  return {
    item_id: m.itemId,
  };
}

export function JSONToLabelVisibilityRequest(m: LabelVisibilityRequestJSON): LabelVisibilityRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
  };
}

export type LabelVisibilityResponse = {
  labels: Array<string>;
}

export type LabelVisibilityResponseJSON = {
  labels?: Array<string>;
}

export function LabelVisibilityResponseToJSON(m: LabelVisibilityResponse): LabelVisibilityResponseJSON {
  return {
    labels: m.labels,
  };
}

export function JSONToLabelVisibilityResponse(m: LabelVisibilityResponseJSON): LabelVisibilityResponse {
  return {
    labels: m.labels != null ? m.labels : [],
  };
}

export type LabelEditVisibilityRequest = {
  itemId: string;
  added: Array<string>;
  deleted: Array<string>;
}

export type LabelEditVisibilityRequestJSON = {
  item_id?: string;
  added?: Array<string>;
  deleted?: Array<string>;
}

export function LabelEditVisibilityRequestToJSON(m: LabelEditVisibilityRequest): LabelEditVisibilityRequestJSON {
  return {
    item_id: m.itemId,
    added: m.added,
    deleted: m.deleted,
  };
}

export function JSONToLabelEditVisibilityRequest(m: LabelEditVisibilityRequestJSON): LabelEditVisibilityRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
    added: m.added != null ? m.added : [],
    deleted: m.deleted != null ? m.deleted : [],
  };
}

export type LabelEditVisibilityResponse = {
}

export type LabelEditVisibilityResponseJSON = {
}

export function LabelEditVisibilityResponseToJSON(m: LabelEditVisibilityResponse): LabelEditVisibilityResponseJSON {
  return {
  };
}

export function JSONToLabelEditVisibilityResponse(m: LabelEditVisibilityResponseJSON): LabelEditVisibilityResponse {
  return {
  };
}

export type LabelCropRequest = {
  itemId: string;
  labelId: string;
  title: string;
}

export type LabelCropRequestJSON = {
  item_id?: string;
  label_id?: string;
  title?: string;
}

export function LabelCropRequestToJSON(m: LabelCropRequest): LabelCropRequestJSON {
  return {
    item_id: m.itemId,
    label_id: m.labelId,
    title: m.title,
  };
}

export function JSONToLabelCropRequest(m: LabelCropRequestJSON): LabelCropRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
    labelId: m.label_id != null ? m.label_id : "",
    title: m.title != null ? m.title : "",
  };
}

export type LabelCropResponse = {
  item: ?FolderItem;
}

export type LabelCropResponseJSON = {
  item?: FolderItemJSON;
}

export function LabelCropResponseToJSON(m: LabelCropResponse): LabelCropResponseJSON {
  return {
    item: m.item != null ? FolderItemToJSON(m.item) : undefined,
  };
}

export function JSONToLabelCropResponse(m: LabelCropResponseJSON): LabelCropResponse {
  return {
    item: m.item != null ? JSONToFolderItem(m.item) : null,
  };
}

export class LabelService {
  hostname: string;
  pathPrefix: string = "/rpc.LabelService/";

  constructor(hostname: string) {
    this.hostname = hostname;
  }

  async get(labelGetRequest: LabelGetRequest): Promise<LabelGetResponse> {
    const url = this.hostname + this.pathPrefix + "Get";
    const body: LabelGetRequestJSON = LabelGetRequestToJSON(labelGetRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToLabelGetResponse(data);
  }

  async addOrEdit(labelAddOrEditRequest: LabelAddOrEditRequest): Promise<LabelAddOrEditResponse> {
    const url = this.hostname + this.pathPrefix + "AddOrEdit";
    const body: LabelAddOrEditRequestJSON = LabelAddOrEditRequestToJSON(labelAddOrEditRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToLabelAddOrEditResponse(data);
  }

  async delete(labelDeleteRequest: LabelDeleteRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "Delete";
    const body: LabelDeleteRequestJSON = LabelDeleteRequestToJSON(labelDeleteRequest);
    await sendTwirpRequest(url, body);
  }

  async deleteAll(labelDeleteAllRequest: LabelDeleteAllRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "DeleteAll";
    const body: LabelDeleteAllRequestJSON = LabelDeleteAllRequestToJSON(labelDeleteAllRequest);
    await sendTwirpRequest(url, body);
  }

  async visibility(labelVisibilityRequest: LabelVisibilityRequest): Promise<LabelVisibilityResponse> {
    const url = this.hostname + this.pathPrefix + "Visibility";
    const body: LabelVisibilityRequestJSON = LabelVisibilityRequestToJSON(labelVisibilityRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToLabelVisibilityResponse(data);
  }

  async editVisibility(labelEditVisibilityRequest: LabelEditVisibilityRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "EditVisibility";
    const body: LabelEditVisibilityRequestJSON = LabelEditVisibilityRequestToJSON(labelEditVisibilityRequest);
    await sendTwirpRequest(url, body);
  }

  async crop(labelCropRequest: LabelCropRequest): Promise<LabelCropResponse> {
    const url = this.hostname + this.pathPrefix + "Crop";
    const body: LabelCropRequestJSON = LabelCropRequestToJSON(labelCropRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToLabelCropResponse(data);
  }
}
