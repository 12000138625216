// @flow strict
/* eslint-disable */
import { sendTwirpRequest } from "./twirp";

export type ActivityVisitPageRequest = {
  pageUrl: string;
  innerBlock: string;
}

export type ActivityVisitPageRequestJSON = {
  page_url?: string;
  inner_block?: string;
}

export function ActivityVisitPageRequestToJSON(m: ActivityVisitPageRequest): ActivityVisitPageRequestJSON {
  return {
    page_url: m.pageUrl,
    inner_block: m.innerBlock,
  };
}

export function JSONToActivityVisitPageRequest(m: ActivityVisitPageRequestJSON): ActivityVisitPageRequest {
  return {
    pageUrl: m.page_url != null ? m.page_url : "",
    innerBlock: m.inner_block != null ? m.inner_block : "",
  };
}

export type ActivityVisitPageResponse = {
}

export type ActivityVisitPageResponseJSON = {
}

export function ActivityVisitPageResponseToJSON(m: ActivityVisitPageResponse): ActivityVisitPageResponseJSON {
  return {
  };
}

export function JSONToActivityVisitPageResponse(m: ActivityVisitPageResponseJSON): ActivityVisitPageResponse {
  return {
  };
}

export type ActivityActionRequest = {
  pageUrl: string;
  name: string;
}

export type ActivityActionRequestJSON = {
  page_url?: string;
  name?: string;
}

export function ActivityActionRequestToJSON(m: ActivityActionRequest): ActivityActionRequestJSON {
  return {
    page_url: m.pageUrl,
    name: m.name,
  };
}

export function JSONToActivityActionRequest(m: ActivityActionRequestJSON): ActivityActionRequest {
  return {
    pageUrl: m.page_url != null ? m.page_url : "",
    name: m.name != null ? m.name : "",
  };
}

export type ActivityActionResponse = {
}

export type ActivityActionResponseJSON = {
}

export function ActivityActionResponseToJSON(m: ActivityActionResponse): ActivityActionResponseJSON {
  return {
  };
}

export function JSONToActivityActionResponse(m: ActivityActionResponseJSON): ActivityActionResponse {
  return {
  };
}

export class ActivityService {
  hostname: string;
  pathPrefix: string = "/rpc.ActivityService/";

  constructor(hostname: string) {
    this.hostname = hostname;
  }

  async visitPage(activityVisitPageRequest: ActivityVisitPageRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "VisitPage";
    const body: ActivityVisitPageRequestJSON = ActivityVisitPageRequestToJSON(activityVisitPageRequest);
    await sendTwirpRequest(url, body);
  }

  async action(activityActionRequest: ActivityActionRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "Action";
    const body: ActivityActionRequestJSON = ActivityActionRequestToJSON(activityActionRequest);
    await sendTwirpRequest(url, body);
  }
}
