// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React from 'react';
import type { Node } from "react";
import styles from './StoreFrontApp.module.css';
import NoImageFound from '../../assets/img/storeFrontImages/NoImageFound.jpg';
import type { StorefrontApp } from "../../rpc/model";
import ToggleButton from "react-toggle-button";
import { Check, X } from "../toggle-button/buttons";
import Cookies from "js-cookie";

type Props = {
  app: StorefrontApp,
  myAppMode: boolean,
  isAdmin: boolean,
  onEdit: (app: StorefrontApp) => void,
  onDelete: (app: StorefrontApp) => Promise<void>,
  onVisibleChanged: (app: StorefrontApp) => Promise<void>,
  onMove: (app: StorefrontApp, delta: number) => Promise<void>,
};

function goto(app: StorefrontApp, myAppMode: boolean) {
  const url = myAppMode ? app.appUrl : app.infoUrl;
  if (url === "")
    return;

  if (!myAppMode)
    window.open(url, '_blank');

  const sessionPart = `session=${Cookies.get('session-id')}`;
  let extendedURL = url + (url.includes("?") ? "&" : "?") + sessionPart;

  if (app.category === "visualization") {
    const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
    const wsPart = `folder=&ws=${encodeURIComponent(`${wsProtocol}://${window.location.host}/websocket/folder`)}`;
    extendedURL += "&" + wsPart;
  }
  window.open(extendedURL, '_blank');
}

export default function StoreFrontApp(props: Props): Node {
  function onEdit(e: SyntheticMouseEvent<HTMLImageElement>) {
    e.preventDefault();
    e.stopPropagation();
    props.onEdit(props.app);
  }

  function onDelete(e: SyntheticMouseEvent<HTMLImageElement>) {
    e.preventDefault();
    e.stopPropagation();
    props.onDelete(props.app);
  }

  function onVisibleChanged() {
    props.onVisibleChanged(props.app);
  }

  const imageUrl = props.app.imageUrl === "" && props.app.imageId === "" ?
    NoImageFound :
    (props.app.imageId !== "" ? `/api/storefront/image/${props.app.id}` : props.app.imageUrl);

  function onMoveLeft() {
    props.onMove(props.app, -1);
  }

  function onMoveRight() {
    props.onMove(props.app, 1);
  }

  return (
    <div className='col-lg-3 col-sm-6 col-xs-12'>
      {props.isAdmin && <div className={styles.visibility}>
        <span className={styles.visibilityLabel}>Visible</span>
        <ToggleButton
          inactiveLabel={<X />}
          activeLabel={<Check />}
          value={props.app.visible}
          onToggle={onVisibleChanged} />
      </div>}
      {props.isAdmin && <div className={styles.buttonIcons}>
        <i className={`fa fa-arrow-circle-left ${styles.buttonIcon}`} onClick={onMoveLeft} />
        <i className={`fa fa-arrow-circle-right ${styles.buttonIcon}`} onClick={onMoveRight} />
        <i className={`fa fa-pencil ${styles.buttonIcon}`} onClick={onEdit} />
        <i className={`fa fa-times ${styles.buttonIcon}`} onClick={onDelete} />
      </div>}
      <div className={styles.imgOverlay} onClick={() => goto(props.app, props.myAppMode)}>
        <div className={styles.overlay}>
          <span className={styles.textBottom}>{props.app.title}
            <p className={styles.description}>{props.app.description}</p>
                        <div className={styles.keepReading}><span>Keep Reading <i className='fa fa-chevron-right' /></span></div>
                    </span>
        </div>
        <img src={imageUrl} alt="img" />
      </div>
    </div>
  );
}
