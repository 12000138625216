// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useRef } from 'react';
import type { Node } from "react";
import type { ArrowOrientation } from '../../model';

const PolyLineStyle = {
  fill: 'none',
};

const PathStyle = {
  fill: 'none'
};

type ArrowProps = {
  width: number,
  height: number,
  orientation: ArrowOrientation,
  lineWidth: number,
  color: string,
  arrowHeadFilled: boolean,
}

let __counter: number = 1;

export default function Arrow(props: ArrowProps): Node {
  const uniqId = useRef<number>(__counter++);

  const { width, height, arrowHeadFilled, lineWidth, orientation, color } = props;

  const viewBox = `0 0 ${width} ${height}`;

  let path = [];
  switch (orientation) {
    case 'n':
      path = [`M ${width / 2} ${height - 5}`, `L ${width / 2} 10`];
      break;
    case 'ne':
      path = [`M 10 ${height - 5}`, `L ${width - 15} 10`];
      break;
    case 'e':
      path = [`M 5 ${height / 2}`, `L ${width - 10} ${height / 2}`];
      break;
    case 'se':
      path = [`M 10 10`, `L ${width - 10} ${height - 5}`];
      break;
    case 's':
      path = [`M ${width / 2} 5`, `L ${width / 2} ${height - 10}`];
      break;
    case 'sw':
      path = [`M ${width - 10} 5`, `L 10 ${height - 5}`];
      break;
    case 'w':
      path = [`M ${width - 5} ${height / 2}`, `L 10 ${height / 2}`];
      break;
    case 'nw':
      path = [`M ${width - 10} ${height - 5}`, `L 10 5`];
      break;
    default:
      break;
  }

  const markerId = `Arrow-pointer-${uniqId.current}`;

  // Based on the props, determine the styles.
  const arrowHeadStyle = {
    ...PolyLineStyle,
    strokeWidth: 0,
    stroke: color
  };
  if (arrowHeadFilled) {
    arrowHeadStyle.fill = color;
  } else {
    arrowHeadStyle.strokeWidth = 1;
  }

  let strokeWidth = lineWidth;
  switch (orientation) {
    case 'n':
    case 'e':
    case 'w':
    case 's':
      strokeWidth--;
      break;
    default:
  }

  const lineStyle = {
    ...PathStyle,
    stroke: color,
    strokeWidth: strokeWidth,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      style={{ overflow: 'visible' }}
    >
      <defs>
        <marker
          id={markerId}
          markerWidth="10"
          markerHeight="5"
          refX="7"
          refY="2"
          orient="auto"
          markerUnits="strokeWidth">
          <polyline
            points="0 0, 9 2, 0 4"
            style={arrowHeadStyle}
          />
        </marker>
      </defs>
      <path
        style={lineStyle}
        d={path.join(' ')}
        markerEnd={`url(#${markerId})`}
      />
    </svg>
  );
}
