// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React, { useEffect, useState } from "react";
import styles from "./UserLabelSelector.module.css";
import type { User, LabelType } from "../../rpc/model";
import { apiEndpoint } from "../../utils/http";
import { LabelTypeService } from "../../rpc/label_types";
import { Button } from "react-bootstrap";
import { LabelTypes } from '../../model';

type Props = {
  user: User,
  cancel: () => void,
}

export default function UserLabelSelector(props: Props): Node {
  const [items, setItems] = useState<Array<LabelType>>([]);
  const [selectedLabelTypes, setSelectedLabelTypes] = useState<Array<LabelType>>([]);

  useEffect(() => {
    async function fetchItems() {
      const resp = await new LabelTypeService(apiEndpoint()).get();
      setItems(resp.labelTypes.filter(labelType => labelType.labelType === LabelTypes.common));
    }

    fetchItems();
  }, []);

  useEffect(() => {
    async function fetchUserLabel() {
      if (items.length > 0) {
        setSelectedLabelTypes([]);
        const resp = await new LabelTypeService(apiEndpoint()).userGet({ userId: props.user.id });
        if (resp.labelTypes != null) {
          for (let i = 0; i < resp.labelTypes.length; i++) {
            const index = items.findIndex(item => item.id === resp.labelTypes[i].id);
            if (index >= 0) {
              setSelectedLabelTypes(selectedLabelTypes => [...selectedLabelTypes, items[index]]);
            }
          }
        }
      }
    }

    fetchUserLabel();
  }, [items, props.user.id]);

  async function onClickSetAll() {
    setSelectedLabelTypes([]);
    setSelectedLabelTypes(items);
  }

  async function onClickClearAll() {
    setSelectedLabelTypes([]);
  }

  async function onClickSave() {
    if (props.user != null) {
       await new LabelTypeService(apiEndpoint()).userSet({ userId: props.user.id, labelTypes: selectedLabelTypes });
    }
    props.cancel();
  }

  async function onClickCancel() {
    props.cancel();
  }

  function onLabelTypeselectedChanged(labelType: LabelType) {
   const index = selectedLabelTypes.indexOf(labelType);
    if (index >= 0) {
      setSelectedLabelTypes(selectedLabelTypes => [
        ...selectedLabelTypes.slice(0, index),
        ...selectedLabelTypes.slice(index + 1),
      ]);
    } else {
    
      setSelectedLabelTypes(selectedLabelTypes => [...selectedLabelTypes, labelType]);
   }
  }

  return <div className={styles.root}>
      <div className={styles.infoBox}>User Label Selection : {props.user.login}</div>
      <div className={styles.items}>
          {items.map(item => <div key={item.id} >
           <div key={item.id} className={styles.container}>
             { selectedLabelTypes.indexOf(item) >= 0 &&
              <i className='fa fa-check-circle cursor-pointer' onClick = { () => onLabelTypeselectedChanged(item)} />
             }
             { selectedLabelTypes.indexOf(item) < 0 &&
              <i className='fa fa-times-circle cursor-pointer' onClick = { () => onLabelTypeselectedChanged(item)} />
             }
             <div className={styles.item} style={{ backgroundColor: item.color }}>   {item.name} </div>
            </div>
         </div>)}
      </div>
      <div className={styles.buttons}>
        <Button bsSize="xsmall"
          bsStyle="primary"
          disabled={false} 
          onClick={() => onClickSetAll()}>
          Set All
        </Button>

        <Button bsSize="xsmall"
          bsStyle="warning"
          disabled={false} 
          onClick={() => onClickClearAll()}>
          Clear All
        </Button>

        <Button bsSize="xsmall"
          bsStyle="primary"
          disabled={false} 
          onClick={() => onClickSave()}>
          Save
        </Button>

        <Button bsSize="xsmall"
          bsStyle="warning"
          disabled={false} 
          onClick={() => onClickCancel()}>
          Cancel
        </Button>
      </div>

  </div>;
}
