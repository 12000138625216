// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useRef, useState } from "react";
import type { Node } from "react";
import RegionSelect from "react-region-select";
import { Anchorme } from 'react-anchorme';
import cx from "classnames";
import Arrow from "../arrow/Arrow";
import type { ArrowOrientation, LiveLabel } from "../../model";
import { LabelTypes } from '../../model';
import styles from "./ImageView.module.css";
import LabelTypesDropdown from "../label-types-dropdown/LabelTypesDropdown";
import type { FolderItem, LabelType, Point } from "../../rpc/model";
import { apiEndpoint } from "../../utils/http";
import Line from '../arrow/Line';

type ImageViewProps = {
  image: FolderItem,
  labels: Array<LiveLabel>,
  labelTypes: Array<LabelType>,
  onLabelsChanged: ?(labels: Array<LiveLabel>, mode: 'rectangle' | 'arrow' | 'line', isMove: boolean, resizeDir: string) => Promise<void>,
  onLabelTypeChanged: (labelId: string, labelTypeId: number) => Promise<void>,
};

export default function ImageView(props: ImageViewProps): Node {
console.log("here at iimage viewer");

  const imageRef = useRef<?HTMLImageElement>(null);

  const [zoom, setZoom] = useState<number>(100);
  const [mode, setMode] = useState<'rectangle' | 'arrow' | 'line'>('rectangle');
  const regionSelect = useRef<any>(null); // flowlint-line unclear-type:off

  useEffect(() => {
    setMode('rectangle');
  }, [props.image.id]);

  useEffect(() => {
    setZoom(100);
  }, [props.image.id]);
  useEffect(() => {
    if (window.location.protocol !== "https:") {
      const flagServer=window.location.href.split("//")[1].split(":")[0]==="localhost"?false:true;
      let socket;
        let dsfURL;
        if (flagServer === false) {
          socket = new WebSocket('ws://localhost:8098');
          dsfURL = "http://localhost:8085";
    
        } else {
          socket = new WebSocket('ws://3.147.82.177:8098');
          dsfURL = "http://3.129.128.234:8085";
    
        }
    
    
      // Handle connection open
      socket.onopen = async () => {
        console.log('WebSocket connection established.');
    
        // Check if the image ID is available
        if (props.image.id) {
          try {
            // Fetch the image as a Blob
            const response = await fetch(`${dsfURL}/api/image/${props.image.id}`);
            if (!response.ok) {
              throw new Error(`Failed to fetch image: ${response.statusText}`);
            }
            const blob = await response.blob();
    
            // Optionally convert Blob to Base64 if required
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64Image = reader.result;
    
              // Send the base64 image over WebSocket
              const payload = JSON.stringify({
                imageId: props.image.id,
                imageData: base64Image,
              });
              socket.send(payload);
              console.log('Sent image with ID:', props.image.id);
            };
            reader.readAsDataURL(blob);
          } catch (error) {
            console.error('Error fetching or sending image:', error);
          }
        }
      };
    
      // Handle incoming messages
      socket.onmessage = (event) => {
        console.log('Message from server:', event.data);
      };
    
      // Handle WebSocket errors
      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    
      // Handle WebSocket close
      socket.onclose = () => {
        console.log('WebSocket connection closed.');
      };
    
      // Cleanup WebSocket connection on component unmount or image ID change
      return () => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.close();
        }
      };
    }
  
}, [props.image.id]);


  function onChange(labels: Array<LiveLabel>) {
    if (props.onLabelsChanged != null) {
      const regionChangeData = regionSelect.current.regionChangeData;
      props.onLabelsChanged(labels, mode, regionChangeData?.isMove ?? false, regionChangeData?.resizeDir ?? '');
    }
  }

  function regionRenderer(regionProps: { index: number }) {
    const currentImage = imageRef.current;
    if (currentImage == null)
      return;

    const label = props.labels[regionProps.index];

    const labelTypes = props.labelTypes.map(lt => ({ ...lt, real: true }));
    let labelType = labelTypes.find(lt => lt.id === label.labelTypeId);
    if (labelType == null) {
      labelType = {
        id: label.labelTypeId || -1,
        name: label.labelTypeId.toString(),
        color: "blue",
        labelType: LabelTypes.common,
        real: false,
      };
      labelTypes.push(labelType);
    }

    const labelStyle = {
      backgroundColor: labelType.color,
    };

    const noteStyle = getNoteStyle(labelType, label.points);

    const arrow = labelType.labelType === LabelTypes.arrow ?
      <Arrow width={currentImage.width * label.width / 100}
        height={currentImage.height * label.height / 100}
        orientation={getArrowOrientation(label.points)}
        color={labelType.color}
        lineWidth={3}
        arrowHeadFilled={true} /> :
      null;

    const line = labelType.labelType === LabelTypes.line ?
      <Line width={currentImage.width * label.width / 100}
        height={currentImage.height * label.height / 100}
        orientation={getArrowOrientation(label.points)}
        color={labelType.color}
        lineWidth={3} /> :
      null;

    let note = label.note;
    if (label.additionalInfo) {
      note = !note ? label.additionalInfo : (note + "\n" + label.additionalInfo);
    }

    return <div className={styles.region}>
      {labelType.labelType === LabelTypes.common && <div className={styles.label} style={labelStyle}>
        <LabelTypesDropdown
          currentLabelType={labelType}
          labelTypes={labelTypes.filter(lt => lt.labelType === LabelTypes.common)}
          onLabelTypeSelected={lt => onLabelTypeChanged(regionProps.index, lt)}
          postfix={(label.id != null && labelType.labelType === LabelTypes.common) ? label.id.substr(0, 3) : ""}
        />
      </div>}
      <div className={styles.labelNote} style={noteStyle}>
        <Anchorme target="_blank" rel="noreferrer noopener">
          {note}
        </Anchorme>
      </div>
      {arrow}{line}
    </div>;
  }

  function onLabelTypeChanged(index: number, labelType: LabelType) {
    const label = index < props.labels.length ? props.labels[index] : null;
    if (label != null && label.id != null && label.isRectangleOrLine !== false)
      props.onLabelTypeChanged(label.id, labelType.id);
  }

  function zoomIn() {
    if (zoom >= 1000)
      return;

    if (zoom < 100)
      setZoom(zoom + 10);
    else if (zoom < 500)
      setZoom(zoom + 50);
    else
      setZoom(zoom + 100);
  }

  function zoomOut() {
    if (zoom <= 20)
      return;

    if (zoom <= 100)
      setZoom(zoom - 10);
    else if (zoom <= 500)
      setZoom(zoom - 50);
    else
      setZoom(zoom - 100);
  }

  function setRectangleMode() {
    setMode('rectangle');
  }

  function setArrowMode() {
    setMode('arrow');
  }

  function setLineMode() {
    setMode('line');
  }

  return (
    <div style={{ width: `${zoom}%` }}>
      <div className={styles.toolbox}>
        <button onClick={() => zoomIn()} disabled={zoom >= 1000}>Zoom In</button>
        <button onClick={() => zoomOut()} disabled={zoom <= 20}>Zoom Out</button>
        {zoom !== 100 && <span className={styles.zoomPercentage}>{zoom}%</span>}
        <div className={styles.modes}>
          <button className={cx({ [styles.selectedMode]: mode === 'rectangle' })} onClick={() => setRectangleMode()}>Rectangle</button>
          <button className={cx({ [styles.selectedMode]: mode === 'arrow' })} onClick={() => setArrowMode()}>Arrow</button>
          <button className={cx({ [styles.selectedMode]: mode === 'line' })} onClick={() => setLineMode()}>Line</button>
        </div>
      </div>
      <RegionSelect
        ref={regionSelect}
        maxRegions={Infinity}
        regions={props.labels}
        constraint={true}
        onChange={onChange}
        regionRenderer={regionRenderer}
        style={{ border: "1px dash black", display: "block" }}
      >
        <img ref={imageRef} alt='main' src={apiEndpoint() + `/api/image/${props.image.id}`}
          style={{ width: `100%`, border: "1px solid grey", margin: "auto" }} />
      </RegionSelect>
    </div>
  );
}

function getArrowOrientation(points: Array<Point>): ArrowOrientation {
  if (points[0].x === points[1].x && points[0].y > points[1].y)
    return "n";
  if (points[0].x < points[1].x && points[0].y > points[1].y)
    return "ne";
  if (points[0].x < points[1].x && points[0].y === points[1].y)
    return "e";
  if (points[0].x < points[1].x && points[0].y < points[1].y)
    return "se";
  if (points[0].x === points[1].x && points[0].y < points[1].y)
    return "s";
  if (points[0].x > points[1].x && points[0].y < points[1].y)
    return "sw";
  if (points[0].x > points[1].x && points[0].y === points[1].y)
    return "w";
  if (points[0].x > points[1].x && points[0].y > points[1].y)
    return "nw";

  return "n";
}

function getNoteStyle(labelType: LabelType, labelPoints: Array<Point>): { [string]: (string | number) } {
  if (labelType.labelType === LabelTypes.arrow || labelType.labelType === LabelTypes.line) {
    switch (getArrowOrientation(labelPoints)) {
      case "n":
        return {
          bottom: 7,
        };
      case "ne":
        return {
          bottom: 7,
        };
      case "e":
        return {
          top: "calc(50% + 7px)",
        };
      case "se":
        return {
          left: 10,
          top: 15,
        };
      case "s":
        return {
          left: 5,
          top: 10,
        };
      case "sw":
        return {
          left: "auto",
          right: 3,
          top: 10,
        };
      case "w":
        return {
          left: "auto",
          right: 3,
          top: "calc(50% + 7px)",
        };
      case "nw":
        return {
          left: "auto",
          right: 3,
          bottom: 7,
        };
      default:
        return {
          left: "auto",
          right: 3,
          bottom: 7,
        };
    }
  }

  return {
    top: "calc(100% + 5px)",
  };
}
