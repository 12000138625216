// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import styles from './Statistics.module.css';
import { Link } from "react-router-dom";
import type { User } from "../../rpc/model";
import { UserService } from "../../rpc/users";
import { ActivityService } from "../../rpc/activity";
import { apiEndpoint, apiGet } from "../../utils/http";

type Statistics = {
  shared_folders: Array<{ [string]: (string | number) }>,
  private_folders: Array<{ [string]: (string | number) }>,
}

export default function DownloadStatistics(): Node {
  const [user, setUser] = useState<?User>(null);
  const [statistics, setStatistics] = useState<Statistics>({
    shared_folders: [],
    private_folders: [],
  });

  useEffect(() => {
    new ActivityService(apiEndpoint()).visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
  }, []);

  useEffect(() => {
    async function fetchMe() {
      const resp = await new UserService(apiEndpoint()).me();
      setUser(resp.user);
    }

    fetchMe();
  }, []);

  useEffect(() => {
    async function fetchStatistics() {
      const resp = await apiGet('/api/statistics/downloads');
      setStatistics(resp.data);
    }

    fetchStatistics();
  }, []);

  function renderDownloadStatistics(items: Array<{ [string]: (string | number) }>, shared: boolean, header: string) {
    if (items.length === 0)
      return null;

    return <div className={styles.root}>
      <h4>{header}</h4>
      <table className={styles.table}>
        <thead>
        <tr>
          {!shared && user != null && user.isAdmin ? <th>User</th> : null}
          <th>Folder Name</th>
          <th>Total Downloads</th>
          <th>Bulk Downloads</th>
          <th>Unique Image Downloads</th>
        </tr>
        </thead>
        <tbody>
        {items.map(f => <tr key={f['$folder_id']}>
          {!shared && user != null && user.isAdmin ? <td>{f['$user']}</td> : null}
          <td>{shared || (user != null && user.displayName === f['$user']) ?
            <Link to={`/folder/${f['$folder_id']}`}>{f['$folder']}</Link> : f['$folder']}</td>
          <td className={styles.counter}>{f['$downloads-operation'] || '-'}</td>
          <td className={styles.counter}>{f['$downloads-zip'] || '-'}</td>
          <td className={styles.counter}>{f['$downloads-image'] || '-'}</td>
        </tr>)}
        </tbody>
      </table>
    </div>;
  }

  return <div>
    {renderDownloadStatistics(statistics.shared_folders, true, 'Downloads - Shared Folders')}
    {renderDownloadStatistics(statistics.private_folders, false, 'Downloads - Private Folders')}
  </div>;
}
