// @flow strict
// Copyright (C) 2018-2021 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import styles from './ProfileList.module.css';
import Button from "../Button/Button";
import { apiEndpoint } from "../../utils/http";
import { UserService } from '../../rpc/users';

const ProfileItem = ({ profileName, onProfileDelete, onProfileRename }) => {
  return <div key={profileName} className={styles.container}>
    <i className='fa fa-times-circle cursor-pointer' onClick={(e) => onProfileDelete(e, profileName)} />
    <i className='fa fa-pencil cursor-pointer' onClick={(e) => onProfileRename(e, profileName)} />
    <div className={styles.item}>
      {profileName}
    </div>
  </div>;
};

const ProfileItems = ({ profiles, onProfileDelete, onProfileRename }) =>
  <div className={styles.items}>
    {
      profiles.map(profileName => (<ProfileItem key={profileName}
        profileName={profileName}
        onProfileDelete={onProfileDelete}
        onProfileRename={onProfileRename}
      />))
    }
  </div>;

export default function ProfileList(): Node {
  const [profiles, setProfiles] = useState<Array<string>>([]);

  useEffect(() => {
    async function fetchProfiles() {
      const resp = await new UserService(apiEndpoint()).profiles();
      setProfiles(resp.profiles);
    }

    fetchProfiles();
  }, []);

  async function onNewProfileClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    let newProfile = window.prompt('Input new profile name:', '');
    if (newProfile == null)
      return;

    newProfile = newProfile.trim();
    if (newProfile === '')
      return;

    const index = profiles.findIndex(profile => profile === newProfile);
    if (index >= 0) {
      window.alert('Already exists!');
      return;
    }

    await new UserService(apiEndpoint()).addProfile({ name: newProfile });
    setProfiles([...profiles, newProfile]);
  }

  async function onProfileDelete(e: SyntheticMouseEvent<HTMLImageElement>, profile: string) {
    e.stopPropagation();

    const response = await new UserService(apiEndpoint()).usageProfile({ name: profile });
    const { userCount } = response;

    const confirmMessageLines: Array<string> = [`You are going to delete the profile '${profile}'`];
    if (userCount > 0) {
      confirmMessageLines.push(`The profile is used by ${userCount} users`);
    }
    confirmMessageLines.push('Are you sure?');

    if (!window.confirm(confirmMessageLines.join('\n')))
      return;

    await new UserService(apiEndpoint()).deleteProfile({ name: profile });

    setProfiles(profiles => profiles.filter(p => p !== profile));
  }

  async function onProfileRename(e: SyntheticMouseEvent<HTMLImageElement>, profile: string) {
    e.stopPropagation();

    const response = await new UserService(apiEndpoint()).usageProfile({ name: profile });
    const { userCount } = response;

    const confirmMessageLines: Array<string> = [`You are going to rename the profile '${profile}'`];
    if (userCount > 0) {
      confirmMessageLines.push(`The profile is used by ${userCount} users`);
    }
    confirmMessageLines.push('Are you sure?');

    if (!window.confirm(confirmMessageLines.join('\n')))
      return;

    let newProfile = window.prompt("New name", profile);
    if (newProfile == null)
      return;

    newProfile = newProfile.trim();
    if (newProfile === "" || newProfile === profile)
      return;

    if (profiles.some(p => p === newProfile)) {
      window.alert('The label with the same name already exists!');
      return;
    }

    await new UserService(apiEndpoint()).renameProfile({ name: profile, newName: newProfile });

    setProfiles(profiles => profiles.map(p => p === profile ? newProfile : p));
  }

  return <div>
    <div className={styles.root}>
      <ProfileItems
        profiles={profiles}
        onProfileDelete={onProfileDelete}
        onProfileRename={onProfileRename}
      />
      <Button onClick={onNewProfileClick}>Add new</Button>
    </div>
  </div>;
}
