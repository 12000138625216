// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import { Link } from "react-router-dom";
import styles from './Statistics.module.css';
import type { User } from "../../rpc/model";
import { UserService } from "../../rpc/users";
import { ActivityService } from "../../rpc/activity";
import { apiEndpoint } from "../../utils/http";

export default function Statistics(): Node {
  const [user, setUser] = useState<?User>(null);

  useEffect(() => {
    new ActivityService(apiEndpoint()).visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
  }, []);

  useEffect(() => {
    async function fetchMe() {
      const resp = await new UserService(apiEndpoint()).me();
      setUser(resp.user);
    }

    fetchMe();
  }, []);

  const isAdmin = user != null && user.isAdmin;

  return <div className={styles.root}>
    <h5><Link to={'/statistics/labels'}>Label Statistics</Link></h5>
    <h5><Link to={'/statistics/downloads'}>Download Counters</Link></h5>
    <h5><Link to={'/statistics/user'}>User Statistics</Link></h5>
    <h5><Link to={'/statistics/storage'}>Storage Usage</Link></h5>
    <h5><Link to={'/statistics/process'}>CPU/RAM Utilization</Link></h5>
    {isAdmin && <h5><Link to={'/statistics/mobile'}>Mobile API Usage</Link></h5>}
  </div>;
}
