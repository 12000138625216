// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState, useRef } from "react";
import type { Node } from "react";
import styles from "./FolderSelector.module.css";
import type { Folder } from "../../rpc/model";
import { FolderService } from "../../rpc/folders";
import { Button, ButtonGroup } from "react-bootstrap";
import ToggleButton from "react-toggle-button";
import { Check, X } from "../toggle-button/buttons";
import { apiEndpoint } from "../../utils/http";

type FolderSelectorProps = {
  rootFolderId: ?string,
  selectTitle: string,
  select: (folders: Array<Folder>) => Promise<void>;
  cancel: () => void;
}

function ancestors(folder: Folder, cache: Map<string, Array<Folder>>, parents: Map<string, ?Folder>): Array<Folder> {
  if (cache.has(folder.id))
    return cache.get(folder.id) || [];

  const parent = parents.get(folder.id);
  if (parent == null) {
    const result = [folder];
    cache.set(folder.id, result);
    return result;
  }

  const result = [...ancestors(parent, cache, parents), folder];
  cache.set(folder.id, result);
  return result;
}

function descendants(folder: Folder, cache: Map<string, Array<Folder>>): Array<Folder> {
  if (cache.has(folder.id))
    return cache.get(folder.id) || [];

  const result = [folder];
  if (folder.children)
    folder.children.forEach(child => result.push(...descendants(child, cache)));
  cache.set(folder.id, result);
  return result;
}

function setParent(folder: Folder, parent: ?Folder, cache: Map<string, ?Folder>) {
  cache.set(folder.id, parent);

  if (!folder.children)
    return;

  folder.children.forEach(child => setParent(child, folder, cache));
}

export default function FolderSelector(props: FolderSelectorProps): Node {
  const [roots, setRoots] = useState<Array<Folder>>([]);
  const [selectedFolders, setSelectedFolders] = useState<Array<Folder>>([]);
  const parents = useRef<Map<string, ?Folder>>(new Map());
  const ancestorsCache = useRef<Map<string, Array<Folder>>>(new Map());
  const descendantsCache = useRef<Map<string, Array<Folder>>>(new Map());

  useEffect(() => {
    async function fetchFolders() {
      let newRoots;
      const endpoint = apiEndpoint();
      if (props.rootFolderId == null) {
        const resp = await new FolderService(endpoint).tree({ sortBy: '' });
        newRoots = [...resp.shared, ...resp.private];
      } else {
        const resp = await new FolderService(endpoint).subTree({ folderId: props.rootFolderId });
        newRoots = resp.folder != null ? [resp.folder] : [];
      }

      parents.current.clear();
      newRoots.forEach(r => setParent(r, null, parents.current));
      ancestorsCache.current.clear();
      descendantsCache.current.clear();

      setRoots(newRoots);

      const allDescendants = [];
      newRoots.forEach(r => allDescendants.push(...descendants(r, descendantsCache.current)));
      setSelectedFolders(allDescendants);
    }

    fetchFolders();
  }, [props.rootFolderId]);

  function onSelectClick() {
    props.select(selectedFolders);
  }

  function onCancelClick() {
    props.cancel();
  }

  function changeSelection(f: Folder) {
    if (selectedFolders.indexOf(f) < 0) {
      ancestors(f, ancestorsCache.current, parents.current).forEach(f => changeSelectionForFolder(f, true));
    } else
      descendants(f, descendantsCache.current).forEach(f => changeSelectionForFolder(f, false));
  }

  function changeSelectionForFolder(f: Folder, selected: boolean) {
    if (!selected) {
      setSelectedFolders(selectedFolders => {
        const index = selectedFolders.indexOf(f);
        return index >= 0 ? [
          ...selectedFolders.slice(0, index),
          ...selectedFolders.slice(index + 1)
        ] : selectedFolders;
      });
    } else {
      setSelectedFolders(selectedFolders => {
        const index = selectedFolders.indexOf(f);
        return index <= 0 ? [...selectedFolders, f] : selectedFolders;
      });
    }
  }

  function renderFolder(f: Folder, level: number) {
    const style = {
      marginLeft: `${level * 10}px`
    };

    return <div key={f.id} style={style}>
      <div className={styles.folder}>
        <ToggleButton
          inactiveLabel={<X />}
          activeLabel={<Check />}
          containerStyle={{ width: "40px" }}
          trackStyle={{ width: "40px", height: "25px" }}
          thumbAnimateRange={[1, 22]}
          value={selectedFolders.indexOf(f) >= 0}
          onToggle={() => changeSelection(f)} />
        <div className={styles.folderName}>{f.name}</div>
      </div>
      {f.children && f.children.map(child => renderFolder(child, level + 1))}
    </div>;
  }

  return <div className={styles.root}>
    {roots.map(r => renderFolder(r, 0))}
    <ButtonGroup className={styles.buttons}>
      <Button bsStyle="info" onClick={onSelectClick} disabled={selectedFolders.length === 0}>
        {props.selectTitle}
      </Button>
      <Button bsStyle="warning" onClick={onCancelClick}>
        Cancel
      </Button>
    </ButtonGroup>
  </div>;
}
