// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import styles from './Statistics.module.css';
import { ActivityService } from "../../rpc/activity";
import { apiEndpoint, apiGet } from "../../utils/http";

type detectAdHocEvent = {
  timestamp: number,
  remote_address: string,
  engine_name: string,
  success: boolean,
}

type Counters = {
  total: number,
  hour24: number,
  day7: number,
  day30: number,
}

export default function MobileAPIStatistics(): Node {
  const [counters, setCounters] = useState<?Counters>(null);
  const [events, setEvents] = useState<Array<detectAdHocEvent>>([]);

  useEffect(() => {
    new ActivityService(apiEndpoint()).visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
  }, []);

  useEffect(() => {
    async function fetchStatistics() {
      const resp = await apiGet('/api/statistics/detect/adhoc');
      setCounters(resp.data.counters);
      setEvents(resp.data.events);
    }

    fetchStatistics();
  }, []);


  const countersTable = counters != null ?
    <div className={styles.root}>
      <h4>Mobile API Usage</h4>
      <table className={styles.table}>
        <thead>
        <tr>
          <th>Total Count</th>
          <th>Last 24 hours</th>
          <th>Last 7 days</th>
          <th>Last 30 days</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td className={styles.counter}>{counters.total}</td>
          <td className={styles.counter}>{counters.hour24}</td>
          <td className={styles.counter}>{counters.day7}</td>
          <td className={styles.counter}>{counters.day30}</td>
        </tr>
        </tbody>
      </table>
    </div> :
    null;

  const eventsTable = events.length > 0 ?
    <div className={styles.root}>
      <h4>Last Mobile API Requests</h4>
      <table className={styles.table}>
        <thead>
        <tr>
          <th>Timestamp</th>
          <th>Remote Address</th>
          <th>Request Type</th>
          <th>Success</th>
        </tr>
        </thead>
        <tbody>
        {events.map(event => <tr key={event.timestamp}>
          <td>{new Date(event.timestamp * 1000).toLocaleString()}</td>
          <td>{event.remote_address}</td>
          <td>{event.engine_name}</td>
          <td className={styles.counter}>{event.success ? 'Success' : 'Fail'}</td>
        </tr>)}
        </tbody>
      </table>
    </div> :
    null;

  return <div>
    {countersTable}
    {eventsTable}
  </div>;
}
