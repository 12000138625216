//@flow strict
import * as React from 'react';
import type { Node } from "react";
import { DropdownButton as BSDropdownButton } from "react-bootstrap";
import styles from "./Button.module.css";

type Props = {
  id: string,
  title: string,
  cls?: string,
  children?: React.Node,
};

export default function DropdownButton(props: Props): Node {
  return <BSDropdownButton id={props.id}
    bsStyle="info"
    title={props.title}
    className={`${styles.root} ${props.cls ? props.cls : ''}`}>
    {props.children}
  </BSDropdownButton>;
}
