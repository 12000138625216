// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { NavLink } from "react-router-dom";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import { MenuItem } from "react-bootstrap";
import sortBy from "lodash/sortBy";
import styles from "./FolderView.module.css";
import type { FolderKind, InvalidateMessage } from "../../model";
import { Features, FolderKinds } from "../../model";
import Modal from "react-modal";
import ImageEdit from "../image-edit/ImageEdit";
import OrmsList from "../orms-list/OrmsList";
import Loader from "react-loader-spinner";
import { Line } from "rc-progress";
import TextEditor from "../text-editor/TextEditor";
import { pluralize } from "../../utils/plural";
import PdfOptionsDialog from "../pdf-options-dialog/PdfOptionsDialog";
import { imageTitleKind } from "../../utils/image";
import Button from "../Button/Button";
import { getFolderCaptionName } from "../../utils/folder";
import StreamView from "../stream-view/StreamView";
import { dateToYYYYMMDDHHMMSS } from "../../utils/convert";
import type { DetectEngine, Folder, FolderItem, FolderItemJSON } from "../../rpc/model";
import { JSONToFolderItem } from "../../rpc/model";
import FolderToRender from "../Folder/Folder";
import { FolderService } from "../../rpc/folders";
import { FolderItemService } from "../../rpc/folder_items";
import { DetectService } from "../../rpc/detect";
import { ActivityService } from "../../rpc/activity";
import CustomDropZone from "../CustomDropZone/CustomDropZone";
import ToggleButton from "react-toggle-button";
import DropdownButton from "../Button/DropdownButton";
import InvalidateNotifier from "../../InvalidateNotifier";
import FolderSelector from "../folder-selector/FolderSelector";
import { getTabId } from "../../utils/tab";
import { apiEndpoint, apiPost, apiGet } from "../../utils/http";
import FolderStatisticsView from "./FolderStatisticsView";
import { StorefrontService } from "../../rpc/storefront";
import FolderFilterView from "./FolderFilterView";
import { FolderFilter } from "./FolderFilter";
import { reaction, toJS } from "mobx";
import FolderAutoDetectStatisticsView from "./FolderAutoDetectStatisticsView";
import ReactTooltip from 'react-tooltip';
import OptionList from '../option-list/OptionList';
import sanitize from 'sanitize-filename';
import type AppState from '../../AppState';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

type FolderViewProps = {
  match: {
    params: {
      id: string
    }
  },
  appState: AppState,
  history: {
    push: (path: string) => void,
  },
  thirdPartyApp_3DAnnot_url: string,
  show_thirdPartyApp_3DAnnot_url: boolean,
  getFolders: () => Promise<void>,
  invalidateNotifier: InvalidateNotifier,
}

type CopiedImages = {
  folderId: string,
  images: Array<string>,
}

type CopiedFolder = {
  folderId: string,
  kind: string,
}

type EngineSelecting = {
  selectedEngineNames: Array<string>,
  enginesFilter?: (engine: DetectEngine) => boolean,
  onSelect: (engines: Array<DetectEngine>, parameterValues: { [string]: { [string]: string } }) => Promise<void>,
  selectTitle: string,
  onLater?: ?(engines: Array<DetectEngine>) => (void | Promise<void>),
  laterTitle?: string,
  onLaterEnabled?: (engines: Array<DetectEngine>) => boolean,
  selectPlusOne?: ?(engines: Array<DetectEngine>, parameterValues: { [string]: { [string]: string } }) => Promise<void>,
  showPendingCounters?: boolean,
}

type EditingPDFOptions = {
  folderId: string,
  children: Array<Folder>,
  selectedImages: Array<string>,
}

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(155, 155, 155, 0.4)"
  },
  content: {
    backgroundColor: localStorage.getItem('isDarkTheme') === "dark" ? "rgb(20,20,20)" : "white",
    border: "2px solid green",
  }
};

const copiedImagesStorageKey = "copied-images";
const copiedFolderStorageKey = "copied-folder";
const ormsEnginesStorageKey = "orms-engines";
const detectLaterStorageKey = "detect-later";
let socket;
// Folder View - a list of folder images (thumbnails).
function FolderView(props: FolderViewProps): Node {
  console.log("here at folder view");
  const [folder, setFolder] = useState<?Folder>(null);
  const [images, setImages] = useState<Array<FolderItem>>([]);
  const [selectedImage, setSelectedImage] = useState<?FolderItem>(null);
  const [processedCount, setProcessedCount] = useState<number>(0);
  const [processingTotalCount, setProcessingTotalCount] = useState<number>(0);
  const [contentLoading, setContentLoading] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<Array<string>>([]);
  const [titleEditing, setTitleEditing] = useState<boolean>(false);
  const [nameEditing, setNameEditing] = useState<boolean>(false);
  const [tempFolderToEdit, setTempFolderToEdit] = useState<?Folder>(null);
  const [imagesToPaste, setImagesToPaste] = useState<?CopiedImages>(null);
  const [engines, setEngines] = useState<Array<DetectEngine>>([]);
  const [showStats, setShowStats] = useState<boolean>(false);
  const [showAutoDetectStats, setShowAutoDetectStats] = useState<boolean>(false);
  const [engineSelecting, setEngineSelecting] = useState<?EngineSelecting>(null);
  const [editingPDFOptions, setEditingPDFOptions] = useState<?EditingPDFOptions>(null);
  const [exportingFolder, setExportingFolder] = useState<boolean>(false);
  const [retrainFolderExists, setRetrainFolderExists] = useState<boolean>(false);
  const [itemsInvalidator, setItemsInvalidator] = useState<number>(0);
  const [chartAppUrl, setChartAppUrl] = useState<string>("");
  const [detectionParameters, setDetectionParameters] = useState<{ [string]: Array<DetectionParameter> }>({});
  const [folderToPaste, setFolderToPaste] = useState<?CopiedFolder>(null);
  const folderId = folder != null ? folder.id : "";
  const [rbiAppUrl, setRbiAppUrl] = useState<string>("");
  const [uploadingFiles, setUploadingFiles] = useState<Array<File>>([]);

  const [filter] = useState<FolderFilter>(() => new FolderFilter(props.match.params.id));
  const shiftSelect = useRef<boolean>(false);

  const loadContentTimeout = useRef<?TimeoutID>(null);

  const [ndeAppUrl, setNdeAppUrl] = useState<string>("");
  const [mapAppUrl, setMapAppUrl] = useState<string>("");
  useEffect(() => {
    return () => {
      if (loadContentTimeout.current != null)
        clearTimeout(loadContentTimeout.current);
    };
  }, []);


  
  useEffect(() => {
    filter.setFolderId(props.match.params.id);
  }, [props.match.params.id, filter]);

  useEffect(() => reaction(() => toJS(filter.state), () => forceInvalidateItems()),
    [props.match.params.id, filter]);
  
  useEffect(() => {
    if (!folderId)
      return;
    setImages([]);
    new ActivityService(apiEndpoint()).visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
  }, [folderId]);


  function establishWebSokcet() {
    // here one time web socket connection for the folder instance
    // Required functionality1:- establish web socket onthe open of any folder  (invoke)
    // Required functionality2:- deestablish the web socket on new we socket connection  (revoke from previous instance)

  if (socket && socket.readyState !== WebSocket.CLOSED) {
    socket.close(); // Close the current WebSocket connection
}
  const folderId = props.match.params.id.length > 36 ?
    props.match.params.id.substr(props.match.params.id.length - 36) :
    props.match.params.id;
// window.location.href.split("//")[1].split(":")[0]==="localhost"
  const flagServer = window.location.href.split("//")[1].split(":")[0] === "localhost" ? false : true;
  let dsfURL;
  if (flagServer === false) {
    socket = new WebSocket('ws://localhost:8098');
    dsfURL = "http://localhost:8085";

  } else {
    socket = new WebSocket('ws://3.147.82.177:8098');
    dsfURL = "http://3.129.128.234:8085";

  }
  console.log("folder ID Calculated", folderId);


// Split the URL by "/"
const urlParts = window.location.href.split("/");

// Join the second last and last parts with "/"
const folderUrl = urlParts.slice(-2).join("/");

// Send the updated message to the socket

  socket.onopen = () => {

    console.log(flagServer === false ? console.log("websocket connected localhost") : console.log("websocket connected server"));
    socket.send(JSON.stringify({
      message: "folder opened",
      url: folderUrl
    }));

   



  };

  socket.onmessage= (event) => {
    try {
      const data=event.data;
      // Check if the incoming data is binary (a Blob or Buffer)
      if (data instanceof Blob) {
          console.log("Received binary data (File/Blob)");
  
          // Convert the binary Blob data into a File object
          const file = new File([data], "screenshot.png", { type: "image/png" });
  
          // Now handle multiple files (example: pushing the created file into an array)

          const files = [file];
          console.log("files before api calls");
        console.log(files);
  
          // Call the function that accepts multiple files
          // yourFunctionThatAcceptsFiles(files);
          console.log(files, "get files");
          uploadFilesWithCsvViewer(files, "none");
          console.log("Screenshot file sent for processing.");
      } else {
          // If the message is in JSON format and not a binary file
          const parsedData = JSON.parse(event.data);
  
          // Check if the message is "saved screenshot"
          if (parsedData.message === "saved screenshot" && parsedData.imageData) {
              // Convert base64 image data to File(s)
              const base64Data = parsedData.imageData;
              const file = base64ToFile(base64Data, "screenshot.png");
  
              // Now handle multiple files (example: pushing the created file into an array)
              const files = [file];
  
              // Call the function that accepts multiple files
              // yourFunctionThatAcceptsFiles(files);
              console.log(files, "get files");
              uploadFilesWithCsvViewer(files, "none");
              console.log("Screenshot file sent for processing.");
          } else {
              console.log("No 'saved screenshot' message or imageData missing.");
          }
      }
  } catch (error) {
      console.error("Error processing WebSocket message:", error);
  }
  
  };

  // Helper function to convert base64 to File
  function base64ToFile(base64, filename) {
    const byteCharacters = atob(base64); // Decodes the base64 string
    const byteArrays = [];

    // Convert the base64 string into an array of bytes
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const byteArray = new Uint8Array(Math.min(1024, byteCharacters.length - offset));
      for (let i = 0; i < byteArray.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(offset + i);
      }
      byteArrays.push(byteArray);
    }

    // Create a Blob from the byte array and convert it into a File
    const blob = new Blob(byteArrays, { type: 'image/png' });
    return new File([blob], filename, { type: 'image/png' });
  }

}
let count=0;
  useEffect(() => {
    count = count + 1;
    // console.log(window.location.protocol);
    if (window.location.protocol !== "https:") {
      establishWebSokcet();
    }

  }, [folderId]);



  useEffect(() => {
    const folderId = props.match.params.id.length > 36 ?
      props.match.params.id.substr(props.match.params.id.length - 36) :
      props.match.params.id;

    setFolder(null);
    setSelectedImage(null);
    setSelectedImages([]);

    const copiedImages = getCopiedImages();
    if (copiedImages != null) {
      setImagesToPaste(copiedImages.folderId !== folderId ?
        copiedImages :
        { ...copiedImages, images: [] });
    } else {
      setImagesToPaste(null);
    }

    async function fetchFolder() {
      await reloadFolderData(folderId, props.appState.sortFoldersBy);

      let copiedFolder = getCopiedFolder();
      if (copiedFolder != null && copiedFolder.folderId === folderId) {
        copiedFolder = null;
      }
      setFolderToPaste(copiedFolder);
    }

    fetchFolder();
  }, [props.match.params.id, props.appState.sortFoldersBy]);

  useEffect(() => {
    async function fetchEngines() {
      const resp = await new DetectService(apiEndpoint()).visibleEngines({ withVersions: false });
      setEngines(resp.engines);
    }

    fetchEngines();
  }, []);

  useEffect(() => {
    if (!folderId || !filter.loaded)
      return;

    async function fetchFolderItems() {
      
      try {
        const resp = await new FolderService(apiEndpoint()).items({
          folderId: folderId,
          itemType: filter.state.itemType,
          labelCondition: filter.state.labelCondition,
          labelTypeIds: filter.state.labelTypeIds,
          userId: filter.state.userId,
          voteResult: filter.state.voteResult,
          voteUserId: filter.state.voteUserId,
          itemName: filter.state.itemName,
          detectionId: filter.state.autoDetectionId,
          noteUserId: filter.state.noteUserId,
          sortBy: props.appState.sortFoldersBy,
        });

        setImages(resp.items);

        const newDetections = {};
        for (let i = 0; i < resp.detectionParameters.length; i++) {
          if (!resp.detectionParameters[i]) {
            continue;
          }
          const parsedParameters: Array<DetectionParameter> = [];
          for (const [key, value] of Object.entries(JSON.parse(resp.detectionParameters[i]))) {
            parsedParameters.push({
              name: key,
              title: (value: any).title || key, // flowlint-line unclear-type:off
              type: (value: any).type, // flowlint-line unclear-type:off
              value: (value: any).value, // flowlint-line unclear-type:off
            });
          }
          parsedParameters.sort((p1, p2) => p1.title.localeCompare(p2.title));
          newDetections[resp.items[i].id] = parsedParameters;
        }
        setDetectionParameters(newDetections);

        setSelectedImages(selectedImages => selectedImages.filter(id => resp.items.some(img => img.id === id)));
        pendingInvalidate.current = false;

        const currentChildId = window.sessionStorage.getItem("folder-current-child-" + folderId);
        if (currentChildId) {
          window.sessionStorage.removeItem("folder-current-child-" + folderId);
          setTimeout(() => makeChildVisible(currentChildId, resp.items.findIndex(it => it.id === currentChildId) >= 6, 10), 100);
        }
      } finally {
        if (loadContentTimeout.current != null)
          clearTimeout(loadContentTimeout.current);
        setContentLoading(false);
      }
    }

    setSelectedImage(null);

    if (loadContentTimeout.current != null)
      clearTimeout(loadContentTimeout.current);
    loadContentTimeout.current = setTimeout(() => {
      setContentLoading(true);
    }, 1000);

    fetchFolderItems();
  }, [folderId, filter, itemsInvalidator, props.appState.sortFoldersBy]);

  async function reloadFolderData(folderId: string, sortBy: string) {
    const resp = await new FolderService(apiEndpoint()).folder({ folderId: folderId, sortBy });
    setFolder(resp.folder);
  }

  const prevSelectedImage = useRef<?FolderItem>(null);

  useEffect(() => {
    if (prevSelectedImage.current != null && selectedImage == null) {
      const prevSelectedImageId = prevSelectedImage.current.id;
      setImages(images => {
        const index = images.findIndex(im => im.id === prevSelectedImageId);
        if (index < 0)
          return images;

        const image = images[index];
        const dummyIndex = image.thumbnailId.indexOf("?dummy=");
        let thumbnailId = dummyIndex >= 0 ? image.thumbnailId.substring(0, dummyIndex) : image.thumbnailId;
        thumbnailId += `?dummy=${+new Date()}`;
        return [
          ...images.slice(0, index),
          {
            ...images[index],
            thumbnailId
          },
          ...images.slice(index + 1)
        ];
      });
    }

    prevSelectedImage.current = selectedImage;
  }, [selectedImage]);

  const pendingInvalidate = useRef<boolean>(false);

  useEffect(() => {
    if (!folderId)
      return;

    function listener(msg: InvalidateMessage) {
      if (msg.folderId === folderId) {
        if (selectedImage == null && !titleEditing && !nameEditing && !showStats && !showAutoDetectStats && !engineSelecting && !editingPDFOptions)
          forceInvalidateItems();
        else
          pendingInvalidate.current = true;
      }
    }

    props.invalidateNotifier.registerListener(listener);
    return () => {
      props.invalidateNotifier.unregisterListener(listener);
    };
  }, [props.invalidateNotifier, folderId, selectedImage, titleEditing, nameEditing, showStats, showAutoDetectStats, engineSelecting, editingPDFOptions]);

  useEffect(() => {
    if (!folderId)
      return;

    async function checkRetrainFolder() {
      const resp = await new FolderService(apiEndpoint()).checkRetrainFolder({ folderId: folderId });
      setRetrainFolderExists(resp.retrainFolderExists);
    }

    checkRetrainFolder();
  }, [folderId]);

  useEffect(() => {
    async function storeFrontApp() {
      const resp = await new StorefrontService(apiEndpoint()).apps({ myApps: true, category: "visualization" });
      if (resp.apps.length > 0) {
        let storeApp = resp.apps.find(f => f.title === "Chart View");
        if (storeApp != null) {
          setChartAppUrl(storeApp.appUrl);
        }
        storeApp = resp.apps.find(f => f.title === "RBI View");
        if (storeApp != null) {
          setRbiAppUrl(storeApp.appUrl);
        }
        storeApp = resp.apps.find(f => f.title === "NDE View");
        if (storeApp != null) {
          setNdeAppUrl(storeApp.appUrl);
        }
        storeApp = resp.apps.find(f => f.title === "MAP View");
        if (storeApp != null) {
          setMapAppUrl(storeApp.appUrl);
        }
      }
    }

    storeFrontApp();
  }, []);

  function onImageClick(item: FolderItem) {
    if (folder != null && folder.kind === FolderKinds.project && item.mime=== "ca/nde") {
      if (ndeAppUrl !== "") {
        callNDEApplication(item.id);
      } else {
        window.alert("Check in Storefront,NDE View Created and URL is not empty");
      }
      return;
    }
    if (folder != null && folder.kind === FolderKinds.project && item.mime=== "application/vnd.google-earth.kml+xml") {
      if (mapAppUrl !== "") {
        callMAPApplication(item.id);
      } else {
        window.alert("Check in Storefront,MAP View Created and URL is not empty");
      }
      return;
    }

    if (folder != null && folder.itemId) {
      if (folder.openIn === 'RBI Viewer' && item.kind === "$original" && rbiAppUrl !== "" && props.appState.me != null) {
          openRbiView(folder.parentId, item.id, item.name, folder.userId === props.appState.me.id ? "true" : "false");
      } else {
        const selectedImage = images.find(img => img.id === item.id);
        setSelectedImage(selectedImage);
      }
    } else {
      if (folder != null && folder.kind !== FolderKinds.sdeRetrain) {
        window.sessionStorage.setItem("folder-current-child-" + folder.id, item.id);
        props.history.push(`/folder/${item.virtualId}`);

        if (props.thirdPartyApp_3DAnnot_url && props.show_thirdPartyApp_3DAnnot_url)
          openThirdPartyApp3DAnnotTab(item.id);
      }
    }
  }

  function onFolderClick(folderId: string) {
    if (folderId)
      props.history.push(`/folder/${folderId}`);
    else
      props.history.push(`/`);
  }

  function onThumbnailContextMenu(e: SyntheticMouseEvent<HTMLImageElement>) {
    e.preventDefault();
  }

  function forceInvalidateItems() {
    setItemsInvalidator(n => n + 1);
  }

  async function uploadFiles(files: Array<File>) {
    if (folder == null)
      return;

    let hasCsv = false;
    for (let i = 0; i < files.length; i++) {
      if (files[i].name.toLowerCase().endsWith('.csv')) {
        hasCsv = true;
        break;
      }
    }

    if (!hasCsv || !rbiAppUrl) {
      await uploadFilesWithCsvViewer(files, '');
    } else {
      setUploadingFiles(files);
    }
  }

  async function uploadFilesWithCsvViewer(files: Array<File>, csvViewer: string) {
    if (folder == null)
      return;

    const sanitizedFileNames = [];
    const sanitizedFileLines = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const sanitizedName = sanitize(file.name).replace(/-\s+/g, '-').replace(/\s+-/g, '-').replace(/\s+/g, '-');
      if (file.name !== sanitizedName) {
        sanitizedFileLines.push(`${file.name} => ${sanitizedName}`);
      }
      sanitizedFileNames.push({
        name: file.name,
        sanitizedName: sanitizedName,
      });
    }

    if (sanitizedFileLines.length > 0) {
      if (!window.confirm(`Next file names will be sanitized:\n\n${sanitizedFileLines.join('\n\n')}`))
        return;
    }

    setEngineSelecting(null);
    setProcessedCount(0);
    setProcessingTotalCount(files.length);
    try {
      if (files.length > 0)
        await new ActivityService(apiEndpoint()).action({
          pageUrl: window.location.pathname,
          name: `file-upload`
        });

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const data = new FormData();
        data.append("images[" + i + "]", file, sanitizedFileNames[i].sanitizedName);
        let endpoint = `/api/folder/${folder.id}/file`;
        if (files[i].name.toLowerCase().endsWith('.csv')) {
          endpoint += `?open_in=${encodeURIComponent('text/csv:')}${encodeURIComponent(csvViewer)}`;
        }
        const response = await apiPost(endpoint, data, { headers: { "content-type": "multipart/form-data" } });
        const newItems = (response.data: Array<FolderItemJSON>).map(JSONToFolderItem);
        setProcessedCount(count => count + 1);
        setImages([...images, ...newItems]);
      }

      filter.reset();
      forceInvalidateItems();

      const response = await new FolderService(apiEndpoint()).children({ folderId: folder.id, sortBy: props.appState.sortFoldersBy });
      setFolder({ ...folder, children: response.children });
    } finally {
      setProcessedCount(0);
      setProcessingTotalCount(0);
      invalidateIfNeed();
    }
  }

  async function closeModal(needReload: boolean) {
    await new ActivityService(apiEndpoint()).visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
    if (needReload && folder != null) {
      await reloadFolderData(folder.id, props.appState.sortFoldersBy);
    }

    setSelectedImage(null);
    if (pendingInvalidate.current && folder != null) {
      const response = await new FolderService(apiEndpoint()).children({ folderId: folder.id, sortBy: props.appState.sortFoldersBy });
      setFolder({ ...folder, children: response.children });
    }
    invalidateIfNeed();
  }

  async function onDetectLabelsClick(e: SyntheticMouseEvent<HTMLElement>) {
    e.preventDefault();

    const detectLaterItemId = window.sessionStorage.getItem(detectLaterStorageKey);
    setEngineSelecting({
      selectedEngineNames: JSON.parse(window.localStorage.getItem(ormsEnginesStorageKey)) || [],
      onSelect: autoDetect,
      selectTitle: "Detect",
      onLater: selectedImages.length !== 1 ? null : () => {
        if (selectedImages.length > 0)
          window.sessionStorage.setItem(detectLaterStorageKey, selectedImages[0]);
      },
      selectPlusOne: (selectedImages.length !== 1 || !detectLaterItemId || detectLaterItemId === selectedImages[0]) ? null : autoDetectPlusOne,
      showPendingCounters: true,
    });
  }

  async function onRetrainClick(e: SyntheticMouseEvent<HTMLElement>) {
    e.preventDefault();

    if (folder == null)
      return;

    const resp = await new FolderService(apiEndpoint()).checkRetrainFolder({ folderId: folder.id });
    if (!resp.workingCopyHasLabels) {
      window.alert("The working copy has no labels");
      return;
    }
    const retrainFolderExists = resp.retrainFolderExists;

    setEngineSelecting({
      selectedEngineNames: [],
      enginesFilter: engine => engine.retrainServerUrl != null && engine.retrainServerUrl !== "",
      onSelect: retrain,
      selectTitle: "Retrain",
      onLater: retrainFolderExists ? async (engines: Array<DetectEngine>) => {
        if (folder == null)
          return;

        for (let i = 0; i < engines.length; i++) {
          await new FolderService(apiEndpoint()).copyItemsToRetrainFolder({
            sourceFolderId: folder.id,
            items: selectedImages.length > 0 ?
              selectedImages :
              images.filter(img => img.kind === "$original").map(img => img.id),
            engineName: engines[i].name,
          });
        }
      } : null,
      laterTitle: "Offline",
      onLaterEnabled: (engines: Array<DetectEngine>) => engines.length > 0,
    });
  }

  async function onRetrainServerUploadClick(e: SyntheticMouseEvent<HTMLElement>) {
    e.preventDefault();
    setEngineSelecting({
      selectedEngineNames: [],
      enginesFilter: engine => engine.retrainServerUrl != null && engine.retrainServerUrl !== "",
      onSelect: retrain,
      selectTitle: "Retrain",
    });
  }

  async function onCopyImagesClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    if (selectedImages.length === 0 || folder == null)
      return;

    const copiedImages: CopiedImages = {
      folderId: folder.id,
      images: selectedImages,
    };
    setCopiedImages(copiedImages);
  }

  async function onPasteImagesClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    if (folder == null)
      return;

    if (imagesToPaste == null || imagesToPaste.images.length === 0)
      return;

    const response = await new FolderService(apiEndpoint()).pasteItems({
      destFolderId: folder.id,
      sourceFolderId: imagesToPaste.folderId,
      items: imagesToPaste.images,
    });
    const pastedItems = response.items;
    setCopiedImages(null);
    setImages([...images, ...pastedItems]);
    setImagesToPaste({ ...imagesToPaste, images: [] });
    filter.reset();
    forceInvalidateItems();

    const childrenResponse = await new FolderService(apiEndpoint()).children({ folderId: folder.id, sortBy: props.appState.sortFoldersBy });
    setFolder({ ...folder, children: childrenResponse.children });
  }

  async function onDeleteItemsClick() {
    await deleteItems(false);
  }

  async function onDeleteDerivedItemsClick() {
    await deleteItems(true);
  }

  async function deleteItems(onlyDerivedItems: boolean) {
    if (selectedImages.length === 0 || folder == null)
      return;

    const itemCountMessage = selectedImages.length === images.length ?
      `all ${folder.itemId ? 'derived ' : ''}items` :
      `selected ${pluralize(selectedImages.length, "item", "items")}`;

    const pin = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
    const confirm = window.prompt(`You are going to delete ${itemCountMessage}.\nPlease type ${pin} to confirm.`);
    if (confirm !== pin)
      return;

    setProcessedCount(0);
    setProcessingTotalCount(selectedImages.length);
    const selectedImageIDs = [...selectedImages];
    try {
      for (let i = 0; i < selectedImageIDs.length; i++) {
        const itemId = selectedImageIDs[i];
        const img = images.find(img => img.id === itemId);
        if (!folder.itemId || (img != null && img.kind !== "$original" && img.kind !== "$annotated")) {
          if (folder.itemId) {
            await new FolderService(apiEndpoint()).deleteItem({ folderId: folder.id, itemId: itemId });
          } else {
            await new FolderService(apiEndpoint()).delete({
              folderId: itemId,
              onlyDerivedItems: onlyDerivedItems
            });
          }
          if (!onlyDerivedItems) {
            setImages(images => images.filter(img => img.id !== itemId));
            setSelectedImages(selectedImages => selectedImages.filter(id => id !== itemId));
            setFolder(folder => folder == null ? null : ({
              ...folder,
              children: folder.children.filter(f => f.itemId !== itemId)
            }));
          }
        }
        setProcessedCount(count => count + 1);
      }
    } finally {
      setProcessedCount(0);
      setProcessingTotalCount(0);

      const copiedImages = getCopiedImages();
      if (copiedImages != null) {
        const images = copiedImages.images.filter(imgId => selectedImageIDs.indexOf(imgId) < 0);
        if (images.length < copiedImages.images.length) {
          setCopiedImages(images.length === 0 ? null : { ...copiedImages, images });
        }
      }

      invalidateIfNeed();
    }
  }

  async function onDownloadImagesClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    await callDownloadApi("zip");
  }

  async function downloadAll() {
    await callDownloadApi("zip");
  }

  async function downloadOriginal() {
    await callDownloadApi("zip", "originals=true");
  }

  async function exportJSONStats() {
    await callDownloadApi("export-stats", "format=json");
  }

  async function OpenChart() {
    if (folder == null)
      return;

    const sessionPart = `session=${Cookies.get('session-id')}`;
    let extendedURL = chartAppUrl + (chartAppUrl.includes("?") ? "&" : "?") + sessionPart;

    const wsPart = `redirect=/api/folder/${folder.id}/export-stats?selection=&format=json`;
    extendedURL += "&" + wsPart;

    window.open(extendedURL, '_blank');

  }

  function openRbiView(folderId: string, itemId: string, name: string, access: string) {
    if (folder == null)
      return;
    const sessionPart = `session=${Cookies.get('session-id')}`;
    let extendedURL = rbiAppUrl + (rbiAppUrl.includes("?") ? "&" : "?") + sessionPart;
    if (folderId !== "" && itemId !== "" && name !== "") {
        const wsPart = `redirect=/api/detect/adhoc&folderId=${folderId}&allow=${access}&view=/api/image/${itemId}&name=${name}`;
        extendedURL += "&" + wsPart;
    } else {
       const wsPart = `redirect=/api/detect/adhoc&folderId=${folder.id}&allow=${access}`;
       extendedURL += "&" + wsPart;
    }
    window.open(extendedURL, '_blank');
  }

  async function callNDEApplication(id: string) {
   const resp = await apiGet(`/api/image/${id}`);
   const val = resp.data.split(",");
   const sessionPart = `session=${Cookies.get('session-id')}`;
   let extendedURL = ndeAppUrl + (ndeAppUrl.includes("?") ? "&" : "?") + sessionPart;
   const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
   const wsPart = `ws=${encodeURIComponent(`${wsProtocol}://${window.location.host}/api/image/${id}`)}&name=${props.appState.me.displayName}&db=${val[0]}&component=${val[1]}&flow=${val[2]}`;
   extendedURL += "&" + wsPart;
   window.open(extendedURL, '_blank');
  }

  async function callMAPApplication(id: string) {
   const resp = await apiGet(`/api/image/${id}`);
   const val = resp.data.split(",");
   const sessionPart = `session=${Cookies.get('session-id')}`;
   let extendedURL = mapAppUrl + (ndeAppUrl.includes("?") ? "&" : "?") + sessionPart;
   const wsProtocol = window.location.protocol === "https:" ? "" : "";
   const wsPart = `kml=${encodeURIComponent(`${"http"}://${window.location.host}/api/image/${id}`)}&api-key=AIzaSyBeCS_-22vOH3hxq9aaMK3xJc2_KHMcOow`;
   extendedURL += "&" + wsPart;
   window.open(extendedURL, '_blank');
  }

  async function selectExportFolders() {
    setExportingFolder(true);
  }

  async function exportFoldersData(folders: Array<Folder>) {
    if (selectedImages.length > 0) {
      await callDownloadApi("export-folder", "");
    } else {
      await callDownloadApi("export-folders", "", folders.map(f => f.id));
    }
    setExportingFolder(false);
  }

  function cancelExportingFolder() {
    setExportingFolder(false);
  }

  async function exportCSVLabels() {
    await callDownloadApi("export-labels", "format=csv");
  }

  async function exportCOCOLabels() {
    await callDownloadApi("export-labels", "format=coco");
  }

  async function callDownloadApi(apiMethod: string, query?: string, selection?: Array<string>) {
    if (folder == null)
      return;

    let selectionId = "";
    if (selection != null) {
      const resp = await new FolderService(apiEndpoint()).saveTemporalSelection({ items: selection });
      selectionId = resp.selectionId;
    } else if (selectedImages.length > 0) {
      const resp = await new FolderService(apiEndpoint()).saveTemporalSelection({ items: selectedImages });
      selectionId = resp.selectionId;
    }

    let downloadURL = apiEndpoint() + `/api/folder/${folder.id}/${apiMethod}?selection=${selectionId}`;
    if (query != null && query !== "")
      downloadURL += `&${query}`;
    window.location.assign(downloadURL);
  }

  async function importCSVLabels(acceptedFiles: Array<File>) {
    await importLabels(acceptedFiles, "csv");
  }

  async function importCOCOLabels(acceptedFiles: Array<File>) {
    await importLabels(acceptedFiles, "coco");
  }

  async function importLabels(acceptedFiles: Array<File>, format: string) {
    if (folder == null)
      return;

    const file = acceptedFiles[0];
    const data = new FormData();
    data.append("file", file, file.name);
    const response = await apiPost(`/api/folder/${folder.id}/import-labels?format=${format}`, data, { headers: { "content-type": "multipart/form-data" } });
    const message = (response.data.message: string);
    window.alert(message);
  }

  async function importFolderData(acceptedFiles: Array<File>) {
    if (folder == null)
      return;

    setProcessedCount(0);
    setProcessingTotalCount(1);
    let message = null;
    try {
      const file = acceptedFiles[0];
      const data = new FormData();
      data.append("file", file, file.name);
      const response = await apiPost(`/api/folder/${folder.id}/import-folders`, data, { headers: { "content-type": "multipart/form-data" } });
      message = (response.data.message: string);

      await reloadFolderData(folder.id, props.appState.sortFoldersBy);
      await props.getFolders();
      filter.reset();
      forceInvalidateItems();
    } finally {
      setProcessedCount(0);
      setProcessingTotalCount(0);
    }
    if (message)
      window.alert(message);
  }

  async function importImageData(acceptedFiles: Array<File>) {
    if (folder == null)
      return;

    setProcessedCount(0);
    setProcessingTotalCount(1);
    let message = null;
    try {
      const file = acceptedFiles[0];
      const data = new FormData();
      data.append("file", file, file.name);
      const response = await apiPost(`/api/folder/${folder.id}/import-image`, data, { headers: { "content-type": "multipart/form-data" } });
      message = (response.data.message: string);

      await reloadFolderData(folder.id, props.appState.sortFoldersBy);
      filter.reset();
      forceInvalidateItems();
    } finally {
      setProcessedCount(0);
      setProcessingTotalCount(0);
    }
    if (message)
      window.alert(message);
  }

  async function onCopyFolderClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    if (folder == null)
      return;

    const copiedFolder: CopiedFolder = {
      folderId: folder.id,
      kind: folder.kind,
    };
    setCopiedFolder(copiedFolder);
    setFolderToPaste(null);
  }

  async function onPasteFolderClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    if (folder == null || folderToPaste == null)
      return;

    const resp = await new FolderService(apiEndpoint()).pasteFolder({
      sourceFolderId: folderToPaste.folderId,
      destFolderId: folder.id,
    });
    if (resp.error) {
      window.alert(resp.error);
      return;
    }

    setCopiedFolder(null);
    setFolderToPaste(null);
    await reloadFolderData(folder.id, props.appState.sortFoldersBy);
    await props.getFolders();
  }

  async function onDeleteFolderClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    if (folder == null)
      return;

    const folderId = folder.id;
    const countResponse = await new FolderService(apiEndpoint()).childrenCount({ folderId: folderId });
    const count = countResponse.count;

    const confirmMessage = !folder.itemId ?
      `You are going to delete the folder${(folder.children.some(f => !f.itemId)) || count > 0 ? " with all subfolders" : ""}.` :
      `You are going to delete the image with all details.`;
    const pin = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
    const confirm = window.prompt(confirmMessage + `\nPlease type ${pin} to confirm.`);
    if (confirm !== pin)
      return;

    await new FolderService(apiEndpoint()).delete({ folderId: folderId, onlyDerivedItems: false });

    const copiedImages = getCopiedImages();
    if (copiedImages != null && copiedImages.folderId === folderId)
      setCopiedImages(null);

    const copiedFolder = getCopiedFolder();
    if (copiedFolder != null && copiedFolder.folderId === folderId)
      setCopiedFolder(null);

    props.history.push(folder.virtualParentId ? `/folder/${folder.virtualParentId}` : "/");
    //to recall api in sidebar
    props.getFolders();
  }


  async function autoDetect(engines: Array<DetectEngine>, parameterValues: { [string]: { [string]: string } }) {
    await autoDetectItems(engines, [], parameterValues);
  }

  async function autoDetectPlusOne(engines: Array<DetectEngine>, parameterValues: { [string]: { [string]: string } }) {
    const detectLaterItemId = window.sessionStorage.getItem(detectLaterStorageKey);
    await autoDetectItems(engines, [detectLaterItemId], parameterValues);
    window.sessionStorage.removeItem(detectLaterStorageKey);
  }

  async function autoDetectItems(engines: Array<DetectEngine>, additionalItemIds: Array<string>, parameterValues: { [string]: { [string]: string } }) {
    if (folder == null)
      return;

    const engineNames = engines.map(e => e.name);
    window.localStorage.setItem(ormsEnginesStorageKey, JSON.stringify(engineNames));
    setEngineSelecting(null);
    await new ActivityService(apiEndpoint()).action({ pageUrl: window.location.pathname, name: "auto-detect-bulk" });
    await new DetectService(apiEndpoint()).detectFolderLabels({
      folderId: folder.id,
      itemIds: selectedImages,
      additionalItemIds: additionalItemIds,
      engines: engineNames.map(name => {
        const engineParameters = parameterValues[name];
        const parameters = [];
        if (engineParameters != null) {
          for (const pName in engineParameters) {
            if (engineParameters.hasOwnProperty(pName))
              parameters.push({ name: pName, value: engineParameters[pName] });
          }
        }
        return {
          name: name,
          parameters: parameters,
        };
      }),
      tabId: getTabId(),
    });
  }

  async function retrain(engines: Array<DetectEngine>) {
    setEngineSelecting(null);
    if (folder == null)
      return;

    if (folder.kind === FolderKinds.sdeRetrain) {
      await new DetectService(apiEndpoint()).retrainLabels({
        folders: selectedImages,
        engines: engines.map(e => e.name),
        tabId: getTabId(),
      });
    } else {
      await new DetectService(apiEndpoint()).retrainLabels({
        folders: [folder.id],
        engines: engines.map(e => e.name),
        tabId: getTabId(),
      });
    }
    setSelectedImages([]);
  }

  function onEnginesSelectedCancel() {
    setEngineSelecting(null);
    invalidateIfNeed();
  }

  async function onPdfOptionsApply(ok: boolean) {
    try {
      if (!ok)
        return;

      if (folder == null)
        return;

      const folderId = folder.itemId ? folder.parentId : folder.id;
      if (folderId == null)
        return;

      await new ActivityService(apiEndpoint()).action({ pageUrl: window.location.pathname, name: "pdf" });

      let selectionId = "";

      if (folder.itemId ||
        (selectedImages.length > 0 &&
          (selectedImages.length < images.length || !filter.empty))) {
        const resp = await new FolderService(apiEndpoint()).saveTemporalSelection({
          items: folder.itemId ? [folder.itemId] : selectedImages,
        });
        selectionId = resp.selectionId;
      }

      window.location.assign(apiEndpoint() + `/api/folder/${folderId}/pdf?selection=${selectionId}`);
    } finally {
      setEditingPDFOptions(null);
      invalidateIfNeed();
    }
  }

  function onImageTitleChanged(itemId: string, newTitle: string) {
    const imageIndex = images.findIndex(i => i.id === itemId);
    if (imageIndex >= 0) {
      setImages([...images.slice(0, imageIndex),
        { ...images[imageIndex], title: newTitle },
        ...images.slice(imageIndex + 1)
      ]);
      setSelectedImage(selectedImage != null && selectedImage.id === itemId ?
        { ...selectedImage, title: newTitle } :
        selectedImage);
    }
  }

  function onImagePositionChanged(itemId: string, x: number, y: number, z: number, t: string) {
    const imageIndex = images.findIndex(i => i.id === itemId);
    if (imageIndex >= 0) {
      const image = images[imageIndex];
      const sourceItemId = image.sourceItemId ? image.sourceItemId : image.id;

      setImages(images.map(i => i.sourceItemId === sourceItemId || i.id === sourceItemId ?
        { ...i, positionX: x, positionY: y, positionZ: z, positionT: t } :
        i));
      setSelectedImage(selectedImage != null && selectedImage.id === itemId ?
        { ...selectedImage, positionX: x, positionY: y, positionZ: z, positionT: t } :
        selectedImage);
    }
  }

  function onImagesAdded(newImages: Array<FolderItem>) {
    setImages([...images, ...newImages]);
  }

  async function onEditTitleClick(e: SyntheticMouseEvent<HTMLImageElement>) {
    e.stopPropagation();
    e.preventDefault();

    setTitleEditing(true);
  }

  async function onEditNameClick(e: SyntheticMouseEvent<HTMLImageElement>, item: FolderItem) {
    e.stopPropagation();
    e.preventDefault();

    let itemName = window.prompt("New name", item.name);
    if (itemName == null)
      return;

    itemName = itemName.trim();
    if (itemName === '' || itemName === item.name)
      return;

    await new FolderService(apiEndpoint()).setName({
      folderId: item.folderId,
      name: itemName,
    });

    setImages(images => images.map(i => i.id !== item.id ? i : { ...i, name: itemName }));
  }

  async function onFolderNameClick(e: SyntheticMouseEvent<HTMLElement>, f: Folder) {
    e.stopPropagation();
    e.preventDefault();
    setNameEditing(true);
    setTempFolderToEdit(f || null);
  }

  async function onEditTitleOK(newTitle: string) {
    if (folder == null)
      return;

    try {
      await new FolderService(apiEndpoint()).setTitle({ folderId: folder.id, title: newTitle });
      setFolder({ ...folder, title: newTitle });
    } finally {
      setTitleEditing(false);
      invalidateIfNeed();
    }
  }

  async function onEditNameOK(newName: string) {
    if (folder == null)
      return;

    if (tempFolderToEdit != null) {
      try {
        await new FolderService(apiEndpoint()).setName({ folderId: tempFolderToEdit.id, name: newName });

        await reloadFolderData(folder.id, props.appState.sortFoldersBy);
        await props.getFolders();
      } finally {
        setNameEditing(false);
        invalidateIfNeed();
      }
    }
  }

  function onEditTitleCancel() {
    setTitleEditing(false);
    invalidateIfNeed();
  }

  function onEditNameCancel() {
    setNameEditing(false);
    invalidateIfNeed();
  }

  function onImageSelect(itemId: string) {
    const imageIndex = selectedImages.indexOf(itemId);
    if (imageIndex >= 0) {
      setSelectedImages([...selectedImages.slice(0, imageIndex), ...selectedImages.slice(imageIndex + 1)]);
    } else {
      if (shiftSelect.current && selectedImages.length > 0) {
        let lastImageIndex = images.findIndex(i => i.id === selectedImages[selectedImages.length-1]);
        let imageIndex = images.findIndex(i => i.id === itemId);
        if (lastImageIndex >= 0 && imageIndex >= 0) {
          if (lastImageIndex > imageIndex) {
            [lastImageIndex, imageIndex] = [imageIndex, lastImageIndex];
          }
        }
        const newIndexes = [];
        for (let i = lastImageIndex; i <= imageIndex; i++) {
          if (selectedImages.indexOf(images[i].id) < 0) {
            newIndexes.push(images[i].id);
          }
        }
        setSelectedImages([...selectedImages, ...newIndexes]);
      } else {
        setSelectedImages([...selectedImages, itemId]);
      }
    }
    setShiftSelect(false);
  }

  async function onGeneratePdfClick(e: SyntheticMouseEvent<HTMLElement>) {
    e.stopPropagation();
    e.preventDefault();

    if (folder == null)
      return;

    setEditingPDFOptions({
      folderId: !folder.itemId ? folder.id : (folder.parentId || ""),
      children: !folder.itemId ? folder.children : [folder],
      selectedImages: !folder.itemId ? [] : selectedImages,
    });
  }

  function onSelectAllClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    e.preventDefault();

    setSelectedImages(images.map(img => img.id));
  }

  function onClearSelectionClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    e.preventDefault();

    setSelectedImages([]);
  }

  async function onImagesSortEnd(e: { oldIndex: number, newIndex: number }) {
    if (folder == null || folder.isReadonly)
      return;

    const newImages = arrayMove(images, e.oldIndex, e.newIndex);
    await new FolderService(apiEndpoint()).sortItems({
      folderId: folder.id,
      sortedItems: newImages.map(img => img.id)
    });
    const response = await new FolderService(apiEndpoint()).children({ folderId: folder.id, sortBy: props.appState.sortFoldersBy });
    setImages(newImages);
    setFolder({ ...folder, children: response.children });
  }

  async function onChildFoldersSortEnd(e: { oldIndex: number, newIndex: number }) {
    if (folder == null || folder.isReadonly)
      return;

    const newChildren = arrayMove(folder.children, e.oldIndex, e.newIndex);
    const response = await new FolderService(apiEndpoint()).sortChildFolders({
      folderId: folder.id,
      sortedChildren: newChildren.map(img => img.id)
    });
    setFolder({ ...folder, children: response.children });
    props.getFolders();
  }

  async function onCreateNewOrgFolderClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    await createNewFolder(FolderKinds.org);
  }

  async function onCreateNewProjectFolderClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    await createNewFolder(FolderKinds.project);
  }

  async function onCreateNewMasterFolderClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    await createNewFolder(FolderKinds.sdeMaster);
  }

  async function onCreateNewSDEFolderClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    await createNewFolder(FolderKinds.sde);
  }

  async function onCreateNewRetrainFolderClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (!retrainFolderExists) {
      await createNewFolder(FolderKinds.sdeRetrain);
    }
  }

  async function createNewFolder(folderKind: FolderKind) {
    if (folder == null)
      return;

    const defaultFolderName = folderKind === FolderKinds.sde ?
      dateToYYYYMMDDHHMMSS(new Date()) :
      "";

    let folderName = window.prompt("New folder name", defaultFolderName);
    if (folderName == null)
      return;

    folderName = folderName.trim();
    if (folderName === "")
      return;

    const currentIndex = folder.children.findIndex(f => f.name === folderName && !f.itemId);

    if (currentIndex >= 0) {
      alert(`Folder '${folderName}' already exists`);
      return;
    }

    try {
      const response = await new FolderService(apiEndpoint()).addFolder({
        name: folderName,
        isShared: folder.isShared,
        parentId: folder.id,
        kind: folderKind,
      });
      if (response.folder == null)
        return;

      props.history.push(`/folder/${response.folder.virtualId}`);
      //to recall api in sidebar
      props.getFolders();
    } catch (error) {
      if (error.response != null) {
        if (error.response.status === 409) {
          alert(`Folder '${folderName}' already exists`);
        } else {
          alert(error.message);
        }
      }
    }
  }

  async function onStatsButtonClicked() {
    setShowStats(true);
  }

  async function onAutoDetectStatsButtonClicked() {
    setShowAutoDetectStats(true);
  }

  function onCloseFolderStats() {
    setShowStats(false);
    invalidateIfNeed();
  }

  function onCloseAutoDetectFolderStats() {
    setShowAutoDetectStats(false);
    invalidateIfNeed();
  }

  function openThirdPartyApp3DAnnotTab(itemId: string) {
    if (folder == null)
      return;

    const childFolder = folder.children.find(c => c.itemId === itemId);
    if (childFolder == null || childFolder.mime !== "3d/3dmm")
      return;

    new ActivityService(apiEndpoint()).action({
      pageUrl: window.location.pathname,
      name: "thirdPartyApp_3DAnnot_url-folder"
    });

    const sessionPart = `session=${Cookies.get('session-id')}`;
    let extendedURL = props.thirdPartyApp_3DAnnot_url + (props.thirdPartyApp_3DAnnot_url.includes("?") ? "&" : "?") + sessionPart;

    const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
    const wsPart = `folder=${childFolder.id}&ws=${encodeURIComponent(`${wsProtocol}://${window.location.host}/websocket/folder`)}`;
    extendedURL += "&" + wsPart;

    window.open(extendedURL, '_blank');
  }

  async function onAddStreamClick() {
    if (folder == null)
      return;

    const streamURL = window.prompt("Input the Streaming URL");
    if (streamURL == null || streamURL === "")
      return;

    await new ActivityService(apiEndpoint()).action({ pageUrl: window.location.pathname, name: "stream-upload" });
    const response = await apiPost(`/api/folder/${folder.id}/stream`, { stream_url: streamURL });
    props.history.push(`/folder/${response.data.virtualId}`);
  }

  function invalidateIfNeed() {
    if (pendingInvalidate.current) {
      forceInvalidateItems();
    }
  }

  function setShiftSelect(value: boolean) {
    shiftSelect.current = value;
  }

  if (folder == null)
    return null;

  const ancestors = folder.ancestors || [];
  const imageEdit = selectedImage != null ?
    (selectedImage.mime.startsWith("stream/") ?
      <StreamView folder={folder} item={selectedImage} onClose={closeModal} /> :
      <ImageEdit appState={props.appState} folder={folder} image={selectedImage} onClose={closeModal}
        onImageTitleChanged={onImageTitleChanged}
        onImagePositionChanged={onImagePositionChanged}
        onImagesAdded={onImagesAdded} />) :
    null;

  const spinnerStyle = {
    overlay: {
      backgroundColor: "rgba(255, 255, 255, 0.5)"
    },
    content: {
      top: "50%",
      right: "50%",
      width: "50%",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "95vh"
    }
  };

  const statModalStyle = {
    overlay: {
      backgroundColor: "rgba(155, 155, 155, 0.4)"
    },
    content: {
      left: "10%",
      top: "10%",
      right: "10%",
      width: "80%",
      bottom: "auto",
      maxHeight: "80vh",
      backgroundColor: localStorage.getItem('isDarkTheme') === "dark" ? "rgb(20,20,20)" : "white",
    }
  };

  const optionsStyle = {
    overlay: {
      backgroundColor: "rgba(255, 255, 255, 0.5)"
    },
    content: {
      top: "50%",
      right: "50%",
      width: "20%",
      bottom: "auto",
      marginRight: "-20%",
      transform: "translate(-50%, -50%)",
      maxHeight: "80vh",
    }
  };

  const processStatusContent = processingTotalCount > 0 ?
    <Modal
      isOpen={true}
      style={spinnerStyle}
    >
      <div>
        <Loader type='Watch' color='green' height={80} width={80} />
        <Line percent={processedCount * 100 / processingTotalCount} strokeWidth='4'
          strokeColor='green' />
        <div
          className={styles.progress}>{Math.floor(processedCount * 100 / processingTotalCount)}%
        </div>
      </div>
    </Modal> :
    null;


  const loadContent = contentLoading ?
    <Modal
      isOpen={true}
      style={{ ...spinnerStyle, content: { ...spinnerStyle.content, width: '120px', marginRight: '-120px' } }}
    >
      <div>
        <Loader type='Watch' color='green' height={80} width={80} />
      </div>
    </Modal> :
    null;

  const engineSelectingContent = engineSelecting != null ?
    <Modal
      isOpen={true}
      style={spinnerStyle}
    >
      <OrmsList
        formats={["image", "video", "audio", "pdf"]}
        showParameters={engineSelecting.enginesFilter == null}
        selectedEngines={engineSelecting.selectedEngineNames}
        enginesFilter={engineSelecting.enginesFilter}
        onEnginesSelected={engineSelecting.onSelect}
        onEnginesSelectedCancel={onEnginesSelectedCancel}
        selectTitle={engineSelecting.selectTitle}
        onLater={engineSelecting.onLater}
        laterTitle={engineSelecting.laterTitle}
        onLaterEnabled={engineSelecting.onLaterEnabled}
        selectPlusOne={engineSelecting.selectPlusOne}
        showPendingCounters={engineSelecting.showPendingCounters ?? false}
      />
    </Modal> :
    null;

  const isImageFolder = !!folder.itemId;
  const me = props.appState.me;

  const pdfOptionsContent = editingPDFOptions != null &&
    <Modal
      isOpen={true}
      style={spinnerStyle}
      ariaHideApp={false}
    >
      <PdfOptionsDialog folderId={editingPDFOptions.folderId}
        children={!isImageFolder && selectedImages.length > 0 ?
          editingPDFOptions.children.filter(f => selectedImages.indexOf(f.itemId) >= 0) :
          editingPDFOptions.children}
        selectedImages={editingPDFOptions.selectedImages}
        engines={engines}
        close={onPdfOptionsApply} />
    </Modal>;

  const imageCount = selectedImages.length === 0 || selectedImages.length === images.length ?
    "" :
    `(${pluralize(selectedImages.length, "item", "items")})`;

  const pdfLink = !folder.isReadonly && me != null && me.features.indexOf(Features.downloadPDF) >= 0 &&
    (folder.kind === FolderKinds.sde || folder.kind === FolderKinds.sdeItem) &&
    <Button href={apiEndpoint() + `/api/folder/${folder.id}/pdf`} download
      onClick={onGeneratePdfClick}
      hidden={images.length === 0}>
      PDF {isImageFolder ? "" : imageCount}
    </Button>;

  const autoDetectLink = !folder.isReadonly && folder.kind === FolderKinds.sde &&
    <Button onClick={onDetectLabelsClick}
      hidden={images.length === 0}>
      Auto Detect labels {imageCount}
    </Button>;

  const folderStatsButton = !folder.isReadonly &&
    (folder.kind === FolderKinds.sde || folder.kind === FolderKinds.sdeItem || folder.kind === FolderKinds.sdeFrames || folder.kind === FolderKinds.sdeMaster) &&
    <DropdownButton id="stat-images" title={`${getFolderCaptionName(folder)} Stat`}>
      <MenuItem key="show" eventKey="1" onSelect={onStatsButtonClicked}>
        Show
      </MenuItem>
      {(folder.kind === FolderKinds.sde || folder.kind === FolderKinds.sdeMaster) &&
      <MenuItem key="auto-detect" eventKey="2" onSelect={onAutoDetectStatsButtonClicked}>
        Auto Detect
      </MenuItem>}
      <MenuItem key="download" eventKey="3" onSelect={exportJSONStats}>
        Export (JSON)
      </MenuItem>
      {chartAppUrl !== "" &&
      <MenuItem key="Chart" eventKey="4" onSelect={OpenChart}>
        Open Chart (JSON)
      </MenuItem>}
    </DropdownButton>;

  const folderAppButton = !folder.isReadonly && rbiAppUrl !== "" && me != null &&
    (folder.kind === FolderKinds.sde) && 
    <DropdownButton id="app" title={`${getFolderCaptionName(folder)} App`}>
      <MenuItem key="rbi" eventKey="1" onSelect={() => openRbiView("", "", "", folder.userId === me.id ? "true" : "false")}>
        Open RBI Viewer
      </MenuItem>
    </DropdownButton>;

  const retrainLink = !folder.isReadonly && isImageFolder ?
    <Button onClick={onRetrainClick}>
      Retrain
    </Button> :
    null;

  const pasteImagesLink = !folder.isReadonly && (folder.kind === FolderKinds.sde || folder.kind === FolderKinds.sdeFrames) && !folder.itemId ?
    <Button onClick={onPasteImagesClick}
      hidden={imagesToPaste == null || imagesToPaste.images.length === 0}>
      Paste {pluralize(imagesToPaste != null ? imagesToPaste.images.length : 0, "item", "items")}
    </Button> :
    null;

  const copyImagesLink = (folder.kind === FolderKinds.sde || folder.kind === FolderKinds.sdeFrames) && !folder.itemId ?
    <Button
      hidden={selectedImages.length === 0}
      onClick={onCopyImagesClick}>{selectedImages.length === images.length ? "Copy all items" : `Copy selected ${imageCount}`}
    </Button>
    : null;

  let deleteImagesLink = null;
  if (selectedImages.length > 0 && !folder.isReadonly) {
    if (folder.kind === FolderKinds.sdeItem || folder.kind === FolderKinds.sdeFrames) {
      deleteImagesLink = <Button onClick={onDeleteItemsClick}>
        {selectedImages.length === images.length ? `Delete all derived items` : `Delete selected ${imageCount}`}
      </Button>;
    } else {
      const buttonTitle = selectedImages.length === images.length ? "Delete all items" : `Delete selected ${imageCount}`;
      deleteImagesLink = <DropdownButton id="delete-items" title={buttonTitle}>
        <MenuItem key="delete-all" eventKey="1" onSelect={onDeleteItemsClick}>
          Everything (including original and working copies)
        </MenuItem>
        <MenuItem key="delete-derived" eventKey="2" onSelect={onDeleteDerivedItemsClick}>
          Derived items (excluding original and working copies)
        </MenuItem>
      </DropdownButton>;
    }
  }

  const hasFolderManageAccess = !folder.isReadonly && me != null && (folder.isShared ? me.isAdmin : folder.userId === me.id);

  const createOrgFolderLink = hasFolderManageAccess && folder.kind === FolderKinds.org && !folder.isAutoCreated ?
    <Button onClick={onCreateNewOrgFolderClick}>
      Add Org Subfolder
    </Button> :
    null;

  const createProjectFolderLink = hasFolderManageAccess && folder.kind === FolderKinds.org && !folder.isAutoCreated ?
    <Button onClick={onCreateNewProjectFolderClick}>
      Add Project Subfolder
    </Button> :
    null;

  const createMasterFolderLink = hasFolderManageAccess && folder.kind === FolderKinds.project && !folder.isAutoCreated ?
    <Button onClick={onCreateNewMasterFolderClick}>
      Add SDE Master Subfolder
    </Button> :
    null;

  const createSDEFolderLink = hasFolderManageAccess && (folder.kind === FolderKinds.project || folder.kind === FolderKinds.sdeMaster) && !isImageFolder && !folder.isAutoCreated ?
    <Button onClick={onCreateNewSDEFolderClick}>
      Add SDE Subfolder
    </Button> :
    null;

  const createRetrainFolderLink = hasFolderManageAccess && folder.kind === FolderKinds.project && !folder.isAutoCreated && !retrainFolderExists ?
    <Button onClick={onCreateNewRetrainFolderClick}>
      Add SDE Retrain Subfolder
    </Button> :
    null;

  const retrainServerUpload = folder.kind === FolderKinds.sdeRetrain && !folder.itemId ?
    <Button
      hidden={selectedImages.length === 0}
      onClick={onRetrainServerUploadClick}>{selectedImages.length === images.length ? "Retrain Server Upload ALL" :
      `Retrain Server Upload selected ${imageCount}`}
    </Button> :
    null;

  const copyFolderLink = !folder.isAutoCreated
    && (folder.kind === FolderKinds.project || folder.kind === FolderKinds.org || folder.kind === FolderKinds.sdeMaster || folder.kind === FolderKinds.sde)
    && <Button onClick={onCopyFolderClick}>
      Copy the {getFolderCaptionName(folder)}
    </Button>;

  const pasteFolderLink = !folder.isReadonly && !folder.isAutoCreated
    && folderToPaste != null
    && <Button onClick={onPasteFolderClick}>
      Paste the copied folder
    </Button>;

  const deleteFolderLink = hasFolderManageAccess && !folder.isAutoCreated && <Button onClick={onDeleteFolderClick}>
    Delete the {getFolderCaptionName(folder)}
  </Button>;

  let downloadImagesLink = null;
  if (selectedImages.length > 0 && !folder.isReadonly && me != null && me.features.indexOf(Features.downloadFiles) >= 0) {
    const buttonTitle = selectedImages.length === images.length ? "Download all items" : `Download selected ${imageCount}`;
    if (folder.kind === FolderKinds.sdeItem || folder.kind === FolderKinds.sdeFrames) {
      downloadImagesLink = <Button onClick={onDownloadImagesClick}>
        {buttonTitle}
      </Button>;
    } else if (folder.kind === FolderKinds.sde) {
      downloadImagesLink = <DropdownButton id="download-images" title={buttonTitle}>
        <MenuItem key="download-all" eventKey="1" onSelect={downloadAll}>
          All
        </MenuItem>
        <MenuItem key="download-org" eventKey="2" onSelect={downloadOriginal}>
          Original
        </MenuItem>
      </DropdownButton>;
    }
  }

  const exportMenuItems: Array<typeof MenuItem> = [];
  if (!folder.isReadonly && me != null && me.features.indexOf(Features.exportLabels) >= 0) {
    if (folder.kind === FolderKinds.sde || folder.kind === FolderKinds.sdeMaster || folder.kind === FolderKinds.project || folder.kind === FolderKinds.org)
      exportMenuItems.push(
        <MenuItem key="folders" eventKey="1" onSelect={selectExportFolders}>
          Folder Data
        </MenuItem>);

    if (((folder.kind === FolderKinds.sde || folder.kind === FolderKinds.sdeItem) && images.length > 0)) {
      exportMenuItems.push(
        <MenuItem key="labels-csv" eventKey="2" onSelect={exportCSVLabels}>
          Labels (CSV)
        </MenuItem>);
      exportMenuItems.push(
        <MenuItem key="labels-coco" eventKey="3" onSelect={exportCOCOLabels}>
          Labels (COCO)
        </MenuItem>);
    }
  }

  const exportDataLink = exportMenuItems.length > 0 &&
    <DropdownButton id="export-data" title="Export">
      {exportMenuItems}
    </DropdownButton>;

  const importMenuItems: Array<typeof MenuItem> = [];
  if (!folder.isReadonly && me != null && me.features.indexOf(Features.importLabels) >= 0) {
    if (folder.kind === FolderKinds.sde || folder.kind === FolderKinds.sdeMaster || folder.kind === FolderKinds.project || folder.kind === FolderKinds.org)
      importMenuItems.push(
        <MenuItem key="folders" eventKey="1">
          <CustomDropZone onDrop={importFolderData} label="Folder Data" accept={["application/zip", ".zip"]} multiple={false} />
        </MenuItem>);

    if (folder.kind === FolderKinds.sde)
      importMenuItems.push(
        <MenuItem key="image" eventKey="2">
          <CustomDropZone onDrop={importImageData} label="Image Data" accept={["application/zip", ".zip"]} multiple={false} />
        </MenuItem>);

    if (((folder.kind === FolderKinds.sde || folder.kind === FolderKinds.sdeItem) && images.length > 0)) {
      importMenuItems.push(
        <MenuItem key="labels-csv" eventKey="3">
          <CustomDropZone onDrop={importCSVLabels} label="Labels (CSV)" accept={["text/csv", ".csv"]} multiple={false} />
        </MenuItem>);
      importMenuItems.push(
        <MenuItem key="labels-coco" eventKey="4">
          <CustomDropZone onDrop={importCOCOLabels} label="Labels (COCO)" accept={["application/json", ".json"]} multiple={false} />
        </MenuItem>
      );
    }
  }

  const importDataLink = exportMenuItems.length > 0 &&
    <DropdownButton id="import-data" title="Import">
      {importMenuItems}
    </DropdownButton>;


  const canUploadFiles = folder.kind === FolderKinds.project || folder.kind === FolderKinds.sde || folder.kind === FolderKinds.sdeItem;
  const canUploadStreams = folder.kind === FolderKinds.sdeMaster;

  let dropZoneProps = {
    onDrop: uploadFiles,
    label: "Upload File",
  };
  if (folder.kind === FolderKinds.project) {
    dropZoneProps = { ...dropZoneProps, accept: '.fbx, .3dmm, .pcd, .nde, .kml' };
  }

  const uploadButton = (!canUploadFiles || folder.isReadonly || folder.isAutoCreated) ? null :
    <Button><CustomDropZone {...dropZoneProps} /></Button>;

  const sortByOptions = [
    <option key='' value=''>Default</option>,
    <option key='<name' value='<name'>Name</option>,
    <option key='>activity' value='>activity'>Activity Date (most recent first)</option>,
    <option key='<activity' value='<activity'>Activity Date (most recent last)</option>,
    <option key='>created' value='>created'>Creation Date (most recent first)</option>,
    <option key='<created' value='<created'>Creation Date (most recent last)</option>,
    <option key='>updated' value='>updated'>Modification Date (most recent first)</option>,
    <option key='<updated' value='<updated'>Modification Date (most recent last)</option>,
  ];

  function onSortByChanged(e: SyntheticEvent<HTMLSelectElement>) {
    props.appState.setSortBy(e.currentTarget.value);
  }

  return (
    <div className={styles.root}>
      <div className='col-lg-12 col-sm-12 col-xs-12'>
        <h5>
          {getFolderCaptionName(folder)}
        </h5>
        {
          ancestors.map(e => {
            const folderLink = "/folder/" + e.virtualId;
            return (
              <React.Fragment key={e.id}>
                <NavLink
                  to={folderLink}
                  className={styles.links}
                  activeclassname='active'
                >{e.name}</NavLink>
                <span className={styles.links}>{">"}</span>
              </React.Fragment>);
          })
        }
        <NavLink to={"/folder/" + folder.virtualId} className={styles.links}>{folder.name}</NavLink>
      </div>
      <div className='col-lg-12 col-sm-12 col-xs-12'>
        <div className={styles.divider} />
      </div>

      <div className='col-lg-12 col-sm-14 col-xs-12'>
        <Button hidden={folder.isReadonly || selectedImages.length >= images.length} onClick={e => onSelectAllClick(e)}>Select All</Button>
        <Button hidden={folder.isReadonly || selectedImages.length === 0} onClick={e => onClearSelectionClick(e)}>Clear Selection</Button>
        {createOrgFolderLink} {createProjectFolderLink} {createMasterFolderLink} {createSDEFolderLink} {createRetrainFolderLink} 
        {autoDetectLink} {retrainLink}
        {uploadButton}
        {!folder.isReadonly && canUploadStreams && <Button onClick={onAddStreamClick}>
          Add Stream
        </Button>}

        {pasteImagesLink} {copyImagesLink} {downloadImagesLink} {exportDataLink} {importDataLink}
        {retrainServerUpload}
        {deleteImagesLink} {pdfLink} {folderStatsButton} {folderAppButton} {copyFolderLink} {pasteFolderLink} {deleteFolderLink} 
      </div>
      <div className='col-lg-12 col-sm-12 col-xs-12'>
        <div className={styles.divider} />
      </div>
      <div className='col-lg-12 col-sm-12 col-xs-12'>
        <div className={styles.folderOperation}>
          <div className='form-group'>
            {
              !isImageFolder && <span>
                  <label>Title: {folder.title !== "" ? folder.title : "unspecified"}</label>
                  <i className='fa fa-pencil' onClick={onEditTitleClick} />
                  <span className={styles.stat}>Total : {images.length}<span className={styles.stat}> Chosen : {selectedImages.length}</span></span>
                </span>
            }
          </div>

        </div>
      </div>

      <div className='col-lg-12 col-sm-12 col-xs-12'>
        <div className={styles.folderOperation}>
          {
            !folder.isReadonly && folder.kind === FolderKinds.sde && !isImageFolder &&
            <FolderFilterView filter={filter} me={me} />
          }
        </div>
      </div>
      <div className='col-lg-12 col-sm-12 col-xs-12'>
        <div className={styles.folderActions}>
          <span onClick={() => onFolderClick(folder.virtualParentId)}>
            <i className='fa fa-arrow-left' />
            <label>Go back</label>
          </span>
          {!folder.itemId && <span className={styles.sortByTitle}>
            <label>Sort by</label>
          </span>}
          {!folder.itemId && <span className={styles.sortBySelect}>
            <select className={cx("form-control", "cursor-pointer")}
              value={props.appState.sortFoldersBy}
              onChange={onSortByChanged}>
              {sortByOptions}
            </select>
          </span>}
        </div>
        <div className='col-lg-12 col-sm-12 col-xs-12'>
          <ChildFolderViews
            axis='xy'
            distance={1}
            onSortEnd={onChildFoldersSortEnd}
            children={folder.children}
            onFolderNameClick={onFolderNameClick}
            user={me}
            disableSort={props.appState.sortFoldersBy}
          />
          <ItemViews
            axis='xy'
            distance={1}
            onSortEnd={onImagesSortEnd}
            folder={folder}
            items={images}
            engines={engines}
            selectedImages={selectedImages}
            disableSort={!filter.empty || props.appState.sortFoldersBy}
            onImageClick={onImageClick}
            onThumbnailContextMenu={onThumbnailContextMenu}
            onImageSelect={onImageSelect}
            onEditNameClick={onEditNameClick}
            detectionParameters={detectionParameters}
            setShiftSelect={setShiftSelect}
          />
        </div>
      </div>
      {
        engineSelectingContent || processStatusContent || pdfOptionsContent || loadContent || <Modal
          isOpen={selectedImage != null}
          onRequestClose={closeModal}
          style={modalStyles}
          shouldCloseOnOverlayClick={false}
        >
          {imageEdit}
        </Modal>
      }
      {
        titleEditing && <Modal isOpen={true} style={spinnerStyle}>
          <TextEditor title='Input new folder title:'
            text={folder.title}
            width={600}
            height={200}
            ok={onEditTitleOK}
            cancel={onEditTitleCancel} />
        </Modal>
      }
      {
        nameEditing && <Modal isOpen={true} style={spinnerStyle} ariaHideApp={false}>
          <TextEditor title='Input new folder name:'
            text={(tempFolderToEdit !== null && tempFolderToEdit !== undefined) ? tempFolderToEdit.name : ""}
            width={600}
            height={200}
            ok={onEditNameOK}
            cancel={onEditNameCancel} />
        </Modal>
      }
      {showStats && <Modal isOpen={showStats} style={statModalStyle} ariaHideApp={false}>
        <FolderStatisticsView folder={folder} onClose={onCloseFolderStats} />
      </Modal>}
      {showAutoDetectStats && <Modal isOpen={showAutoDetectStats} style={statModalStyle} ariaHideApp={false}>
        <FolderAutoDetectStatisticsView folder={folder} onClose={onCloseAutoDetectFolderStats} />
      </Modal>}
      {exportingFolder && <Modal isOpen={true} style={spinnerStyle} ariaHideApp={false}>
        <FolderSelector
          rootFolderId={folder.id}
          selectTitle="Export"
          select={exportFoldersData}
          cancel={cancelExportingFolder}
        />
      </Modal>}
      {uploadingFiles.length > 0 && <Modal isOpen={true} style={optionsStyle} ariaHideApp={false}>
        <OptionList
          options={["Detect Engine", "RBI Viewer"]}
          title={"How to process uploaded CSV"}
          onSelect={async option => {
            setUploadingFiles([]);
            await uploadFilesWithCsvViewer(uploadingFiles, option === "Detect Engine" ? "" : option);
          }}
        />
      </Modal>}
    </div>
  );
}

function getCopiedImages(): ?CopiedImages {
  const copiedImagesContent = window.sessionStorage.getItem(copiedImagesStorageKey);
  if (copiedImagesContent)
    return (JSON.parse(copiedImagesContent): CopiedImages);
  return null;
}

function setCopiedImages(copiedImages: ?CopiedImages) {
  if (copiedImages == null)
    window.sessionStorage.removeItem(copiedImagesStorageKey);
  else
    window.sessionStorage.setItem(copiedImagesStorageKey, JSON.stringify(copiedImages));
}

function getCopiedFolder(): ?CopiedFolder {
  const copiedFolderContent = window.sessionStorage.getItem(copiedFolderStorageKey);
  if (copiedFolderContent)
    return (JSON.parse(copiedFolderContent): CopiedFolder);
  return null;
}

function setCopiedFolder(copiedFolder: ?CopiedFolder) {
  if (copiedFolder == null)
    window.sessionStorage.removeItem(copiedFolderStorageKey);
  else
    window.sessionStorage.setItem(copiedFolderStorageKey, JSON.stringify(copiedFolder));
}

function preventDefault(e: SyntheticEvent<HTMLElement>) {
  e.preventDefault();
}

function stopPropagation(e: SyntheticEvent<HTMLElement>) {
  e.stopPropagation();
}

const ItemView = SortableElement(({
  item,
  folder,
  engines,
  isSelected,
  onImageClick,
  onThumbnailContextMenu,
  onImageSelect,
  onEditNameClick,
  detectionParameters,
  setShiftSelect,
}) => {
  let imageTitle = item.name;
  if (folder != null && folder.itemId)
    imageTitle = imageTitleKind(item, engines);

  let imageBadges = [];
  if (folder != null && !folder.itemId && item.childItemKinds != null)
    imageBadges = item.childItemKinds.map((k, i) => {
      const engine = engines.find(e => e.name === k);
      const badge = k === "$annotated" ? "work" : (engine != null ? (engine.abbreviation || engine.title || engine.name) : k);
      const color = k === "$annotated" ? "green" : (engine != null ? (engine.color || "red") : "red");
      return <span key={i} className={styles.badge} style={{
        color: color,
        borderColor: color
      }}>{badge}</span>;
    });

  const detectParameters = detectionParameters ?
    detectionParameters.map(p => detectionParameterString(p)).filter(p => p).join('<br/>') :
    null;

  const tooltip = `Created: ${new Date(Date.parse(item.createdAt)).toLocaleString()}` + (detectParameters ? `<br/><br/>${detectParameters}` : '');

  return <div id={"child-" + item.id} className='col-lg-4 col-sm-6 col-xs-12' onClick={() => onImageClick(item)}>
    <div className={styles.mediaCard}>
      <div className={styles.mediaImg} data-tip data-for={"tooltip-" + item.id}>
        <img src={apiEndpoint() + `/api/image/thumbnail/${item.thumbnailId}`} alt='thumbnail'
          onContextMenu={onThumbnailContextMenu} onDragStart={preventDefault} />
        {(item.mime.startsWith("video/") || item.mime.startsWith("audio/")) &&
        <img src={require("../../images/video.png")} alt='video' />}
        <ReactTooltip html={true} multiline={true} id={"tooltip-" + item.id} place="top" effect="solid"
          type="info" backgroundColor="#404040" offset={{ top: 0, left: 100 }}>
          {tooltip}
        </ReactTooltip>
      </div>

      <div className={styles.mediaInfo}>
        <h5 className='text-ellipsis'>
          {folder != null && !folder.itemId && !folder.isReadonly &&
          <i className='fa fa-pencil cursor-pointer' onClick={e => onEditNameClick(e, item)} />}
          {imageTitle}
        </h5>
        <div onClick={stopPropagation} onMouseDown={e => setShiftSelect(e.shiftKey)}>
          <ToggleButton
            inactiveLabel=""
            activeLabel=""
            containerStyle={{ width: "40px" }}
            trackStyle={{ width: "40px", height: "25px" }}
            thumbAnimateRange={[1, 22]}
            value={isSelected}
            onToggle={() => onImageSelect(item.id)}
          />
        </div>
        <div className={styles.badges}>
          {imageBadges}
        </div>
      </div>
    </div>
  </div>;
});

const ItemViews = SortableContainer(({
    items,
    folder,
    engines,
    disableSort,
    selectedImages,
    onImageClick,
    onThumbnailContextMenu,
    onImageSelect,
    onEditNameClick,
    detectionParameters,
    setShiftSelect,
  }) => {
    return (
      <div className={styles.images}>
        {
          items.map((item, i) => (
            <ItemView key={item.id}
              index={i}
              item={item}
              folder={folder}
              engines={engines}
              isSelected={selectedImages.indexOf(item.id) >= 0}
              disabled={disableSort}
              onImageClick={onImageClick}
              onThumbnailContextMenu={onThumbnailContextMenu}
              onImageSelect={onImageSelect}
              onEditNameClick={onEditNameClick}
              detectionParameters={detectionParameters[item.id]}
              setShiftSelect={setShiftSelect}
            />))
        }
      </div>
    );
  }
);

const ChildFolderView = SortableElement(({
  folder,
  folderIndex,
  onFolderNameClick,
  user,
}) => {
  return <FolderToRender
    folder={folder}
    folderIndex={folderIndex}
    onFolderNameClick={onFolderNameClick}
    user={user} />;
});

const ChildFolderViews = SortableContainer(({
    children,
    onFolderNameClick,
    user,
    disableSort,
  }) => {
    const childFolders = children.filter(f => !f.itemId);
    const sortedIds = sortBy(childFolders, child => child.createdAt).map(child => child.id);
    return <div className={styles.images}>
      {childFolders.map((f, i) =>
        <ChildFolderView
          key={f.id}
          index={i}
          disabled={disableSort}
          folder={f}
          folderIndex={sortedIds.indexOf(f.id)}
          onFolderNameClick={onFolderNameClick}
          user={user} />
      )}
    </div>;
  }
);

function makeChildVisible(childId: string, alignToTop: boolean, tryCount: number) {
  const childElement = document.getElementById("child-" + childId);
  if (childElement != null)
    childElement.scrollIntoView(alignToTop);
  else if (tryCount > 0)
    setTimeout(() => makeChildVisible(childId, alignToTop, tryCount - 1), 100);
}

type DetectionParameter = {
  name: string,
  title: string,
  type: string,
  value: string,
}

function detectionParameterString(p: DetectionParameter): string {
  if (p.type === 'boolean') {
    return p.value === 'true' ? p.title : '';
  }
  if (p.type === 'percentage') {
    return `${p.title}: ${p.value}%`;
  }
  return `${p.title}: ${p.value}`;
}

export default (observer(FolderView): typeof FolderView);