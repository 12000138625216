// @flow strict
// Copyright (C) 2018-2021 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { FolderRight } from "../../rpc/model";
import { FolderService } from "../../rpc/folders";
import { apiEndpoint } from "../../utils/http";
import { makeAutoObservable, runInAction } from "mobx";
import { UserService } from "../../rpc/users";

export class FolderRightsState {
  folderId: string;
  rights: Array<FolderRight>;

  constructor(folderId: string) {
    this.folderId = folderId;
    this.rights = [];
    this.fetchRights();
    makeAutoObservable(this);
  }

  async fetchRights() {
    const resp = await new FolderService(apiEndpoint()).folderRights({ folderId: this.folderId });
    runInAction(() => this.rights = resp.rights);
  }

  async changeUserRights(right: FolderRight, newPermission: string) {
    await new FolderService(apiEndpoint()).grantFolderAccess({
      userId: right.userId,
      folderId: this.folderId,
      permission: newPermission
    });

    runInAction(() => {
      const index = this.rights.findIndex(r => r.userId === right.userId && !r.isInherited);
      if (newPermission === "") {
        if (index >= 0)
          this.rights.splice(index, 1);
      } else {
        if (index >= 0)
          this.rights[index].permission = newPermission;
        else
          this.rights.push({
            userId: right.userId,
            userDisplayName: right.userDisplayName,
            permission: newPermission,
            isInherited: false,
          });
      }
    });
  }

  async addUser(userEmail: string): Promise<string> {
    const resp = await new UserService(apiEndpoint()).getUser({ email: userEmail });
    const user = resp.user;
    if (user == null)
      return `User with email '${userEmail}' doesn't exist`;
    runInAction(() => {
      if (!this.rights.some(r => r.userId === user.id && !r.isInherited))
        this.rights.push({
          userId: user.id,
          userDisplayName: user.displayName,
          permission: '',
          isInherited: false,
        });
    });
    return '';
  }
}
