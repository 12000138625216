// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React, { useState } from "react";
import sortBy from "lodash/sortBy";
import styles from "./FolderRights.module.css";
import type { Folder, FolderRight } from "../../rpc/model";
import Button from "../Button/Button";
import { FolderRightsState } from "./FolderRightsState";
import { observer } from "mobx-react-lite";

type FolderRightsProps = {
  folder: Folder;
}

function FolderRightsView(props: FolderRightsProps): Node {
  const [state] = useState<FolderRightsState>(() => new FolderRightsState(props.folder.id));

  async function onRightChanged(e: SyntheticInputEvent<HTMLSelectElement>, right: FolderRight) {
    await state.changeUserRights(right, e.target.value);
  }

  async function onAddUserClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    let userEmail = window.prompt("User email");
    if (userEmail == null)
      return;

    userEmail = userEmail.trim();
    if (userEmail === '')
      return;

    const err = await state.addUser(userEmail);
    if (err)
      window.alert(err);
  }

  function permissionDisplayValue(permission: string): string {
    if (permission === 'view')
      return 'View Content and User Labels';
    if (permission === 'view-labels')
      return 'View Content and All Labels';
    if (permission === 'owner')
      return 'Folder Owner';
    if (permission === 'disabled')
      return 'Block Access';
    if (permission === '')
      return '';
    return 'Unknown';
  }

  const rights = <table className={styles.userTable}>
    <thead>
    <tr>
      <th>USER</th>
      <th>Permissions</th>
    </tr>
    </thead>
    <tbody>
    {sortBy(state.rights.filter(r => !r.isInherited), r => r.userDisplayName).map(r => {
      return <tr key={r.userId}>
        <td>{r.userDisplayName}</td>
        <td>
          {r.permission === 'owner' ?
            permissionDisplayValue(r.permission) :
            <select value={r.permission} onChange={e => onRightChanged(e, r)}>
              <option key="" value="">{permissionDisplayValue('')}</option>
              <option key="view" value="view">{permissionDisplayValue('view')}</option>
              <option key="view-labels" value="view-labels">{permissionDisplayValue('view-labels')}</option>
              <option key="disabled" value="disabled">{permissionDisplayValue('disabled')}</option>
            </select>}
        </td>
      </tr>;
    })}
    </tbody>
  </table>;

  const ancestorRights = state.rights.some(r => r.isInherited) ? (<div>
    <h3>Ancestor Rights</h3>
    <table className={styles.userTable}>
      <thead>
      <tr>
        <th>USER</th>
        <th>Permissions</th>
      </tr>
      </thead>
      <tbody>
      {sortBy(state.rights.filter(r => r.isInherited), r => r.userDisplayName).map(r => {
        return <tr key={r.userId}>
          <td>{r.userDisplayName}</td>
          <td>{permissionDisplayValue(r.permission)}</td>
        </tr>;
      })}
      </tbody>
    </table>
  </div>) : null;

  return <div className={styles.root}>
    <h4>Folder: {props.folder.name}</h4>
    <h5>created at: {new Date(Date.parse(props.folder.createdAt)).toLocaleString()}</h5>
    <div>
      <Button onClick={onAddUserClick}>
        Add User
      </Button>
    </div>
    {rights}
    {ancestorRights}
  </div>;
}

export default (observer(FolderRightsView): typeof FolderRightsView);
