// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import styles from './Statistics.module.css';
import { toDDHHMMSS } from '../../utils/convert';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import type { User } from "../../rpc/model";
import { JSONToUser } from "../../rpc/model";
import { ActivityService } from "../../rpc/activity";
import { apiEndpoint, apiGet } from "../../utils/http";

type UserItem = {
  user: User,
  upload_pdf_interval: number,
  login_count: number,
  total_time: number,
}

type UserDetails = {
  parent_folder_name: string,
  parent_folder_id: string,
  image_folder_name: string,
  image_folder_id: string,
  upload_pdf_interval: number,
}

type SpentTimeTitle = {
  text: string,
  link?: string,
}

type SpentTime = {
  title: Array<SpentTimeTitle>,
  total_time: number,
  hour_24_ago: number,
  day_7_ago: number,
  day_30_ago: number,
}

type Click = {
  title: string,
  total_count: number,
  hour_24_ago: number,
  day_7_ago: number,
  day_30_ago: number,
}

type SessionClick = {
  title: string,
  count: number,
}

type SessionPage = {
  title: Array<SpentTimeTitle>,
  total_time: number,
}

type Session = {
  ip_address: string,
  start_time: string,
  start: number,
  finish: number,
  clicks: Array<SessionClick>,
  pages: Array<SessionPage>,
}

type SelectedUserItem = {
  user: User,
  details: {
    upload_pdf_intervals: Array<UserDetails>,
    spent_time: Array<SpentTime>,
    clicks: Array<Click>,
    sessions: Array<Session>,
  },
}

export default function UserStatistics(): Node {
  const [usersItems, setUsersItems] = useState<Array<UserItem>>([]);
  const [selectedUserItem, setSelectedUserItem] = useState<?SelectedUserItem>(null);

  useEffect(() => {
    new ActivityService(apiEndpoint()).visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
  }, []);

  useEffect(() => {
    async function fetchUserItems() {
      const resp = await apiGet('/api/statistics/user');
      setUsersItems(resp.data.map(i => ({ ...i, user: JSONToUser(i.user) })));
    }

    fetchUserItems();
  }, []);

  async function onUserClick(e: SyntheticMouseEvent<HTMLButtonElement>, user: User) {
    e.preventDefault();

    if (selectedUserItem != null && selectedUserItem.user.id === user.id)
      return;

    const userDetailsRequest = await apiGet(`/api/statistics/user/${user.id}`);

    setSelectedUserItem({
      user,
      details: userDetailsRequest.data,
    });
  }

  let userStatistics = null;
  if (selectedUserItem != null) {
    userStatistics = <div>
      <h4>{selectedUserItem.user.displayName}</h4>
      <Tabs defaultActiveKey='image-inspection-time'>
        <Tab eventKey='image-inspection-time' title='Image Repo Inspection Time'>
          <table className={styles.table}>
            <thead>
            <tr>
              <th>Folder</th>
              <th>Image Set</th>
              <th>Image Repo inspection time (IRIT)</th>
            </tr>
            </thead>
            <tbody>
            {selectedUserItem.details.upload_pdf_intervals.map((d, i) =>
              <tr key={i}>
                <td><Link to={`/folder/${d.parent_folder_id}`}>{d.parent_folder_name}</Link></td>
                <td><Link to={`/folder/${d.image_folder_id}`}>{d.image_folder_name}</Link></td>
                <td>{d.upload_pdf_interval ? toDDHHMMSS(d.upload_pdf_interval) : '-'}</td>
              </tr>)}
            </tbody>
          </table>
        </Tab>
        <Tab eventKey='actions' title='Actions'>
          <table className={styles.table}>
            <thead>
            <tr>
              <th>Title</th>
              <th>Total Count</th>
              <th>Last 24 hours</th>
              <th>Last 7 days</th>
              <th>Last 30 days</th>
            </tr>
            </thead>
            <tbody>
            {selectedUserItem.details.clicks.map((d, i) =>
              <tr key={i}>
                <td>{d.title}</td>
                <td>{d.total_count || '-'}</td>
                <td>{d.hour_24_ago || '-'}</td>
                <td>{d.day_7_ago || '-'}</td>
                <td>{d.day_30_ago || '-'}</td>
              </tr>)}
            </tbody>
          </table>
        </Tab>
        <Tab eventKey='page-spent-time' title='Page Spent Time'>
          <table className={styles.table}>
            <thead>
            <tr>
              <th>Page</th>
              <th>Total Time</th>
              <th>Last 24 hours</th>
              <th>Last 7 days</th>
              <th>Last 30 days</th>
            </tr>
            </thead>
            <tbody>
            {selectedUserItem.details.spent_time.map((d, i) =>
              <tr key={i}>
                <td>
                  {(d.title || []).map(t => t.link != null ?
                    <Link to={t.link}>{t.text}</Link> :
                    <span>{t.text}</span>)}
                </td>
                <td>{d.total_time ? toDDHHMMSS(d.total_time) : '-'}</td>
                <td>{d.hour_24_ago ? toDDHHMMSS(d.hour_24_ago) : '-'}</td>
                <td>{d.day_7_ago ? toDDHHMMSS(d.day_7_ago) : '-'}</td>
                <td>{d.day_30_ago ? toDDHHMMSS(d.day_30_ago) : '-'}</td>
              </tr>)}
            </tbody>
          </table>
        </Tab>
        <Tab eventKey='sessions' title='Last Sessions'>
          {selectedUserItem.details.sessions.map(session => <div>
            <h4>Session {session.start_time} ({toDDHHMMSS(session.finish - session.start)}) {session.ip_address !== '' ? ` from ${session.ip_address}` : ''}</h4>
            <h5>Pages</h5>
            <table className={styles.table}>
              <thead>
              <tr>
                <th>Page</th>
                <th>Total Time</th>
              </tr>
              </thead>
              <tbody>
              {session.pages.map((d, i) =>
                <tr key={i}>
                  <td>
                    {(d.title || []).map(t => t.link != null ?
                      <Link to={t.link}>{t.text}</Link> :
                      <span>{t.text}</span>)}
                  </td>
                  <td>{d.total_time ? toDDHHMMSS(d.total_time) : '-'}</td>
                </tr>)}
              </tbody>
            </table>
            {session.clicks.length > 0 && <div>
              <h5>Clicks</h5>
              <table className={styles.table}>
                <thead>
                <tr>
                  <th>Title</th>
                  <th>Click Count</th>
                </tr>
                </thead>
                <tbody>
                {session.clicks.map((d, i) =>
                  <tr key={i}>
                    <td>{d.title}</td>
                    <td>{d.count || '-'}</td>
                  </tr>)}
                </tbody>
              </table>
            </div>}
          </div>)}
        </Tab>
      </Tabs>
    </div>;
  }

  return <div className={styles.root}>
    <h3>User Statistics</h3>
    <table className={styles.table}>
      <thead>
      <tr>
        <th>User</th>
        <th>Image Repo inspection time (IRIT)</th>
        <th>Login count</th>
        <th>Total Time</th>
      </tr>
      </thead>
      <tbody>
      {usersItems.map(u =>
        <tr key={u.user.id} className={styles.clickable}
          onClick={e => onUserClick(e, u.user)}>
          <td>{u.user.displayName}</td>
          <td>{u.upload_pdf_interval ? toDDHHMMSS(u.upload_pdf_interval) : '-'}</td>
          <td>{u.login_count ? u.login_count : '-'}</td>
          <td>{u.total_time ? toDDHHMMSS(u.total_time) : '-'}</td>
        </tr>)}
      </tbody>
    </table>
    {userStatistics}
  </div>;
}
