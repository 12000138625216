// @flow strict
// Copyright (C) 2018-2021 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { autorun, makeAutoObservable, runInAction } from "mobx";
import type { UserNote } from "../../rpc/model";
import { apiEndpoint } from "../../utils/http";
import { IReactionDisposer } from 'mobx';
import { UserService } from '../../rpc/users';
import AppState from '../../AppState';

export class UserNotesState {
  appState: AppState;
  userId: string;
  fromAdmin: boolean;
  notes: Array<UserNote>;
  editingNote: string;
  editingNoteId: string | null;
  disposes: (typeof IReactionDisposer)[];

  constructor(appState: AppState, userId: string, fromAdmin: boolean) {
    this.appState = appState;
    this.notes = [];
    this.editingNote = '';
    this.editingNoteId = null;
    this.userId = userId;
    this.fromAdmin = fromAdmin;
    this.disposes = [];

    makeAutoObservable(this, {
      appState: false,
      disposes: false,
    }, { autoBind: true });

    this.disposes.push(
      autorun(() => {
        this.fetchNotes(this.userId, this.fromAdmin);
      })
    );
  }

  dispose() {
    this.disposes.forEach(dispose => dispose());
  }

  setUserId(userId: string, fromAdmin: boolean) {
    this.userId = userId;
    this.fromAdmin = fromAdmin;
  }

  async fetchNotes(userId: string, fromAdmin: boolean) {
    const resp = await new UserService(apiEndpoint()).userNotes({ userId, fromAdmin });
    runInAction(() => {
      if (userId === this.userId && fromAdmin === this.fromAdmin) {
        this.notes = resp.notes;
        this.editingNoteId = null;
        this.editingNote = '';
      }
    });
  }

  startEditNote(note: UserNote) {
    this.editingNoteId = note.id;
    this.editingNote = note.note;
  }

  startAddNote() {
    this.editingNoteId = '';
    this.editingNote = '';
  }

  cancelEditNote() {
    this.editingNoteId = null;
    this.editingNote = '';
  }

  async addNote() {
    const userId = this.userId;
    const fromAdmin = this.fromAdmin;
    if (this.editingNoteId == null || this.editingNoteId !== '') {
      return;
    }
    const resp = await new UserService(apiEndpoint()).addUserNote({ userId, fromAdmin, note: this.editingNote });
    runInAction(() => {
      if (userId === this.userId && fromAdmin === this.fromAdmin && resp.note != null) {
        this.notes.push(resp.note);
      }
      this.editingNoteId = null;
      this.editingNote = '';
    });
  }

  async editNote() {
    const noteId = this.editingNoteId;
    if (noteId == null || noteId === '') {
      return;
    }
    const note = this.editingNote;
    await new UserService(apiEndpoint()).editUserNote({ noteId, note });
    runInAction(() => {
      const n = this.notes.find(n => n.id === noteId);
      if (n != null) {
        n.note = note;
      }
      this.editingNoteId = null;
      this.editingNote = '';
    });
  }

  async markNoteAsRead(noteId: string) {
    const userId = this.userId;
    await new UserService(apiEndpoint()).markUserNoteAsRead({ noteId });
    runInAction(() => {
      const n = this.notes.find(n => n.id === noteId);
      if (n != null) {
        n.readAt = new Date().toString();
        if (this.appState.me != null && this.appState.me.id === userId && this.appState.unreadAlertCount > 0) {
          this.appState.unreadAlertCount--;
        }
      }
    });
  }

  async deleteNote(noteId: string) {
    await new UserService(apiEndpoint()).deleteUserNote({ noteId });
    runInAction(() => {
      const index = this.notes.findIndex(n => n.id === noteId);
      if (index >= 0) {
        this.notes.splice(index, 1);
      }
    });
  }
}
