// @flow strict
// Copyright (C) 2018-2021 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React, { useState } from "react";
import styles from "./FrameExtractorView.module.css";
import { FrameExtractorState } from './FrameExtractorState';
import ToggleButton from 'react-toggle-button';
import { observer } from 'mobx-react-lite';
import InputRange from 'react-input-range';
import cx from "classnames";
import { Button, ButtonGroup } from 'react-bootstrap';
import Modal from 'react-modal';
import Loader from 'react-loader-spinner';

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "50%",
    width: "50%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "95vh"
  }
};

type FrameExtractorViewProps = {
  videoId: string,
  currentPosition: ?number,
  close: () => void,
}

function FrameExtractorView(props: FrameExtractorViewProps): Node {
  const [state] = useState<FrameExtractorState>(() => new FrameExtractorState(props.videoId, props.currentPosition));

  async function extractFrames() {
    if (await state.extractFrames())
      props.close();
  }

  return <div className={styles.root}>
    <div className={styles.row}>
      <ToggleButton
        inactiveLabel=""
        activeLabel=""
        containerStyle={{ width: "40px" }}
        trackStyle={{ width: "40px", height: "25px" }}
        thumbAnimateRange={[1, 22]}
        value={state.extractAllFrames}
        onToggle={state.setExtractAllFrames} />
      <span className={styles.rowCaption}>All Frames</span>
    </div>
    {
      state.currentPosition != null && <div className={styles.row}>
        <ToggleButton
          inactiveLabel=""
          activeLabel=""
          containerStyle={{ width: "40px" }}
          trackStyle={{ width: "40px", height: "25px" }}
          thumbAnimateRange={[1, 22]}
          value={state.extractCurrentFrame}
          onToggle={state.setExtractCurrentFrame} />
        <span className={styles.rowCaption}>{`Current Frame (${positionString(state.currentPosition)})`}</span>
      </div>
    }
    <div className={styles.row}>
      <ToggleButton
        inactiveLabel=""
        activeLabel=""
        containerStyle={{ width: "40px" }}
        trackStyle={{ width: "40px", height: "25px" }}
        thumbAnimateRange={[1, 22]}
        value={state.extractFrameRange}
        onToggle={state.setExtractFrameRange} />
      <span className={styles.rowCaption}>Frame Range</span>
    </div>
    <div className={styles.range}>
      <InputRange
        value={{ min: state.startPosition, max: state.endPosition }}
        minValue={0}
        maxValue={state.maxPosition}
        step={1}
        disabled={!state.extractFrameRange}
        onChange={value => state.setFrameRange(value.min, value.max)}
        formatLabel={positionString}
      />
    </div>
    <div className={styles.row}>
      <span className={styles.rowCaption}>FPS</span>
    </div>
    <div className={styles.row}>
      {state.fpsItems.filter(i => i >= 1).map(i =>
        <span className={cx(styles.fps, { [styles.currentFps]: i === state.fps })} onClick={() => state.setFPS(i)}>
          {i}
        </span>
      )}
    </div>
    <div className={styles.row}>
      {state.fpsItems.filter(i => i < 1).map(i =>
        <span className={cx(styles.fps, { [styles.currentFps]: i === state.fps })} onClick={() => state.setFPS(i)}>
          {i}
        </span>
      )}
    </div>
    <ButtonGroup className={styles.buttons}>
      <Button bsStyle="info" onClick={extractFrames} disabled={!state.extractAllFrames && !state.extractCurrentFrame && !state.extractFrameRange}>
        Extract Frames
      </Button>
      <Button bsStyle="warning" onClick={props.close}>
        Cancel
      </Button>
    </ButtonGroup>
    {
      state.waiting && <Modal isOpen={state.waiting} style={modalStyle} ariaHideApp={false}>
        <Loader type="Watch" color="green" height={80} width={80} />
      </Modal>
    }
  </div>;
}

function positionString(position: number): string {
  const hours = Math.floor(position / 3600);
  const minutes = Math.floor((position - (hours * 3600)) / 60);
  const seconds = Math.floor(position - (hours * 3600) - (minutes * 60));
  const milliseconds = Math.floor(position * 1000) % 1000;

  const hoursStr = hours < 10 ? "0" + hours.toString() : hours.toString();
  const minutesStr = minutes < 10 ? "0" + minutes.toString() : minutes.toString();
  const secondsStr = seconds < 10 ? "0" + seconds.toString() : seconds.toString();
  const millisecondsStr = milliseconds < 10 ? "00" + milliseconds.toString()
    : (milliseconds < 100 ? "0" + milliseconds.toString() : milliseconds.toString());
  return millisecondsStr !== '000'
    ? hoursStr + ':' + minutesStr + ':' + secondsStr + '.' + millisecondsStr
    : hoursStr + ':' + minutesStr + ':' + secondsStr;
}

export default (observer(FrameExtractorView): typeof FrameExtractorView);
