//@flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import * as React from 'react';
import type { Node } from "react";
import { Button as BSButton } from "react-bootstrap";
import styles from "./Button.module.css";

type Props = {
  cls?: string,
  hidden?: boolean,
  onClick?: (e: SyntheticMouseEvent<HTMLButtonElement>) => void | Promise<void>,
  href?: string,
  disabled?: boolean,
  children?: Node,
};

export default function Button(props: Props): Node {
  if (props.hidden)
    return null;

  return <BSButton bsStyle="info"
    className={`${styles.root} ${props.cls ? props.cls : ''}`}
    disabled={props.disabled}
    onClick={props.onClick} href={props.href}>
    {props.children}
  </BSButton>;
}
