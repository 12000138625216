// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { DetectEngine, FolderItem } from "../rpc/model";

export function imageTitleKind(image: FolderItem, engines: Array<DetectEngine>): string {
  let imageTitle = image.title;
  switch (image.kind) {
    case '$original':
      imageTitle = 'Original (non-editable)';
      break;
    case '$annotated':
      imageTitle = 'Working copy';
      break;
    case '$crop':
      imageTitle = image.title !== '' ? `Cropped: ${image.title}` : 'Cropped';
      break;
    case '$custom':
      imageTitle = 'Additional';
      break;
    default:
      const engine = engines.find(e => e.name === image.kind);
      imageTitle = engine != null && engine.title ? engine.title : image.kind;
      imageTitle += ` ${image.title}`;
  }
  return imageTitle;
}