// @flow strict
/* eslint-disable */
import { sendTwirpRequest } from "./twirp";
import type { FolderItemNote, FolderItemNoteJSON } from "./model";
import { FolderItemNoteToJSON, JSONToFolderItemNote } from "./model";
import type { Position, PositionJSON } from "./model";
import { PositionToJSON, JSONToPosition } from "./model";
export type FolderItemSetItemTitleRequest = {
  itemId: string;
  title: string;
}

export type FolderItemSetItemTitleRequestJSON = {
  item_id?: string;
  title?: string;
}

export function FolderItemSetItemTitleRequestToJSON(m: FolderItemSetItemTitleRequest): FolderItemSetItemTitleRequestJSON {
  return {
    item_id: m.itemId,
    title: m.title,
  };
}

export function JSONToFolderItemSetItemTitleRequest(m: FolderItemSetItemTitleRequestJSON): FolderItemSetItemTitleRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
    title: m.title != null ? m.title : "",
  };
}

export type FolderItemSetItemTitleResponse = {
}

export type FolderItemSetItemTitleResponseJSON = {
}

export function FolderItemSetItemTitleResponseToJSON(m: FolderItemSetItemTitleResponse): FolderItemSetItemTitleResponseJSON {
  return {
  };
}

export function JSONToFolderItemSetItemTitleResponse(m: FolderItemSetItemTitleResponseJSON): FolderItemSetItemTitleResponse {
  return {
  };
}

export type FolderItemSetItemPositionRequest = {
  itemId: string;
  position: ?Position;
}

export type FolderItemSetItemPositionRequestJSON = {
  item_id?: string;
  position?: PositionJSON;
}

export function FolderItemSetItemPositionRequestToJSON(m: FolderItemSetItemPositionRequest): FolderItemSetItemPositionRequestJSON {
  return {
    item_id: m.itemId,
    position: m.position != null ? PositionToJSON(m.position) : undefined,
  };
}

export function JSONToFolderItemSetItemPositionRequest(m: FolderItemSetItemPositionRequestJSON): FolderItemSetItemPositionRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
    position: m.position != null ? JSONToPosition(m.position) : null,
  };
}

export type FolderItemSetItemPositionResponse = {
}

export type FolderItemSetItemPositionResponseJSON = {
}

export function FolderItemSetItemPositionResponseToJSON(m: FolderItemSetItemPositionResponse): FolderItemSetItemPositionResponseJSON {
  return {
  };
}

export function JSONToFolderItemSetItemPositionResponse(m: FolderItemSetItemPositionResponseJSON): FolderItemSetItemPositionResponse {
  return {
  };
}

export type FolderItemExtractVideoFramesRequest = {
  itemId: string;
  tabId: string;
  startPosition: number;
  endPosition: number;
  fps: number;
}

export type FolderItemExtractVideoFramesRequestJSON = {
  item_id?: string;
  tab_id?: string;
  start_position?: number;
  end_position?: number;
  fps?: number;
}

export function FolderItemExtractVideoFramesRequestToJSON(m: FolderItemExtractVideoFramesRequest): FolderItemExtractVideoFramesRequestJSON {
  return {
    item_id: m.itemId,
    tab_id: m.tabId,
    start_position: m.startPosition,
    end_position: m.endPosition,
    fps: m.fps,
  };
}

export function JSONToFolderItemExtractVideoFramesRequest(m: FolderItemExtractVideoFramesRequestJSON): FolderItemExtractVideoFramesRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
    tabId: m.tab_id != null ? m.tab_id : "",
    startPosition: m.start_position != null ? m.start_position : 0,
    endPosition: m.end_position != null ? m.end_position : 0,
    fps: m.fps != null ? m.fps : 0,
  };
}

export type FolderItemExtractVideoFramesResponse = {
}

export type FolderItemExtractVideoFramesResponseJSON = {
}

export function FolderItemExtractVideoFramesResponseToJSON(m: FolderItemExtractVideoFramesResponse): FolderItemExtractVideoFramesResponseJSON {
  return {
  };
}

export function JSONToFolderItemExtractVideoFramesResponse(m: FolderItemExtractVideoFramesResponseJSON): FolderItemExtractVideoFramesResponse {
  return {
  };
}

export type FolderItemEstimateVideoExtractionRequest = {
  itemId: string;
  startPosition: number;
  endPosition: number;
  fps: number;
}

export type FolderItemEstimateVideoExtractionRequestJSON = {
  item_id?: string;
  start_position?: number;
  end_position?: number;
  fps?: number;
}

export function FolderItemEstimateVideoExtractionRequestToJSON(m: FolderItemEstimateVideoExtractionRequest): FolderItemEstimateVideoExtractionRequestJSON {
  return {
    item_id: m.itemId,
    start_position: m.startPosition,
    end_position: m.endPosition,
    fps: m.fps,
  };
}

export function JSONToFolderItemEstimateVideoExtractionRequest(m: FolderItemEstimateVideoExtractionRequestJSON): FolderItemEstimateVideoExtractionRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
    startPosition: m.start_position != null ? m.start_position : 0,
    endPosition: m.end_position != null ? m.end_position : 0,
    fps: m.fps != null ? m.fps : 0,
  };
}

export type FolderItemEstimateVideoExtractionResponse = {
  availableSize: number;
  estimatedSize: number;
  frameCount: number;
}

export type FolderItemEstimateVideoExtractionResponseJSON = {
  available_size?: number;
  estimated_size?: number;
  frame_count?: number;
}

export function FolderItemEstimateVideoExtractionResponseToJSON(m: FolderItemEstimateVideoExtractionResponse): FolderItemEstimateVideoExtractionResponseJSON {
  return {
    available_size: m.availableSize,
    estimated_size: m.estimatedSize,
    frame_count: m.frameCount,
  };
}

export function JSONToFolderItemEstimateVideoExtractionResponse(m: FolderItemEstimateVideoExtractionResponseJSON): FolderItemEstimateVideoExtractionResponse {
  return {
    availableSize: m.available_size != null ? m.available_size : 0,
    estimatedSize: m.estimated_size != null ? m.estimated_size : 0,
    frameCount: m.frame_count != null ? m.frame_count : 0,
  };
}

export type FolderItemExtractVideoLabelFramesRequest = {
  itemId: string;
  labelId: string;
  tabId: string;
}

export type FolderItemExtractVideoLabelFramesRequestJSON = {
  item_id?: string;
  label_id?: string;
  tab_id?: string;
}

export function FolderItemExtractVideoLabelFramesRequestToJSON(m: FolderItemExtractVideoLabelFramesRequest): FolderItemExtractVideoLabelFramesRequestJSON {
  return {
    item_id: m.itemId,
    label_id: m.labelId,
    tab_id: m.tabId,
  };
}

export function JSONToFolderItemExtractVideoLabelFramesRequest(m: FolderItemExtractVideoLabelFramesRequestJSON): FolderItemExtractVideoLabelFramesRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
    labelId: m.label_id != null ? m.label_id : "",
    tabId: m.tab_id != null ? m.tab_id : "",
  };
}

export type FolderItemExtractVideoLabelFramesResponse = {
}

export type FolderItemExtractVideoLabelFramesResponseJSON = {
}

export function FolderItemExtractVideoLabelFramesResponseToJSON(m: FolderItemExtractVideoLabelFramesResponse): FolderItemExtractVideoLabelFramesResponseJSON {
  return {
  };
}

export function JSONToFolderItemExtractVideoLabelFramesResponse(m: FolderItemExtractVideoLabelFramesResponseJSON): FolderItemExtractVideoLabelFramesResponse {
  return {
  };
}

export type FolderItemExtractVideoFrameRequest = {
  itemId: string;
  position: number;
  tabId: string;
}

export type FolderItemExtractVideoFrameRequestJSON = {
  item_id?: string;
  position?: number;
  tab_id?: string;
}

export function FolderItemExtractVideoFrameRequestToJSON(m: FolderItemExtractVideoFrameRequest): FolderItemExtractVideoFrameRequestJSON {
  return {
    item_id: m.itemId,
    position: m.position,
    tab_id: m.tabId,
  };
}

export function JSONToFolderItemExtractVideoFrameRequest(m: FolderItemExtractVideoFrameRequestJSON): FolderItemExtractVideoFrameRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
    position: m.position != null ? m.position : 0,
    tabId: m.tab_id != null ? m.tab_id : "",
  };
}

export type FolderItemExtractVideoFrameResponse = {
}

export type FolderItemExtractVideoFrameResponseJSON = {
}

export function FolderItemExtractVideoFrameResponseToJSON(m: FolderItemExtractVideoFrameResponse): FolderItemExtractVideoFrameResponseJSON {
  return {
  };
}

export function JSONToFolderItemExtractVideoFrameResponse(m: FolderItemExtractVideoFrameResponseJSON): FolderItemExtractVideoFrameResponse {
  return {
  };
}

export type FolderItemVideoInfoRequest = {
  itemId: string;
}

export type FolderItemVideoInfoRequestJSON = {
  item_id?: string;
}

export function FolderItemVideoInfoRequestToJSON(m: FolderItemVideoInfoRequest): FolderItemVideoInfoRequestJSON {
  return {
    item_id: m.itemId,
  };
}

export function JSONToFolderItemVideoInfoRequest(m: FolderItemVideoInfoRequestJSON): FolderItemVideoInfoRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
  };
}

export type FolderItemVideoInfoResponse = {
  duration: number;
  fps: number;
}

export type FolderItemVideoInfoResponseJSON = {
  duration?: number;
  fps?: number;
}

export function FolderItemVideoInfoResponseToJSON(m: FolderItemVideoInfoResponse): FolderItemVideoInfoResponseJSON {
  return {
    duration: m.duration,
    fps: m.fps,
  };
}

export function JSONToFolderItemVideoInfoResponse(m: FolderItemVideoInfoResponseJSON): FolderItemVideoInfoResponse {
  return {
    duration: m.duration != null ? m.duration : 0,
    fps: m.fps != null ? m.fps : 0,
  };
}

export type FolderItemItemNotesRequest = {
  itemId: string;
}

export type FolderItemItemNotesRequestJSON = {
  item_id?: string;
}

export function FolderItemItemNotesRequestToJSON(m: FolderItemItemNotesRequest): FolderItemItemNotesRequestJSON {
  return {
    item_id: m.itemId,
  };
}

export function JSONToFolderItemItemNotesRequest(m: FolderItemItemNotesRequestJSON): FolderItemItemNotesRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
  };
}

export type FolderItemItemNotesResponse = {
  notes: Array<FolderItemNote>;
}

export type FolderItemItemNotesResponseJSON = {
  notes?: Array<FolderItemNoteJSON>;
}

export function FolderItemItemNotesResponseToJSON(m: FolderItemItemNotesResponse): FolderItemItemNotesResponseJSON {
  return {
    notes: m.notes.map(FolderItemNoteToJSON),
  };
}

export function JSONToFolderItemItemNotesResponse(m: FolderItemItemNotesResponseJSON): FolderItemItemNotesResponse {
  return {
    notes: m.notes != null ? m.notes.map(JSONToFolderItemNote) : [],
  };
}

export type FolderItemSetItemNoteRequest = {
  noteId: string;
  itemId: string;
  note: string;
  whomId: string;
}

export type FolderItemSetItemNoteRequestJSON = {
  note_id?: string;
  item_id?: string;
  note?: string;
  whom_id?: string;
}

export function FolderItemSetItemNoteRequestToJSON(m: FolderItemSetItemNoteRequest): FolderItemSetItemNoteRequestJSON {
  return {
    note_id: m.noteId,
    item_id: m.itemId,
    note: m.note,
    whom_id: m.whomId,
  };
}

export function JSONToFolderItemSetItemNoteRequest(m: FolderItemSetItemNoteRequestJSON): FolderItemSetItemNoteRequest {
  return {
    noteId: m.note_id != null ? m.note_id : "",
    itemId: m.item_id != null ? m.item_id : "",
    note: m.note != null ? m.note : "",
    whomId: m.whom_id != null ? m.whom_id : "",
  };
}

export type FolderItemMapViewSettingsRequest = {
  itemId: string;
}

export type FolderItemMapViewSettingsRequestJSON = {
  item_id?: string;
}

export function FolderItemMapViewSettingsRequestToJSON(m: FolderItemMapViewSettingsRequest): FolderItemMapViewSettingsRequestJSON {
  return {
    item_id: m.itemId,
  };
}

export function JSONToFolderItemMapViewSettingsRequest(m: FolderItemMapViewSettingsRequestJSON): FolderItemMapViewSettingsRequest {
  return {
    itemId: m.item_id != null ? m.item_id : "",
  };
}

export type FolderItemMapViewSettingsResponse = {
  appTitle: string;
  appUrl: string;
}

export type FolderItemMapViewSettingsResponseJSON = {
  app_title?: string;
  app_url?: string;
}

export function FolderItemMapViewSettingsResponseToJSON(m: FolderItemMapViewSettingsResponse): FolderItemMapViewSettingsResponseJSON {
  return {
    app_title: m.appTitle,
    app_url: m.appUrl,
  };
}

export function JSONToFolderItemMapViewSettingsResponse(m: FolderItemMapViewSettingsResponseJSON): FolderItemMapViewSettingsResponse {
  return {
    appTitle: m.app_title != null ? m.app_title : "",
    appUrl: m.app_url != null ? m.app_url : "",
  };
}

export class FolderItemService {
  hostname: string;
  pathPrefix: string = "/rpc.FolderItemService/";

  constructor(hostname: string) {
    this.hostname = hostname;
  }

  async setItemTitle(folderItemSetItemTitleRequest: FolderItemSetItemTitleRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "SetItemTitle";
    const body: FolderItemSetItemTitleRequestJSON = FolderItemSetItemTitleRequestToJSON(folderItemSetItemTitleRequest);
    await sendTwirpRequest(url, body);
  }

  async setItemPosition(folderItemSetItemPositionRequest: FolderItemSetItemPositionRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "SetItemPosition";
    const body: FolderItemSetItemPositionRequestJSON = FolderItemSetItemPositionRequestToJSON(folderItemSetItemPositionRequest);
    await sendTwirpRequest(url, body);
  }

  async extractVideoFrames(folderItemExtractVideoFramesRequest: FolderItemExtractVideoFramesRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "ExtractVideoFrames";
    const body: FolderItemExtractVideoFramesRequestJSON = FolderItemExtractVideoFramesRequestToJSON(folderItemExtractVideoFramesRequest);
    await sendTwirpRequest(url, body);
  }

  async estimateVideoExtraction(folderItemEstimateVideoExtractionRequest: FolderItemEstimateVideoExtractionRequest): Promise<FolderItemEstimateVideoExtractionResponse> {
    const url = this.hostname + this.pathPrefix + "EstimateVideoExtraction";
    const body: FolderItemEstimateVideoExtractionRequestJSON = FolderItemEstimateVideoExtractionRequestToJSON(folderItemEstimateVideoExtractionRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderItemEstimateVideoExtractionResponse(data);
  }

  async extractVideoLabelFrames(folderItemExtractVideoLabelFramesRequest: FolderItemExtractVideoLabelFramesRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "ExtractVideoLabelFrames";
    const body: FolderItemExtractVideoLabelFramesRequestJSON = FolderItemExtractVideoLabelFramesRequestToJSON(folderItemExtractVideoLabelFramesRequest);
    await sendTwirpRequest(url, body);
  }

  async extractVideoFrame(folderItemExtractVideoFrameRequest: FolderItemExtractVideoFrameRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "ExtractVideoFrame";
    const body: FolderItemExtractVideoFrameRequestJSON = FolderItemExtractVideoFrameRequestToJSON(folderItemExtractVideoFrameRequest);
    await sendTwirpRequest(url, body);
  }

  async videoInfo(folderItemVideoInfoRequest: FolderItemVideoInfoRequest): Promise<FolderItemVideoInfoResponse> {
    const url = this.hostname + this.pathPrefix + "VideoInfo";
    const body: FolderItemVideoInfoRequestJSON = FolderItemVideoInfoRequestToJSON(folderItemVideoInfoRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderItemVideoInfoResponse(data);
  }

  async itemNotes(folderItemItemNotesRequest: FolderItemItemNotesRequest): Promise<FolderItemItemNotesResponse> {
    const url = this.hostname + this.pathPrefix + "ItemNotes";
    const body: FolderItemItemNotesRequestJSON = FolderItemItemNotesRequestToJSON(folderItemItemNotesRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderItemItemNotesResponse(data);
  }

  async setItemNote(folderItemSetItemNoteRequest: FolderItemSetItemNoteRequest): Promise<void> {
    const url = this.hostname + this.pathPrefix + "SetItemNote";
    const body: FolderItemSetItemNoteRequestJSON = FolderItemSetItemNoteRequestToJSON(folderItemSetItemNoteRequest);
    await sendTwirpRequest(url, body);
  }

  async mapViewSettings(folderItemMapViewSettingsRequest: FolderItemMapViewSettingsRequest): Promise<FolderItemMapViewSettingsResponse> {
    const url = this.hostname + this.pathPrefix + "MapViewSettings";
    const body: FolderItemMapViewSettingsRequestJSON = FolderItemMapViewSettingsRequestToJSON(folderItemMapViewSettingsRequest);
    const data = await sendTwirpRequest(url, body);
    return JSONToFolderItemMapViewSettingsResponse(data);
  }
}
