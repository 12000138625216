// @flow strict
// Copyright (C) 2018-2022 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { makeAutoObservable, runInAction } from "mobx";
import type { Folder, FolderItem } from "../../rpc/model";
import { IReactionDisposer } from 'mobx';
import { FolderService } from '../../rpc/folders';
import { apiEndpoint } from '../../utils/http';

export class SearchState {
  query: string;
  filter: string;
  searching: boolean;
  folders: Array<Folder>;
  folderItems: Array<FolderItem>;
  disposes: (typeof IReactionDisposer)[];

  constructor() {
    this.query = '';
    const searchFilter = window.localStorage.getItem("search.filter");
    this.filter = searchFilter ? searchFilter : 'all';
    this.searching = false;
    this.folders = [];
    this.folderItems = [];
    this.disposes = [];

    makeAutoObservable(this, {
      disposes: false
    }, { autoBind: true });
  }

  dispose() {
    this.disposes.forEach(dispose => dispose());
  }

  async search() {
    if (!this.query) {
      return;
    }

    window.localStorage.setItem("search.filter", this.filter);
    this.searching = true;
    const resp = await new FolderService(apiEndpoint()).search({ query: this.query, filter: this.filter });
    runInAction(() => {
      this.folders = resp.folders;
      this.folderItems = resp.folderItems;
      this.searching = false;
    });
  }
}
