// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Point } from "./rpc/model";

export type ArrowOrientation = "n" | "ne" | "e" | "se" | "s" | "sw" | "w" | "nw";

// Live Label info (used by react-region-select)
export type LiveLabel = {
  id: ?string,
  itemId: ?string,
  userId: ?string,
  my: ?boolean,
  labelTypeId: number,
  note: string,
  x: number,
  y: number,
  width: number,
  height: number,
  prevX: number,
  prevY: number,
  prevWidth: number,
  prevHeight: number,
  startFrame: number,
  endFrame: number,
  additionalInfo: string,
  points: Array<Point>,
  startPosition: number,
  endPosition: number,
  isRectangleOrLine: boolean,
  new: boolean,
  isChanging: boolean,
  data: {
    regionStyle?: { [string]: string }
  },
  start?: Point,
}

export const FolderKinds = {
  root: "root",
  org: "org",
  project: "project",
  sdeMaster: "sde-master",
  sde: "sde",
  sdeItem: "sde-item",
  sdeFrames: "sde-frames",
  sdeRetrain: "sde-retrain",
};

export type FolderKind = $Values<typeof FolderKinds>;

export const Features = {
  downloadFiles: "download-files",
  downloadPDF: "download-pdf",
  exportLabels: "export-labels",
  importLabels: "import-labels",
};

export type ImagePdfOptions = {
  image_id: string,
  include_image: boolean,
}

// PDF Options
export type FolderPdfOptions = {
  folder_id: string,
  title_text: string,
  title_image_id: string,
  title_image_file: ?File,
  image_options: Array<ImagePdfOptions>,
}

export function isClassificationLabel(lbl: LiveLabel): boolean {
  return lbl.points != null && lbl.points.length === 0;
}

export function isDetectionLabel(lbl: LiveLabel): boolean {
  return !isClassificationLabel(lbl);
}

export type InvalidateMessage = {
  folderId: string,
}

export const LabelTypes = {
  common: "common",
  arrow: "arrow",
  line: "line",
  obsolete: "obsolete",
};
