// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from "react";
import type { Node } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Sidebar.module.css";
import HeaderLinks from "../Header/HeaderLinks.js";
import logo from "../../assets/img/reactlogo.png";
import type { DashboardRoute } from "../../routes/dashboard";
import ToggleButton from "react-toggle-button";
import type AppState from '../../AppState';
import { observer } from 'mobx-react-lite';

type Props = {
  location: {
    pathname: string,
  },
  appState: AppState,
  logo_text: string,
  logo_url: string,
  dashboardRoutes: Array<DashboardRoute>,
  onTreeParentClicked: (treeJson: DashboardRoute) => void,
  openedSidebars: Array<string>,
  currentTheme: string,
  isDrawerOpen: boolean,
  toggleDarkTheme: () => void,
  showHelpDialog: () => void,
}

function Sidebar(props: Props): Node {
  const [width, setWidth] = useState<number>(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  function updateDimensions() {
    setWidth(window.innerWidth);
  }

  function onTreeParentClicked(treeJson) {
    props.onTreeParentClicked(treeJson);
  }

  function renderTree(treeJson: Array<DashboardRoute>) {
    return treeJson.map((prop, key) => {
      if (!prop.redirect) {
        if (!prop.path) {
          return <li key={key}>
            <a
              // to={prop.redirect}
              className="nav-link"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (prop.onClick)
                  prop.onClick();
                onTreeParentClicked(prop);
              }}
              href="/"
            >
              <i className={prop.icon} />
              <p className={styles.node}>{prop.name}</p>
            </a>
            {
              prop.children && props.openedSidebars.indexOf(prop.id) !== -1 &&
              <ul className="nav sub-nav">{renderTree(prop.children)}
              </ul>
            }
          </li>;
        }

        return (
          <li
            className={activeRoute(prop.path)}
            key={key}
          >
            <NavLink
              to={prop.path}
              className="nav-link"
              activeclassname="active"
              onClick={() => {
                if (prop.onClick)
                  prop.onClick();
                onTreeParentClicked(prop);
              }}
            >
              <i className={prop.icon} />
              <p className={styles.node}>{prop.name}</p>
            </NavLink>
            {
              prop.children && props.openedSidebars.indexOf(prop.id) !== -1 &&
              <ul className="nav sub-nav">{renderTree(prop.children)}
              </ul>
            }
          </li>
        );
      } else {
        return (
          <li key={key}>
            <a
              // to={prop.redirect}
              className="nav-link"
              target="_blank"
              rel="noopener noreferrer"
              onClick={prop.onClick}
              href={prop.redirect}
            >
              <i className={prop.icon} />
              <p className={styles.node}>{prop.name}</p>
            </a>
          </li>
        );
      }
    });
  }

  return (
    <div>
      <div
        id="sidebar"
        className={props.isDrawerOpen ? "sidebar" : "closeDrawer"}
        data-color="black"
      >
        <div>
          <div className="sidebar-background" />
          <div className="logo">
            <div
              className="simple-text logo-mini"
            >
              <div className="logo-img">
                <img src={props.logo_url || logo} alt="logo_image" />
              </div>
            </div>
            <a
              href="/"
              className="simple-text logo-normal"
            >
              {props.logo_text}
              {/* Image Labeling */}
            </a>
          </div>
          <div className="sidebar-wrapper">
            <ul className="nav">
              {width <= 991 ? <HeaderLinks appState={props.appState} showHelpDialog={props.showHelpDialog} /> : null}
              {
                renderTree(props.dashboardRoutes)
              }
              <li
                // key={key}
              >
                <div
                  className="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: "flex", flexDirection: "row",
                    alignItems: "center", padding: 10, marginLeft: 10
                  }}>
                  <p>Dark Theme</p>
                  <ToggleButton
                    inactiveLabel=""
                    activeLabel=""
                    containerStyle={{ width: "40px", marginLeft: 5 }}
                    trackStyle={{ width: "40px", height: "25px" }}
                    thumbAnimateRange={[1, 22]}
                    value={props.currentTheme === 'dark'}
                    onToggle={() => props.toggleDarkTheme()} />

                </div>
              </li>
              <li>

              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default (observer(Sidebar): typeof Sidebar);
