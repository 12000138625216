// @flow strict
/* eslint-disable */
import { sendTwirpRequest } from "./twirp";

export type Empty = {
}

export type EmptyJSON = {
}

export function EmptyToJSON(m: Empty): EmptyJSON {
  return {
  };
}

export function JSONToEmpty(m: EmptyJSON): Empty {
  return {
  };
}

export type User = {
  id: string;
  login: string;
  displayName: string;
  email: string;
  tierId: number;
  isAdmin: boolean;
  me: boolean;
  features: Array<string>;
  profileId: string;
  phone: string;
}

export type UserJSON = {
  id?: string;
  login?: string;
  display_name?: string;
  email?: string;
  tier_id?: number;
  is_admin?: boolean;
  me?: boolean;
  features?: Array<string>;
  profile_id?: string;
  phone?: string;
}

export function UserToJSON(m: User): UserJSON {
  return {
    id: m.id,
    login: m.login,
    display_name: m.displayName,
    email: m.email,
    tier_id: m.tierId,
    is_admin: m.isAdmin,
    me: m.me,
    features: m.features,
    profile_id: m.profileId,
    phone: m.phone,
  };
}

export function JSONToUser(m: UserJSON): User {
  return {
    id: m.id != null ? m.id : "",
    login: m.login != null ? m.login : "",
    displayName: m.display_name != null ? m.display_name : "",
    email: m.email != null ? m.email : "",
    tierId: m.tier_id != null ? m.tier_id : 0,
    isAdmin: m.is_admin != null ? m.is_admin : false,
    me: m.me != null ? m.me : false,
    features: m.features != null ? m.features : [],
    profileId: m.profile_id != null ? m.profile_id : "",
    phone: m.phone != null ? m.phone : "",
  };
}

export type FolderRight = {
  userId: string;
  userDisplayName: string;
  permission: string;
  isInherited: boolean;
}

export type FolderRightJSON = {
  user_id?: string;
  user_display_name?: string;
  permission?: string;
  is_inherited?: boolean;
}

export function FolderRightToJSON(m: FolderRight): FolderRightJSON {
  return {
    user_id: m.userId,
    user_display_name: m.userDisplayName,
    permission: m.permission,
    is_inherited: m.isInherited,
  };
}

export function JSONToFolderRight(m: FolderRightJSON): FolderRight {
  return {
    userId: m.user_id != null ? m.user_id : "",
    userDisplayName: m.user_display_name != null ? m.user_display_name : "",
    permission: m.permission != null ? m.permission : "",
    isInherited: m.is_inherited != null ? m.is_inherited : false,
  };
}

export type FolderItem = {
  id: string;
  name: string;
  folderId: string;
  thumbnailId: string;
  width: number;
  height: number;
  title: string;
  sortIndex: number;
  kind: string;
  mime: string;
  childItemKinds: Array<string>;
  positionX: number;
  positionY: number;
  positionZ: number;
  positionT: string;
  sourceItemId: string;
  virtualId: string;
  createdAt: string;
}

export type FolderItemJSON = {
  id?: string;
  name?: string;
  folder_id?: string;
  thumbnail_id?: string;
  width?: number;
  height?: number;
  title?: string;
  sort_index?: number;
  kind?: string;
  mime?: string;
  child_item_kinds?: Array<string>;
  position_x?: number;
  position_y?: number;
  position_z?: number;
  position_t?: string;
  source_item_id?: string;
  virtual_id?: string;
  created_at?: string;
}

export function FolderItemToJSON(m: FolderItem): FolderItemJSON {
  return {
    id: m.id,
    name: m.name,
    folder_id: m.folderId,
    thumbnail_id: m.thumbnailId,
    width: m.width,
    height: m.height,
    title: m.title,
    sort_index: m.sortIndex,
    kind: m.kind,
    mime: m.mime,
    child_item_kinds: m.childItemKinds,
    position_x: m.positionX,
    position_y: m.positionY,
    position_z: m.positionZ,
    position_t: m.positionT,
    source_item_id: m.sourceItemId,
    virtual_id: m.virtualId,
    created_at: m.createdAt,
  };
}

export function JSONToFolderItem(m: FolderItemJSON): FolderItem {
  return {
    id: m.id != null ? m.id : "",
    name: m.name != null ? m.name : "",
    folderId: m.folder_id != null ? m.folder_id : "",
    thumbnailId: m.thumbnail_id != null ? m.thumbnail_id : "",
    width: m.width != null ? m.width : 0,
    height: m.height != null ? m.height : 0,
    title: m.title != null ? m.title : "",
    sortIndex: m.sort_index != null ? m.sort_index : 0,
    kind: m.kind != null ? m.kind : "",
    mime: m.mime != null ? m.mime : "",
    childItemKinds: m.child_item_kinds != null ? m.child_item_kinds : [],
    positionX: m.position_x != null ? m.position_x : 0,
    positionY: m.position_y != null ? m.position_y : 0,
    positionZ: m.position_z != null ? m.position_z : 0,
    positionT: m.position_t != null ? m.position_t : "",
    sourceItemId: m.source_item_id != null ? m.source_item_id : "",
    virtualId: m.virtual_id != null ? m.virtual_id : "",
    createdAt: m.created_at != null ? m.created_at : "",
  };
}

export type Point = {
  x: number;
  y: number;
}

export type PointJSON = {
  x?: number;
  y?: number;
}

export function PointToJSON(m: Point): PointJSON {
  return {
    x: m.x,
    y: m.y,
  };
}

export function JSONToPoint(m: PointJSON): Point {
  return {
    x: m.x != null ? m.x : 0,
    y: m.y != null ? m.y : 0,
  };
}

export type Label = {
  id: string;
  itemId: string;
  userId: string;
  my: boolean;
  labelTypeId: number;
  note: string;
  left: number;
  top: number;
  right: number;
  bottom: number;
  startFrame: number;
  endFrame: number;
  startPosition: number;
  endPosition: number;
  additionalInfo: string;
  points: Array<Point>;
}

export type LabelJSON = {
  id?: string;
  item_id?: string;
  user_id?: string;
  my?: boolean;
  label_type_id?: number;
  note?: string;
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
  start_frame?: number;
  end_frame?: number;
  start_position?: number;
  end_position?: number;
  additional_info?: string;
  points?: Array<PointJSON>;
}

export function LabelToJSON(m: Label): LabelJSON {
  return {
    id: m.id,
    item_id: m.itemId,
    user_id: m.userId,
    my: m.my,
    label_type_id: m.labelTypeId,
    note: m.note,
    left: m.left,
    top: m.top,
    right: m.right,
    bottom: m.bottom,
    start_frame: m.startFrame,
    end_frame: m.endFrame,
    start_position: m.startPosition,
    end_position: m.endPosition,
    additional_info: m.additionalInfo,
    points: m.points.map(PointToJSON),
  };
}

export function JSONToLabel(m: LabelJSON): Label {
  return {
    id: m.id != null ? m.id : "",
    itemId: m.item_id != null ? m.item_id : "",
    userId: m.user_id != null ? m.user_id : "",
    my: m.my != null ? m.my : false,
    labelTypeId: m.label_type_id != null ? m.label_type_id : 0,
    note: m.note != null ? m.note : "",
    left: m.left != null ? m.left : 0,
    top: m.top != null ? m.top : 0,
    right: m.right != null ? m.right : 0,
    bottom: m.bottom != null ? m.bottom : 0,
    startFrame: m.start_frame != null ? m.start_frame : 0,
    endFrame: m.end_frame != null ? m.end_frame : 0,
    startPosition: m.start_position != null ? m.start_position : 0,
    endPosition: m.end_position != null ? m.end_position : 0,
    additionalInfo: m.additional_info != null ? m.additional_info : "",
    points: m.points != null ? m.points.map(JSONToPoint) : [],
  };
}

export type Position = {
  x: number;
  y: number;
  z: number;
  t: string;
}

export type PositionJSON = {
  x?: number;
  y?: number;
  z?: number;
  t?: string;
}

export function PositionToJSON(m: Position): PositionJSON {
  return {
    x: m.x,
    y: m.y,
    z: m.z,
    t: m.t,
  };
}

export function JSONToPosition(m: PositionJSON): Position {
  return {
    x: m.x != null ? m.x : 0,
    y: m.y != null ? m.y : 0,
    z: m.z != null ? m.z : 0,
    t: m.t != null ? m.t : "",
  };
}

export type Folder = {
  id: string;
  name: string;
  isShared: boolean;
  userId: string;
  title: string;
  forMobileApi: boolean;
  parentId: string;
  itemId: string;
  sortIndex: number;
  mime: string;
  kind: string;
  isReadonly: boolean;
  children: Array<Folder>;
  ancestors: Array<Folder>;
  isAutoCreated: boolean;
  virtualId: string;
  virtualParentId: string;
  createdAt: string;
  openIn: string;
  usedAt: string;
  updatedAt: string;
}

export type FolderJSON = {
  id?: string;
  name?: string;
  is_shared?: boolean;
  user_id?: string;
  title?: string;
  for_mobile_api?: boolean;
  parent_id?: string;
  item_id?: string;
  sort_index?: number;
  mime?: string;
  kind?: string;
  is_readonly?: boolean;
  children?: Array<FolderJSON>;
  ancestors?: Array<FolderJSON>;
  is_auto_created?: boolean;
  virtual_id?: string;
  virtual_parent_id?: string;
  created_at?: string;
  open_in?: string;
  used_at?: string;
  updated_at?: string;
}

export function FolderToJSON(m: Folder): FolderJSON {
  return {
    id: m.id,
    name: m.name,
    is_shared: m.isShared,
    user_id: m.userId,
    title: m.title,
    for_mobile_api: m.forMobileApi,
    parent_id: m.parentId,
    item_id: m.itemId,
    sort_index: m.sortIndex,
    mime: m.mime,
    kind: m.kind,
    is_readonly: m.isReadonly,
    children: m.children.map(FolderToJSON),
    ancestors: m.ancestors.map(FolderToJSON),
    is_auto_created: m.isAutoCreated,
    virtual_id: m.virtualId,
    virtual_parent_id: m.virtualParentId,
    created_at: m.createdAt,
    open_in: m.openIn,
    used_at: m.usedAt,
    updated_at: m.updatedAt,
  };
}

export function JSONToFolder(m: FolderJSON): Folder {
  return {
    id: m.id != null ? m.id : "",
    name: m.name != null ? m.name : "",
    isShared: m.is_shared != null ? m.is_shared : false,
    userId: m.user_id != null ? m.user_id : "",
    title: m.title != null ? m.title : "",
    forMobileApi: m.for_mobile_api != null ? m.for_mobile_api : false,
    parentId: m.parent_id != null ? m.parent_id : "",
    itemId: m.item_id != null ? m.item_id : "",
    sortIndex: m.sort_index != null ? m.sort_index : 0,
    mime: m.mime != null ? m.mime : "",
    kind: m.kind != null ? m.kind : "",
    isReadonly: m.is_readonly != null ? m.is_readonly : false,
    children: m.children != null ? m.children.map(JSONToFolder) : [],
    ancestors: m.ancestors != null ? m.ancestors.map(JSONToFolder) : [],
    isAutoCreated: m.is_auto_created != null ? m.is_auto_created : false,
    virtualId: m.virtual_id != null ? m.virtual_id : "",
    virtualParentId: m.virtual_parent_id != null ? m.virtual_parent_id : "",
    createdAt: m.created_at != null ? m.created_at : "",
    openIn: m.open_in != null ? m.open_in : "",
    usedAt: m.used_at != null ? m.used_at : "",
    updatedAt: m.updated_at != null ? m.updated_at : "",
  };
}

export type Setting = {
  name: string;
  value: string;
}

export type SettingJSON = {
  name?: string;
  value?: string;
}

export function SettingToJSON(m: Setting): SettingJSON {
  return {
    name: m.name,
    value: m.value,
  };
}

export function JSONToSetting(m: SettingJSON): Setting {
  return {
    name: m.name != null ? m.name : "",
    value: m.value != null ? m.value : "",
  };
}

export type LabelType = {
  id: number;
  name: string;
  color: string;
  labelType: string;
}

export type LabelTypeJSON = {
  id?: number;
  name?: string;
  color?: string;
  label_type?: string;
}

export function LabelTypeToJSON(m: LabelType): LabelTypeJSON {
  return {
    id: m.id,
    name: m.name,
    color: m.color,
    label_type: m.labelType,
  };
}

export function JSONToLabelType(m: LabelTypeJSON): LabelType {
  return {
    id: m.id != null ? m.id : 0,
    name: m.name != null ? m.name : "",
    color: m.color != null ? m.color : "",
    labelType: m.label_type != null ? m.label_type : "",
  };
}

export type DetectEngineParameter = {
  name: string;
  title: string;
  parameterType: string;
  defaultValue: string;
  supportedFormats: Array<string>;
}

export type DetectEngineParameterJSON = {
  name?: string;
  title?: string;
  parameter_type?: string;
  default_value?: string;
  supported_formats?: Array<string>;
}

export function DetectEngineParameterToJSON(m: DetectEngineParameter): DetectEngineParameterJSON {
  return {
    name: m.name,
    title: m.title,
    parameter_type: m.parameterType,
    default_value: m.defaultValue,
    supported_formats: m.supportedFormats,
  };
}

export function JSONToDetectEngineParameter(m: DetectEngineParameterJSON): DetectEngineParameter {
  return {
    name: m.name != null ? m.name : "",
    title: m.title != null ? m.title : "",
    parameterType: m.parameter_type != null ? m.parameter_type : "",
    defaultValue: m.default_value != null ? m.default_value : "",
    supportedFormats: m.supported_formats != null ? m.supported_formats : [],
  };
}

export type DetectEngine = {
  name: string;
  title: string;
  abbreviation: string;
  color: string;
  retrainServerUrl: string;
  supportedFormats: Array<string>;
  parameters: Array<DetectEngineParameter>;
  version: string;
  group: string;
}

export type DetectEngineJSON = {
  name?: string;
  title?: string;
  abbreviation?: string;
  color?: string;
  retrain_server_url?: string;
  supported_formats?: Array<string>;
  parameters?: Array<DetectEngineParameterJSON>;
  version?: string;
  group?: string;
}

export function DetectEngineToJSON(m: DetectEngine): DetectEngineJSON {
  return {
    name: m.name,
    title: m.title,
    abbreviation: m.abbreviation,
    color: m.color,
    retrain_server_url: m.retrainServerUrl,
    supported_formats: m.supportedFormats,
    parameters: m.parameters.map(DetectEngineParameterToJSON),
    version: m.version,
    group: m.group,
  };
}

export function JSONToDetectEngine(m: DetectEngineJSON): DetectEngine {
  return {
    name: m.name != null ? m.name : "",
    title: m.title != null ? m.title : "",
    abbreviation: m.abbreviation != null ? m.abbreviation : "",
    color: m.color != null ? m.color : "",
    retrainServerUrl: m.retrain_server_url != null ? m.retrain_server_url : "",
    supportedFormats: m.supported_formats != null ? m.supported_formats : [],
    parameters: m.parameters != null ? m.parameters.map(JSONToDetectEngineParameter) : [],
    version: m.version != null ? m.version : "",
    group: m.group != null ? m.group : "",
  };
}

export type DetectEngineRight = {
  engineId: string;
  userId: string;
}

export type DetectEngineRightJSON = {
  engine_id?: string;
  user_id?: string;
}

export function DetectEngineRightToJSON(m: DetectEngineRight): DetectEngineRightJSON {
  return {
    engine_id: m.engineId,
    user_id: m.userId,
  };
}

export function JSONToDetectEngineRight(m: DetectEngineRightJSON): DetectEngineRight {
  return {
    engineId: m.engine_id != null ? m.engine_id : "",
    userId: m.user_id != null ? m.user_id : "",
  };
}

export type Feature = {
  id: string;
  title: string;
}

export type FeatureJSON = {
  id?: string;
  title?: string;
}

export function FeatureToJSON(m: Feature): FeatureJSON {
  return {
    id: m.id,
    title: m.title,
  };
}

export function JSONToFeature(m: FeatureJSON): Feature {
  return {
    id: m.id != null ? m.id : "",
    title: m.title != null ? m.title : "",
  };
}

export type Tier = {
  id: number;
  internalName: string;
  title: string;
}

export type TierJSON = {
  id?: number;
  internal_name?: string;
  title?: string;
}

export function TierToJSON(m: Tier): TierJSON {
  return {
    id: m.id,
    internal_name: m.internalName,
    title: m.title,
  };
}

export function JSONToTier(m: TierJSON): Tier {
  return {
    id: m.id != null ? m.id : 0,
    internalName: m.internal_name != null ? m.internal_name : "",
    title: m.title != null ? m.title : "",
  };
}

export type TierFeature = {
  id: number;
  tierId: number;
  featureId: string;
}

export type TierFeatureJSON = {
  id?: number;
  tier_id?: number;
  feature_id?: string;
}

export function TierFeatureToJSON(m: TierFeature): TierFeatureJSON {
  return {
    id: m.id,
    tier_id: m.tierId,
    feature_id: m.featureId,
  };
}

export function JSONToTierFeature(m: TierFeatureJSON): TierFeature {
  return {
    id: m.id != null ? m.id : 0,
    tierId: m.tier_id != null ? m.tier_id : 0,
    featureId: m.feature_id != null ? m.feature_id : "",
  };
}

export type StorefrontApp = {
  id: number;
  category: string;
  title: string;
  description: string;
  visible: boolean;
  imageId: string;
  imageUrl: string;
  appUrl: string;
  sortIndex: number;
  infoUrl: string;
}

export type StorefrontAppJSON = {
  id?: number;
  category?: string;
  title?: string;
  description?: string;
  visible?: boolean;
  image_id?: string;
  image_url?: string;
  app_url?: string;
  sort_index?: number;
  info_url?: string;
}

export function StorefrontAppToJSON(m: StorefrontApp): StorefrontAppJSON {
  return {
    id: m.id,
    category: m.category,
    title: m.title,
    description: m.description,
    visible: m.visible,
    image_id: m.imageId,
    image_url: m.imageUrl,
    app_url: m.appUrl,
    sort_index: m.sortIndex,
    info_url: m.infoUrl,
  };
}

export function JSONToStorefrontApp(m: StorefrontAppJSON): StorefrontApp {
  return {
    id: m.id != null ? m.id : 0,
    category: m.category != null ? m.category : "",
    title: m.title != null ? m.title : "",
    description: m.description != null ? m.description : "",
    visible: m.visible != null ? m.visible : false,
    imageId: m.image_id != null ? m.image_id : "",
    imageUrl: m.image_url != null ? m.image_url : "",
    appUrl: m.app_url != null ? m.app_url : "",
    sortIndex: m.sort_index != null ? m.sort_index : 0,
    infoUrl: m.info_url != null ? m.info_url : "",
  };
}

export type ServerVersion = {
  commitDate: number;
  commitAuthor: string;
  commitHash: string;
  commitMessage: string;
}

export type ServerVersionJSON = {
  commit_date?: number;
  commit_author?: string;
  commit_hash?: string;
  commit_message?: string;
}

export function ServerVersionToJSON(m: ServerVersion): ServerVersionJSON {
  return {
    commit_date: m.commitDate,
    commit_author: m.commitAuthor,
    commit_hash: m.commitHash,
    commit_message: m.commitMessage,
  };
}

export function JSONToServerVersion(m: ServerVersionJSON): ServerVersion {
  return {
    commitDate: m.commit_date != null ? m.commit_date : 0,
    commitAuthor: m.commit_author != null ? m.commit_author : "",
    commitHash: m.commit_hash != null ? m.commit_hash : "",
    commitMessage: m.commit_message != null ? m.commit_message : "",
  };
}

export type PDFControl = {
  id: string;
  text: string;
  controlType: string;
}

export type PDFControlJSON = {
  id?: string;
  text?: string;
  control_type?: string;
}

export function PDFControlToJSON(m: PDFControl): PDFControlJSON {
  return {
    id: m.id,
    text: m.text,
    control_type: m.controlType,
  };
}

export function JSONToPDFControl(m: PDFControlJSON): PDFControl {
  return {
    id: m.id != null ? m.id : "",
    text: m.text != null ? m.text : "",
    controlType: m.control_type != null ? m.control_type : "",
  };
}

export type PDFLine = {
  controls: Array<PDFControl>;
}

export type PDFLineJSON = {
  controls?: Array<PDFControlJSON>;
}

export function PDFLineToJSON(m: PDFLine): PDFLineJSON {
  return {
    controls: m.controls.map(PDFControlToJSON),
  };
}

export function JSONToPDFLine(m: PDFLineJSON): PDFLine {
  return {
    controls: m.controls != null ? m.controls.map(JSONToPDFControl) : [],
  };
}

export type PDFTemplate = {
  id: number;
  title: string;
  template: string;
  parsedTemplate: Array<PDFLine>;
}

export type PDFTemplateJSON = {
  id?: number;
  title?: string;
  template?: string;
  parsed_template?: Array<PDFLineJSON>;
}

export function PDFTemplateToJSON(m: PDFTemplate): PDFTemplateJSON {
  return {
    id: m.id,
    title: m.title,
    template: m.template,
    parsed_template: m.parsedTemplate.map(PDFLineToJSON),
  };
}

export function JSONToPDFTemplate(m: PDFTemplateJSON): PDFTemplate {
  return {
    id: m.id != null ? m.id : 0,
    title: m.title != null ? m.title : "",
    template: m.template != null ? m.template : "",
    parsedTemplate: m.parsed_template != null ? m.parsed_template.map(JSONToPDFLine) : [],
  };
}

export type FolderPDFTemplate = {
  folderId: string;
  templateId: number;
  values: string;
}

export type FolderPDFTemplateJSON = {
  folder_id?: string;
  template_id?: number;
  values?: string;
}

export function FolderPDFTemplateToJSON(m: FolderPDFTemplate): FolderPDFTemplateJSON {
  return {
    folder_id: m.folderId,
    template_id: m.templateId,
    values: m.values,
  };
}

export function JSONToFolderPDFTemplate(m: FolderPDFTemplateJSON): FolderPDFTemplate {
  return {
    folderId: m.folder_id != null ? m.folder_id : "",
    templateId: m.template_id != null ? m.template_id : 0,
    values: m.values != null ? m.values : "",
  };
}

export type StorefrontAppRight = {
  appId: number;
  userId: string;
  permission: string;
}

export type StorefrontAppRightJSON = {
  app_id?: number;
  user_id?: string;
  permission?: string;
}

export function StorefrontAppRightToJSON(m: StorefrontAppRight): StorefrontAppRightJSON {
  return {
    app_id: m.appId,
    user_id: m.userId,
    permission: m.permission,
  };
}

export function JSONToStorefrontAppRight(m: StorefrontAppRightJSON): StorefrontAppRight {
  return {
    appId: m.app_id != null ? m.app_id : 0,
    userId: m.user_id != null ? m.user_id : "",
    permission: m.permission != null ? m.permission : "",
  };
}

export type FolderNote = {
  id: string;
  folderId: string;
  user: ?User;
  note: string;
  createdAt: string;
  updatedAt: string;
}

export type FolderNoteJSON = {
  id?: string;
  folder_id?: string;
  user?: UserJSON;
  note?: string;
  created_at?: string;
  updated_at?: string;
}

export function FolderNoteToJSON(m: FolderNote): FolderNoteJSON {
  return {
    id: m.id,
    folder_id: m.folderId,
    user: m.user != null ? UserToJSON(m.user) : undefined,
    note: m.note,
    created_at: m.createdAt,
    updated_at: m.updatedAt,
  };
}

export function JSONToFolderNote(m: FolderNoteJSON): FolderNote {
  return {
    id: m.id != null ? m.id : "",
    folderId: m.folder_id != null ? m.folder_id : "",
    user: m.user != null ? JSONToUser(m.user) : null,
    note: m.note != null ? m.note : "",
    createdAt: m.created_at != null ? m.created_at : "",
    updatedAt: m.updated_at != null ? m.updated_at : "",
  };
}

export type FolderItemNote = {
  id: string;
  itemId: string;
  user: ?User;
  note: string;
  whom: ?User;
  createdAt: string;
  updatedAt: string;
}

export type FolderItemNoteJSON = {
  id?: string;
  item_id?: string;
  user?: UserJSON;
  note?: string;
  whom?: UserJSON;
  created_at?: string;
  updated_at?: string;
}

export function FolderItemNoteToJSON(m: FolderItemNote): FolderItemNoteJSON {
  return {
    id: m.id,
    item_id: m.itemId,
    user: m.user != null ? UserToJSON(m.user) : undefined,
    note: m.note,
    whom: m.whom != null ? UserToJSON(m.whom) : undefined,
    created_at: m.createdAt,
    updated_at: m.updatedAt,
  };
}

export function JSONToFolderItemNote(m: FolderItemNoteJSON): FolderItemNote {
  return {
    id: m.id != null ? m.id : "",
    itemId: m.item_id != null ? m.item_id : "",
    user: m.user != null ? JSONToUser(m.user) : null,
    note: m.note != null ? m.note : "",
    whom: m.whom != null ? JSONToUser(m.whom) : null,
    createdAt: m.created_at != null ? m.created_at : "",
    updatedAt: m.updated_at != null ? m.updated_at : "",
  };
}

export type UserNote = {
  id: string;
  userId: string;
  fromId: string;
  note: string;
  createdAt: string;
  updatedAt: string;
  readAt: string;
}

export type UserNoteJSON = {
  id?: string;
  user_id?: string;
  from_id?: string;
  note?: string;
  created_at?: string;
  updated_at?: string;
  read_at?: string;
}

export function UserNoteToJSON(m: UserNote): UserNoteJSON {
  return {
    id: m.id,
    user_id: m.userId,
    from_id: m.fromId,
    note: m.note,
    created_at: m.createdAt,
    updated_at: m.updatedAt,
    read_at: m.readAt,
  };
}

export function JSONToUserNote(m: UserNoteJSON): UserNote {
  return {
    id: m.id != null ? m.id : "",
    userId: m.user_id != null ? m.user_id : "",
    fromId: m.from_id != null ? m.from_id : "",
    note: m.note != null ? m.note : "",
    createdAt: m.created_at != null ? m.created_at : "",
    updatedAt: m.updated_at != null ? m.updated_at : "",
    readAt: m.read_at != null ? m.read_at : "",
  };
}

export type ApiKey = {
  id: string;
  appName: string;
  key: string;
  secret: string;
  note1: string;
  note2: string;
  disabled: boolean;
}

export type ApiKeyJSON = {
  id?: string;
  app_name?: string;
  key?: string;
  secret?: string;
  note1?: string;
  note2?: string;
  disabled?: boolean;
}

export function ApiKeyToJSON(m: ApiKey): ApiKeyJSON {
  return {
    id: m.id,
    app_name: m.appName,
    key: m.key,
    secret: m.secret,
    note1: m.note1,
    note2: m.note2,
    disabled: m.disabled,
  };
}

export function JSONToApiKey(m: ApiKeyJSON): ApiKey {
  return {
    id: m.id != null ? m.id : "",
    appName: m.app_name != null ? m.app_name : "",
    key: m.key != null ? m.key : "",
    secret: m.secret != null ? m.secret : "",
    note1: m.note1 != null ? m.note1 : "",
    note2: m.note2 != null ? m.note2 : "",
    disabled: m.disabled != null ? m.disabled : false,
  };
}

