// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useState } from "react";
import type { Node } from "react";
import styles from "./ApiKeyForm.module.css";
import { Button, ButtonGroup, Col, ControlLabel, Form, FormControl, FormGroup } from "react-bootstrap";
import type { ApiKey } from "../../rpc/model";

type ApiKeyFormProps = {
  apiKey: ApiKey,
  applyChanges: (key: ApiKey) => Promise<void>;
  close: () => void;
}

export default function ApiKeyForm(props: ApiKeyFormProps): Node {
  const [key, setKey] = useState<ApiKey>(props.apiKey);
  const [dirty, setDirty] = useState<boolean>(false);

  async function onSaveChangesClick() {
    await props.applyChanges(key);
    props.close();
  }

  function onCancelClick() {
    props.close();
  }

  function onAppNameChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setKey({ ...key, appName: e.currentTarget.value });
    setDirty(true);
  }

  function onNote1Changed(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setKey({ ...key, note1: e.currentTarget.value });
    setDirty(true);
  }

  function onNote2Changed(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setKey({ ...key, note2: e.currentTarget.value });
    setDirty(true);
  }

  return <div className={styles.root}>
    <Form horizontal>
      <FormGroup controlId="appName">
        <Col componentClass={ControlLabel} sm={3}>
          Application
        </Col>
        <Col sm={9}>
          <FormControl
            placeholder="application name"
            value={key.appName}
            onChange={onAppNameChanged}
            autoFocus={true}
          />
        </Col>
      </FormGroup>
      <FormGroup controlId="note1">
        <Col componentClass={ControlLabel} sm={3}>
          Template
        </Col>
        <Col sm={9}>
          <FormControl
            value={key.note1}
            onChange={onNote1Changed}
          />
        </Col>
      </FormGroup>
      <FormGroup controlId="note2">
        <Col componentClass={ControlLabel} sm={3}>
          Template
        </Col>
        <Col sm={9}>
          <FormControl
            value={key.note2}
            onChange={onNote2Changed}
          />
        </Col>
      </FormGroup>
    </Form>
    <ButtonGroup className={styles.buttons}>
      <Button bsStyle="info" onClick={onSaveChangesClick} disabled={!dirty || key.appName === ''}>
        Save Changes
      </Button>
      <Button bsStyle="warning" onClick={onCancelClick}>
        Cancel
      </Button>
    </ButtonGroup>
  </div>;
}
