//@flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useState } from 'react';
import type { Node } from "react";
import { NavLink } from 'react-router-dom';
import Button from '../Button/Button';
import Modal from 'react-modal';
import styles from "./Folder.module.css";
import type { Folder, User } from "../../rpc/model";
import FolderRightsView from "../folder-rights/FolderRights";
import ReactTooltip from 'react-tooltip';
import FolderNotesView from './FolderNotesView';
import { FolderKinds } from '../../model';

const rainbowColors = [
  '#AA66CC', //Violet
  '#8F59FB', //INDIGO
  '#33B5E5', //BLUE
  '#99CC00', //GREEN
  '#E7D002', //YELLOW
  '#FFBB33', //ORANGE
  '#FF4444', //RED
];

type Props = {
  folder: Folder,
  user: User,
  folderIndex: number,
  onFolderNameClick: (e: SyntheticMouseEvent<HTMLElement>, f: Folder) => Promise<void>,
  onClick?: () => void,
};

const isDarkTheme = localStorage.getItem('isDarkTheme') === "dark";
const modalStyle = {
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.5)"
  },
  content: {
    top: "10%",
    right: "50%",
    width: "70%",
    marginRight: "-70%",
    transform: "translate(-50%, 0%)",
    maxHeight: "80vh",
    backgroundColor: isDarkTheme ? "rgb(20,20,20)" : 'white',
    color: isDarkTheme ? '#f5deb3' : 'undefined',
  }
};

export default function FolderToRender(props: Props): Node {
  const [showFolderRights, setShowFolderRights] = useState<boolean>(false);
  const [showFolderNotes, setShowFolderNotes] = useState<boolean>(false);
  const { folder, user, folderIndex, onFolderNameClick } = props;

  let tooltip = `Created: ${new Date(Date.parse(props.folder.createdAt)).toLocaleString()}`;
  if (props.folder.usedAt) {
    tooltip += `<br>Last Accessed: ${new Date(Date.parse(props.folder.usedAt)).toLocaleString()}`;
  }
  if (props.folder.updatedAt) {
    tooltip += `<br>Date Modified: ${new Date(Date.parse(props.folder.updatedAt)).toLocaleString()}`;
  }

  return (
    <div className='col-lg-4 col-sm-6 col-xs-12'>
      <NavLink
        to={`/folder/${folder.virtualId}`}
        className={styles.folderBox}
        style={{ background: rainbowColors[folderIndex % rainbowColors.length] }}
        data-tip
        data-for={"tooltip-folder-" + folder.id}
        onClick={() => {
          if (props.onClick) props.onClick();
        }}
      >
        <div>
          <div className={styles.icon}>
            <i className='fa fa-folder' />
          </div>
        </div>

        <div className={styles.subFolderBox}>
          <h5>{folder.name}</h5>
        </div>
        <div className={styles.commands}>
          {!folder.isAutoCreated && <div>
            <i className='fa fa-pencil' onClick={e => onFolderNameClick(e, folder)} />
          </div>}
          <div>
            {
              (user != null && (folder.isShared ? user.isAdmin : folder.userId === user.id)) &&
              <i className='fa fa-user' onClick={e => {
                e.preventDefault();
                setShowFolderRights(true);
              }} />
            }
          </div>
          <div>
            {
              !folder.isReadonly && (folder.kind === FolderKinds.sde || folder.kind === FolderKinds.sdeMaster) &&
              <i className='fa pe-7s-note2' onClick={e => {
                e.preventDefault();
                setShowFolderNotes(true);
              }} />
            }
          </div>
        </div>
      </NavLink>
      <ReactTooltip html={true} multiline={true} id={"tooltip-folder-" + folder.id} place="top" effect="solid"
        type="info" backgroundColor="#404040" offset={{ top: 0, left: 100 }}>
        {tooltip}
      </ReactTooltip>
      {
        showFolderRights && (user != null && (folder.isShared ? user.isAdmin : folder.userId === user.id)) && <Modal
          ariaHideApp={false}
          isOpen={showFolderRights}
          style={modalStyle}
        >
          <div>
            <div className='col-sm-12'>
              <Button cls="pull-right" onClick={() => setShowFolderRights(false)}>
                Close
              </Button>
            </div>
            <FolderRightsView folder={folder} />
          </div>
        </Modal>
      }
      {
        showFolderNotes && <Modal
          ariaHideApp={false}
          isOpen={showFolderNotes}
          style={modalStyle}
        >
          <div>
            <div className='col-sm-12'>
              <Button cls="pull-right" onClick={() => setShowFolderNotes(false)}>
                Close
              </Button>
            </div>
            <FolderNotesView folder={folder} me={user} />
          </div>
        </Modal>
      }
    </div>
  );
}
