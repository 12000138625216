// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React, { useState } from 'react';
import styles from './HelpDialog.module.css';
import { Button, ButtonGroup, Col, ControlLabel, Form, FormControl, FormGroup } from "react-bootstrap";
import { apiEndpoint } from "../../utils/http";
import { UserService } from "../../rpc/users";

type HelpDialogProps = {
  close: () => void,
};

const actions = [
  '',
  'Detection',
  'Upload',
];

export default function HelpDialog(props: HelpDialogProps): Node {
  const [action, setAction] = useState<string>("");
  const [issue, setIssue] = useState<string>("");
  const [note, setNote] = useState<string>("");

  async function onSendClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    send();
    props.close();
  }

  async function send() {
    await new UserService(apiEndpoint()).sendIssue({ action, issue, note });
  }

  function onActionChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setAction(e.currentTarget.value.trim());
  }

  function onIssueChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setIssue(e.currentTarget.value);
  }

  function onNoteChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    setNote(e.currentTarget.value);
  }

  return <div className={styles.root}>
    <Form horizontal>
      <FormGroup controlId="action">
        <Col componentClass={ControlLabel} sm={2}>
          Action
        </Col>
        <Col sm={10}>
          <FormControl componentClass="select" placeholder="action" value={action} onChange={onActionChanged}>
            {actions.map(a => (<option key={a} value={a}>{a}</option>))}
          </FormControl>
        </Col>
      </FormGroup>
      <FormGroup controlId="issue">
        <Col componentClass={ControlLabel} sm={2}>
          Issue
        </Col>
        <Col sm={10}>
          <FormControl
            componentClass="textarea"
            value={issue}
            onChange={onIssueChanged}
          />
        </Col>
      </FormGroup>
      <FormGroup controlId="note">
        <Col componentClass={ControlLabel} sm={2}>
          Additional Notes
        </Col>
        <Col sm={10}>
          <FormControl
            componentClass="textarea"
            value={note}
            onChange={onNoteChanged}
          />
        </Col>
      </FormGroup>
    </Form>
    <ButtonGroup className={styles.buttons}>
      <Button bsStyle="info" onClick={onSendClick} disabled={action === "" && issue === "" && note === ""}>
        Send
      </Button>
    </ButtonGroup>
  </div>;
}
