// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from "react";
import type { Node } from "react";
import styles from './Statistics.module.css';
import { Link } from "react-router-dom";
import { toMegaBytes } from "../../utils/convert";
import type { User } from "../../rpc/model";
import { JSONToUser } from "../../rpc/model";
import { ActivityService } from "../../rpc/activity";
import { apiEndpoint, apiGet } from "../../utils/http";

type UserItem = {
  user: User,
  image_size: number,
  thumbnail_size: number,
}

type FolderItem = {
  folder_id: string,
  folder_name: string,
  image_size: number,
  thumbnail_size: number,
}

type SelectedUserItem = {
  user: User,
  items: Array<FolderItem>,
}

export default function StorageStatistics(): Node {
  const [usersItems, setUsersItems] = useState<Array<UserItem>>([]);
  const [selectedUserItem, setSelectedUserItem] = useState<?SelectedUserItem>(null);

  useEffect(() => {
    new ActivityService(apiEndpoint()).visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
  }, []);

  useEffect(() => {
    async function fetchUserItems() {
      const resp = await apiGet('/api/statistics/storage');
      setUsersItems(resp.data.map(i => ({ ...i, user: JSONToUser(i.user) })));
    }

    fetchUserItems();
  }, []);

  async function onUserClick(e: SyntheticMouseEvent<HTMLButtonElement>, user: User) {
    e.preventDefault();

    if (selectedUserItem != null && selectedUserItem.user.id === user.id)
      return;

    const userDetailsRequest = await apiGet(`/api/statistics/storage/${user.id}`);

    setSelectedUserItem({
      user,
      items: userDetailsRequest.data,
    });
  }

  let userStatistics = null;
  if (selectedUserItem != null) {
    userStatistics = <div>
      <h4>{selectedUserItem.user.displayName}</h4>
      <table className={styles.table}>
        <thead>
        <tr>
          <th>Folder</th>
          <th>Total Size</th>
          <th>Image Size</th>
          <th>Thumbnail Size</th>
        </tr>
        </thead>
        <tbody>
        {selectedUserItem.items.map((d, i) =>
          <tr key={i}>
            <td>{d.folder_id != null ?
              <Link to={`/folder/${d.folder_id}`}>{d.folder_name}</Link> : d.folder_name}</td>
            <td>{toMegaBytes(d.image_size + d.thumbnail_size)}</td>
            <td>{d.image_size ? toMegaBytes(d.image_size) : "-"}</td>
            <td>{d.thumbnail_size ? toMegaBytes(d.thumbnail_size) : "-"}</td>
          </tr>)}
        </tbody>
      </table>
    </div>;
  }

  return <div className={styles.root}>
    <h3>Storage Usage</h3>
    <table className={styles.table}>
      <thead>
      <tr>
        <th>User</th>
        <th>Total Size</th>
        <th>Image Size</th>
        <th>Thumbnail Size</th>
      </tr>
      </thead>
      <tbody>
      {usersItems.map(u =>
        <tr key={u.user.id} className={styles.clickable}
          onClick={e => onUserClick(e, u.user)}>
          <td>{u.user.displayName}</td>
          <td>{toMegaBytes(u.image_size + u.thumbnail_size)}</td>
          <td>{u.image_size ? toMegaBytes(u.image_size) : "-"}</td>
          <td>{u.thumbnail_size ? toMegaBytes(u.thumbnail_size) : "-"}</td>
        </tr>)}
      </tbody>
    </table>
    {userStatistics}
  </div>;
}
