// @flow strict
// Copyright (C) 2018-2021 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React, { useEffect, useState } from "react";
import { ControlLabel, Form, FormControl, FormGroup, Tabs, Tab } from "react-bootstrap";
import { observer } from 'mobx-react-lite';
import Select from 'react-select'; // flowlint-line nonstrict-import:off
import styles from "./UserProfileView.module.css";
import { UserProfileState } from './UserProfileState';
import UserNotesView from './UserNotesView';
import AppState from '../../AppState';

type UserProfileProps = {
  appState: AppState,
  match: {
    params: {
      id: string
    }
  },
}

function UserProfileView(props: UserProfileProps): Node {
  const [state] = useState<UserProfileState>(() => new UserProfileState(props.match.params.id));

  useEffect(() => {
    return () => state.dispose();
  }, [state]);

  useEffect(() => {
    state.setUserId(props.match.params.id);
  }, [state, props.match.params.id]);

  function onUserNameChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    const name = e.currentTarget.value;
    state.setUserName(name);
  }

  function onUserPhoneChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    const phone = e.currentTarget.value;
    state.setUserPhone(phone);
  }

  function onUserProfileChanged(value: { value: string }) {
    state.setProfile(value.value);
  }

  const isDarkTheme = localStorage.getItem('isDarkTheme') === "dark";
  const selectStyles = {
    control: css => ({ ...css, backgroundColor: undefined }),
    singleValue: css => (isDarkTheme ? { ...css, color: '#f3e5ca' } : css),
  };

  return <div className={styles.root}>
    <div className={styles.left}>
      <Form>
        <FormGroup controlId="name">
          <ControlLabel>Name</ControlLabel>
          <FormControl
            placeholder="name"
            value={state.userName}
            onChange={onUserNameChanged}
          />
        </FormGroup>
        <FormGroup controlId="email">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            placeholder="email"
            value={state.userEmail}
            readOnly={true}
          />
        </FormGroup>
        <FormGroup controlId="phone">
          <ControlLabel>Phone</ControlLabel>
          <FormControl
            placeholder="phone"
            value={state.userPhone}
            onChange={onUserPhoneChanged}
          />
        </FormGroup>
        <FormGroup controlId="profile">
          <ControlLabel>Profile</ControlLabel>
          <Select styles={selectStyles}
            onMenuOpen={async () => await state.fetchProfiles()}
            isSearchable={false}
            value={{ label: state.userProfile, value: state.userProfile }}
            options={[{ label: '', value: '' }, ...state.profiles.map(profile => ({ value: profile, label: profile }))]}
            onChange={onUserProfileChanged}
          />
        </FormGroup>
      </Form>
    </div>
    <div className={styles.right}>
      <Tabs defaultActiveKey={1} animation={false} id='user-profile-tabs'>
        <Tab eventKey={1} title="Alerts">
          <UserNotesView appState={props.appState} userId={state.userId} fromAdmin={true} />
        </Tab>
        <Tab eventKey={2} title="Notes">
          <UserNotesView appState={props.appState} userId={state.userId} fromAdmin={false} />
        </Tab>
        <Tab eventKey={3} title="License">
          <div className={styles.license}>
            {state.license}
          </div>
        </Tab>
        <Tab eventKey={4} title="KYC" disabled>
        </Tab>
      </Tabs>
    </div>
  </div>;
}

export default (observer(UserProfileView): typeof UserProfileView);
