// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import styles from './DetectEngineRights.module.css';
import ToggleButton from 'react-toggle-button';
import { Check, X } from '../toggle-button/buttons';
import type { DetectEngine, User } from "../../rpc/model";
import { UserService } from "../../rpc/users";
import { DetectService } from "../../rpc/detect";
import { ActivityService } from "../../rpc/activity";
import { apiEndpoint } from "../../utils/http";

export default function DetectEngineRights(): Node {
  const [engines, setEngines] = useState<Array<DetectEngine>>([]);
  const [users, setUsers] = useState<Array<User>>([]);
  const [rights, setRights] = useState<{ [user: string]: { [engine: string]: boolean } }>({});

  useEffect(() => {
    new ActivityService(apiEndpoint()).visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
  }, []);

  useEffect(() => {
    async function fetchEngines() {
      const resp = await new DetectService(apiEndpoint()).engines({ withVersions: false });
      setEngines(resp.engines);
    }

    fetchEngines();
  }, []);

  useEffect(() => {
    async function fetchUsers() {
      const resp = await new UserService(apiEndpoint()).users();
      setUsers(resp.users);
    }

    fetchUsers();
  }, []);

  useEffect(() => {
    async function fetchRights() {
      const resp = await new DetectService(apiEndpoint()).rights();
      const rawRights = resp.rights;
      const rights = {};
      for (let i = 0; i < rawRights.length; i++) {
        const right = rawRights[i];
        rights[right.userId] = rights[right.userId] || {};
        rights[right.userId][right.engineId] = true;
      }

      setRights(rights);
    }

    fetchRights();
  }, []);

  async function onRightChanged(user: User, engine: DetectEngine) {
    const granted = rights[user.id] ? !rights[user.id][engine.name] : true;
    await new DetectService(apiEndpoint()).grantRight({ engineId: engine.name, userId: user.id, granted: granted });

    setRights({
      ...rights,
      [user.id]: {
        ...rights[user.id],
        [engine.name]: granted,
      }
    });
  }

  return <div className={styles.root}>
    <h4>Detect Engine Access</h4>
    <table className={styles.table}>
      <thead>
      <tr>
        <th>USER</th>
        {engines.map(e => <th>{e.title || e.name}</th>)}
      </tr>
      </thead>
      <tbody>
      {users.filter(u => u.isAdmin === false).map(u =>
        <tr>
          <th>{u.displayName}</th>
          {engines.map(e =>
            <td>
              <ToggleButton
                inactiveLabel={<X />}
                activeLabel={<Check />}
                value={rights[u.id] && rights[u.id][e.name]}
                onToggle={() => onRightChanged(u, e)} />
            </td>)}
        </tr>)}
      </tbody>
    </table>
  </div>;
}
