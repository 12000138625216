// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React, { useEffect, useState } from "react";
import ContentEditable from 'react-contenteditable';
import styles from "./FolderNotesView.module.css";
import { FolderNotesState } from "./FolderNotesState";
import { observer } from "mobx-react-lite";
import type { Folder, User } from "../../rpc/model";
import Button from "react-bootstrap/lib/Button";
import { runInAction } from 'mobx';

type FolderNotesViewProps = {
  me: User,
  folder: Folder,
}

function FolderNotesView(props: FolderNotesViewProps): Node {
  const [state] = useState<FolderNotesState>(() => new FolderNotesState(props.folder.id));

  useEffect(() => {
    return () => state.dispose();
  }, [state]);

  useEffect(() => {
    runInAction(() => state.folderId = props.folder.id);
  }, [props.folder.id, state]);

  function onNoteChanged(e: SyntheticKeyboardEvent<HTMLInputElement>) {
    // $FlowIgnore[prop-missing]
    const value = e.target.value;
    runInAction(() => {
      state.editingNote = value;
    });
  }

  return <div className={styles.root}>
    <div className={styles.title}>{props.folder.name}</div>
    {state.notes.map(note => {
      const user = note.user;
      const userName = user != null ? user.displayName : '';
      const userId = user != null ? user.id : '';

      return <div key={note.id} className={styles.note}>
        <ContentEditable
          className={styles.noteNote}
          disabled={state.editingNoteId !== note.id}
          html={state.editingNoteId !== note.id ? note.note : state.editingNote}
          onChange={onNoteChanged}
        />
        <div className={styles.noteFooter}>
          <span className={styles.noteAuthor}>{userName}</span>
          <span className={styles.noteCreatedAt}>({new Date(Date.parse(note.createdAt)).toLocaleDateString()})</span>
          {state.editingNoteId !== note.id && userId === props.me.id &&
          <Button
            bsStyle="info"
            disabled={state.editingNoteId != null}
            onClick={() => state.startEditNote(note)}
          >
            Edit
          </Button>}
          {state.editingNoteId === note.id && <Button bsStyle="primary" disabled={state.editingNote === ''} onClick={state.editNote}>Save</Button>}
          {state.editingNoteId === note.id && <Button bsStyle="warning" onClick={state.cancelEditNote}>Cancel</Button>}
        </div>
      </div>;
    })}
    {state.editingNoteId !== '' &&
    <Button
      bsStyle="info"
      disabled={state.editingNoteId != null}
      onClick={state.startAddNote}
    >
      Add Note
    </Button>}
    {
      state.editingNoteId === '' &&
      <div key={'new-note'} className={styles.note}>
        <ContentEditable
          className={styles.noteNote}
          html={state.editingNote}
          onChange={onNoteChanged}
        />
        <div className={styles.noteFooter}>
          {<Button bsStyle="primary" disabled={state.editingNote === ''} onClick={state.addNote}>Save</Button>}
          {<Button bsStyle="warning" onClick={state.cancelEditNote}>Cancel</Button>}
        </div>
      </div>
    }
  </div>;
}

export default (observer(FolderNotesView): typeof FolderNotesView);
