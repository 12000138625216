// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useRef } from 'react';
import type { Node } from "react";
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown';
import styles from './LabelTypesDropdown.module.css';
import type { LabelType } from "../../rpc/model";
import { LabelTypes } from '../../model';

type LabelTypesDropdownProps = {
  labelTypes: Array<LabelType>;
  currentLabelType: ?LabelType;
  onLabelTypeSelected: (labelType: LabelType) => void,
  postfix: string,
  placeholder?: string,
}

export default function LabelTypesDropdown(props: LabelTypesDropdownProps): Node {
  const dropdownRef = useRef<typeof Dropdown>(null);

  function onLabelTypeSelected(labelType: LabelType) {
    if (dropdownRef.current != null)
      dropdownRef.current.hide();

    props.onLabelTypeSelected(labelType);
  }

  const items = props.labelTypes.map(lt => (
    <li key={lt.id} onClick={() => onLabelTypeSelected(lt)}>
      <span style={{ color: lt.color }}>■ </span>{lt.name}
    </li>));

  let currentLabelTypeName;
  if (props.currentLabelType != null) {
    currentLabelTypeName = props.currentLabelType.name;

    if (props.currentLabelType.labelType === LabelTypes.arrow && currentLabelTypeName.startsWith('$'))
      currentLabelTypeName = currentLabelTypeName.substr(1);

    if (props.postfix !== '')
      currentLabelTypeName += `-${props.postfix}`;
  } else {
    currentLabelTypeName = props.placeholder || '';
  }

  return <span className={styles.root}>
    <Dropdown ref={dropdownRef} disabled = {props.labelTypes.length === 0}>
      <DropdownTrigger>
        {currentLabelTypeName}
      </DropdownTrigger>
      <DropdownContent>
        <div className={styles.items}>
          <ul>
            {items}
          </ul>
        </div>
      </DropdownContent>
    </Dropdown>
  </span>;
}
