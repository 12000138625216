// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import styles from './Statistics.module.css';
import { Link } from 'react-router-dom';
import { ActivityService } from "../../rpc/activity";
import { apiEndpoint, apiGet } from "../../utils/http";

type SummaryStat = {
  user_id: string,
  user_name: string,
  image_count: number,
  any_label_count: number,
  labels: ?{ [string]: number },
}

type FolderStat = {
  folder_id: string,
  folder_name: string,
  image_count: number,
  any_label_count: number,
  labels: ?{ [string]: number },
}

type SelectedUserStat = {
  user_id: string,
  user_name: string,
  items: Array<FolderStat>,
}

type Statistics = {
  label_types: Array<string>,
  items: Array<SummaryStat>,
}

export default function LabelStatistics(): Node {
  const [statistics, setStatistics] = useState<Statistics>({
    label_types: [],
    items: [],
  });
  const [selectedUserStat, setSelectedUserStat] = useState<?SelectedUserStat>(null);

  useEffect(() => {
    new ActivityService(apiEndpoint()).visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
  }, []);

  useEffect(() => {
    async function fetchStatistics() {
      const resp = await apiGet('/api/statistics/labels-summary');
      setStatistics(resp.data);
    }

    fetchStatistics();
  }, []);

  async function onUserClick(e: SyntheticMouseEvent<HTMLButtonElement>, user_id: string, user_name: string) {
    e.preventDefault();

    if (selectedUserStat != null && selectedUserStat.user_id === user_id)
      return;

    const resp = await apiGet(`/api/statistics/labels/${user_id}`);
    setSelectedUserStat({
      user_id,
      user_name,
      items: resp.data,
    });
  }

  function renderUserStat(stat: SelectedUserStat) {
    return <div>
      <h4>{stat.user_name}</h4>
      <table className={styles.table}>
        <thead>
        <tr>
          <th>Folder</th>
          <th>Image Set Count</th>
          <th>Any Label</th>
          {statistics.label_types.map(lt => <th key={lt}>{lt}</th>)}
        </tr>
        </thead>
        <tbody>
        {stat.items.map(f => <tr key={f.folder_id}>
          <td><Link to={`/folder/${f.folder_id}`}>{f.folder_name}</Link></td>
          <td className={styles.counter}>{f.image_count}</td>
          <td className={styles.counter}>
            {f.any_label_count || ''} {f.any_label_count ? `(${Math.round((100 * f.any_label_count) / f.image_count)} %)` : ''}
          </td>
          {statistics.label_types.map(lt => {
            const count = f.labels != null ? f.labels[lt] : 0;
            return <td key={lt} className={styles.counter}>
              {count || ''} {count ? `(${Math.round((100 * count) / f.image_count)} %)` : ''}
            </td>;
          })}
        </tr>)}
        </tbody>
      </table>
    </div>;
  }

  const userStatistics = selectedUserStat != null ? renderUserStat(selectedUserStat) : null;

  return <div className={styles.root}>
    <h3>Labels Statistics</h3>
    <table className={styles.table}>
      <thead>
      <tr>
        <th>User</th>
        <th>Image Set Count</th>
        <th>Any Label</th>
        {statistics.label_types.map(lt => <th key={lt}>{lt}</th>)}
      </tr>
      </thead>
      <tbody>
      {statistics.items.map(f => <tr key={f.user_id} className={styles.clickable}
        onClick={e => onUserClick(e, f.user_id, f.user_name)}>
        <td>{f.user_name}</td>
        <td className={styles.counter}>{f.image_count}</td>
        <td className={styles.counter}>
          {f.any_label_count || ''} {f.any_label_count ? `(${Math.round((100 * f.any_label_count) / f.image_count)} %)` : ''}
        </td>
        {statistics.label_types.map(lt => {
          const count = f.labels != null ? f.labels[lt] : 0;
          return <td key={lt} className={styles.counter}>
            {count || ''} {count ? `(${Math.round((100 * count) / f.image_count)} %)` : ''}
          </td>;
        })}
      </tr>)}
      </tbody>
    </table>
    {userStatistics}
  </div>;
}
