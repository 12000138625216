export const Colors = {
    //dark
    darkTintColor: "#f5deb3",
    darkBgColor: "rgb(30, 30, 30)",
    darkTextColor: "#f5deb3",
    darkActiveTintColor: "#f5deb3",
    black: "black",



    //light
    lightTintColor: "rgba(29,43,54,1)",
    lightTextColor: "black",
    activeTintColor: "#1dc7ea"
};