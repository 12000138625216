// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React, { useEffect, useState } from 'react';
import cx from "classnames";
import styles from './OrmsList.module.css';
import ToggleButton from 'react-toggle-button';
import { Check, X } from '../toggle-button/buttons';
import type { DetectEngine } from "../../rpc/model";
import type { DetectPendingDetection } from "../../rpc/detect";
import { DetectService } from "../../rpc/detect";
import EngineParameters from "./EngineParameters";
import { Button } from "react-bootstrap";
import { apiEndpoint } from "../../utils/http";

const DETECT_SERVER_NOT_REACHABLE_STATE = "Not Reachable";

type OrmsListProps = {
  formats: Array<string>,
  showParameters: boolean,
  selectedEngines: Array<string>,
  enginesFilter?: ?(engine: DetectEngine) => boolean,
  onEnginesSelected: (engines: Array<DetectEngine>, parameterValues: { [string]: { [string]: string } }) => Promise<void>,
  onEnginesSelectedCancel: () => void,
  selectTitle: string,
  onLater?: ?(engines: Array<DetectEngine>) => (void | Promise<void>),
  laterTitle?: string,
  onLaterEnabled?: (engines: Array<DetectEngine>) => boolean,
  selectPlusOne?: ?(engines: Array<DetectEngine>, parameterValues: { [string]: { [string]: string } }) => Promise<void>,
  showPendingCounters: boolean,
};

type EngineGroup = {
  name: string,
  path: string,
  parent: string,
  expanded: boolean,
}

export default function OrmsList(props: OrmsListProps): Node {
  const [enginesLoaded, setEnginesLoaded] = useState<boolean>(false);
  const [engines, setEngines] = useState<Array<DetectEngine>>([]);
  const [engineGroups, setEngineGroups] = useState<{ [string]: EngineGroup }>({});
  const [selectedEngines, setSelectedEngines] = useState<Array<string>>(props.selectedEngines);
  const [parameterValues, setParameterValues] = useState<{ [string]: { [string]: string } }>({});
  const [pendingDetections, setPendingDetections] = useState<?Array<DetectPendingDetection>>(null);

  useEffect(() => {
    const enginesFilter = props.enginesFilter;

    async function fetchEngines() {
      const resp = await new DetectService(apiEndpoint()).visibleEngines({ withVersions: true });
      const engines = resp.engines
        .filter(e => e.supportedFormats.some(f => props.formats.indexOf(f) >= 0));

      let selectedEngines = enginesFilter != null ?
        engines.filter(e => props.selectedEngines.indexOf(e.name) >= 0 && enginesFilter(e)).map(e => e.name) :
        props.selectedEngines;
      selectedEngines = selectedEngines.filter(engineName =>
        engines.some(e => e.name === engineName && e.version !== DETECT_SERVER_NOT_REACHABLE_STATE));
      selectedEngines = selectedEngines.length > 0 ? selectedEngines.slice(0, 1) : selectedEngines;

      const rawGroups = [...new Set(engines.map(e => e.group))];
      rawGroups.sort();
      const groups = {};
      for (let i = 0; i < rawGroups.length; i++) {
        const parts = rawGroups[i] !== "" ? rawGroups[i].split("/") : [];
        for (let j = 0; j <= parts.length; j++) {
          const groupParts = parts.slice(0, j);
          const path = groupParts.join("/") + "/";
          if (groups[path] === undefined) {
            const parentPath = groupParts.length > 0 ? groupParts.slice(0, groupParts.length - 1).join("/") + "/" : "";
            groups[path] = {
              name: groupParts.length > 0 ? groupParts[groupParts.length - 1] : "",
              path: path,
              parent: parentPath,
              expanded: engines.some(e => selectedEngines.indexOf(e.name) >= 0 && (e.group + "/").startsWith(path)),
            };
          }
        }
      }

      setEngineGroups(groups);
      setEngines(engines.map(e => ({ ...e, group: e.group + "/" })));
      setSelectedEngines(selectedEngines);
      setEnginesLoaded(true);

      const parameterValues: { [string]: { [string]: string } } = {};
      if (props.showParameters) {
        engines.forEach(e => {
          const values: { [string]: string } = {};
          const storedValues = JSON.parse(window.localStorage.getItem(`orms.${e.name}.parameters`)) || {};
          e.parameters
            .filter(p => p.supportedFormats.length === 0 || p.supportedFormats.some(f => props.formats.indexOf(f) >= 0))
            .forEach(p => values[p.name] = storedValues[p.name] != null ? storedValues[p.name] : p.defaultValue);

          parameterValues[e.name] = values;
        });
      }

      setParameterValues(parameterValues);
    }

    fetchEngines();
  }, [props.formats, props.selectedEngines, props.enginesFilter, props.showParameters]);

  useEffect(() => {
    async function fetchPendingDetections() {
      const resp = await new DetectService(apiEndpoint()).pendingDetections();
      setPendingDetections(resp.detections);
    }

    if (props.showPendingCounters)
      fetchPendingDetections();
    else
      setPendingDetections(null);
  }, [props.showPendingCounters]);

  function onEngineSelectionChanged(engine: DetectEngine) {
    if (props.enginesFilter != null && !props.enginesFilter(engine))
      return;
    if (engine.version === DETECT_SERVER_NOT_REACHABLE_STATE)
      return;
    const index = selectedEngines.findIndex(e => e === engine.name);
    setSelectedEngines(index < 0 ? [engine.name] : []);
  }

  function onSelectClick() {
    const es = engines.filter(e => selectedEngines.indexOf(e.name) >= 0);
    const paramValues = {};
    es.forEach(e => {
      const engineParamValues = {};
      for (const [name, value] of Object.entries(parameterValues[e.name])) {
        const p = e.parameters.find(pp => pp.name === name);
        let coercedValue = ((value: any): string); // flowlint-line unclear-type:off
        if (p != null && p.parameterType === 'float') {
          if (coercedValue === '.' || coercedValue === '')
            coercedValue = '0';
          if (coercedValue !== '' && coercedValue.substr(coercedValue.length-1) === '.')
            coercedValue = coercedValue.substr(0, coercedValue.length-1);
          if (coercedValue !== '' && coercedValue.substr(0, 1) === '.')
            coercedValue = '0' + coercedValue;
        }
        engineParamValues[name] = coercedValue;
      }
      paramValues[e.name] = engineParamValues;
    });
    props.onEnginesSelected(es, paramValues);
  }

  function onSelectPlusOneClick() {
    const selectPlusOne = props.selectPlusOne;
    if (selectPlusOne == null)
      return;

    const es = engines.filter(e => selectedEngines.indexOf(e.name) >= 0);
    const paramValues = {};
    es.forEach(e => {
      paramValues[e.name] = parameterValues[e.name];
    });
    selectPlusOne(es, paramValues);
  }

  function onLaterClick() {
    const es = engines.filter(e => selectedEngines.indexOf(e.name) >= 0);
    if (props.onLater != null)
      props.onLater(es);
    props.onEnginesSelectedCancel();
  }

  function onLaterEnabled() {
    const onLaterEnabled = props.onLaterEnabled;
    if (onLaterEnabled == null)
      return true;

    const es = engines.filter(e => selectedEngines.indexOf(e.name) >= 0);
    return onLaterEnabled(es);
  }

  function onGroupClick(group: EngineGroup) {
    setEngineGroups(groups => ({
      ...groups,
      [group.path]: { ...groups[group.path], expanded: !groups[group.path].expanded }
    }));
  }

  function renderEngineGroup(group: EngineGroup, level: number) {
    const groupExpanded = level === 0 || group.expanded;
    // flowlint unclear-type:off
    const groups = Object.values(engineGroups).map(g => ((g: any): EngineGroup));
    const children = groups.filter(eg => eg.parent === group.path);

    const style = { marginLeft: `${level}em` };
    return <div key={group.path}>
      <span className={styles.groupHeader} onClick={() => onGroupClick(group)}>
        {level > 0 && <i className={`fa ${groupExpanded ? "fa-minus-square-o" : "fa-plus-square-o"}`} />}
        <span className={styles.groupTitle + " " + (engines.some(engine => selectedEngines.indexOf(engine.name) >= 0 && engine.group.startsWith(group.path)) ? styles.groupWithSelectedEngines : "")}>
          {engineGroups[group.path].name}
        </span>
      </span>
      <div style={style}>
        {groupExpanded && children.map(child => renderEngineGroup(child, level + 1))}
        {groupExpanded && engines.filter(engine => engine.group === group.path).map(engine => {
          const pendingDetection = pendingDetections != null ?
            (pendingDetections.find(d => d.engine === engine.name) ?? { engine: engine.name, count: 0 }) :
            null;

          return <div key={engine.name}>
            <div className={styles.engine}>
              <ToggleButton
                inactiveLabel={<X />}
                activeLabel={<Check />}
                value={selectedEngines.indexOf(engine.name) >= 0}
                onToggle={() => onEngineSelectionChanged(engine)} />
              <span
                className={cx(styles.engineName, { [styles.engineDisabled]: props.enginesFilter != null && !props.enginesFilter(engine) })}>
          {engine.title || engine.name}{engine.version ? ` (${engine.version})` : ""}
                {(pendingDetection != null && engine.version !== DETECT_SERVER_NOT_REACHABLE_STATE) ? <span>
                    <span className={styles.queuedJobs}>Queued jobs: </span>{pendingDetection.count}
                    </span> : ""}
              </span>
            </div>
            {props.showParameters && engine.parameters.length > 0 && selectedEngines.indexOf(engine.name) >= 0 &&
            <div className={styles.parameters}>
              <EngineParameters
                formats={props.formats}
                engine={engine}
                parameterValues={parameterValues[engine.name]}
                onParameterValueChanged={(p, value) => {
                  const storedValues = JSON.parse(window.localStorage.getItem(`orms.${engine.name}.parameters`)) || {};
                  storedValues[p.name] = value;
                  window.localStorage.setItem(`orms.${engine.name}.parameters`, JSON.stringify(storedValues));

                  setParameterValues({
                    ...parameterValues,
                    [engine.name]: {
                      ...parameterValues[engine.name],
                      [p.name]: value
                    }
                  });
                }}
              />
            </div>}
          </div>;
        })}
      </div>
    </div>;
  }

  return <div className={styles.root}>
    {Object.keys(engineGroups).length > 0 ?
      renderEngineGroup(engineGroups["/"], 0) :
      (enginesLoaded ? 'No Available Engines' : "Loading Engines Wait .....")}
    <div className={styles.buttons}>
      <Button
        bsSize="xsmall"
        bsStyle="primary"
        onClick={onSelectClick}
        disabled={engines.length === 0 || selectedEngines.length === 0}>
        {props.selectTitle}
      </Button>
      {props.selectPlusOne != null && <Button
        bsSize="xsmall"
        bsStyle="primary"
        onClick={onSelectPlusOneClick}>
        {props.selectTitle} + 1
      </Button>}
      {props.onLater != null && <Button
        bsSize="xsmall"
        bsStyle="primary"
        disabled={!onLaterEnabled()}
        onClick={onLaterClick}>
        {props.selectTitle} {props.laterTitle != null ? props.laterTitle : "Later"}
      </Button>}
      <Button
        bsSize="xsmall"
        bsStyle="warning"
        onClick={props.onEnginesSelectedCancel}>
        Cancel
      </Button>
    </div>
  </div>;
}

