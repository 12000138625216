// @flow strict

import React, { useEffect, useState } from "react";
import type { Node } from "react";
import Cookies from 'js-cookie';
import { Route, Switch } from "react-router-dom";
import Modal from "react-bootstrap/lib/Modal";

import Header from "../../controls/Header/Header";
import Sidebar from "../../controls/Sidebar/Sidebar";

import FolderView from "../../controls/folder-view/FolderView";
import FolderList from "../../controls/folder-list/FolderList";
import Settings from "../../controls/settings/Settings";
import Statistics from "../../controls/statistics/Statistics";
import Admin from "../../controls/admin/Admin";
import type { DashboardRoute } from "../../routes/dashboard.js";
import dashboardRouteList from "../../routes/dashboard.js";
import StoreFront from '../../controls/store-front/StoreFront';
import UserStatistics from '../../controls/statistics/UserStatistics';
import MobileAPIStatistics from '../../controls/statistics/MobileAPIStatistics';
import DownloadStatistics from '../../controls/statistics/DownloadStatistics';
import LabelStatistics from '../../controls/statistics/LabelStatistics';
import DetectEngineRights from '../../controls/admin/DetectEngineRights';
import StorageStatistics from '../../controls/statistics/StorageStatistics';
import ProcessStatistics from '../../controls/statistics/ProcessStatistics';
import UserList from '../../controls/user-list/UserList';
import type { Folder } from "../../rpc/model";
import { FolderService } from "../../rpc/folders";
import { ActivityService } from "../../rpc/activity";
import TierList from "../../controls/admin/TierList";
import InvalidateNotifier from "../../InvalidateNotifier";
import PdfTemplates from "../../controls/admin/PdfTemplates";
import { apiEndpoint, apiGet } from "../../utils/http";
import StoreFrontRights from "../../controls/store-front/StoreFrontRights";
import EmailNotifications from "../../controls/admin/EmailNotifications";
import { StorefrontService } from "../../rpc/storefront";
import HelpDialog from "../../controls/HelpDialog/HelpDialog";
import UserProfileView from '../../controls/user-list/UserProfileView';
import type AppState from '../../AppState';
import { observer } from 'mobx-react-lite';
import ApiKeys from '../../controls/admin/ApiKeys';

type DashboardProps = {
  location: {
    pathname: string,
  },
  history: {
    location: {
      pathname: string,
    },
    action: string,
    push: (path: string) => void,
  },
  appState: AppState,
  invalidateNotifier: InvalidateNotifier,
};

function Dashboard(props: DashboardProps): Node {
  const [dashboardRoutes, setDashboardRoutes] = useState<Array<DashboardRoute>>(dashboardRouteList);
  const [thirdPartyApp_3DAnnot_url, setThirdPartyApp_3DAnnot_url] = useState<string>("");
  const [logoText, setLogoText] = useState<string>("");
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [showThirdPartyApp_3DAnnot_url, setShow_thirdPartyApp_3DAnnot_url] = useState<boolean>(false);
  const [openedSidebars, setOpenedSidebars] = useState<Array<string>>([]);
  const [showStoreFront, setShowStoreFront] = useState<boolean>(false);
  const darkThemeText = localStorage.getItem('isDarkTheme');
  const [currentTheme, setCurrentTheme] = useState<string>(darkThemeText ? darkThemeText : "");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [userManualUrl, setUserManualUrl] = useState<string>("");
  const [videoTutorialsUrl, setVideoTutorialsUrl] = useState<string>("");
  const [helpVisible, setHelpVisible] = useState<boolean>(false);
  const [knowledgeBaseUrl, setKnowledgeBaseUrl] = useState<string>("");

  function onTreeParentClicked(r: DashboardRoute) {
    setOpenedSidebars(openedSidebars => {
      const index = openedSidebars.indexOf(r.id);
      if (index < 0)
        return [...openedSidebars, r.id];
      else
        return [...openedSidebars.slice(0, index), ...openedSidebars.slice(index + 1)];
    });
  }

  useEffect(() => {
    if (props.appState.me != null)
      getConfigData();
  }, [props.appState.me]);

  useEffect(() => {
    if (props.appState.me != null)
      getFolders();
  }, [props.appState.me]);

  useEffect(() => {
    async function fetchExperimentalFeatures() {
      const resp = await new StorefrontService(apiEndpoint()).apps({ myApps: true, category: "experimental-features" });
      setDashboardRoutes(routes => routes.map(route => route.name !== "Experimental" ?
        route :
        {
          ...route,
          hidden: resp.apps.length === 0,
          children: resp.apps.map(app => ({
            id: "StorefrontApp-" + app.id.toString(),
            name: app.title,
            path: "",
            icon: "",
            redirect: app.appUrl,
          }))
        },
      ));
    }

    if (props.appState.me != null)
      fetchExperimentalFeatures();
  }, [props.appState.me]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
    setDashboardRoutes(dashboardRoutes => dashboardRoutes.map(r => r.name !== "3D Annotation" ? r :
      {
        ...r,
        hidden: !thirdPartyApp_3DAnnot_url || !showThirdPartyApp_3DAnnot_url,
        redirect: thirdPartyApp_3DAnnot_url ?
          `${thirdPartyApp_3DAnnot_url}?folder=&session=${Cookies.get('session-id')}&ws=${encodeURIComponent(`${wsProtocol}://${window.location.host}/websocket/folder`)}` :
          "",
        onClick: onThirdPartyApp3DAnnotURLClick,
      }));
  }, [thirdPartyApp_3DAnnot_url, showThirdPartyApp_3DAnnot_url]);

  useEffect(() => {
    setDashboardRoutes(dashboardRoutes => dashboardRoutes.map(r => r.name !== "Storefront" && r.name !== "My Apps" ? r :
      {
        ...r,
        hidden: !showStoreFront,
        onClick: r.name === "Storefront" ? onStoreFrontClick : onStoreFrontMyAppsClick,
      }));
  }, [showStoreFront]);

  useEffect(() => {
    setDashboardRoutes(dashboardRoutes => dashboardRoutes.map(r => r.name !== "Help" ? r :
      {
        ...r,
        onClick: onHelpClick,
      }));
  }, []);

  useEffect(() => {
    setDashboardRoutes(dashboardRoutes => dashboardRoutes.map(r => r.name !== "User Manual" ? r :
      {
        ...r,
        hidden: !userManualUrl,
        redirect: userManualUrl,
        onClick: onUserManualURLClick,
      }));
  }, [userManualUrl]);

  useEffect(() => {
    setDashboardRoutes(dashboardRoutes => dashboardRoutes.map(r => r.name !== "Video Tutorials" ? r :
      {
        ...r,
        hidden: !videoTutorialsUrl,
        redirect: videoTutorialsUrl,
        onClick: onVideoTutorialsURLClick,
      }));
  }, [videoTutorialsUrl]);

  useEffect(() => {
    setDashboardRoutes(dashboardRoutes => dashboardRoutes.map(r => r.name !== "Knowledge Base" ? r :
      {
        ...r,
        hidden: !knowledgeBaseUrl,
        redirect: knowledgeBaseUrl,
        onClick: onKnowledgeBaseURLClick,
      }));
  }, [knowledgeBaseUrl]);


  async function getConfigData() {
    const res = await apiGet("/api/config");

    const logo_text = res.data.logo_text ? res.data.logo_text : 'Image Labeling';
    const logo_url = res.data.logo_url ? res.data.logo_url + '?' + Date.now() : '';
    const show_thirdPartyApp_3DAnnot_url = res.data.show_thirdPartyApp_3DAnnot_url || false;
    const showStoreFront = res.data.storefront_visibility === "protected" || res.data.storefront_visibility === "public";
    const userManualUrl = res.data.userManual_url;
    const videoTutorialsUrl = res.data.videoTutorials_url;
    const knowledgeBaseUrl = res.data.knowledge_base_url;

    //update states
    setThirdPartyApp_3DAnnot_url(res.data.thirdPartyApp_3DAnnot_url);
    setLogoText(logo_text);
    setLogoUrl(logo_url);
    setShow_thirdPartyApp_3DAnnot_url(show_thirdPartyApp_3DAnnot_url);
    setShowStoreFront(showStoreFront);
    setUserManualUrl(userManualUrl);
    setVideoTutorialsUrl(videoTutorialsUrl);
    setKnowledgeBaseUrl(knowledgeBaseUrl);
  }

  async function getFolders() {
    //to make api call for folders and updating tree structure
    const res = await new FolderService(apiEndpoint()).tree({ sortBy: props.appState.sortFoldersBy });

    const sharedChildren = buildTree(res.shared);
    const privateChildren = buildTree(res.private);
    const privateUsers = res.privateUsers.map(user => ({
      id: user.id,
      path: "/user-folders/" + user.id,
      name: user.me ? 'My' : user.displayName,
      icon: "pe-7s-user",
      children: privateChildren.filter(child => child.userId === user.id),
    }));

    setDashboardRoutes(dashboardRoutes => [
      {
        ...dashboardRoutes[0],
        children: dashboardRoutes[0].children != null ? [
          { ...dashboardRoutes[0].children[0], children: sharedChildren },
          { ...dashboardRoutes[0].children[1], children: privateUsers },
        ] : undefined,
      },
      ...dashboardRoutes.slice(1)]);
  }

  function onHelpClick() {
    setHelpVisible(true);
  }

  function closeHelpDialog() {
    setHelpVisible(false);
  }

  const visibleDashboardRoutes = dashboardRoutes.filter(r => !r.hidden &&
    ((props.appState.me != null && props.appState.me.isAdmin) ||
      r.path == null ||
      (r.path !== '/admin' && r.path !== '/settings' && r.path !== '/admin/users' && r.path !== '/admin/tiers')));

  const invalidateNotifier = props.invalidateNotifier;
  const user = props.appState.me;

  return (
    <div className="wrapper" style={{ flex: 1, display: "flex" }}>
      <Sidebar
        appState={props.appState}
        toggleDrawer={setOpenDrawer}
        isDrawerOpen={openDrawer}
        logo_text={logoText}
        logo_url={logoUrl}
        dashboardRoutes={visibleDashboardRoutes}
        onTreeParentClicked={onTreeParentClicked}
        openedSidebars={openedSidebars}
        location={props.location}
        currentTheme={currentTheme}
        showHelpDialog={onHelpClick}
        toggleDarkTheme={() => {
          setCurrentTheme(currentTheme === 'dark' ? 'light' : 'dark');
          if (currentTheme === 'dark') {
            localStorage.setItem('isDarkTheme', 'light');
          } else {
            localStorage.setItem('isDarkTheme', 'dark');
          }
          localStorage.setItem('setDrawerOpen', 'yes');
          setTimeout(() => {
            window.location.reload(false);
          }, 500);
        }}
      />
      <div id="main-panel" className={openDrawer ? "main-panel" : "main-panel-close"} style={{ width: "100%" }}>
        <Header history={props.history} appState={props.appState} toggleDrawer={setOpenDrawer} isDrawerOpen={openDrawer} location={props.location} showHelpDialog={onHelpClick} />
        <Switch>
          <div>
            <Route
              exact path="/"
              render={ps =>
                <FolderList getFolders={getFolders} showPrivateFolders={true} showSharedFolders={true} appState={props.appState} {...ps} />}
            />
            <Route
              exact
              path="/private-folders"
              render={ps =>
                <FolderList getFolders={getFolders} showPrivateFolders={true} showSharedFolders={false} appState={props.appState} {...ps} />}
            />
            <Route
              exact
              path="/shared-folders"
              render={ps =>
                <FolderList getFolders={getFolders} showPrivateFolders={false} showSharedFolders={true} appState={props.appState} {...ps} />}
            />
            <Route
              exact
              path="/user-folders/:id"
              render={ps =>
                <FolderList getFolders={getFolders} showPrivateFolders={true} showSharedFolders={false} appState={props.appState} {...ps} />}
            />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/statistics" component={Statistics} />
            <Route exact path="/statistics/labels" component={LabelStatistics} />
            <Route exact path="/statistics/downloads" component={DownloadStatistics} />
            <Route exact path="/statistics/user" component={UserStatistics} />
            <Route exact path="/statistics/mobile" component={MobileAPIStatistics} />
            <Route exact path="/statistics/storage" component={StorageStatistics} />
            <Route exact path="/statistics/process" component={ProcessStatistics} />
            <Route
              exact
              path="/folder/:id"
              render={ps => (
                <FolderView
                  show_thirdPartyApp_3DAnnot_url={showThirdPartyApp_3DAnnot_url}
                  thirdPartyApp_3DAnnot_url={thirdPartyApp_3DAnnot_url}
                  getFolders={getFolders}
                  invalidateNotifier={invalidateNotifier}
                  appState={props.appState}
                  {...ps}
                />
              )}
            />
            <Route
              exact
              path="/admin"
              render={props => <Admin getConfigData={getConfigData} {...props} />}
            />
            <Route exact path="/admin/users" component={UserList} />
            <Route exact path="/admin/tiers" component={TierList} />
            <Route exact path="/admin/notifications" component={EmailNotifications} />
            <Route exact path="/admin/engine-rights" component={DetectEngineRights} />
            <Route exact path="/storefront" render={props => <StoreFront user={user} myAppsMode={false} {...props} />} />
            <Route exact path="/storefront/my" render={props => <StoreFront user={user} myAppsMode={true} {...props} />} />
            <Route exact path="/storefront/rights" component={StoreFrontRights} />
            <Route exact path="/pdf-templates" component={PdfTemplates} />
            <Route exact path="/user/:id" render={ps => <UserProfileView appState={props.appState} {...ps} />} />
            <Route exact path="/admin/api-keys" component={ApiKeys} />
          </div>
        </Switch>
        <Modal show={helpVisible}>
          <Modal.Header closeButton onHide={closeHelpDialog}>
            <Modal.Title>Send new issue</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <HelpDialog close={closeHelpDialog}/>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

function buildTree(folders: Array<Folder>): Array<DashboardRoute> {
  const tree = [];
  folders.forEach(folder => {
    const children = folder.children ? buildTree(folder.children) : undefined;
    const obj = {
      id: folder.id,
      path: "/folder/" + folder.virtualId,
      name: folder.name,
      icon: "",
      children: children,
      userId: folder.userId,
    };
    tree.push(obj);
  });
  return tree;
}

function onThirdPartyApp3DAnnotURLClick() {
  new ActivityService(apiEndpoint()).action({
    pageUrl: window.location.pathname,
    name: 'thirdPartyApp_3DAnnot_url-sidebar',
  });
}

function onStoreFrontClick() {
  new ActivityService(apiEndpoint()).action({
    pageUrl: window.location.pathname,
    name: "StoreFront",
  });
}

function onStoreFrontMyAppsClick() {
  new ActivityService(apiEndpoint()).action({
    pageUrl: window.location.pathname,
    name: "StoreFront/MyApp",
  });
}

function onUserManualURLClick() {
  new ActivityService(apiEndpoint()).action({
    pageUrl: window.location.pathname,
    name: "UserManual",
  });
}

function onVideoTutorialsURLClick() {
  new ActivityService(apiEndpoint()).action({
    pageUrl: window.location.pathname,
    name: "VideoTutorials",
  });
}

function onKnowledgeBaseURLClick() {
  new ActivityService(apiEndpoint()).action({
    pageUrl: window.location.pathname,
    name: "KnowledgeBase",
  });
}

function onResize() {
  const { documentElement } = document;
  if (documentElement == null)
    return;

  if (
    window.innerWidth < 993 &&
    documentElement.className.indexOf("nav-open") !== -1
  ) {
    documentElement.classList.toggle("nav-open");
  }
}

export default (observer(Dashboard): typeof Dashboard);
