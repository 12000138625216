// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React, { useEffect, useState } from "react";
import styles from "./UserList.module.css";
import type { Tier, User } from "../../rpc/model";
import { UserService } from "../../rpc/users";
import { TierService } from "../../rpc/tiers";
import { apiEndpoint } from "../../utils/http";
import DropdownButton from "../Button/DropdownButton";
import Button from "../Button/Button";
import { MenuItem } from "react-bootstrap";
import Modal from "react-modal";
import UserLabelSelector from "./UserLabelSelector";

export default function UserList(): Node {
  const [tiers, setTiers] = useState<Array<Tier>>([]);
  const [users, setUsers] = useState<Array<User>>([]);
  const [user, setUser] = useState<?User>(null);
  const [userLabelSelector, setUserLabelSelector] = useState<boolean>(false);
  const [unSortUsers, setUnSortUsers] = useState<Array<User>>([]);
  const [unSortSelector, setUnSortSelector] = useState<boolean>(false);

  useEffect(() => {
    async function fetchTiers() {
      const resp = await new TierService(apiEndpoint()).tiers();
      setTiers(resp.tiers);
    }

    fetchTiers();
  }, []);

  useEffect(() => {
    async function fetchUsers() {
      const resp = await new UserService(apiEndpoint()).users();
      const localAdminUserId = '9bb9f766-8e5f-497e-9e27-e1be439a73be';
      const users = resp.users.filter(u => u.id !== localAdminUserId);
      setUsers(users);
      setUnSortUsers(unSortUsers => [...unSortUsers, ...users]);
    }

    fetchUsers();
  }, []);

  async function onUserTireChanged(e: SyntheticInputEvent<HTMLSelectElement>, user: User) {
    const newTierId = +e.target.value;
    if (newTierId === 0)
      return;

    await new UserService(apiEndpoint()).setTier({ userId: user.id, tierId: newTierId });
    setUsers(users => users.map(u => u.id === user.id ?
      { ...u, tierId: newTierId } :
      u));
  }

  async function deleteUser(user: User, mode: string) {
    const suffix = mode === "move_to_admin" ?
      "and transfer his data to you" :
      "and all his data";
    const confirmMessage = `You are going to delete the user '${user.login}' ${suffix}.`;
    const pin = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
    const confirm = window.prompt(confirmMessage + `\nPlease type ${pin} to confirm.`);
    if (confirm !== pin)
      return;

    await new UserService(apiEndpoint()).deleteUser({ mode: mode, userId: user.id });
    setUsers(users => users.filter(u => u.id !== user.id));
  }

  async function onUserLabelSelector(user: User) {
    setUser(user);
    setUserLabelSelector(true);
  }

  function onUserLabelSelectorCancel() {
    setUser(null);
    setUserLabelSelector(false);
  }

  async function onUserSort() {
    if (unSortSelector === true) {
      setUsers([]);
      setUsers(users => [...users, ...unSortUsers]); 
    } else {
        setUsers(users.sort((p1, p2) => p1.login.localeCompare(p2.login)));
    }
    setUnSortSelector(!unSortSelector);
  }

  const spinnerStyle = {
    overlay: {
      backgroundColor: "rgba(255, 255, 255, 0.5)"
    },
    content: {
     left: "20%",
      top: "10%",
      right: "20%",
      width: "60%",
      bottom: "auto",
      maxHeight: "80vh",
      backgroundColor: localStorage.getItem('isDarkTheme') === "dark" ? "rgb(20,20,20)" : "rgba(211, 211, 211, 1)",
    }
  };

  return <div className={styles.root}>
     <h3>
      <span className={styles.header}>User List</span>
      <Button onClick={onUserSort}>
        {unSortSelector === false ? "Sort" : "Undo Sort"}
      </Button>
    </h3>
    <table className={styles.userTable}>
      <thead>
      <tr>
        <th>Login</th>
        <th>Email</th>
        <th>Display Name</th>
        <th>User Tier</th>
      </tr>
      </thead>
      <tbody>
      {users.map(u => <tr key={u.id}>
        <td>
          <a href={`/user/${u.id}`}>{u.login}</a>
          <div style={{ float: "right" }}>
            <DropdownButton id="delete-user" title="Manage">
              {
                u.id !== '5994b9c8-096d-4ccc-8ad8-4a3ed895b4d3' && <MenuItem key="delete-user-without-data" eventKey="1" onSelect={() => deleteUser(u, "move_to_admin")}>
                  Delete a user and transfer his data to you
                </MenuItem>
              }
              {
                u.id !== '5994b9c8-096d-4ccc-8ad8-4a3ed895b4d3' && <MenuItem key="delete-user-with-data" eventKey="2" onSelect={() => deleteUser(u, "")}>
                  Delete a user and all his data
                </MenuItem>
              }
              <MenuItem key="assign-user-labels" eventKey="3" disabled = {u.isAdmin} onSelect={() => onUserLabelSelector(u)}>
                Assign labels to user
              </MenuItem>
            </DropdownButton>
          </div>
        </td>
        <td>{u.email}</td>
        <td>{u.displayName}</td>
        <td>
          {
            u.id === '5994b9c8-096d-4ccc-8ad8-4a3ed895b4d3'
              ? (tiers.find(t => t.id === u.tierId) ?? { title: '' }).title
              : <select style={{ color: "black" }} value={u.tierId} onChange={e => onUserTireChanged(e, u)}>
                <option key='new' value='0'>New (unapproved)</option>
                {tiers.map(t => <option key={t.id} value={t.id}>{t.title}</option>)}
              </select>
          }
        </td>
      </tr>)}
      </tbody>
    </table>
    { userLabelSelector === true && user != null && <Modal isOpen={true} style={spinnerStyle} >
      <UserLabelSelector
       user={user}
       cancel={onUserLabelSelectorCancel} />
    </Modal>} 

  </div>;
}
