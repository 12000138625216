// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React from "react";
import cx from "classnames";
import Select from 'react-select'; // flowlint-line nonstrict-import:off
import styles from "./FolderFilterView.module.css";
import { FolderFilter } from "./FolderFilter";
import { observer } from "mobx-react-lite";
import type { User } from "../../rpc/model";
import Button from "react-bootstrap/lib/Button";
import { LabelTypes } from '../../model';

type FolderViewFilterProps = {
  filter: FolderFilter,
  me: ?User,
}

function FolderFilterView(props: FolderViewFilterProps): Node {
  function onFilterItemTypeChanged(e: SyntheticEvent<HTMLSelectElement>) {
    props.filter.setItemType(e.currentTarget.value);
  }

  function onFilterLabelTypesChanged(values: Array<{ value: number }>) {
    props.filter.setLabelTypeIds(values != null ? values.map(v => v.value) : []);
  }

  function onFilterLabelConditionChanged(e: SyntheticEvent<HTMLSelectElement>) {
    props.filter.setLabelCondition(e.currentTarget.value);
  }

  function onFilterUserChanged(e: SyntheticEvent<HTMLSelectElement>) {
    props.filter.setUserId(e.currentTarget.value);
  }

  function onFilterVoteResultChanged(e: SyntheticEvent<HTMLSelectElement>) {
    props.filter.setVoteResult(e.currentTarget.value);
  }

  function onFilterVoteUserChanged(e: SyntheticEvent<HTMLSelectElement>) {
    props.filter.setVoteUserId(e.currentTarget.value);
  }

  function onFilterItemNameChanged(e: SyntheticEvent<HTMLSelectElement>) {
    props.filter.setItemName(e.currentTarget.value);
  }

  function onFilterAutoDetectionChanged(value: ?{ value: string }) {
    props.filter.setAutoDetection(value != null ? value.value : '');
  }

  function onFilterNoteUserChanged(e: SyntheticEvent<HTMLSelectElement>) {
    props.filter.setNoteUserId(e.currentTarget.value);
  }

  const { filter } = props;

  const filterItemTypeOptions = [
    <option key='all' value="">All Items</option>,
    <option key='images' value="image/">Images</option>,
    <option key='video' value="video/">Video</option>
  ];

  const filterLabelOptions = [
    ...props.filter.labelTypes.filter(lt => lt.labelType === LabelTypes.common).map(lt => ({
      value: lt.id,
      label: lt.name,
    })),
  ];

  const filterLabelValue = filterLabelOptions.filter(o => props.filter.state.labelTypeIds.some(id => id === o.value));

  const autoDetectionOptions = [
    ...props.filter.autoDetections.map(d => ({
      value: d.id,
      label: d.name,
    })),
  ];

  const autoDetectionValue = autoDetectionOptions.find(o => o.value === props.filter.state.autoDetectionId);

  const filterLabelConditionOptions = [
    <option key='' value="" />,
    <option key='+any' value="+any">have any label</option>,
    <option key='+or' value="+or">have any of...</option>,
    <option key='+and' value="+and">have all of...</option>,
    <option key='-any' value="-any">do not have labels</option>,
    <option key='-or' value="-or">do not have any of...</option>,
    <option key='-and' value="-and">do not have all of...</option>,
  ];

  const filterVoteResultOptions = [
    <option key='all' value="" />,
    <option key='unanimously' value="unanimously">Unanimously Labeled</option>,
    <option key='not-unanimously' value="not-unanimously">Not Unanimously Labeled</option>];

  const filterUserOptions = [<option key='all' value="" />];
  filterUserOptions.push(filter.users
    .map(u => <option key={u.id} value={u.id}>{u.displayName}</option>));

  const filterVoteUserOptions = [<option key='all' value="" />];
  filterVoteUserOptions.push(filter.users
    .filter(u => props.me != null && u.id !== props.me.id)
    .map(u => <option key={u.id} value={u.id}>{u.displayName}</option>));

  const filterNoteUserOptions = [
    <option key='all' value="" />,
    <option key='<-any' value="<-any">from Any User</option>,
  ];
  filterNoteUserOptions.push(filter.users
    .filter(u => props.me != null && u.id !== props.me.id)
    .map(u => <option key={`<-${u.id}`} value={`<-${u.id}`}>from {u.displayName}</option>));
  filterNoteUserOptions.push(<option key='->any' value="->any">for Any User</option>);
  filterNoteUserOptions.push(filter.users
    .filter(u => props.me != null && u.id !== props.me.id)
    .map(u => <option key={`->${u.id}`} value={`->${u.id}`}>for {u.displayName}</option>));

  return <div className='form-group'>
    <div className={styles.filterGroup}>
      <label className={styles.filterLabel}>Type</label>
      <select className={cx("form-control", "cursor-pointer", styles.filterControl)}
        value={filter.state.itemType}
        onChange={onFilterItemTypeChanged}>
        {filterItemTypeOptions}
      </select>
      <label className={styles.filterLabel}>Labels</label>
      <select className={cx("form-control", "cursor-pointer", styles.filterControl)}
        value={filter.state.labelCondition}
        onChange={onFilterLabelConditionChanged}>
        {filterLabelConditionOptions}
      </select>
      <Select className={cx(styles.filterControl)}
        isDisabled={filter.state.labelCondition === '' || filter.state.labelCondition === '+any' || filter.state.labelCondition === '-any'}
        onMenuOpen={async () => await filter.fetchLabelTypes()}
        isMulti={true}
        isSearchable={true}
        value={filterLabelValue}
        options={filterLabelOptions}
        onChange={onFilterLabelTypesChanged}
      />
      <label className={styles.filterLabel}>Labeled By</label>
      <select className={cx("form-control", "cursor-pointer", styles.filterControl)}
        value={filter.state.userId}
        onChange={onFilterUserChanged}>
        {filterUserOptions}
      </select>
      <label className={styles.filterLabel}>Item Name</label>
      <input className={cx("form-control", styles.filterControl)}
        value={filter.state.itemName}
        onChange={onFilterItemNameChanged} />
    </div>
    <div className={styles.filterGroup}>
      <label className={styles.filterLabel}>Vote Result</label>
      <select className={cx("form-control", "cursor-pointer", styles.filterControl)}
        value={filter.state.voteResult}
        onChange={onFilterVoteResultChanged}>
        {filterVoteResultOptions}
      </select>
      <label className={styles.filterLabel}>User</label>
      <select className={cx("form-control", "cursor-pointer", styles.filterControl)}
        disabled={filter.state.voteResult === ""}
        onMouseDown={async () => await filter.fetchUsers(filter.folderId)}
        onTouchStart={async () => await filter.fetchUsers(filter.folderId)}
        value={filter.state.voteUserId}
        onChange={onFilterVoteUserChanged}>
        {filterVoteUserOptions}
      </select>
      <label className={styles.filterLabel}>Auto Detection</label>
      <Select className={cx(styles.filterControl, styles.filterAutoDetectionControl)}
        onMenuOpen={async () => await filter.fetchAutoDetections(filter.folderId)}
        isClearable={true}
        isSearchable={false}
        value={autoDetectionValue === undefined ? null : autoDetectionValue}
        options={autoDetectionOptions}
        onChange={onFilterAutoDetectionChanged}
      />
      <label className={styles.filterLabel}>Notes</label>
      <select className={cx("form-control", "cursor-pointer", styles.filterControl)}
        onMouseDown={async () => await filter.fetchUsers(filter.folderId)}
        onTouchStart={async () => await filter.fetchUsers(filter.folderId)}
        value={filter.state.noteUserId}
        onChange={onFilterNoteUserChanged}>
        {filterNoteUserOptions}
      </select>
      <Button bsStyle={filter.empty ? 'info' : 'danger'}
        className={cx(styles.filterButton, { [styles.filterExists]: !filter.empty })}
        onClick={() => filter.reset()}
      >
        {filter.empty ? 'No Filter set' : 'Clear Filter'}
      </Button>
    </div>
  </div>;
}

export default (observer(FolderFilterView): typeof FolderFilterView);
