// @flow strict

import axios from "axios";

let _apiEndpoint = "";

export function apiEndpoint(): string {
  return _apiEndpoint;
}

export function setApiEndpoint(apiEndpoint: string) {
  _apiEndpoint = apiEndpoint;
}

// flowlint unclear-type:off
export async function apiGet(endpoint: string): any {
  return await axios.get(apiEndpoint() + endpoint, { withCredentials: true });
}

export async function apiPost(endpoint: string, data?: any, config?: Object): any {
  return await axios.post(apiEndpoint() + endpoint, data, { ...config, withCredentials: true });
}

export async function apiDelete(endpoint: string, config?: Object): any {
  return await axios.delete(apiEndpoint() + endpoint, { withCredentials: true });
}

// flowlint unclear-type:error