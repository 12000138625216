// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React from 'react';
import type { Node } from "react";
import styles from './PdfTemplateView.module.css';
import type { LabelType, PDFControl, PDFTemplate } from "../../rpc/model";
import ToggleButton from "react-toggle-button";

type PdfTemplateViewProps = {
  template: PDFTemplate,
  labelTypes: Array<LabelType>,
  getTextControlValue: (template: PDFTemplate, controlId: string) => string,
  setTextControlValue: (template: PDFTemplate, controlId: string, value: string) => void,
  getCheckControlValue: (template: PDFTemplate, controlId: string) => boolean,
  toggleCheckControlValue: (template: PDFTemplate, controlId: string) => void,
};

function wrapIntColumns(itemCount: number, columnCount: number): Array<number> {
  const rowCount = Math.floor(itemCount / columnCount) + (itemCount % columnCount === 0 ? 0 : 1);
  const indexes = new Array(columnCount * rowCount);
  indexes.fill(-1);
  for (let i = 0; i < itemCount; i++) {
    const index = (i % rowCount) * columnCount + Math.floor(i / rowCount);
    indexes[index] = i;
  }
  return indexes;
}

export default function PdfTemplateView(props: PdfTemplateViewProps): Node {
  function renderControl(control: PDFControl, index: number) {
    switch (control.controlType) {
      case "plain":
        return <span key={index} className={styles.plain}>{control.text}</span>;
      case "text":
        return <span key={index} className={styles.controlText}>
          <span className={styles.textLabel}>{control.text}</span>
          <input
            className={styles.text}
            value={props.getTextControlValue(props.template, control.id)}
            onChange={e => props.setTextControlValue(props.template, control.id, e.currentTarget.value)}
          />
        </span>;
      case "check":
        return <span key={index} className={styles.controlCheck}>
          <span className={styles.check}>
            <ToggleButton
              inactiveLabel=""
              activeLabel=""
              containerStyle={{ width: "40px" }}
              trackStyle={{ width: "40px", height: "25px" }}
              thumbAnimateRange={[1, 22]}
              value={props.getCheckControlValue(props.template, control.id)}
              onToggle={() => props.toggleCheckControlValue(props.template, control.id)} />
          </span>
          <span className={styles.plain}>{control.text}</span>
          </span>;
      case "checks":
        return <div key={index} className={styles.checks}>
          {wrapIntColumns(props.labelTypes.length, 2).map((index, i) =>
            index < 0 ? <span key={-i} className={styles.controlCheck} /> :
              <span key={props.labelTypes[index].id} className={styles.controlCheck}>
                <span className={styles.check}>
                  <ToggleButton
                    inactiveLabel=""
                    activeLabel=""
                    containerStyle={{ width: "40px" }}
                    trackStyle={{ width: "40px", height: "25px" }}
                    thumbAnimateRange={[1, 22]}
                    value={props.getCheckControlValue(props.template, control.id + "_" + props.labelTypes[index].name.toLowerCase())}
                    onToggle={() => props.toggleCheckControlValue(props.template, control.id + "_" + props.labelTypes[index].name.toLowerCase())} />
                </span>
                <span className={styles.plain}>{props.labelTypes[index].name}</span>
          </span>)}
        </div>;
      default:
        return null;
    }
  }

  return <div className={styles.root}>
    {props.template.parsedTemplate.map((line, i) => <div key={i} className={styles.line}>
      {line.controls.map(renderControl)}
    </div>)}
  </div>;
}
