// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

export type DashboardRoute = {
  path: string,
  name: string,
  icon: string,
  id: string,
  children?: Array<DashboardRoute>,
  redirect?: string,
  hidden?: boolean,
  onClick?: () => void,
  userId?: string,
}

const dashboardRoutes: Array<DashboardRoute> = [
  {
    path: "/",
    name: "Folder View",
    icon: "pe-7s-graph",
    id: "folderView",
    children: [
      {
        path: "/shared-folders",
        name: "Shared",
        id: "Shared",
        icon: "pe-7s-share",
      },
      {
        path: "/private-folders",
        name: "Private",
        id: "Private",
        icon: "pe-7s-door-lock",
      }
    ]
  },
  {
    path: "/settings",
    name: "Settings",
    id: "Settings",
    icon: "pe-7s-settings",
  },
  {
    path: "/statistics",
    name: "Statistics",
    id: "Statistics",
    icon: "pe-7s-note2",
  },
  {
    path: "/admin",
    name: "Admin",
    id: "Admin",
    icon: "pe-7s-user",
    children: [
      {
        path: "/admin/users",
        name: "Users",
        id: "Users",
        icon: "pe-7s-user",
      },
      {
        path: "/admin/tiers",
        name: "User Tiers",
        id: "Tiers",
        icon: "pe-7s-user",
      },
      {
        path: "/admin/notifications",
        name: "Notifications",
        id: "Notifications",
        icon: "pe-7s-mail",
      },
    ]
  },
  {
    id: "3D Annotation",
    name: "3D Annotation",
    icon: "pe-7s-link",
    path: "/3dAnnotationTool",
  },
  {
    id: "Storefront",
    name: "Storefront",
    icon: "pe-7s-shopbag",
    path: "/storefront",
  },
  {
    id: "My Apps",
    name: "My Apps",
    icon: "pe-7s-albums",
    path: "/storefront/my",
  },
  {
    id: "Experimental",
    name: "Experimental",
    icon: "pe-7s-attention",
    path: "",
    hidden: true,
    children: [],
  },
  {
    id: "Help",
    name: "Help",
    icon: "pe-7s-help1",
    path: "",
  },
  {
    id: "Manual",
    name: "User Manual",
    icon: "pe-7s-file",
    path: "",
  },
  {
    id: "Tutorials",
    name: "Video Tutorials",
    icon: "pe-7s-play",
    path: "",
  },
  {
    id: "Knowledge",
    name: "Knowledge Base",
    icon: "pe-7s-info",
    path: "",
  },
  {
    path: "/pdf-templates",
    name: "PDF Templates",
    id: "PDF-Templates",
    icon: "pe-7s-news-paper",
  },
];

export default dashboardRoutes;
