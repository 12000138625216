// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import type { Node } from "react";
import React from 'react';
import styles from './OptionList.module.css';
import { Button } from "react-bootstrap";

type OptionListProps = {
  options: Array<string>,
  title: string,
  onSelect: (option: string) => Promise<void>,
};

export default function OptionList(props: OptionListProps): Node {
  return <div className={styles.root}>
    <h3>{props.title}</h3>
    <div className={styles.buttons}>
      {props.options.map((option, i) => <Button
        bsStyle={i === 0 ? "primary" : "secondary"}
        onClick={() => props.onSelect(option)}
      >
        {option}
      </Button>)}
    </div>
  </div>;
}

