// @flow strict

import React, { useEffect, useState } from "react";
import type { Node } from "react";
import styles from "./ApiKeys.module.css";
import type { ApiKey } from "../../rpc/model";
import { AdminService } from "../../rpc/admin";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import ToggleButton from "react-toggle-button";
import { Check, X } from '../toggle-button/buttons';
import { apiEndpoint } from "../../utils/http";
import Modal from 'react-modal';
import ApiKeyForm from './ApiKeyForm';

export default function ApiKeys(): Node {
  const [apiKeys, setApiKeys] = useState<Array<ApiKey>>([]);
  const [editingKey, setEditingKey] = useState<?ApiKey>(null);

  useEffect(() => {
    async function fetchApiKeys() {
      const resp = await new AdminService(apiEndpoint()).apiKeys();
      setApiKeys(resp.keys);
    }

    fetchApiKeys();
  }, []);

  async function deleteApiKey(key: ApiKey) {
    if (!window.confirm(`You are going to delete the api key "${key.key}"\nAre you sure?`))
      return;

    await new AdminService(apiEndpoint()).deleteApiKey({ id: key.id });
    setApiKeys(apiKeys => apiKeys.filter(k => k.id !== key.id));
  }

  async function addApiKey() {
    const appName = window.prompt("Application Name");
    if (!appName)
      return;

    const resp = await new AdminService(apiEndpoint()).addApiKey({ appName: appName });
    const newApiKey = resp.key;
    if (newApiKey == null)
      return;

    setApiKeys(apiKeys => [...apiKeys, newApiKey]);
  }

  function onEditingKeyClose() {
    setEditingKey(null);
  }

  function editApiKey(key: ApiKey) {
    setEditingKey(key);
  }

  async function enableApiKeyComment(key: ApiKey) {
    await new AdminService(apiEndpoint()).enableApiKey({ id: key.id, disabled: !key.disabled });
    setApiKeys(apiKeys => apiKeys.map(k => k.id !== key.id ? k : { ...k, disabled: !key.disabled }));
  }

  async function onEditingKeyApply(key: ApiKey) {
    await new AdminService(apiEndpoint()).editApiKey({ id: key.id, appName: key.appName, note1: key.note1, note2: key.note2 });
    setApiKeys(keys => keys.map(k => k.id === key.id ? key : k));
  }

  return <div className={styles.root}>
    <ButtonToolbar className="mb-2">
      <Button size="sm" variant="primary" className="ml-2" onClick={addApiKey}>
        New API Key
      </Button>
    </ButtonToolbar>
    <Table>
      <thead>
      <tr>
        <th className={styles.cellApplication}>Application</th>
        <th className={styles.cellKey}>API Key</th>
        <th className={styles.cellKey}>API Secret</th>
        <th>Note 1</th>
        <th>Note 2</th>
        <th className={styles.cellEnable}>Enabled</th>
        <th className={styles.cellCommands} />
      </tr>
      </thead>
      <tbody>
      {apiKeys.map(apiKey =>
        <tr key={apiKey.id}>
          <td>{apiKey.appName}</td>
          <td>{apiKey.key}</td>
          <td>{apiKey.secret}</td>
          <td>{apiKey.note1}</td>
          <td>{apiKey.note2}</td>
          <td>
            <ToggleButton
              inactiveLabel={<X />}
              activeLabel={<Check />}
              value={!apiKey.disabled}
              onToggle={() => enableApiKeyComment(apiKey)} />
          </td>
          <td>
            <ButtonGroup>
              <Button variant="outline-secondary" size="sm"
                onClick={() => editApiKey(apiKey)}>
                Edit
              </Button>
              <Button variant="outline-danger" size="sm"
                onClick={() => deleteApiKey(apiKey)}>
                Delete
              </Button>
            </ButtonGroup>
          </td>
        </tr>)}
      </tbody>
    </Table>
    {editingKey != null && <Modal isOpen={true} style={modalStyle}>
      <ApiKeyForm apiKey={editingKey}
        applyChanges={onEditingKeyApply}
        close={onEditingKeyClose}
      />
    </Modal>}
  </div>;
}

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "50%",
    width: "30%",
    bottom: "auto",
    marginRight: "-30%",
    transform: "translate(-50%, -50%)",
  }
};
