// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import styles from './PdfTemplates.module.css';
import type { PDFTemplate } from "../../rpc/model";
import Button from "../Button/Button";
import { PDFService } from "../../rpc/pdf";
import Modal from "react-modal";
import PdfTemplateForm from "./PdfTemplateForm";
import { apiEndpoint } from "../../utils/http";

export default function PdfTemplates(): Node {
  const [templates, setTemplates] = useState<Array<PDFTemplate>>([]);
  const [editingTemplate, setEditingTemplate] = useState<?PDFTemplate>(null);

  useEffect(() => {
    async function fetchTemplates() {
      const resp = await new PDFService(apiEndpoint()).templates();
      setTemplates(resp.templates);
    }

    fetchTemplates();
  }, []);

  async function addTemplate() {
    editTemplate({ id: -1, title: "", template: "", parsedTemplate: [] });
  }

  async function onEditingTemplateApply(template: PDFTemplate): Promise<string> {
    if (template.id <= 0) {
      const resp = await new PDFService(apiEndpoint()).addTemplate({ template: template });
      const newTemplate = resp.template;
      if (newTemplate != null)
        setTemplates(templates => [...templates, newTemplate]);
      return resp.error;
    } else {
      const resp = await new PDFService(apiEndpoint()).editTemplate({ template: template });
      setTemplates(templates => templates.map(t => t.id === template.id ? template : t));
      return resp.error;
    }
  }

  function onEditingTemplateClose() {
    setEditingTemplate(null);
  }

  function editTemplate(template: PDFTemplate) {
    setEditingTemplate(template);
  }

  async function deleteTemplate(template: PDFTemplate) {
    if (!window.confirm(`You are going to delete the template '${template.title}'.\nAre you sure?`))
      return;

    await new PDFService(apiEndpoint()).deleteTemplate({ templateId: template.id });
    setTemplates(templates => templates.filter(t => t.id !== template.id));
  }

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "50%",
      width: "30%",
      bottom: "auto",
      marginRight: "-30%",
      transform: "translate(-50%, -50%)",
    }
  };

  return <div className={styles.root}>
    <h3>
      <span className={styles.header}>PDF Templates</span>
      <Button onClick={addTemplate}>
        Add Template
      </Button>
    </h3>
    <table className={styles.pdfTable}>
      <thead>
      <tr>
        <th>Title</th>
        <th />
      </tr>
      </thead>
      <tbody>
      {templates.map(t => <tr key={t.id}>
        <td>
          {t.title}
        </td>
        <td className={styles.commands}>
          <Button onClick={() => editTemplate(t)}>
            Edit
          </Button>
          <Button onClick={() => deleteTemplate(t)}>
            Delete
          </Button>
        </td>
      </tr>)}
      </tbody>
    </table>
    {editingTemplate != null && <Modal isOpen={editingTemplate != null} style={modalStyle}>
      <PdfTemplateForm template={editingTemplate}
        applyChanges={onEditingTemplateApply}
        close={onEditingTemplateClose}
      />
    </Modal>}
  </div>;
}
