// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { makeAutoObservable, observable, runInAction, toJS } from "mobx";
import type { LabelType, User } from "../../rpc/model";
import { apiEndpoint } from "../../utils/http";
import { FolderService } from "../../rpc/folders";
import { LabelTypeService } from "../../rpc/label_types";
import type { FolderAutoDetection } from "../../rpc/folders";

type FilterState = {
  itemType: string;
  labelCondition: string;
  labelTypeIds: Array<number>;
  userId: string;
  voteResult: string;
  voteUserId: string;
  itemName: string;
  autoDetectionId: string;
  noteUserId: string;
}

const emptyFilter: FilterState = {
  itemType: "",
  labelCondition: '',
  labelTypeIds: [],
  userId: "",
  voteResult: "",
  voteUserId: "",
  itemName: "",
  autoDetectionId: "",
  noteUserId: "",
};

export class FolderFilter {
  folderId: string;
  users: Array<User>;
  labelTypes: Array<LabelType>;
  autoDetections: Array<FolderAutoDetection>;

  state: FilterState;
  loaded: boolean;

  constructor(folderId: string) {
    this.setFolderId(folderId);
    this.labelTypes = [];
    this.fetchLabelTypes();
    makeAutoObservable(this);
  }

  setFolderId(folderId: string) {
    this.folderId = folderId;
    this.loaded = false;
    this.users = [];
    this.state = observable(emptyFilter);
    this.autoDetections = [];

    this.loadState(folderId);
    this.fetchUsers(folderId);
    this.fetchAutoDetections(folderId);
  }

  setItemType(itemType: string) {
    this.state.itemType = itemType;
    this.storeState();
  }

  setLabelTypeIds(labelTypeIds: Array<number>) {
    this.state.labelTypeIds = labelTypeIds;
    this.storeState();
  }

  setLabelCondition(labelCondition: string) {
    this.state.labelCondition = labelCondition;
    if (labelCondition === '' || labelCondition === '+any' || labelCondition === '-any')
      this.state.labelTypeIds = [];
    this.storeState();
  }

  setUserId(userId: string) {
    this.state.userId = userId;
    this.storeState();
  }

  setVoteResult(voteResult: string) {
    this.state.voteResult = voteResult;
    if (voteResult === "") {
      this.state.voteUserId = "";
    }
    this.storeState();
  }

  setVoteUserId(voteUserId: string) {
    this.state.voteUserId = voteUserId;
    this.storeState();
  }

  setItemName(itemName: string) {
    this.state.itemName = itemName;
    this.storeState();
  }

  setAutoDetection(detectionId: string) {
    this.state.autoDetectionId = detectionId;
    this.storeState();
  }

  setNoteUserId(noteUserId: string) {
    this.state.noteUserId = noteUserId;
    this.storeState();
  }

  async fetchUsers(folderId: string) {
    const resp = await new FolderService(apiEndpoint()).grantedUsers({ folderId });
    runInAction(() => {
      if (folderId === this.folderId)
        this.users = resp.users;
    });
  }

  async fetchLabelTypes() {
    const resp = await new LabelTypeService(apiEndpoint()).get();
    runInAction(() => this.labelTypes = resp.labelTypes);
  }

  async fetchAutoDetections(folderId: string) {
    const resp = await new FolderService(apiEndpoint()).autoDetections({ folderId });
    runInAction(() => {
      if (folderId === this.folderId)
        this.autoDetections = resp.detections;
    });
  }

  async loadState(folderId: string) {
    const resp = await new FolderService(apiEndpoint()).filter({ folderId });
    runInAction(() => {
      if (folderId === this.folderId) {
        this.state = resp.filter !== '' ? observable({ ...emptyFilter, ...JSON.parse(resp.filter) }) : observable(emptyFilter);
        this.loaded = true;
      }
    });
  }

  async storeState() {
    const filter = this.empty ? '' : JSON.stringify(toJS(this.state));
    await new FolderService(apiEndpoint()).setFilter({ folderId: this.folderId, filter });
  }

  reset() {
    this.state.itemType = "";
    this.state.labelCondition = '';
    this.state.labelTypeIds = [];
    this.state.userId = "";
    this.state.voteResult = "";
    this.state.voteUserId = "";
    this.state.itemName = "";
    this.state.autoDetectionId = "";
    this.state.noteUserId = "";
    this.storeState();
  }

  get empty(): boolean {
    return this.state.itemType === "" && this.state.labelCondition === '' && this.state.labelTypeIds.length === 0 &&
      this.state.userId === "" && this.state.voteResult === "" && this.state.voteUserId === "" &&
      this.state.itemName === "" && this.state.autoDetectionId === "" && this.state.noteUserId === "";
  }
}
