// @flow strict
// Copyright (C) 2018-2021 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { autorun, IReactionDisposer, makeAutoObservable, runInAction } from "mobx";
import debounce from "lodash/debounce";
import { apiEndpoint } from "../../utils/http";
import { UserService } from '../../rpc/users';

type SaveUserDebounced = {
  (userId: string, name: string, phone: string, profile: string): void,
  flush: () => void,
  cancel: () => void,
};

export class UserProfileState {
  userId: string;
  userName: string;
  userEmail: string;
  userPhone: string;
  userProfile: string;
  license: string;
  profiles: Array<string>;
  saveImageLabelDebounced: SaveUserDebounced;
  disposes: (typeof IReactionDisposer)[];

  constructor(userId: string) {
    this.userId = userId;
    this.userName = '';
    this.userEmail = '';
    this.userPhone = '';
    this.userProfile = '';
    this.license = '';
    this.profiles = [];
    this.saveImageLabelDebounced = debounce(this.saveChanges, 500);
    this.disposes = [];
    this.fetchProfiles();

    makeAutoObservable(this, {
      disposes: false,
      saveChanges: false,
      saveImageLabelDebounced: false,
    }, { autoBind: true });

    this.disposes.push(
      autorun(() => {
        this.fetchUser(this.userId);
      })
    );
  }

  dispose() {
    this.disposes.forEach(dispose => dispose());
  }

  setUserId(userId: string) {
    this.saveImageLabelDebounced.flush();
    this.userId = userId;
    this.userName = '';
    this.userEmail = '';
    this.userPhone = '';
    this.userProfile = '';
    this.license = '';
  }

  setUserName(userName: string) {
    this.userName = userName;
    this.saveImageLabelDebounced(this.userId, this.userName, this.userPhone, this.userProfile);
  }

  setUserPhone(userPhone: string) {
    this.userPhone = userPhone.replace(/[^0-9-]/g, '');
    this.saveImageLabelDebounced(this.userId, this.userName, this.userPhone, this.userProfile);
  }

  setProfile(profile: string) {
    this.userProfile = profile;
    this.saveImageLabelDebounced(this.userId, this.userName, this.userPhone, this.userProfile);
  }

  async saveChanges(userId: string, name: string, phone: string, profile: string) {
    await new UserService(apiEndpoint()).editUserProfile({ userId, name, phone, profileId: profile });
  }

  async fetchUser(userId: string) {
    const resp = await new UserService(apiEndpoint()).userProfile({ id: userId });
    runInAction(() => {
      if (userId === this.userId) {
        this.userName = resp.user?.displayName || '';
        this.userEmail = resp.user?.email || '';
        this.userPhone = resp.user?.phone || '';
        this.userProfile = resp.user?.profileId || '';
        this.license = resp.license;
      }
    });
  }

  async fetchProfiles() {
    const resp = await new UserService(apiEndpoint()).profiles();
    runInAction(() => {
      this.profiles = resp.profiles;
    });
  }
}
