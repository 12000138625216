// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import styles from './Settings.module.css';
import LabelTypesList from "../label-types-list/LabelTypesList";
import { Link } from 'react-router-dom';
import type { Folder, User } from "../../rpc/model";
import { UserService } from "../../rpc/users";
import { FolderService } from "../../rpc/folders";
import { ActivityService } from "../../rpc/activity";
import { apiEndpoint } from "../../utils/http";
import ProfileList from '../user-list/ProfileList';

export default function Settings(): Node {
  const [user, setUser] = useState<?User>(null);
  const [sharedFolders, setSharedFolders] = useState<Array<Folder>>([]);

  useEffect(() => {
    new ActivityService(apiEndpoint()).visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
  }, []);

  useEffect(() => {
    async function fetchMe() {
      const resp = await new UserService(apiEndpoint()).me();
      setUser(resp.user);
    }

    fetchMe();
  }, []);

  useEffect(() => {
    async function fetchMobileAPICandidates() {
      const resp = await new FolderService(apiEndpoint()).mobileAPICandidates();
      setSharedFolders(resp.folders);
    }

    fetchMobileAPICandidates();
  }, []);

  async function onForMobileAPIChanged(e: SyntheticEvent<HTMLSelectElement>) {
    if (user == null || !user.isAdmin)
      return;

    const folderId = e.currentTarget.value;
    const folder = folderId ? sharedFolders.find(f => f.id === folderId) : null;
    if (folder != null) {
      await new FolderService(apiEndpoint()).setForMobileAPI({ folderId: folder.id, forMobileApi: true });
    } else {
      const currentForMobileAPIFolder = sharedFolders.find(f => f.forMobileApi === true);
      if (currentForMobileAPIFolder != null)
        await new FolderService(apiEndpoint()).setForMobileAPI({ folderId: currentForMobileAPIFolder.id, forMobileApi: false });
    }

    setSharedFolders(sharedFolders.map(f => ({ ...f, forMobileApi: f.id === folderId })));
  }

  const forMobileAPIFolder = sharedFolders.find(f => f.forMobileApi === true);

  const foldersOptions = [
    <option key='unspecified' value=''>unspecified</option>,
    ...sharedFolders.map(f => <option key={f.id} value={f.id}>{f.name}</option>)];

  const settingsSection = [<div key='mobile-api-folder' className={styles.settingsItem}>
    <span className={styles.settingsItemLabel}>Folder for Mobile API images</span>
    {user != null && user.isAdmin ?
      <select value={forMobileAPIFolder != null ? forMobileAPIFolder.id : ''}
        onChange={onForMobileAPIChanged}>
        {foldersOptions}
      </select> :
      <span>{forMobileAPIFolder != null ? forMobileAPIFolder.name : 'unspecified'}</span>
    }</div>];

  return <div className={styles.root}>
    <h1>Settings</h1>
    <div className={styles.settings}>{settingsSection}</div>
    <Link to='/admin'>Server Settings</Link>
    <h1>Label Types</h1>
    <LabelTypesList />
    <h1>User Profiles</h1>
    <ProfileList />
  </div>;
}
