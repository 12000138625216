// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import styles from './PdfOptionsDialog.module.css';
import type { FolderPdfOptions } from '../../model';
import Dropzone from 'react-dropzone';
import ToggleButton from 'react-toggle-button';
import { Check, X } from '../toggle-button/buttons';
import { imageTitleKind } from '../../utils/image';
import type {
  DetectEngine,
  Folder,
  FolderItem,
  FolderPDFTemplate,
  LabelType,
  PDFTemplate
} from "../../rpc/model";
import { FolderService } from "../../rpc/folders";
import { PDFService } from "../../rpc/pdf";
import { Col, ControlLabel, Form, FormControl, FormGroup } from "react-bootstrap";
import PdfTemplateView from "./PdfTemplateView";
import { LabelTypeService } from "../../rpc/label_types";
import { apiEndpoint, apiGet, apiPost } from "../../utils/http";
import { LabelTypes } from '../../model';

type PdfOptionsDialogProps = {
  folderId: string,
  children: Array<Folder>,
  selectedImages: Array<string>,
  engines: Array<DetectEngine>,
  close: (ok: boolean) => Promise<void>,
};

type ParsedFolderPdfTemplate = {
  template: FolderPDFTemplate,
  values: { [string]: string },
}

export default function PdfOptionsDialog(props: PdfOptionsDialogProps): Node {
  const [options, setOptions] = useState<?FolderPdfOptions>(null);
  const [images, setImages] = useState<{ [string]: Array<FolderItem> }>({});
  const [titleImagePreview, setTitleImagePreview] = useState<?string>(null);
  const [templates, setTemplates] = useState<Array<PDFTemplate>>([]);
  const [originalFolderTemplates, setOriginalFolderTemplates] = useState<Array<FolderPDFTemplate>>([]);
  const [folderTemplates, setFolderTemplates] = useState<Array<ParsedFolderPdfTemplate>>([]);
  const [labelTypes, setLabelTypes] = useState<Array<LabelType>>([]);

  useEffect(() => {
    async function fetchData() {
      const optionsResponse = await apiGet(`/api/folder/${props.folderId}/pdf-options`);
      const options = (optionsResponse.data: FolderPdfOptions);

      const nestedImagesResponse = await new FolderService(apiEndpoint()).nestedItems({ folderId: props.folderId });
      const nestedImages = nestedImagesResponse.items.filter(img => img.mime.startsWith("image/"));
      const images: { [string]: Array<FolderItem> } = {};
      const knownImageIDs = [];

      for (let i = 0; i < nestedImages.length; i++) {
        if (!props.children.some(f => f.id === nestedImages[i].folderId))
          continue;

        knownImageIDs.push(nestedImages[i].id);

        if (images[nestedImages[i].folderId] == null)
          images[nestedImages[i].folderId] = [];

        images[nestedImages[i].folderId].push(nestedImages[i]);
      }

      options.image_options = options.image_options.filter(o => knownImageIDs.indexOf(o.image_id) >= 0);

      if (props.selectedImages.length > 0) {
        options.image_options = options.image_options.map(
          o => ({ ...o, include_image: props.selectedImages.indexOf(o.image_id) >= 0 }));

        const missingSelectedImageIDs = props.selectedImages.filter(
          imageId => knownImageIDs.indexOf(imageId) >= 0 && !options.image_options.some(o => o.image_id === imageId));
        options.image_options.push(...missingSelectedImageIDs.map(imageId => ({
          image_id: imageId,
          include_image: true
        })));
      }

      setOptions(options);
      setImages(images);
    }

    fetchData();
  }, [props.folderId, props.children, props.selectedImages]);

  useEffect(() => {
    async function fetchTemplates() {
      const resp = await new PDFService(apiEndpoint()).templates();
      setTemplates(resp.templates);
    }

    fetchTemplates();
  }, []);

  useEffect(() => {
    async function fetchFolderTemplates() {
      const resp = await new PDFService(apiEndpoint()).folderTemplates({ folderId: props.folderId });
      setOriginalFolderTemplates(resp.templates);
      setFolderTemplates(resp.templates.map(t => ({
        template: t,
        values: t.values !== "" ? JSON.parse(t.values) : {}
      })));
    }

    fetchFolderTemplates();
  }, [props.folderId]);

  useEffect(() => {
    async function fetchLabelTypes() {
      const resp = await new LabelTypeService(apiEndpoint()).get();
      setLabelTypes(resp.labelTypes.filter(lt => lt.labelType === LabelTypes.common));
    }

    fetchLabelTypes();
  }, []);

  useEffect(() => {
    return () => {
      if (titleImagePreview != null)
        URL.revokeObjectURL(titleImagePreview);
    };
  }, [titleImagePreview]);

  function onTitleTextChanged(e: SyntheticInputEvent<HTMLTextAreaElement>) {
    if (options == null)
      return;

    setOptions({ ...options, title_text: e.target.value });
  }

  function onTitleImageDrop(acceptedFiles: Array<File>) {
    if (options == null || acceptedFiles.length === 0)
      return;

    setOptions({ ...options, title_image_file: acceptedFiles[0] });
    setTitleImagePreview(URL.createObjectURL(acceptedFiles[0]));
  }

  function onTitleImageDelete(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (options == null)
      return;

    setOptions({ ...options, title_image_file: null, title_image_id: '' });
    setTitleImagePreview(null);
  }

  async function generatePDF(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    await saveOptions();
    await saveFolderTemplates();
    props.close(true);
  }

  async function saveOptionsAndClose(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    await saveOptions();
    await saveFolderTemplates();
    props.close(false);
  }

  async function saveOptions() {
    if (options == null)
      return;

    const saveOptions = { ...options };
    const titleImageFile = saveOptions.title_image_file;
    delete saveOptions.title_image_file;

    const data = new FormData();
    data.append('options', JSON.stringify(saveOptions));
    if (titleImageFile != null)
      data.append('title-image-file', titleImageFile);
    await apiPost(`/api/folder/${props.folderId}/pdf-options`, data, { headers: { 'content-type': 'multipart/form-data' } });
  }

  async function saveFolderTemplates() {
    const deletedFolderTemplates = originalFolderTemplates
      .filter(oft => !folderTemplates.some(
        ft => ft.template.templateId === oft.templateId && ft.template.folderId === oft.folderId));

    const newFolderTemplates = folderTemplates
      .filter(ft => !originalFolderTemplates.some(
        oft => ft.template.templateId === oft.templateId && ft.template.folderId === oft.folderId))
      .map(ft => ({ ...ft.template, values: JSON.stringify(ft.values) }));

    const modifiedFolderTemplates = folderTemplates
      .filter(ft => originalFolderTemplates.some(
        oft => ft.template.templateId === oft.templateId && ft.template.folderId === oft.folderId && JSON.stringify(ft.values) !== oft.values))
      .map(ft => ({ ...ft.template, values: JSON.stringify(ft.values) }));

    await new PDFService(apiEndpoint()).saveFolderTemplates({
      deleted: deletedFolderTemplates,
      new: newFolderTemplates,
      modified: modifiedFolderTemplates,
    });
  }

  function cancel(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    props.close(false);
  }

  function onImageSelect(imageId: string) {
    if (options == null)
      return;

    const index = options.image_options.findIndex(o => o.image_id === imageId);
    if (index < 0) {
      setOptions({ ...options, image_options: [...options.image_options, { image_id: imageId, include_image: true }] });
    } else {
      setOptions({
        ...options,
        image_options: [
          ...options.image_options.slice(0, index),
          { ...options.image_options[index], include_image: !options.image_options[index].include_image },
          ...options.image_options.slice(index + 1)]
      });
    }
  }

  function onImageClick(e: SyntheticMouseEvent<HTMLDivElement>, imageId: string) {
    e.preventDefault();
    onImageSelect(imageId);
  }

  function onTemplateChanged(e: SyntheticKeyboardEvent<HTMLInputElement>, child: Folder) {
    const templateId = +e.currentTarget.value;
    if (templateId > 0) {
      setFolderTemplates(folderTemplates => [
        ...folderTemplates.filter(ft => ft.template.folderId !== child.id),
        { template: { folderId: child.id, templateId: templateId, values: "" }, values: {} }
      ]);
    } else {
      setFolderTemplates(folderTemplates => folderTemplates.filter(ft => ft.template.folderId !== child.id));
    }
  }

  function getTextControlValue(template: PDFTemplate, folderId: string, controlId: string): string {
    const folderTemplate = folderTemplates.find(ft => ft.template.templateId === template.id && ft.template.folderId === folderId);
    if (folderTemplate == null)
      return "";
    const value = folderTemplate.values[controlId];
    return value ?? "";
  }

  function getCheckControlValue(template: PDFTemplate, folderId: string, controlId: string): boolean {
    const folderTemplate = folderTemplates.find(ft => ft.template.templateId === template.id && ft.template.folderId === folderId);
    if (folderTemplate == null)
      return false;
    const value = folderTemplate.values[controlId];
    return value === "true";
  }

  function setTextControlValue(template: PDFTemplate, folderId: string, controlId: string, value: string) {
    let folderTemplate: ParsedFolderPdfTemplate = folderTemplates.find(ft => ft.template.templateId === template.id && ft.template.folderId === folderId) ??
      { template: { templateId: template.id, folderId: folderId, values: "" }, values: {} };
    folderTemplate = {
      ...folderTemplate,
      values: { ...folderTemplate.values, [controlId]: value }
    };
    setFolderTemplates(folderTemplates => [
      ...folderTemplates.filter(ft => ft.template.folderId !== folderId),
      folderTemplate
    ]);
  }

  function toggleCheckControlValue(template: PDFTemplate, folderId: string, controlId: string) {
    let folderTemplate: ParsedFolderPdfTemplate = folderTemplates.find(ft => ft.template.templateId === template.id && ft.template.folderId === folderId) ??
      { template: { templateId: template.id, folderId: folderId, values: "" }, values: {} };
    folderTemplate = {
      ...folderTemplate,
      values: {
        ...folderTemplate.values,
        [controlId]: folderTemplate.values[controlId] !== "true" ? "true" : "false"
      }
    };
    setFolderTemplates(folderTemplates => [
      ...folderTemplates.filter(ft => ft.template.folderId !== folderId),
      folderTemplate
    ]);
  }

  if (options == null)
    return null;

  return <div className={styles.root}>
    <div className={styles.item}>
      <div className={styles.itemLabel}>Title Text</div>
      <textarea className={styles.title} value={options.title_text}
        onChange={onTitleTextChanged} />
    </div>
    <div className={styles.item}>
      <div className={styles.itemLabel}>Title Image</div>
      <Dropzone accept='image/*' multiple={false} onDrop={onTitleImageDrop}>
        {({ getRootProps, getInputProps }) => {
          const rootProps = { ...getRootProps() };
          delete rootProps.tabIndex;
          return <div {...rootProps} className={styles.itemImage}>
            <input {...getInputProps()} />
            {titleImagePreview == null && options.title_image_id === '' ?
              <div>Select an image</div> :
              <div className={styles.itemImageDelete} onClick={onTitleImageDelete}>Delete the image</div>}
            {(titleImagePreview != null || options.title_image_id !== '') &&
            <img alt='preview' className={styles.itemPreview}
              src={titleImagePreview != null ?
                titleImagePreview :
                (apiEndpoint() + `/api/folder/${options.folder_id}/pdf-options/image/${options.title_image_id}`)}
            />}
          </div>;
        }}
      </Dropzone>
    </div>
    <div className={`${styles.item} ${styles.verticalItem}`}>
      {props.children.filter(child => !!child.itemId && images[child.id]).map(child => {
        const folderTemplate = folderTemplates.find(ft => ft.template.folderId === child.id);
        const template = folderTemplate != null ? templates.find(t => t.id === folderTemplate.template.templateId) : null;

        return <div className={styles.child} key={child.id}>
          <Form horizontal>
            <FormGroup controlId={"template-" + child.id}>
              <Col componentClass={ControlLabel} sm={2}>
                Template
              </Col>
              <Col sm={4}>
                <FormControl
                  componentClass="select"
                  value={template != null ? template.id : ""}
                  onChange={e => onTemplateChanged(e, child)}
                >
                  <option key="" />
                  {templates.map(t => <option key={t.id} value={t.id}>
                    {t.title}
                  </option>)}
                </FormControl>
              </Col>
            </FormGroup>
          </Form>
          {template != null && <PdfTemplateView
            template={template}
            labelTypes={labelTypes}
            getTextControlValue={(t, control) => getTextControlValue(t, child.id, control)}
            getCheckControlValue={(t, control) => getCheckControlValue(t, child.id, control)}
            setTextControlValue={(t, control, value) => setTextControlValue(t, child.id, control, value)}
            toggleCheckControlValue={(t, control) => toggleCheckControlValue(t, child.id, control)}
          />}
          <div className={styles.childTitle}>{child.name}</div>
          <div className={styles.images}>
            {images[child.id].map(img => {
              const imgClassName = img.width > img.height ? styles.imageHorizontal : styles.imageVertical;
              return <div key={img.id} className={styles.image} onClick={e => onImageClick(e, img.id)}>
                <img alt={img.name} className={imgClassName} src={apiEndpoint() + `/api/image/thumbnail/${img.thumbnailId}`} />
                <div className={styles.imageTitle}>{imageTitleKind(img, props.engines)}</div>
                <div className={styles.imageCheckbox}>
                  <ToggleButton
                    inactiveLabel={<X />}
                    activeLabel={<Check />}
                    value={options.image_options.some(o => o.image_id === img.id && o.include_image)}
                    onToggle={() => onImageSelect(img.id)} />
                </div>
              </div>;
            })}
          </div>
        </div>;
      })}
    </div>
    <div className={styles.buttons}>
      <button onClick={generatePDF}>
        Generate PDF
      </button>
      <button onClick={saveOptionsAndClose}>
        Save Selections
      </button>
      <button onClick={cancel}>
        Cancel
      </button>
    </div>
  </div>;
}
