// @flow strict
// Copyright (C) 2018-2019 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import React, { useEffect, useState } from 'react';
import type { Node } from "react";
import styles from './Admin.module.css';
import { Check, X } from '../toggle-button/buttons';
import ToggleButton from 'react-toggle-button';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import { ActivityService } from "../../rpc/activity";
import { apiEndpoint, apiGet, apiPost } from "../../utils/http";
import InputRange from 'react-input-range';

const currentTheme = localStorage.getItem('isDarkTheme');

type OAuthConfig = {
  provider: string,
  root_url: string,
  client_id: string,
  client_secret: string,
}

type DetectEngineConfig = {
  name: string,
  title: string,
  host: string,
  disabled: boolean,
  max_running_instances: number,
  retrain_server_url: string,
}

type Config = {
  external_address: string,
  oauth: OAuthConfig,
  administrators: Array<string>,
  detect_engines: Array<DetectEngineConfig>,
  thirdPartyApp_3DAnnot_url: string,
  show_thirdPartyApp_3DAnnot_url: boolean,
  logo_text: string,
  logo_url?: string,
  support_url: string,
  storefront_visibility: string,
  knowledge_base_url: string,
  userManual_url: string,
  videoTutorials_url: string,
  automatic_user_approval_period: number,
  google_map_api_key: string,
}

type AdminProps = {
  getConfigData: () => Promise<void>,
}

type Delta = {
  external_address?: string,
  oauth: $Shape<OAuthConfig>,
  detect_engines: Array<$Shape<DetectEngineConfig>>,
  administrators?: string,
  thirdPartyApp_3DAnnot_url?: string,
  show_thirdPartyApp_3DAnnot_url?: boolean,
  logo_text?: string,
  logo_url?: ?string,
  support_url?: string,
  storefront_visibility?: string,
  knowledge_base_url?: string,
  automatic_user_approval_period?: number,
  google_map_api_key?: string,
}

export default function Admin(props: AdminProps): Node {
  const [config, setConfig] = useState<?Config>(null);
  const [administrators, setAdministrators] = useState<string>("");
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [errors, setErrors] = useState<Array<string>>([]);
  const [logoImageFile, setLogoImageFile] = useState<?File>(null);
  const [logoImagePreview, setLogoImagePreview] = useState<?string>(null);
  const [delta, setDelta] = useState<Delta>({ oauth: {}, detect_engines: [] });

  useEffect(() => {
    new ActivityService(apiEndpoint()).visitPage({ pageUrl: window.location.pathname, innerBlock: "" });
  }, []);

  useEffect(() => {
    reloadConfig();
  }, []);

  useEffect(() => {
    return () => {
      if (logoImagePreview != null)
        URL.revokeObjectURL(logoImagePreview);
    };
  }, [logoImagePreview]);

  async function reloadConfig() {
    const resp = await apiGet('/api/config');
    setConfig({
      ...resp.data,
      logo_url: resp.data.logo_url ? `${resp.data.logo_url}?dummy=${+new Date()}` : resp.data.logo_url,
      detect_engines: resp.data.detect_engines != null ? resp.data.detect_engines : [],
    });
    setAdministrators(resp.data.administrators.join(' '));
    setLogoImageFile(null);
    setLogoImagePreview(null);
    setHasChanges(false);
    setDelta({
      oauth: {},
      detect_engines: resp.data.detect_engines != null ? resp.data.detect_engines.map(() => ({})) : [],
    });
  }

  function onProviderChanged(e: SyntheticInputEvent<HTMLInputElement>) {
    if (config == null)
      return;

    setConfig({ ...config, oauth: { ...config.oauth, provider: e.target.value } });
    setDelta({ ...delta, oauth: { ...delta.oauth, provider: e.target.value } });
    setHasChanges(true);
  }

  function onRootUrlChanged(e: SyntheticInputEvent<HTMLInputElement>) {
    if (config == null)
      return;

    setConfig({ ...config, oauth: { ...config.oauth, root_url: e.target.value } });
    setDelta({ ...delta, oauth: { ...delta.oauth, root_url: e.target.value } });
    setHasChanges(true);
  }

  function onClientIdChanged(e: SyntheticInputEvent<HTMLInputElement>) {
    if (config == null)
      return;

    setConfig({ ...config, oauth: { ...config.oauth, client_id: e.target.value } });
    setDelta({ ...delta, oauth: { ...delta.oauth, client_id: e.target.value } });
    setHasChanges(true);
  }

  function onClientSecretChanged(e: SyntheticInputEvent<HTMLInputElement>) {
    if (config == null)
      return;

    setConfig({ ...config, oauth: { ...config.oauth, client_secret: e.target.value } });
    setDelta({ ...delta, oauth: { ...delta.oauth, client_secret: e.target.value } });
    setHasChanges(true);
  }

  function onExternalAddressChanged(e: SyntheticInputEvent<HTMLInputElement>) {
    if (config == null)
      return;

    setConfig({ ...config, external_address: e.target.value });
    setDelta({ ...delta, external_address: e.target.value });
    setHasChanges(true);
  }

  function onAdministratorsChanged(e: SyntheticInputEvent<HTMLInputElement>) {
    setAdministrators(e.target.value);
    setDelta({ ...delta, administrators: e.target.value });
    setHasChanges(true);
  }

  function onThirdPartyApp3DAnnotURLChanged(e: SyntheticInputEvent<HTMLInputElement>) {
    if (config == null)
      return;

    setConfig({ ...config, thirdPartyApp_3DAnnot_url: e.target.value });
    setDelta({ ...delta, thirdPartyApp_3DAnnot_url: e.target.value });
    setHasChanges(true);
  }

  function onLogoTextChanged(e: SyntheticInputEvent<HTMLInputElement>) {
    if (config == null)
      return;

    setConfig({ ...config, logo_text: e.target.value });
    setDelta({ ...delta, logo_text: e.target.value });
    setHasChanges(true);
  }

  function onEngineTitleChanged(e: SyntheticInputEvent<HTMLInputElement>, index: number) {
    if (config == null)
      return;

    setConfig({
      ...config,
      detect_engines: [
        ...config.detect_engines.slice(0, index),
        { ...config.detect_engines[index], title: e.target.value },
        ...config.detect_engines.slice(index + 1),
      ]
    });

    setDelta({
      ...delta,
      detect_engines: [
        ...delta.detect_engines.slice(0, index),
        { ...delta.detect_engines[index], title: e.target.value },
        ...delta.detect_engines.slice(index + 1),
      ]
    });
    setHasChanges(true);
  }

  function onEngineHostChanged(e: SyntheticInputEvent<HTMLInputElement>, index: number) {
    if (config == null)
      return;

    setConfig({
      ...config,
      detect_engines: [
        ...config.detect_engines.slice(0, index),
        { ...config.detect_engines[index], host: e.target.value },
        ...config.detect_engines.slice(index + 1),
      ]
    });

    setDelta({
      ...delta,
      detect_engines: [
        ...delta.detect_engines.slice(0, index),
        { ...delta.detect_engines[index], host: e.target.value },
        ...delta.detect_engines.slice(index + 1),
      ]
    });

    setHasChanges(true);
  }

  function onEngineRetrainServerURLChanged(e: SyntheticInputEvent<HTMLInputElement>, index: number) {
    if (config == null)
      return;

    setConfig({
      ...config,
      detect_engines: [
        ...config.detect_engines.slice(0, index),
        { ...config.detect_engines[index], retrain_server_url: e.target.value },
        ...config.detect_engines.slice(index + 1),
      ]
    });

    setDelta({
      ...delta,
      detect_engines: [
        ...delta.detect_engines.slice(0, index),
        { ...delta.detect_engines[index], retrain_server_url: e.target.value },
        ...delta.detect_engines.slice(index + 1),
      ]
    });

    setHasChanges(true);
  }

  function onEngineDisabledChanged(index: number) {
    if (config == null)
      return;

    setConfig({
      ...config,
      detect_engines: [
        ...config.detect_engines.slice(0, index),
        { ...config.detect_engines[index], disabled: !config.detect_engines[index].disabled },
        ...config.detect_engines.slice(index + 1),
      ]
    });

    setDelta({
      ...delta,
      detect_engines: [
        ...delta.detect_engines.slice(0, index),
        { ...delta.detect_engines[index], disabled: !config.detect_engines[index].disabled },
        ...delta.detect_engines.slice(index + 1),
      ]
    });

    setHasChanges(true);
  }

  function onStorefrontVisibilityChanged(e: SyntheticInputEvent<HTMLInputElement>) {
    if (config == null)
      return;

    setConfig({ ...config, storefront_visibility: e.target.value });
    setDelta({ ...delta, storefront_visibility: e.target.value });
    setHasChanges(true);
  }

  function onShowThirdPartyApp3DAnnotURLChanged() {
    if (config == null)
      return;

    const showThirdPartyApp_3DAnnot_url = config.show_thirdPartyApp_3DAnnot_url;
    setConfig({ ...config, show_thirdPartyApp_3DAnnot_url: !showThirdPartyApp_3DAnnot_url });
    setDelta({ ...delta, show_thirdPartyApp_3DAnnot_url: !showThirdPartyApp_3DAnnot_url });
    setHasChanges(true);
  }

  function onSupportUrlChanged(e: SyntheticInputEvent<HTMLInputElement>) {
    if (config == null)
      return;

    setConfig({ ...config, support_url: e.target.value });
    setDelta({ ...delta, support_url: e.target.value });
    setHasChanges(true);
  }

  function onKnowledgeBaseURLChanged(e: SyntheticInputEvent<HTMLInputElement>) {
    if (config == null)
      return;

    setConfig({ ...config, knowledge_base_url: e.target.value });
    setDelta({ ...delta, knowledge_base_url: e.target.value });
    setHasChanges(true);
  }

  function onEngineMaxRunningInstancesChanged(e: SyntheticInputEvent<HTMLSelectElement>, index: number) {
    if (config == null)
      return;

    setConfig({
      ...config,
      detect_engines: [
        ...config.detect_engines.slice(0, index),
        { ...config.detect_engines[index], max_running_instances: +e.target.value },
        ...config.detect_engines.slice(index + 1),
      ]
    });
    setDelta({
      ...delta,
      detect_engines: [
        ...delta.detect_engines.slice(0, index),
        { ...delta.detect_engines[index], max_running_instances: +e.target.value },
        ...delta.detect_engines.slice(index + 1),
      ]
    });
    setHasChanges(true);
  }

  function onUserManualUrlChanged(e: SyntheticInputEvent<HTMLInputElement>) {
    if (config == null)
      return;

    setConfig({ ...config, userManual_url: e.target.value });
    setDelta({ ...delta, userManual_url: e.target.value });
    setHasChanges(true);
  }

  function onVideoTutorialsUrlChanged(e: SyntheticInputEvent<HTMLInputElement>) {
    if (config == null)
      return;

    setConfig({ ...config, videoTutorials_url: e.target.value });
    setDelta({ ...delta, videoTutorials_url: e.target.value });
    setHasChanges(true);
  }

  async function onApplyChangesClick() {
    const data = new FormData();
    data.append('delta', JSON.stringify(delta));
    if (logoImageFile != null)
      data.append('logo-image', logoImageFile);

    const request = await apiPost('/api/config', data, { headers: { 'content-type': 'multipart/form-data' } });
    const applyErrors = request.data.errors;
    setErrors(applyErrors);
    if (applyErrors.length === 0) {
      props.getConfigData();
      await reloadConfig();
    }
  }

  async function onRevertChangesClick() {
    await reloadConfig();
    setErrors([]);
  }

  function onLogoImageDrop(acceptedFiles: Array<File>) {
    if (acceptedFiles.length === 0)
      return;

    setLogoImageFile(acceptedFiles[0]);
    setLogoImagePreview(URL.createObjectURL(acceptedFiles[0]));
    setHasChanges(true);
  }

  function onLogoImageDelete(e: SyntheticMouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (config == null)
      return;

    setConfig({ ...config, logo_url: '' });
    setDelta({ ...delta, logo_url: '' });
    setLogoImageFile(null);
    setLogoImagePreview(null);
    setHasChanges(true);
  }

  function onAutomaticUserApprovalPeriodChanged(value: number) {
    if (config == null)
      return;

    setConfig({
      ...config,
      automatic_user_approval_period: value,
    });
    setDelta({
      ...delta,
      automatic_user_approval_period: value,
    });
    setHasChanges(true);
  }

  function onGoogleMapAPIKeyChanged(e: SyntheticInputEvent<HTMLInputElement>) {
    if (config == null)
      return;

    setConfig({
      ...config,
      google_map_api_key: e.target.value,
    });
    setDelta({
      ...delta,
      google_map_api_key: e.target.value,
    });
    setHasChanges(true);
  }

  if (config == null)
    return null;

  const enginesRows = config.detect_engines.map((engine, i) => (
    <tr key={engine.name}>
      <td>{engine.name}</td>
      <td><input className={styles.enginesTitle} type='text' value={engine.title}
        onChange={e => onEngineTitleChanged(e, i)} /></td>
      <td>
        <ToggleButton
          inactiveLabel={<X />}
          activeLabel={<Check />}
          value={engine.disabled === false}
          onToggle={() => onEngineDisabledChanged(i)} />
      </td>
      <td><input className={styles.enginesHost} type='text' value={engine.host}
        onChange={e => onEngineHostChanged(e, i)} /></td>
      <td><select style={{ color: "black" }} value={engine.max_running_instances}
        onChange={e => onEngineMaxRunningInstancesChanged(e, i)}>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option>
      </select></td>
    </tr>
  ));
  const enginesTable = <table className={styles.engines}>
    <thead>
    <tr>
      <th>Name</th>
      <th>Title</th>
      <th>Enabled</th>
      <th>Host</th>
      <th>Max Instances</th>
    </tr>
    </thead>
    <tbody>
    {enginesRows}
    </tbody>
  </table>;

  const retrainRows = config.detect_engines.map((engine, i) => (
    <tr key={engine.name}>
      <td>{engine.name}</td>
      <td><input className={styles.retrainHost} type='text' value={engine.retrain_server_url}
        onChange={e => onEngineRetrainServerURLChanged(e, i)} /></td>
    </tr>
  ));
  const retrainTable = <table className={styles.engines}>
    <thead>
    <tr>
      <th>Name</th>
      <th>Retrain Server URL</th>
    </tr>
    </thead>
    <tbody>
    {retrainRows}
    </tbody>
  </table>;

  return <div className={styles.root}>
    <div className={styles.errors}>
      {errors.map((msg, i) => <div key={i}>{msg}</div>)}
    </div>
    <table>
      <tbody>
      <tr>
        <td>External Address</td>
        <td><input className={styles.input} type='text' value={config.external_address}
          onChange={onExternalAddressChanged} /></td>
      </tr>
      </tbody>
    </table>
    <div className={styles.header}>OAuth Settings</div>
    <table>
      <tbody>
      <tr>
        <td>Provider</td>
        <td>
          <select className={styles.input} value={config.oauth.provider}
            onChange={onProviderChanged}>
            <option value='auth0' key='auth0'>Auth0</option>
            <option value='wordpress' key='wordpress'>WordPress</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Root URL</td>
        <td><input className={styles.input} type='text' value={config.oauth.root_url}
          onChange={onRootUrlChanged} /></td>
      </tr>
      <tr>
        <td>Client ID</td>
        <td><input className={styles.input} type='text' value={config.oauth.client_id}
          onChange={onClientIdChanged} /></td>
      </tr>
      <tr>
        <td>Client Secret</td>
        <td><input className={styles.input} type='text' value={config.oauth.client_secret}
          onChange={onClientSecretChanged} /></td>
      </tr>
      </tbody>
    </table>
    <div className={styles.header}>Administrators</div>
    <input className={styles.administrators} type='text' value={administrators}
      onChange={onAdministratorsChanged} />
    <div className={styles.header}>Detect Engines</div>
    {enginesTable}
    <Link to='/admin/engine-rights'>User Access</Link>
    <div className={styles.header}>Retrain Servers</div>
    {retrainTable}
    <div className={styles.header}>3D Annotation</div>
    <div className={styles.threeDAnnot}>
      <input className={styles.threeDAnnotUrl} type='text' value={config.thirdPartyApp_3DAnnot_url}
        onChange={onThirdPartyApp3DAnnotURLChanged} />
      <ToggleButton
        inactiveLabel={<X />}
        activeLabel={<Check />}
        value={config.show_thirdPartyApp_3DAnnot_url}
        onToggle={() => onShowThirdPartyApp3DAnnotURLChanged()} />
    </div>
    <div className={styles.header}>Logo</div>
    <input className={styles.logoText} type='text' value={config.logo_text}
      onChange={onLogoTextChanged} />
    <Dropzone accept='image/*' multiple={false} onDrop={onLogoImageDrop}>
      {({ getRootProps, getInputProps }) => {
        const rootProps = { ...getRootProps() };
        delete rootProps.tabIndex;
        return <div {...rootProps} className={styles.logoImage}>
          <input {...getInputProps()} />
          {logoImagePreview == null && !config.logo_url ?
            <div style={currentTheme === "dark" ? { color: "rgb(132, 230, 255)" } : {}}>Select an image</div> :
            <div className={styles.logoImageDelete} onClick={onLogoImageDelete}>Delete the image</div>}
          {(logoImagePreview != null || config.logo_url) &&
          <img alt='preview' className={styles.logoImagePreview}
            src={logoImagePreview != null ? logoImagePreview : config.logo_url} />}
        </div>;
      }}
    </Dropzone>
    <div className={styles.header}>Storefront</div>
    <div>
      <div>
        <span>Visibility </span>
        <select className={styles.input} value={config.storefront_visibility}
          onChange={onStorefrontVisibilityChanged}>
          <option value="hidden" key="hidden">Hidden</option>
          <option value="protected" key="protected">Protected (visible for authenticated users)</option>
          <option value="public" key="public">Public (visible for anybody)</option>
        </select>
      </div>
      <Link to='/storefront/rights'>User Access for Apps</Link>
    </div>
    <div className={styles.header}>Support URL</div>
    <div className={styles.threeDAnnot}>
      <input className={styles.threeDAnnotUrl} type='text'
        value={config.support_url}
        onChange={onSupportUrlChanged} />
    </div>
    <div className={styles.header}>User Manual URL</div>
    <div className={styles.threeDAnnot}>
      <input className={styles.threeDAnnotUrl} type='text'
        value={config.userManual_url}
        onChange={onUserManualUrlChanged} />
    </div>
    <div className={styles.header}>Video Tutorials URL</div>
    <div className={styles.threeDAnnot}>
      <input className={styles.threeDAnnotUrl} type='text'
        value={config.videoTutorials_url}
        onChange={onVideoTutorialsUrlChanged} />
    </div>
    <div className={styles.header}>Knowledge Base URL</div>
    <div className={styles.threeDAnnot}>
      <input className={styles.threeDAnnotUrl} type='text'
        value={config.knowledge_base_url}
        onChange={onKnowledgeBaseURLChanged} />
    </div>
    <div className={styles.header}>Automatic User Approval Period (days)</div>
    <div className={styles.rangeContainer}>
      <div className={styles.range}>
        <InputRange
          value={+config.automatic_user_approval_period}
          minValue={0}
          maxValue={100}
          step={1}
          onChange={onAutomaticUserApprovalPeriodChanged}
        />
      </div>
      <span className={styles.rangeValue}>{config.automatic_user_approval_period > 0 ? config.automatic_user_approval_period + ` day${config.automatic_user_approval_period > 1 ? 's' : ''}` : 'disabled'}</span>
    </div>
    <div className={styles.header}>Google MAP API Key</div>
    <div className={styles.threeDAnnot}>
      <input className={styles.threeDAnnotUrl} type='text'
        value={config.google_map_api_key}
        onChange={onGoogleMapAPIKeyChanged} />
    </div>

    <div className={styles.header}>
      <Link to='/admin/api-keys'>API Keys</Link>
    </div>

    <div className={styles.buttons}>
      <button className={styles.button} disabled={!hasChanges}
        onClick={onApplyChangesClick}>Apply Changes
      </button>
      <button className={styles.button} disabled={!hasChanges}
        onClick={onRevertChangesClick}>Revert Changes
      </button>
    </div>
  </div>;
}
